import { FlatList, StyleSheet, Text, View } from "react-native";
import ChatRoom from "./Room";
import { SwipeListView } from "react-native-swipe-list-view";
import { GlobalStyles } from "../../constant/styles";
import { deleteChatRooms, fetchUsers } from "../../util/http";
import { useContext, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TouchableOpacity } from "react-native-gesture-handler";
import { verifyDelete } from "../../util/verifyDelete";
import { UsersContext } from "../../store/user-context";
import LoadingOverlay from "../UI/LoadingOverlay";

function renderChatRoomsItem(itemData) {
  return <ChatRoom {...itemData.item} />;
}

function ChatRoomsList({ chatRooms }) {
  const [isAdmin, setIsAdmin] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isUserInfoReady, setIsUserInfoReady] = useState(false);
  const navigation = useNavigation();
  const [roomList, setRoomList] = useState([]);

  const userCtx = useContext(UsersContext);
  async function fetchRole() {
    const storedRole = await AsyncStorage.getItem("role");
    switch (storedRole) {
      case "admin":
        setIsAdmin(true);
        break;
      case "manager":
        setIsAdmin(true);
        break;
      default:
        setIsAdmin(false);
    }
  }
  fetchRole();

  useEffect(() => {
  async function getUsersList() {
    try {
      const userList = await fetchUsers();
      userCtx.setUser(userList);
      setIsUserInfoReady(true);
    } catch (error) {
    }
  }
  async function chatRoomsHandler(chatRoomsData) {
    const storedUID = await AsyncStorage.getItem("uid");

    chatRoomsData.forEach((data)=>{
      // console.log(data.userId);
      if (data.userId.length <= 2  && data.roomName.indexOf(" と ") > 0){
      const chatToId = data.userId.filter((userId) => userId !==  storedUID);
      const interInfo = userCtx.users.find((user) => user.userId == chatToId);        
        // console.log(interInfo);
        data.roomName = interInfo.userName;
        data.roomImage = interInfo.userImage? interInfo.userImage:null;
    }});
    setRoomList(chatRooms);
    setIsFetching(false);

  }
  getUsersList();
  // console.log(userCtx.length);
  if(isUserInfoReady){
  chatRoomsHandler(chatRooms);}
  
  }, [navigation,isUserInfoReady]);

  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...roomList];
    const prevIndex = roomList.findIndex((item) => item.id === rowKey);
    newData.splice(prevIndex, 1);
    setRoomList(newData);
  };

  const renderHiddenItem = (itemData, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => {
          navigation.navigate("ChatFormStack", {
            screen: "ManageChatRoom",
            params: {
              id: itemData.item.id,
              roomId: itemData.item.roomId,
              roomName: itemData.item.roomName,
            },
          });
          closeRow(rowMap, itemData.item.id);
        }}
      >
        <Text style={styles.backTextWhite}>編集</Text>
      </TouchableOpacity>
      {isAdmin && (
        <TouchableOpacity
          style={[styles.backRightBtn, styles.backRightBtnRight]}
          onPress={async () => {
            const confirmDel = await verifyDelete("チャットルームの削除");
            if (confirmDel) {
              deleteChatRooms(itemData.item.roomId);
              deleteRow(rowMap, itemData.item.id);
            }
          }}
        >
          <Text style={styles.backTextWhite}>削除</Text>
        </TouchableOpacity>
      )}
    </View>
  );
  const convertSqlToDate = (dateIn) => {
    const timeSplit = dateIn.split(" ");
    const day = timeSplit[0].split("-");
    const time = timeSplit[1].split(":");
    const formatedDate = new Date(
      day[0],
      day[1] - 1,
      day[2],
      time[0],
      time[1],
      time[2]
    );
    return formatedDate;
  };
  return (
    <>
      {/* {isAdmin && ( */}
     {!isFetching ? (<SwipeListView
        useFlatList={true}
        data={roomList.sort((a, b) => {
          const dateA = convertSqlToDate(a.roomLastestTime);
          const dateB = convertSqlToDate(b.roomLastestTime);
          return dateB - dateA;
        })
      }
        renderItem={renderChatRoomsItem}
        keyExtractor={(item) => item.id}
        renderHiddenItem={renderHiddenItem}
        rightOpenValue={-150}
        onRowDidOpen={onRowDidOpen}
        disableRightSwipe={true}
        closeOnScroll={true}
      />):null}
      {/* )} */}
      {/* {!isAdmin && (
        <FlatList
          data={roomList.sort((a, b) => {
            const dateA = convertSqlToDate(a.roomLastestTime);
            const dateB = convertSqlToDate(b.roomLastestTime);
            return dateB - dateA;
          })}
          renderItem={renderChatRoomsItem}
          keyExtractor={(item) => item.id}
        />
      )} */}
    </>
  );
}
export default ChatRoomsList;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16,
  },
  rowFront: {
    alignItems: "center",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    height: 50,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
  },
});
