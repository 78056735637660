import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { FlatList } from "react-native";
import DailyReportItem from "./DailyReportItem";

// const saveItemStatus = async (itemId, isRead) => {
//   try {
//     await AsyncStorage.setItem(`@itemStatus_${itemId}`, isRead.toString());
//   } catch (error) {
//     console.error(error);
//   }
// };
// const onToggleRead = async (item) => {
//   setData((prevData) => {
//     const newData = prevData.map(d => {
//       if (d === item) {
//         const isRead = !d.isRead;
//         saveItemStatus(d.id, isRead);
//         return { ...d, isRead };
//       }
//       return d;
//     });
//     return newData;
//   });
// };
function DailyReportsList({ dailyReports, roomId, roomName }) {
  const [data, setData] = useState([]);
  const [firstUnreadIndex, setFirstUnreadIndex] = useState();
  const navigation = useNavigation();
  let readId = [];
  let readIdNew = [];

  const flatListRef = useRef(null);
  useEffect(() => {
    const loadData = async () => {
      try {
        const items = await AsyncStorage.getItem(`@itemStatus_${roomId}`);
        if (items) {
          console.log("read data");
          readId = items;
          dailyReports.map((dailyReport) =>
            readIdNew.push(dailyReport.dailyreportId)
          );
          await AsyncStorage.setItem(
            `@itemStatus_${roomId}`,
            JSON.stringify(readIdNew)
          );
          // setData(JSON.parse(items));
        } else {
          // dailyReports.map((dailyReport) => (console.log(dailyReport.dailyreportId)));
          console.log("mem new data");
          dailyReports.map((dailyReport) =>
            readId.push(dailyReport.dailyreportId)
          );

          await AsyncStorage.setItem(
            `@itemStatus_${roomId}`,
            JSON.stringify(readId)
          );
        }
        const alreadyReadDailyReport = dailyReports.map(
          (reportItem) => reportItem.dailyreportId
        );
        const getUnread = alreadyReadDailyReport.filter(
          (readItem) => !readId.includes(readItem)
        );
        const unreadIndex = dailyReports.findIndex(
          (item) => item.dailyreportId === Math.min(...getUnread)
        );
        // const unreadIndex = dailyReports.findIndex((item) =>
        //   readId.find((readitem) => item.dailyreportId !== readitem)
        // );
        console.log("unreadIndex");
        console.log(unreadIndex);
        setFirstUnreadIndex(unreadIndex);
        if (unreadIndex > 0) {
          jumpToIndex(unreadIndex);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadData();
  }, [navigation]);

  const jumpToIndex = (index) => {
    console.log("jumpToIndex");
    console.log(index);
    flatListRef.current.scrollToIndex({
      index: index - 1,
      animated: true,
    });
  };
  // console.log(dailyReports.findIndex(item => readId.find(readitem=> item.dailyreportId!==readitem)));
  function renderDailyReportsItem({ item, index }) {
    // console.log(item);
    // console.log(index);
    // // console.log(firstUnreadIndex === itemData.id);
    return (
      <DailyReportItem
        {...item}
        isFirstUnread={firstUnreadIndex === index && index > 0}
        roomName={roomName}
      />
    );
  }
  // const getItemLayout = (data, index) => ({
  //   index
  // });
  const onScrollToIndexFailed = (info) => {
    console.log(info);
  };
  return (
    <FlatList
      // data={dailyReports}
      ref={flatListRef}
      data={dailyReports.sort(function (a, b) {
        return a.dailyreportCreatedTimestamp > b.dailyreportCreatedTimestamp
          ? -1
          : a.dailyreportCreatedTimestamp < b.dailyreportCreatedTimestamp
          ? 1
          : 0;
      })}
      inverted
      renderItem={renderDailyReportsItem}
      keyExtractor={(item) => item.id}
      removeClippedSubviews={true}
      initialNumToRender={10}
      // getItemLayout={getItemLayout}
      onScrollToIndexFailed={onScrollToIndexFailed}
    />
  );
}

export default DailyReportsList;
