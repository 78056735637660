import { useContext, useState } from "react";
import {
  Alert,
  KeyboardAvoidingView,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import AuthContent from "../component/Auth/AuthContent";
import Button from "../component/UI/Button";
import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { AuthContext } from "../store/auth-context";
import { createUser } from "../util/auth";

function SignupScreen({ navigation }) {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isUserIdValid, setIsUserIdValid] = useState(false);
  const [isUserRoleValid, setIsUserRoleValid] = useState(false);
  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);

  const authCtx = useContext(AuthContext);

  async function signupHandler({ username, password, userRole }) {
    setIsAuthenticating(true);
    try {
      const [token, userrole, userID, pushID, userName] = await createUser(
        username,
        password,
        userRole
      );
      setIsTokenValid(typeof token !== "undefined");
      setIsUserIdValid(typeof userrole !== "undefined");
      setIsUserRoleValid(typeof userID !== "undefined");
      setIsAuthenticating(false);
    } catch (error) {
      // console.log(error);
      Alert.alert(
        "アカウント登録が失敗しました",
        "アカウント登録ができません。 記入した情報を確認して、再送信してください."
      );
      setIsAuthenticating(false);
    }

    if (isTokenValid && isUserIdValid && isUserRoleValid) {
      Alert.alert("アカウント登録状態", "アカウント登録が完成しました", [
        {
          text: "前のページへ戻る",
          onPress: () => navigation.goBack(),
        },
      ]);
    }
  }

  if (isAuthenticating) {
    return <LoadingOverlay message="Creating user..." />;
  }

  return (
    <ScrollView>
      <View style={styles.backIconContainer}>
        {/* <IconButton
            icon="chevron-back-outline"
            size={24}
            color={GlobalStyles.colors.primary600}
            onPress={() => navigation.goBack()}
            styleButtonContainer={styles.buttonBox}
            label={`キャンセル`}
          /> */}
        <Button
          style={styles.button}
          mode="flat"
          onPress={() => navigation.goBack()}
          styleText={styles.buttonText}
        >
          キャンセル
        </Button>
      </View>
      <AuthContent onAuthenticate={signupHandler} />
    </ScrollView>
  );
}

export default SignupScreen;

const styles = StyleSheet.create({
  backIconContainer: {
    position: "absolute",
    top: "8%",
    left: "1%",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonBox: { marginRight: 2, zIndex: 1001 },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },
  buttonText: {
    fontSize: 18,
    color: GlobalStyles.colors.primary600,
  },
});
