import { Platform, PixelRatio, Dimensions, useWindowDimensions } from 'react-native';
// import ExtraDimensions from 'react-native-extra-dimensions-android'
// import DeviceInfo from 'react-native-device-info';
// import { isIphoneX } from 'react-native-iphone-x-helper'
export const getscaledDimension = () =>{
    const {height, width} = useWindowDimensions(); // import useWindowDimensions()
    const guidelineBaseWidth = 828; //standard width which will be used as base for calculating the scale.
    const guidelineBaseHeight = 1792; //standard height which will be used as base for calculating the scale.
    let ratio = 1;
    const height_ratio = height/guidelineBaseHeight+0.2;
    const width_ratio = (width/guidelineBaseWidth)+0.4;

    return {hieghtScale:height_ratio,fontScale:width_ratio};
}
// export const getscaledDimension = (type = 'height'|'width' |'font') =>{
//     const {height, width} = useWindowDimensions(); // import useWindowDimensions()
//     const guidelineBaseWidth = 828; //standard width which will be used as base for calculating the scale.
//     const guidelineBaseHeight = 1792; //standard height which will be used as base for calculating the scale.
//     let ratio = 1;
//     switch (type){
//         case'height':
//             ratio = height/guidelineBaseHeight;
//             break;
//         case'width':
//         case'font':
//             ratio = (width/guidelineBaseWidth)+0.4;
//             break;
//     }
//     return ratio;
// }
    //     return this.screenSize.width / this.guidelineBaseWidth;
    //   }
//   const { width, height } = Dimensions.get("window");
// export default class DeviceUiInfo {
//   static platform = Platform.OS;  //gives the device platform iOS or Android
//   static screenSize = { width, height };  //gives the width & height of device
//   static screenSizeWithPixelRatio = { width: width * PixelRatio.get(), height: height * PixelRatio.get() }; //calculate the width & height based on device pixel ratio
//   static guidelineBaseWidth = 828; //standard width which will be used as base for calculating the scale.
//   static guidelineBaseHeight = 1792; //standard height which will be used as base for calculating the scale.
// //   static isIphoneX = isIphoneX() //check if device is iPhoneX
// //   static isTablet = DeviceInfo.isTablet() //check if device is Tablet
// //   static appVersion = DeviceInfo.getVersion();  //gives app version
// //   static softBarHeight = ExtraDimensions.get('SOFT_MENU_BAR_HEIGHT'); //gives soft menu bar height
// //   static statusBarHeight = ExtraDimensions.get('STATUS_BAR_HEIGHT');  //gives status bar height
//   static fontScale = PixelRatio.getFontScale()  //gives font scale based on pixel ratio
  
//   static getPlatform() {
//     return this.platform
//   }
//   static getScreenSize() {
//     return this.screenSize
//   }
//   static getScreenSizeWithPixelRatio() {
//     return this.screenSizeWithPixelRatio
//   }
// //   static isIphoneX() {
// //     return this.isIphoneX
// //   }
//   static scale() {
//     return this.screenSize.width / this.guidelineBaseWidth;
//   }
//   static verticalScale(size) {
//     return (this.screenSize.height) / this.guidelineBaseHeight * size;
//   }
//   static moderateScale(size, factor = 0.5) {
//     return size + (this.scale(size) - size) * factor;
//   }
//   static actualScale(size) {
//     const inputSize = DeviceUiInfo.moderateScale(size)
//     return inputSize / this.fontScale
//   }
// }