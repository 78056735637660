import { View, TextInput, StyleSheet, Text, Alert } from "react-native";

import { useState } from "react";
import Button from "../UI/Button";
import { getFormattedDate } from "../../util/date";
import { GlobalStyles } from "../../constant/styles";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import UserOutput from "../User/UserOutput";
import Input from "../DailyReport/Input";
import ImagePicker from "../DailyReport/ImagePicker";
function RoomForm({
  submitButtonLabel,
  onCancel,
  onSubmit,
  defaultValues,
  users,
  defaultUser,
}) {
  //   defaultUser.roomId !== nulltypeof;
  const isDefined = typeof defaultUser === "undefined" || defaultUser === null;
  const [isAdmin, setIsAdmin] = useState(true);
  const [userType, setUserType] = useState("PERSON");
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      if (storedRole === "store") {
        setUserType("STORE");
      } else {
        setUserType("PERSON");
      }
      switch (storedRole) {
        case "admin":
          setIsAdmin(true);
          break;
        case "manager":
          setIsAdmin(true);
          break;
        default:
          setIsAdmin(false);
      }
    }
    fetchRole();
  }, []);
  const [inputs, setInput] = useState({
    roomName: {
      value: defaultValues ? defaultValues.roomName : "",
      isValid: true,
    },
    roomImage: {
      value: defaultValues ? defaultValues.roomImage : "",
      isValid: true,
    },
    userId: {
      value: defaultValues ? defaultValues.userId : Array(),
      isValid: true,
    },
  });
  //   console.log(inputs.userId.value);
  const ref_member = isDefined ? Array() : defaultValues.userId;
  function inputChangedHandler(inputIdentifier, enteredValue) {
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      };
    });
  }
  function submitHandler() {
    // let intersection = inputs.userId.value.filter((x) =>
    //   ref_member.includes(x)
    // );
    // console.log(inputs.userId.value);
    // console.log(intersection);
    let difference_for_delete = ref_member.filter(
      (x) => !inputs.userId.value.includes(x)
    );
    // console.log(difference_for_delete);
    const roomData = {
      roomName: inputs.roomName.value,
      roomImage: inputs.roomImage.value,
      userId: inputs.userId.value,
      addUserId: inputs.userId.value,
      deleteUserId: difference_for_delete,
    };
    const roomNameIsValid = roomData.roomName.trim().length > 0;

    if (!roomNameIsValid) {
      // show feedback
      //   Alert.alert("Invalid input", "Please check your input values");
      setInput((curInputs) => {
        return {
          roomName: {
            value: curInputs.roomName.value,
            isValid: roomNameIsValid,
          },
        };
      });
      return;
    }
    onSubmit(roomData);
  }
  const formIsInvalid = !inputs.roomName.isValid;

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function onChecktest(userData) {
    if (userData.isSelected) {
      inputs.userId.value.push(userData.selectedUserId); // userIdArray=
    } else {
      inputs.userId.value = inputs.userId.value.filter(
        (e) => e !== userData.selectedUserId
      );
    }
    inputs.userId.value.filter(onlyUnique);
  }

  function onTakenImageHandler(onTakenImage) {
    console.log(onTakenImage);
    inputChangedHandler("roomImage", onTakenImage);
  }

  return (
    <View style={styles.form}>
      <Text style={styles.title}>{"日報レポートグループ作成"}</Text>
      <Input
        label="グループ名"
        invalid={!inputs.roomName.isValid}
        textInputConfig={{
          onChangeText: inputChangedHandler.bind(this, "roomName"),
          value: inputs.roomName.value,
          color: GlobalStyles.colors.primary700,
          marginBottom: 8,
          paddingLeft: 12,
          placeholder: "グループ名を記入してください",
        }}
        styleLabel={{ fontSize: 16, marginBottom: 12 }}
      />
      <ImagePicker
        textInput="グループ画像を追加"
        onTakenImage={onTakenImageHandler}
      />
      {isAdmin && (
        <UserOutput
          users={users}
          fallbackText="No Users registered. "
          onCheck={onChecktest}
          defaultUser={defaultUser}
          isMemberlist={false}
        />
      )}

      {formIsInvalid && (
        <Text style={styles.errorText}>グループ名型の無効な入力構文</Text>
      )}

      <View style={styles.buttons}>
        <Button
          style={styles.button}
          mode="flat"
          onPress={onCancel}
          styleText={styles.buttonText}
        >
          キャンセル
        </Button>
        <Button
          style={styles.button}
          onPress={submitHandler}
          styleButton={{ backgroundColor: GlobalStyles.colors.primary700 }}
          styleText={styles.buttonText}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </View>
  );
}
export default RoomForm;

const styles = StyleSheet.create({
  form: { flex: 1 },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    marginVertical: 12,
    textAlign: "center",
  },
  inputsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowInput: {
    flex: 1,
  },
  errorText: {
    textAlign: "center",
    color: GlobalStyles.colors.error500,
    margin: 8,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },
  userList: {
    borderRadius: 20,
    backgroundColor: GlobalStyles.colors.neutral10,
  },
  buttonText: {
    fontSize: 18,
  },
});
