import { FlatList, StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import UserAuthItem from "./UserAuthItem";

function UserAuthList({ userAuths, onSetting }) {
  function renderUesrsItem(itemData) {
    return <UserAuthItem {...itemData.item} onSetting={onSetting} />;
  }
  return (
    <FlatList
      data={userAuths}
      renderItem={renderUesrsItem}
      keyExtractor={(item) => item.id}
      ListHeaderComponent={() => (
        <View style={styles.userListItem}>
          <View style={styles.idContainer}>
            <Text style={[styles.textBase, styles.description]}>ID</Text>
          </View>
          <View style={styles.userNameContainer}>
            <Text style={[styles.textBase, styles.description]}>
              ユーザー名
            </Text>
          </View>
          <View style={styles.userNameContainer}>
            <Text style={[styles.textBase, styles.description]}>
              パスワード
            </Text>
          </View>
          <View style={styles.userNameContainer}>
            <Text style={[styles.textBase, styles.description]}>役割</Text>
          </View>
          <View style={styles.iconContainer}>
            <Text style={[styles.textBase, styles.description]}></Text>
          </View>
        </View>
      )}
      scrollToOverflowEnabled={true}
      removeClippedSubviews={true}
      //   alwaysBounceHorizontal={true}
    />
  );
}

export default UserAuthList;

const styles = StyleSheet.create({
  userListItem: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.neutral200,
    justifyContent: "space-evenly",
    borderBottomWidth: 0.25,
    borderTopWidth: 0.25,
    borderColor: GlobalStyles.colors.primary1000,
    flexDirection: "row",
    borderRadius: 6,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  textBase: {
    marginLeft: 6,
    color: GlobalStyles.colors.primary600,
  },
  description: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: "bold",
  },
  idContainer: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  userNameContainer: {
    flex: 3,
    alignSelf: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    flex: 2,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
});
