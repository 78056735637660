import {
  Text,
  TextInput,
  View,
  StyleSheet,
  DatePickerIOSBase,
  SafeAreaView,
  Modal,
  Pressable,
} from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { InputOutline } from "react-native-input-outline";
import IconButton from "../UI/IconButton";
// import DateTimePickerModal from "react-native-modal-datetime-picker";
// import DatePicker from "react-native-date-picker";

import { getFormattedDate, getFormattedDateJp } from "../../util/date";
import { createElement, useState } from "react";
// import DateTimePicker from "@react-native-community/datetimepicker";
import Input from "./Input";
import Button from "../UI/Button";
import { DatePickerModal } from "react-native-paper-dates";
function DateInput({
  label,
  invalid,
  style,
  textInputConfig,
  inputValueDate,
  getDataFromTextInput,
}) {
  let inputStyles = [styles.input];
  // if (textInputConfig && textInputConfig.multiline) {
  //   inputStyles.push(styles.inputMultiline);
  // }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [dateValue, setDateValue] = useState();
  const [date, setDate] = useState(new Date());

  const [isDateChange, setIsDateChange] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // const showDatePicker = () => {
  //   setOpen(true);
  // };

  // const hideDatePicker = () => {
  //   setOpen(false);
  // };

  // const handleConfirm = (date) => {
  //   console.warn("A date has been picked: ", date);
  //   setDateValue(getFormattedDate(date));
  //   hideDatePicker();
  // };
  let calenderDate = "";
  const setDateInput = (date) => {
    setOpen(false);
    const currentDate = date;
    // console.log(currentDate.getFullYear());
    // console.log(currentDate.getMonth() + 1);
    // console.log(currentDate.getDate());
    const saveDate = getFormattedDate(currentDate);
    console.log("saveDate: " + saveDate);

    setDateValue(saveDate);

    //send out date data
    getDataFromTextInput(saveDate);
    setIsDateChange(true);
    setOpen(false);
  };

  const outputDate = (time) => {
    // console.log("SUBTOP!!!" + time);
    // send out date data
    getDataFromTextInput(time);
    setOpen(false);
  };

  // function DateTimePicker({ value, onChange }) {
  //   return createElement("input", {
  //     type: "date",
  //     value: value,
  //     onInput: onChange,
  //   });
  // }

  return (
    <>
      <View style={[styles.inputContainer, style]}>
        <Text style={[styles.label, invalid && styles.invalidLabel]}>
          {label}
        </Text>
        <View style={styles.inputBox}>
          <TextInput
            onFocus={() => setOpen(true)}
            style={styles.input}
            value={
              isDateChange
                ? dateValue
                : inputValueDate !== "0000-00-00 00:00:00" && inputValueDate
                ? inputValueDate
                : ""
            }
            showSoftInputOnFocus={false}
            onChangeText={outputDate}
            {...textInputConfig}
          />
          <IconButton
            icon={"calendar-outline"}
            size={36}
            color={GlobalStyles.colors.neutral0}
            onPress={() => {
              setOpen(true);
            }}
          />
        </View>
        {/* <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Hello World!</Text>

            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => setModalVisible(!modalVisible)}
            >
              <Text style={styles.textStyle}>Hide Modal</Text>
            </Pressable>
          </View>
        </View> */}
        {open && (
          // <DateTimePicker
          //   testID="dateTimePicker"
          //   value={date}
          //   mode="date"
          //   onChange={(date) => {
          //     // console.log("SET DATE: test" + date.target.value);
          //     setDateInput(date.target.value);
          //   }}
          //   display="default"
          //   style={styles.calendar}
          //   accentColor={GlobalStyles.colors.primary600}
          // />
          <DatePickerModal
            locale="jp"
            mode="single"
            visible={open}
            onDismiss={() => setOpen(false)}
            date={date}
            onConfirm={(date) => {
              console.log(date.date);
              // console.log("SET DATE: test" + date.target.value);
              setDateInput(date.date);
            }}
          />
        )}
        {open && (
          <Button
            onPress={() => {
              setOpen(!setOpen);
              // setDateInput();
            }}
            styleButton={styles.button}
            styleText={styles.buttonText}
          >
            {"日付を指定"}
          </Button>
        )}
      </View>
    </>
  );
}
export default DateInput;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  inputBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  label: {
    fontSize: 12,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 4,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary700,
    padding: 12,
    borderRadius: 6,
    fontSize: 18,
    flex: 1,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  container: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    padding: 20,
  },
  datePickerStyle: {
    width: 200,
    marginTop: 20,
  },
  calendar: {
    backgroundColor: GlobalStyles.colors.neutral10,
    colors: GlobalStyles.colors.primary600,
    alignSelf: "center",
    alignContent: "center",
    borderRadius: 10,
    padding: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    // flex: 2,
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: GlobalStyles.colors.primary700,
    marginTop: 10,
    alignSelf: "flex-end",
    // alignContent: "flex-end",
  },
  buttonText: {
    fontSize: 14,
    paddingHorizontal: 5,
  },
});
