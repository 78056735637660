import { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Modal,
  Dimensions,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import IconButton from "../component/UI/IconButton";
import { GlobalStyles } from "../constant/styles";
// import Pdf from "react-native-pdf";
// import SQLite from "react-native-sqlite-storage";
import {
  addPdf,
  addProblem,
  deletePdf,
  fetchProblemList,
  fetchProblemListWithTroubleshooting,
  updatePdf,
} from "../util/http";
import StoreManualEditForm from "../component/StoreManual/StoreManualEditForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../store/auth-context";
import NetInfo from "@react-native-community/netinfo";
import { Link } from "@react-navigation/native";
import { auth, storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";
import PdfItem from "../component/StoreManual/PdfItem";
import { SwipeListView } from "react-native-swipe-list-view";
import { Pressable } from "react-native";
import ChoiceItem from "../component/Troubleshooting/ChoiceItem";
import { getscaledDimension } from "../util/deviceUiInfo";

function TroubleShootingGuideScreen({ navigation, route }) {
  const [problemList, setProblemList] = useState([]);
  const [isOnline, setIsOnline] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(false);
  const [userRole, setUserRole] = useState();
  const { widthScreen, heightScreen } = Dimensions.get("window");
  const [curPdf, setCurPdf] = useState({});
  const [problemDataWithTroubleshooting, setProblemDataWithTroubleshooting] =
    useState([]);
  // console.log(route);

  const [currentStep, setCurrentStep] = useState(1);
  const [choiceList, setChoiceList] = useState([]);

  const curProblemId = route.params.problemId;
  const problemTitle = route.params.problemTitle;
  // pdfData;
  // console.log(userData);
  const [stepInstruction, setStepInstruction] = useState("");
  const [actionRecord, setActionRecord] = useState([]);

  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32*fontScale;

  useEffect(() => {
    // Check if the device is online
    const handleConnectivityChange = (isConnected) => {
      setIsOnline(isConnected);
    };
    NetInfo.addEventListener(handleConnectivityChange);
    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      setUserRole(storedRole);
      switch (storedRole) {
        case "admin":
          setIsAdmin(true);
          break;
        case "manager":
          setIsAdmin(true);
          break;
        default:
          setIsAdmin(false);
      }
    }
    fetchRole();
    return () => {
      // SQLite.close();
      // NetInfo.removeEventListener(handleConnectivityChange);
    };
  }, [navigation]);

  const fetchProblem = async () => {
    try {
      const response = await fetchProblemList();
      setProblemList(response);
      // savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEditingProblem = async (index) => {
    try {
      const response = await fetchProblemListWithTroubleshooting(index);
      // setProblemDataWithTroubleshooting(response);
      setProblemList(response);

      setChoiceList(
        response.filter(
          (problem) => problem.troubleshootingStepStepStage == currentStep
        )
      );
      const instruction = [
        ...new Set(
          response
            .filter((obj) => obj.troubleshootingStepStepStage == currentStep)
            .map((obj) => obj.troubleshootingStepInstruction)
        ),
      ];
      setStepInstruction(instruction);
      console.log(stepInstruction);
      console.log(choiceList);

      // console.log(problemList);
      // console.log(problemDataWithTroubleshooting);
      // console.log(response);
      // savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isOnline) {
      // fetchProblem();
      fetchEditingProblem(curProblemId);
    }
  }, [isOnline]);
  const handlePress = async (item) => {
    // setPdfModalVisible(!pdfModalVisible);
    if (item.choiceNextStep != -1) {
      setCurrentStep(item.choiceNextStep);
      console.log(currentStep);
      setChoiceList(
        problemList.filter(
          (problem) =>
            problem.troubleshootingStepStepStage == item.choiceNextStep
        )
      );
      const instruction = [
        ...new Set(
          problemList
            .filter(
              (obj) => obj.troubleshootingStepStepStage == item.choiceNextStep
            )
            .map((obj) => obj.troubleshootingStepInstruction)
        ),
      ];
      setActionRecord((prev) => [
        ...prev,
        {
          troubleshooting_step_id: item.troubleshootingStepId,
          troubleshooting_step_step_stage: item.troubleshootingStepStepStage,
          step_instruction: stepInstruction[0],
          action: item.choiceAction,
        },
      ]);
      setStepInstruction(instruction);
    } else {
      // alert("お疲れ様でした。");
      setActionRecord((prev) => [
        ...prev,
        {
          troubleshooting_step_id: item.troubleshootingStepId,
          troubleshooting_step_step_stage: item.troubleshootingStepStepStage,
          step_instruction: stepInstruction[0],
          action: item.choiceAction,
        },
      ]);
      const recordActionData = [
        ...actionRecord,
        {
          troubleshooting_step_id: item.troubleshootingStepId,
          troubleshooting_step_step_stage: item.troubleshootingStepStepStage,
          step_instruction: stepInstruction[0],
          action: item.choiceAction,
        },
      ];
      navigation.navigate("TroubleShootingReportScreen", {
        problemTitle: problemTitle,
        problemId: curProblemId,
        actionRecord: recordActionData,
      });
    }
    // console.log(actionRecord);
    // setCurPdf(pdf);
    // console.log(pdf.manualUri);
  };

  const renderItem = ({ item }) => {
    // console.log(item);
    const keyChoice = item.troubleshootingStepId + item.choiceLocalId;
    // console.log("keyChoice");
    // console.log(keyChoice);
    return (
      <View key={item.keyChoice}>
        <Pressable
          style={({ pressed }) => [pressed && styles.pressed]}
          onPress={() => handlePress(item)}
        >
          <ChoiceItem item={item} />
        </Pressable>
      </View>
    );
  };

  // const handleChoicePress = async (pdf) => {
  //   setPdfModalVisible(!pdfModalVisible);
  //   setCurPdf(pdf);
  // console.log(pdf.manualUri);
  // };

  // const fallbackText ="サーバーの問題でマニュアルリストを表示できません　- 後でもう一度試してください";
  let choiceListContent = (
    <Text style={styles.infoText}>
      <Text style={styles.infoTextHeader}>{`サーバーと接続中...`}</Text>
      {`
    
    サーバーの問題が発生する時もありので。一定期間、お知らせが出なかったら後でもう一度試してください`}
    </Text>
  );

  if (choiceList.length > 0) {
    choiceListContent = (
      <FlatList
        data={choiceList}
        renderItem={renderItem}
        keyExtractor={(item) =>
          item.troubleshootingStepId + item.choiceLocalId + item.choiceId
        }
      />
    );
  }
  return (
    <>
      <View style={styles.titleContainerIconLeft}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <IconButton
            icon="chevron-back"
            color={GlobalStyles.colors.primary600}
            size={32}
            onPress={() => {
              navigation.goBack();
            }}
            styleButtonContainer={styles.homeIconContainer}
          ></IconButton>
          {/* <View style={styles.iconLeftText}>
            <Text style={styles.textHome}>戻る</Text>
          </View> */}
        </TouchableOpacity>
      </View>

      <View style={styles.rootContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{problemTitle}</Text>
          </View>
        </View>
        <View style={styles.guideContainerText}>
          <Text style={styles.guideText}>{stepInstruction}</Text>
        </View>
        <View style={styles.contentContainer}>{choiceListContent}</View>
      </View>
    </>
  );
}

export default TroubleShootingGuideScreen;

const makeStyles = (hieghtScale,fontScale) => StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    marginHorizontal: "3%",
    marginBottom: 50,
  },
  headerContainer: {
    marginTop: "5%",
    flex: 1/hieghtScale,
    flexDirection: "row",
  },
  contentContainer: {
    flex: 10,
  },
  pdf: {
    // flex: 1,
    width: Dimensions.get("window").width,
    // height: "80%",
    height: Dimensions.get("window").height * 0.8,
  },
  subMenuContainer: {
    flex: 2,
    // marginBottom: 50,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainerIcon: {
    flexDirection: "row",
    position: "absolute",
    marginTop: "5%",
    right: "2%",
    zIndex: 5,
  },
  titleContainerIconLeft: {
    position: "absolute",
    marginTop: "5%",
    left: "4%",
    zIndex: 5,
  },
  closePdfModalContainer: {
    // position: "absolute",
  },
  iconContainer: {
    marginHorizontal: 1,
    paddingLeft: 1,
    paddingRight: 6,
  },
  evalIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
  },
  homeIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
    paddingBottom: 0,
  },
  menuRowContainer: {
    flex: 1,
    flexDirection: "row",
  },
  buttonContainer: {
    marginHorizontal: "2.5%",
    width: "45%",
    height: "100%",
  },
  subbuttonContainer: {
    marginVertical: 10,
    width: "95%",
    alignSelf: "center",
  },
  button: {
    marginTop: "5%",
    height: "95%",
  },
  buttonDisabled: {
    opacity: 0.75,
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  subbutton: {
    minWidth: 341,
    height: 65,
  },
  guideContainerText: {
    // flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    marginVertical: "12%",
  },
  guideText: {
    fontSize: 36,
    marginBottom: 32,
    color: GlobalStyles.colors.primary600,
    // fontWeight: "bold",
  },
  title: {
    fontSize: 28,
    marginBottom: 4,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  buttonText: {
    marginHorizontal: 16,
    fontSize: 26,
  },
  subbuttonText: {
    fontSize: 26,
  },
  notificationReportBadge: {
    position: "absolute",
    right: "35%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textReport: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
  textHome: {
    color: GlobalStyles.colors.primary600,
    fontSize: 16,
  },
  notificationChatBadge: {
    position: "absolute",
    right: "5%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textChat: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
  centeredView: {
    // justifyContent: "center",
    // alignItems: "center",
    marginTop: 1,
  },
  modalView: {
    marginHorizontal: 20,
    marginTop: "20%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    paddingVertical: 10,
    // paddingHorizontal: 14,
    // alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxHeight: "90%",
    overflow: "visible",
  },
  modalTopContainer: {
    flexDirection: "row",
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
    alignItems: "center",
  },
  pdfModalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    alignContent: "center",
    alignItems: "center",
    flex: 20,
    marginVertical: 10,
  },
  modalCloseContainer: {
    alignContent: "center",
    alignItems: "center",
    flex: 1,
  },
  pdfCloseIconContainer: {
    marginHorizontal: 0,
    marginVertical: 10,
    padding: 0,
    alignContent: "center",
    alignItems: "center",
  },
  pdfCenteredView: {
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  pdfModalView: {
    margin: 20,
    marginTop: "15%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginHorizontal: "5%",
    marginBottom: 15,
    borderRadius: 10,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16,
  },
  pressed: {
    opacity: 0.9,
  },
  // infoText: {
  //   color: GlobalStyles.colors.primary600,
  //   fontSize: 22,
  //   textAlign: "center",
  //   marginTop: 32,
  // },
  infoText: {
    color: GlobalStyles.colors.primary600,
    textAlign: "center",
  },
  infoTextHeader: {
    color: GlobalStyles.colors.primary600,
    fontSize: 20,
    fontWeight: "600",
  },
});
