import AsyncStorage from "@react-native-async-storage/async-storage";

import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({
  token: "",
  isAuthenticated: false,
  authenticate: (token) => {},
  setRole: (role) => {},
  setID: (uid) => {},
  setNotificationID: (notice_uid) => {},
  setOwnerUserName: (username) =>{},
  logout: () => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState();
  const [userRole, setUserRole] = useState();
  const [userID, setUserID] = useState();
  const [pushID, setPushID] = useState();
  const [userName, setUserName] = useState();

  function authenticate(token) {
    setAuthToken(token);
    AsyncStorage.setItem("token", token);
  }
  function setRole(role) {
    setUserRole(role);
    AsyncStorage.setItem("role", role);
  }
  function setID(uid) {
    setUserID(uid);
    AsyncStorage.setItem("uid", uid);
  }
  function setNotificationID(notice_uid) {
    setPushID(notice_uid);
    AsyncStorage.setItem("notice_uid", notice_uid);
  }
  function setOwnerUserName(username) {
    setUserName(username);
    AsyncStorage.setItem("owner_username", username);
  }
  function logout() {
    setAuthToken("");
    setUserRole("");
    setUserID("");
    setNotificationID("");
    setOwnerUserName("");
    AsyncStorage.removeItem("token");
    AsyncStorage.removeItem("role");
    AsyncStorage.removeItem("uid");
    AsyncStorage.removeItem("notice_uid");
    AsyncStorage.removeItem("owner_username");
  }

  const value = {
    token: authToken,
    isAuthenticated: !!authToken,
    userRole: userRole,
    userID: userID,
    pushID: pushID,
    authenticate: authenticate,
    userName: userName,
    setRole: setRole,
    setID: setID,
    setNotificationID: setNotificationID,
    setOwnerUserName: setOwnerUserName,
    logout: logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
