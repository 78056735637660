import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Picker } from '@react-native-picker/picker';

function DropDownPicker({ data, value, onValueChange, placeholder, disabled = false }) {
  const [isFocus, setIsFocus] = useState(false);

  const handleValueChange = (itemValue, itemIndex) => {
    onValueChange(itemValue);
    setIsFocus(false);
  };

  return (
    <View style={[styles.container, isFocus && { borderColor: 'blue' }]}>
      {isFocus ? (
        <Text style={styles.placeholderStyle}>{placeholder}</Text>
      ) : (
        <Text style={styles.placeholderStyle}>{placeholder || 'Select User Role'}</Text>
      )}
      <Picker
        selectedValue={value}
        onValueChange={handleValueChange}
        enabled={!disabled}
        style={styles.picker}
        itemStyle={styles.pickerItem}
      >
        {data.map((item, index) => (
          <Picker.Item key={index} label={item.label} value={item.value} />
        ))}
      </Picker>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 10,
  },
  picker: {
    width: '100%',
  },
  pickerItem: {
    fontSize: 16,
  },
  placeholderStyle: {
    color: '#999',
    fontSize: 16,
  },
});

export default DropDownPicker;