import { createContext, useReducer } from "react";

export const MyTaskContext = createContext({
  task: [],
  addTask: ({
    taskId,
    createdBy,
    updatedBy,
    taskTitle,
    taskDescription,
    status,
    createdAt,
    updatedAt,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    content,
    workhours,
    createdByName,
    createdByImage,
    updatedByName,
    updatedByImage,
  }) => {},
  setTask: (task) => {},
  deleteTask: (id) => {},
  updateTask: ({
    taskId,
    createdBy,
    updatedBy,
    taskTitle,
    taskDescription,
    status,
    createdAt,
    updatedAt,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    content,
    workhours,
    createdByName,
    createdByImage,
    updatedByName,
    updatedByImage,
  }) => {},
});

function taskReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      // console.log(state);
      const inverted = action.payload.reverse();
      return inverted;
    case "UPDATE":
      const updatableTaskIndex = state.findIndex(
        (task) => task.taskId === action.payload.data.taskId
      );
      // console.log(updatableTaskIndex);
      const updatableTask = state[updatableTaskIndex];
      const updatedItem = { ...updatableTask, ...action.payload.data };
      const updatedTask = [...state];
      updatedTask[updatableTaskIndex] = updatedItem;
      console.log(updatedItem);

      return updatedTask;
    case "DELETE":
      return state.filter((task) => task.taskId !== action.payload.taskId);
    default:
      return state;
  }
}

function MyTaskContextProvider({ children }) {
  const [taskState, dispatch] = useReducer(taskReducer, []);

  function addTask(taskData) {
    dispatch({ type: "ADD", payload: taskData });
  }
  function setTask(task) {
    dispatch({ type: "SET", payload: task });
  }
  function deleteTask(id) {
    dispatch({ type: "DELETE", payload: id });
  }
  function updateTask(id, taskData) {
    dispatch({ type: "UPDATE", payload: { id: id, data: taskData } });
  }

  const value = {
    task: taskState,
    setTask: setTask,
    addTask: addTask,
    deleteTask: deleteTask,
    updateTask: updateTask,
  };

  return (
    <MyTaskContext.Provider value={value}>{children}</MyTaskContext.Provider>
  );
}
export default MyTaskContextProvider;
