import { StyleSheet, View } from "react-native";
import DailyReportComment from "../component/DailyReportOutput/DailyReportComment";
import DailyReportModal from "../component/DailyReportOutput/DailyReportModalDetail";
import TaskComment from "../component/TaskManager/TaskComment";
import { useContext, useEffect, useState } from "react";
import { UsersContext } from "../store/user-context";
import { fetchUserInRooms } from "../util/http";
import LoadingOverlay from "../component/UI/LoadingOverlay";

function DailyReportDetailModalScreen({ navigation, route }) {
  let dailyReport = JSON.parse(JSON.stringify(route.params));
  // console.log("dailyReportModal" + dailyReport);

  return (
    <View style={styles.dailyReportContainer}>
      <DailyReportModal
        needAttentionReport={dailyReport.needAttentionReport}
        dailyReport={dailyReport.dailyReport}
        dailyreportId={dailyReport.dailyreportId}
        troubleReport={dailyReport.troubleReport}
        excitedReport={dailyReport.excitedReport}
        tomorrowTargetReport={dailyReport.tomorrowTargetReport}
        totalIncome={dailyReport.totalIncome}
        totalWaste={dailyReport.totalWaste}
        wasteAmountTaiyaki={dailyReport.wasteAmountTaiyaki}
        wasteAmountDango={dailyReport.wasteAmountDango}
        wasteAmountTakoyaki={dailyReport.wasteAmountTakoyaki}
        wasteAmountOkonomiyaki={dailyReport.wasteAmountOkonomiyaki}
        wasteAmountYakisoba={dailyReport.wasteAmountYakisoba}
        dailyreportImage={dailyReport.dailyreportImage}
        dailyreportDate={dailyReport.dailyreportDate}
        dailyreportCreatedTimestamp={dailyReport.dailyreportCreatedTimestamp}
        dailyreportUpdatedTimestamp={dailyReport.dailyreportUpdatedTimestamp}
        userName={dailyReport.userName}
        userImage={dailyReport.userImage}
        hadProfileImg={dailyReport.hadProfileImg}
        hadReportImg={dailyReport.hadReportImg}
        rejiCost={dailyReport.rejiCost}
        weatherStatus={dailyReport.weatherStatus}
        clockInTime={dailyReport.clockInTime}
        clockOutTime={dailyReport.clockOutTime}
        curRoomId={dailyReport.curRoomId}
        curRoomName={dailyReport.curRoomName}
        dailyReportRoute={dailyReport}
      />
    </View>
  );
}
export default DailyReportDetailModalScreen;

const styles = StyleSheet.create({
  dailyReportContainer: { flex: 1 },
});
