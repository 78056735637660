import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import { Dimensions, KeyboardAvoidingView, SafeAreaView, Text, View } from "react-native";
import DailyReportsOutput from "../component/DailyReportOutput/DailyReportsOutput";
import DailyReportRoomsOutput from "../component/DailyReportRoom/RoomsOutput";
import Button from "../component/UI/Button";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
// import { DailyReportsContext } from "../store/dailyreport-context";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
// import { getDateMinusDays } from "../util/date";
import { fetchDailyReportRooms} from "../util/http";
import { screenContainerWeb} from "../util/screenContainerWeb";

function DailyReportsScreen({ navigation }) {
  const [isFetching, setIsFectching] = useState(true);
  const [error, setError] = useState();
  const dailyReportRoomsCtx = useContext(DailyReportRoomsContext);
  const isFocused = useIsFocused();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      // setUserRole(storedRole);
      let isAdmin;
      let isStore;
      switch (storedRole) {
        case "admin":
          // setIsAdmin(true);
          isAdmin = true;
          break;
        case "manager":
          // setIsAdmin(true);
          isAdmin = true;
          break;
        default:
          // setIsAdmin(false);
          isAdmin = false;
      }
      switch (storedRole) {
        case "store":
          // setIsStore(true);
          isStore = true;
          break;
        default:
          // setIsStore(false);
          isStore = false;
      }
      navigation.setOptions({
        headerLeft: () => (
          <IconButton
            icon="chevron-back-outline"
            size={24}
            color={GlobalStyles.colors.primary600}
            onPress={() => navigation.goBack()}
            title="Update count"
          />
        ),
        headerRight: () => (
          <View>
            {isAdmin && (
              <Button
                styleText={{
                  color: GlobalStyles.colors.primary600,
                  fontSize: 18,
                }}
                mode="flat"
                onPress={() => {
                  navigation.navigate("DailyReportFormStack", {
                    screen: "ManageDailyReportRoom",
                  });
                }}
              >
                {"追加"}
              </Button>
            )}
          </View>
        ),
      });
    }
    fetchRole();
    // const interval = setInterval(() => {
    //   setCounter((prevCounter) => prevCounter + 1);
    // }, 10000);

    async function getDailyReports() {
      // setIsFectching(true);
      try {
        const dailyReportRooms = await fetchDailyReportRooms();
        dailyReportRoomsCtx.setDailyReportRoom(dailyReportRooms);
        // const dailyreportWithRooms = [];
        // for (const key_room in dailyReportRooms) {
        //   const dailyReports = await fetchDailyReports(
        //     dailyReportRooms[key_room].roomId
        //   );
        //   for (const key_report in dailyReports) {
        //     const dailyreportWithRoomObject = {
        //       id: dailyReports[key_report].dailyreportId,
        //       roomId: dailyReports[key_report].dailyreportRoomId,
        //     };
        //     dailyreportWithRooms.push(dailyreportWithRoomObject);
        //   }
        // }
        // // console.log(dailyreportWithRooms);
        // storeAllData(dailyreportWithRooms);
        // setIsFectching(false);
      } catch (error) {
        setError(
          "サーバーの問題で日報グループリストを表示できません　- 後でもう一度試してください"
        );
      }
      setIsFectching(false);
    }
    getDailyReports();

    // return () => clearInterval(interval);
  }, [navigation]);

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <DailyReportRoomsOutput
      dailyReportRooms={dailyReportRoomsCtx.dailyReportRooms}
      fallbackText="グループにはまだ指定していません "
    />
  );
}

export default DailyReportsScreen;
