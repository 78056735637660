import { Pressable, View, Text, StyleSheet, Image } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import {
  getFormatDateForNotification,
  getFormattedDate,
} from "../../util/date";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import Button from "../UI/Button";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import { fetchUnreadReport } from "../../util/http";

function DailyReportRoom({
  roomId,
  roomName,
  roomImage,
  userId,
  priority,
  latestUpdateTimestamp,
}) {
  const authCtx = useContext(AuthContext);
  const [seenData, setSeenData] = useState();
  const [allData, setAllData] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [diffNum, setDiffNum] = useState();

  const isFocused = useIsFocused();

  const hadProfileImg = roomImage ? true : false;
  const navigation = useNavigation();
  useEffect(() => {
    setIsFectching(true);

    async function getUnreadData() {
      const unreadNum = await fetchUnreadReport(roomId);
      setDiffNum(unreadNum[0].unreadNum ? unreadNum[0].unreadNum : 0);
    }

    getUnreadData();
    setIsFectching(false);
    /////////////////////////////NOW COMPARE READ AND UNREAD/////////////////
  }, [navigation, isFocused, isFetching]);

  function dailyReportPressHandler() {
    navigation.navigate("DailyReportStack", {
      screen: "DailyReportDetailScreen",
      params: { dailyReportRoomName: roomName, dailyReportRoomId: roomId },
    });
  }
  // console.log(hadProfileImg);
  return (
    <Pressable
      onPress={dailyReportPressHandler}
      style={({ pressed }) => pressed && styles.pressed}
    >
      <View style={styles.dailyReportRoom}>
        <View style={styles.profilePictureContainer}>
          <View style={styles.profilePicture}>
            {hadProfileImg && (
              <Image
                style={styles.profileImg}
                source={roomImage ? { uri: `${roomImage}` } : null}
              />
            )}
            {!hadProfileImg && roomName && (
              <Text style={styles.textProfile}>{`${roomName
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
        </View>
        <View style={styles.textContainer}>
          <Text style={[styles.textBase, styles.description]}>{roomName}</Text>
        </View>
        <View style={styles.roomRightInfo}>
          {latestUpdateTimestamp !== "0000-00-00 00:00:00" && (
            <View style={styles.textLatestUpdateContainer}>
              <Text
                style={[styles.textBase, styles.textLatestUpdate]}
              >{`${getFormatDateForNotification(latestUpdateTimestamp)}`}</Text>
            </View>
          )}
          {diffNum > 0 && (
            <View style={styles.notificationBadge}>
              <Text style={styles.textNotice}>{diffNum}</Text>
            </View>
          )}
        </View>
      </View>
    </Pressable>
  );
}

export default DailyReportRoom;

const styles = StyleSheet.create({
  pressed: {
    backgroundColor: GlobalStyles.colors.neutral700,
  },
  dailyReportRoom: {
    padding: 24,
    backgroundColor: GlobalStyles.colors.neutral10,
    justifyContent: "space-between",
    borderBottomWidth: 0.25,
    borderTopWidth: 0.25,
    borderColor: GlobalStyles.colors.primary1000,
    flexDirection: "row",
  },
  textBase: {
    marginLeft: 6,
    color: GlobalStyles.colors.primary600,
  },
  textLatestUpdate: {
    fontSize: 12,
    alignSelf: "center",
  },
  textLatestUpdateContainer: {
    marginBottom: 12,
    justifyContent: "center",
  },
  description: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: "bold",
  },

  buttonContainer: {
    // justifyContent: "end",
    flexDirection: "row-reverse",
  },
  profilePictureContainer: {
    flex: 1,
    justifyContent: "center",
  },
  userNameContainer: {
    marginLeft: 16,
    flex: 8,
    alignSelf: "center",
  },
  profilePicture: {
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
  profileImg: {
    width: 50,
    height: 50,
    borderRadius: 100,
  },
  textContainer: {
    flex: 8,
    justifyContent: "center",
    marginLeft: "5%",
  },
  notificationBadge: {
    width: 30,
    height: 30,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    // marginRight: 4,
  },
  textNotice: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 14,
  },
  roomRightInfo: {
    minHeight: 50,
    alignItems: "flex-end",
  },
});
