import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import InputStore from "./InputStore";

function WasteInput({
  label_text,
  label_code,
  invalid,
  itemUnit,
  getWasteAmount,
  defaultValues,
  style,
  styleLabel,
  textInputConfigCost,
  textInputConfigItemAmount,
}) {
  let inputStyles = [styles.input];
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }

  return (
    <View style={styles.wasteItemContainer}>
      <View style={styles.calContainer}>
        <InputStore
          label={label_text}
          invalid={invalid}
          styleInputWithPrefixContainer={{
            backgroundColor: GlobalStyles.colors.neutral200,
          }}
          textInputConfig={textInputConfigCost}
        />
      </View>
      <View style={styles.amountContainer}>
        <TextInput
          style={inputStyles}
          color={GlobalStyles.colors.primary700}
          keyboardType={"number-pad"}
          backgroundColor={GlobalStyles.colors.neutral10}
          autocomplete="off"
          {...textInputConfigItemAmount}
        />
      </View>
      <View style={styles.unitContainer}>
        <Text style={styles.unitText}>{itemUnit}</Text>
      </View>
    </View>
  );
}
export default WasteInput;

const styles = StyleSheet.create({
  unitText: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 4,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary600,
    padding: 6,
    borderRadius: 6,
    fontSize: 18,
  },

  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  wasteItemContainer: { flex: 1, flexDirection: "row" },
  calContainer: { flex: 8, justifyContent: "center" },
  amountContainer: { flex: 3, justifyContent: "center", marginHorizontal: 4 },
  unitContainer: {
    flex: 1,
    alignSelf: "center",
    marginHorizontal: 4,
  },
});
