import { useIsFocused } from "@react-navigation/native";
import { useContext, useLayoutEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
} from "react-native";
import DailyReportForm from "../component/DailyReport/DailyReportForm";
import RoomForm from "../component/DailyReportRoom/RoomForm";
import ErrorOverlay from "../component/UI/ErrorOverlay";

import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { DailyReportsContext } from "../store/dailyreport-context";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
import { UsersContext } from "../store/user-context";
import {
  fetchUsers,
  storeDailyReportRooms,
  updateDailyReportRooms,
} from "../util/http";

function ManageDailyRoom({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();

  const [isFetching, setIsFectching] = useState(true);
  const userCtx = useContext(UsersContext);
  const dailyReportRoomsContext = useContext(DailyReportRoomsContext);

  const editedRoomId = route.params?.roomId;
  const isEditing = !!editedRoomId;
  const roomName = route.params?.roomName;
  const selectedDailyReportRooms =
    dailyReportRoomsContext.dailyReportRooms.find(
      (room) => room.roomId === editedRoomId
    );
  // console.log(selectedDailyReportRooms);
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    navigation.setOptions({
      title: isEditing ? "グループ編集" : "グループ作成",
    });
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        // console.log(userList);
        userCtx.setUser(userList);
      } catch (error) {
        setError(
          "サーバーの問題で日報グループリストを表示できません　- 後でもう一度試してください"
        );
      }
      setIsFectching(false);
    }
    getUsersList();
  }, [navigation, isEditing, setIsSubmitting, isFocused]);

  function cancelHandler() {
    navigation.goBack();
  }

  async function confirmHandler(roomData) {
    setIsSubmitting(true);
    try {
      if (isEditing) {
        await updateDailyReportRooms(editedRoomId, roomData);
        dailyReportRoomsContext.updateDailyReportRoom(editedRoomId, roomData);
        setIsSubmitting(false);
      } else {
        const responseRooms = await storeDailyReportRooms(roomData);
        dailyReportRoomsContext.addDailyReportRoom({
          ...roomData,
          id: responseRooms[0].id + 1,
        });
        setIsSubmitting(false);
      }
      navigation.goBack();
    } catch (error) {
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }

  if (error && !isSubmitting) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsSubmitting(true);
          setError();
        }}
      />
    );
  }
  if (isSubmitting) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <View style={styles.container}>
        <RoomForm
          submitButtonLabel={isEditing ? "更新" : "追加"}
          onSubmit={confirmHandler}
          onCancel={cancelHandler}
          defaultValues={selectedDailyReportRooms}
          users={userCtx.users}
          defaultUser={selectedDailyReportRooms}
        />
      </View>
    </>
  );
}

export default ManageDailyRoom;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: GlobalStyles.colors.primary600,
  },

  deleteContainer: {
    paddingTop: 8,
    borderTopWidth: 2,
    borderTopColor: GlobalStyles.colors.neutral0,
    alignItems: "center",
  },
});
