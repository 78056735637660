import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, Modal, StyleSheet, Text, View } from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import { GlobalStyles } from "../../constant/styles";
import { getFormattedDate } from "../../util/date";
import ImagePicker from "../DailyReport/ImagePicker";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import UserOutput from "../User/UserOutput";
import DateInputTask from "./DateInputTask";
import InputTask from "./InputTask";

function TaskForm({
  task,
  todoTask = true,
  defaultValues,
  taskStatusIn,
  users,
  onSubmit,
  onCancel,
  defaultUser,
  headerFormLabel,
  isEditing = false,
}) {
  const navigation = useNavigation();
  const [taskStatus, setTaskStatus] = useState("incomplete");
  // let hadProfileImg = defaultValues.userImage ? true : false;
  const [progress, setProgress] = useState(0);
  const [UID, setUID] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);

  const [progressStatus, setProgressStatus] = useState(
    "しばらくお待ちください、アップロードしています。。。"
  );
  // const [enteredNewsId, setEnteredNewsId] = useState(
  //   defaultValues ? defaultValues.newsId : ""
  // );
  const [enteredTaskTitle, setEnteredTaskTitle] = useState(
    defaultValues ? defaultValues.taskTitle : ""
  );
  const [enteredTaskDescription, setEnteredTaskDescription] = useState(
    defaultValues ? defaultValues.taskDescription : ""
  );
  const [enteredPlannedStartDate, setEnteredPlannedStartDate] = useState(
    defaultValues ? defaultValues.plannedStartDate : new Date()
  );
  const [enteredPlannedEndDate, setEnteredPlannedEndDate] = useState(
    defaultValues ? defaultValues.plannedEndDate : new Date()
  );
  const [enteredStatus, setEnteredStatus] = useState(
    defaultValues ? defaultValues.status : ""
  );
  const [deleteUserIdForSubmit, setDeleteUserIdForSubmit] = useState();
  // const [enteredNewsTag, setEnteredNewsTag] = useState(
  //   defaultValues ? defaultValues.newsTag : ""
  // );
  // const [enteredStatus, setEnteredStatus] = useState(
  //   defaultValues ? defaultValues.newsTag : ""
  // );
  const [enteredContent, setEnteredContent] = useState(
    defaultValues ? defaultValues.content : ""
  );
  const [enteredWorkhours, setEnteredWorkhours] = useState(
    defaultValues ? defaultValues.workhours : ""
  );
  //   defaultUser.roomId !== nulltypeof;
  // const isDefined = typeof defaultUser !== "undefined";

  // console.log(defaultUser);
  const [inputs, setInput] = useState({
    enteredUserId: {
      // value: isDefined ? defaultcheck() : Array(),
      // value: Array(),
      value: Array(),
      isValid: true,
    },
    enteredSelectedUser: {
      value: Array(),
      // value: defaultValues ? defaultUser : Array(),
      isValid: true,
    },
  });
  //insert defaultUser into inputs.enteredUserId.value when defaultUser is not null
  const defaultcheck = () => {
    if (defaultUser) {
      // console.log("defaultUser");
      return true;
    }
  };
  const isDefined = defaultcheck();
  if (isDefined && inputs.enteredUserId.value.length === 0) {
    console.log("isDefined");
    defaultUser.forEach((userId) => {
      inputs.enteredUserId.value.push(userId);
      inputs.enteredSelectedUser.value.push(userId);
    });
    // inputs.enteredUserId["value"].push(defaultUser);
    console.log(inputs.enteredUserId.value);
  }

  // console.log(inputs);

  // const ref_member = isDefined ? Array() : defaultUser.userId;
  const ref_member = Array();

  // console.log("defaultUser");
  // console.log(defaultUser);
  // let defaultUserId = [];

  // console.log(defaultUserId);
  // const [enteredSelectedUser, setEnteredSelectedUser] = useState(
  //   defaultUser ? defaultUser : Array()
  // );
  // let enteredSelectedUser = defaultUser ? defaultUser : Array();
  // const [enteredNewsUriImage, setEnteredNewsUriImage] = useState(
  //   defaultValues ? defaultValues.newsUriImage : ""
  // );
  // 'task_title',
  // 'task_description',
  // 'status',
  // 'planned_start_date',
  // 'planned_end_date',

  // useEffect(() => {}, []);
  useEffect(() => {
    async function fetchRole() {
      // const uid = await AsyncStorage.getItem("uid");
      // setUID(uid);
    }
    fetchRole();
    // defaultcheck();
  }, []);
  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "taskTitle":
        setEnteredTaskTitle(enteredValue);
        break;
      case "taskDescription":
        setEnteredTaskDescription(enteredValue);
        break;
      // case "newsUriImage":
      //   setEnteredNewsUriImage(enteredValue);
      //   break;
      case "plannedStartDate":
        setEnteredPlannedStartDate(enteredValue);
        break;
      case "plannedEndDate":
        setEnteredPlannedEndDate(enteredValue);
        break;
      case "status":
        setEnteredStatus(enteredValue);
        break;
      case "content":
        setEnteredContent(enteredValue);
        break;
      case "workhours":
        setEnteredWorkhours(enteredValue);
        break;
      // case "newsTag":
      //   setEnteredNewsTag(enteredValue);
      //   break;
    }
  }
  function submitHandler() {
    const plannedStartTime = getFormattedDate(enteredPlannedStartDate);
    const plannedEndTime = getFormattedDate(enteredPlannedEndDate);

    const taskData = {
      taskTitle: enteredTaskTitle,
      taskDescription: enteredTaskDescription,
      plannedStartDate: new Date(plannedStartTime),
      plannedEndDate: new Date(plannedEndTime),
      status: isEditing ? enteredStatus : { value: "incomplete" },
      content: enteredContent,
      workhours: enteredWorkhours,
      addUserId: inputs.enteredUserId.value,
      deleteUserId: deleteUserIdForSubmit,
    };
    onSubmit(taskData);
  }
  // console.log("defaultcheck()");
  // console.log(defaultcheck());

  function inputChangedHandler(inputIdentifier, enteredValue) {
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      };
    });
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  let userIdForUserItem;
  function onChecktest(userData) {
    if (userData.isSelected) {
      inputs.enteredUserId.value.push(userData.selectedUserId); // userIdArray=
    } else {
      inputs.enteredUserId.value = inputs.enteredUserId.value.filter(
        (e) => e !== userData.selectedUserId
      );
    }

    const filteredInputUserId = inputs.enteredUserId.value.filter(onlyUnique);
    // const filteredSelectedUser =
    //   inputs.enteredSelectedUser.value.filter(onlyUniqueForObject);
    // console.log("inputs.enteredSelectedUser.value");
    // console.log(inputs.enteredSelectedUser.value);

    // const filteredSelectedUser = [...new Set(inputs.enteredSelectedUser.value)];
    // console.log("filteredSelectedUser");
    // console.log(filteredSelectedUser);

    // console.log(filteredInputUserId);
    inputChangedHandler("enteredUserId", filteredInputUserId);
    userIdForUserItem = {
      userId: inputs.enteredUserId.value,
    };
    inputChangedHandler("enteredSelectedUser", userIdForUserItem);

    // console.log(inputs.enteredUserId.value);
    // console.log(inputs.enteredSelectedUser.value);
  }
  function userInTaskEditSubmit(userData) {
    // console.log(userData);
    setIsEditingUser(false);
    let difference_for_delete = ref_member.filter(
      (x) => !inputs.enteredUserId.value.includes(x)
    );
    setDeleteUserIdForSubmit(difference_for_delete);
  }

  const data = [
    { label: "未対応", value: "incomplete" },
    { label: "対応中", value: "in_progress" },
    { label: "完了", value: "complete" },
    { label: "保留", value: "on_hold" },
  ];

  let assignedTo = [];
  let senderData;
  // console.log("inputs.enteredUserId");
  // console.log(inputs.enteredUserId);
  if (
    typeof inputs.enteredUserId.value !== "undefined" &&
    inputs.enteredUserId.value.length > 0
  ) {
    // console.log("inputs.enteredUserId");
    // console.log(inputs.enteredUserId);

    inputs.enteredUserId.value.forEach((userId) => {
      // console.log("userId");
      // console.log(userId);

      senderData = users.find((users) => {
        // console.log("users");
        // console.log(userId);
        // console.log(users.userId == userId);
        return users.userId == userId;
      });

      assignedTo.push(
        <View key={userId} style={styles.assignedByNameContainer}>
          <View style={styles.profilePicture}>
            {senderData.userImage ? (
              <Image
                style={styles.profileImg}
                source={
                  senderData.userImage
                    ? { uri: `${senderData.userImage}` }
                    : null
                }
              />
            ) : (
              <Text style={styles.textProfile}>{`${senderData.userName
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
          <Text style={styles.assignedByNameText}>{senderData.userName}</Text>
        </View>
      );
    });
  }

  return (
    <View style={styles.taskFormContainer}>
      <View style={styles.taskFormTitleContainer}>
        <Text style={styles.taskFormTitle}>{headerFormLabel}</Text>
      </View>
      <View style={styles.detailContainer}>
        <InputTask
          label="タスクの件名"
          textInputConfig={{
            onChangeText: updateInputValueHandler.bind(this, "taskTitle"),
            value: enteredTaskTitle,
            color: GlobalStyles.colors.primary600,
            placeholder: "【公開】名古屋で新店舗開店について",
            keyboardType: "default",
            borderRadius: 10,
            backgroundColor: GlobalStyles.colors.neutral0,
            borderColor: GlobalStyles.colors.primary600,
            borderWidth: 0.4,
            selectionColor: GlobalStyles.colors.primary600,
          }}
        />
        <InputTask
          label="タスクの内容"
          textInputConfig={{
            multiline: true,
            onChangeText: updateInputValueHandler.bind(this, "taskDescription"),
            value: enteredTaskDescription,
            color: GlobalStyles.colors.primary600,
            placeholder: "タスク...",
            keyboardType: "default",
            borderRadius: 10,
            backgroundColor: GlobalStyles.colors.neutral0,
            borderColor: GlobalStyles.colors.primary600,
            borderWidth: 0.4,
            selectionColor: GlobalStyles.colors.primary600,
          }}
          styleTextInput={{
            height: 270,
          }}
        />
        {isEditing ? (
          <View style={styles.container}>
            <Text style={styles.dropdownText}>タスク状態</Text>
            <Dropdown
              style={[styles.dropdown, isFocus && { borderColor: "green" }]}
              placeholderStyle={[styles.placeholderStyle, styles.textBase]}
              selectedTextStyle={[styles.selectedTextStyle, styles.textBase]}
              inputSearchStyle={[styles.inputSearchStyle, styles.textBase]}
              iconStyle={styles.iconStyle}
              data={data}
              // disable={false}
              maxHeight={300}
              labelField="label"
              valueField="value"
              placeholder={!isFocus ? "タスク状態を選択してください" : "..."}
              searchPlaceholder="Search..."
              value={taskStatusIn ? taskStatusIn : enteredStatus}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              onChange={updateInputValueHandler.bind(this, "status")}
            />
            {/* <Text style={styles.remarkText}>
            ＊管理者やマネジャー以外はユーザーの担当が変更できません
          </Text> */}
          </View>
        ) : null}
        <View style={styles.assignedToContainer}>
          <Text style={styles.assignedToTitleText}> タスク指定対象</Text>
          <IconButton
            icon="add-circle"
            color={GlobalStyles.colors.primary600}
            // addStyleContainer={{ backgroundColor: GlobalStyles.colors.primary700 }}
            size={24}
            onPress={() => setIsEditingUser(!isEditingUser)}
            styleButtonContainer={styles.iconContainer}
          />
        </View>
        {assignedTo}
        <View style={styles.datePickerContainer}>
          <View style={styles.dateInputContainer}>
            <DateInputTask
              label={"開始予定日付"}
              inputValueDate={enteredPlannedStartDate}
              getDataFromTextInput={updateInputValueHandler.bind(
                this,
                "plannedStartDate"
              )}
            />
          </View>
          <View style={styles.dateInputContainer}>
            <DateInputTask
              label={"終了予定日付"}
              inputValueDate={enteredPlannedEndDate}
              getDataFromTextInput={updateInputValueHandler.bind(
                this,
                "plannedEndDate"
              )}
            />
          </View>
        </View>
        {/* <View>assignto</View> */}
        {isEditingUser ? (
          <Modal
            animationType="slide"
            transparent={true}
            visible={isEditingUser}
            onRequestClose={() => {
              Alert.alert("User選択ページが閉じる");
              setIsEditingUser(!isEditingUser);
            }}
          >
            <UserOutput
              users={users}
              fallbackText="No Users registered. "
              onCheck={onChecktest}
              defaultUser={inputs.enteredSelectedUser.value}
              // defaultUser={inputs.enteredUserId.value}
              isMemberlist={false}
              styleContainer={styles.userListEditor}
              forTaskManager={true}
              onCancel={() => {
                setIsEditingUser(!isEditingUser);
              }}
              onSubmit={() => {
                userInTaskEditSubmit(inputs.enteredUserId.value);
              }}
            />

            {/* </View> */}
          </Modal>
        ) : null}

        {/* <ImagePicker /> */}
        <View style={styles.footerContainer}>
          <Button
            onPress={() => onCancel()}
            style={styles.footerButton}
            mode={"flat"}
            styleText={styles.footerButtonText}
          >
            キャンセル
          </Button>
          <Button onPress={() => submitHandler()} style={styles.footerButton}>
            情報送信
          </Button>
        </View>
      </View>
    </View>
  );
}
export default TaskForm;

const styles = StyleSheet.create({
  taskFormContainer: {},
  taskFormTitleContainer: {},
  taskFormTitle: {
    alignSelf: "center",
    fontSize: 20,
    fontWeight: "700",
    color: GlobalStyles.colors.primary600,
    marginBottom: 10,
  },
  detailContainer: {},
  assignedByContainer: {},
  selfCreatedAssignedToContainer: {},
  assignedToContainer: { flexDirection: "row" },
  assignedToTitleText: {
    // marginBottom: 8,
    color: GlobalStyles.colors.primary600,
    fontWeight: "600",
    alignSelf: "center",
  },
  datePickerContainer: { flexDirection: "row" },
  dateInputContainer: { flex: 1 },
  userInputModalContainer: { height: "80%" },
  userListEditor: {
    position: "absolute",
    height: "80%",
    width: "90%",
    top: "8%",
    right: "5%",
    padding: 10,
    borderRadius: 12,
    elevation: 3,
    shadowColor: GlobalStyles.colors.gray500,
    shadowRadius: 4,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.4,
  },
  rightTopContainer: {
    position: "absolute",
    top: "4%",
    right: "5%",
  },
  statusContainer: {
    borderRadius: 100,
  },
  statusText: {
    color: GlobalStyles.colors.neutral0,
  },
  dueDateContainer: {},
  dropdown: {
    height: 50,
    borderColor: GlobalStyles.colors.primary600,
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
    backgroundColor: GlobalStyles.colors.neutral0,
  },
  dropdownText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    marginBottom: 6,
    fontWeight: "600",
    // backgroundColor: GlobalStyles.colors.neutral0,
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  placeholderStyle: {
    fontSize: 16,
    backgroundColor: GlobalStyles.colors.neutral0,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  footerContainer: { flexDirection: "row" },
  footerButton: { flex: 1, alignSelf: "center", marginHorizontal: 30 },
  footerButtonText: { color: GlobalStyles.colors.primary600 },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    // zIndex: 100,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 18,
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: 1000,
  },
  assignedByNameContainer: {
    marginVertical: 15,
    flexDirection: "row",
    marginLeft: 20,
  },
  profilePicture: {
    width: 40,
    height: 40,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  assignedByNameText: {
    paddingLeft: 5,
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    alignItems: "center",
    alignSelf: "center",
  },
});
