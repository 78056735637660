import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { StyleSheet, Text, View } from "react-native";
import IconButton from "../component/UI/IconButton";
import MenuButton from "../component/UI/MenuButton";
import { GlobalStyles } from "../constant/styles";
import { AuthContext } from "../store/auth-context";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
import {
  fetchDailyReportRooms,
  fetchDailyReports,
  fetchUnreadChatAll,
  fetchUnreadReportAll,
} from "../util/http";
import { getscaledDimension } from "../util/deviceUiInfo";

function HomeScreen({ navigation }) {
  const [fetchedMessage, setFetchedMesssage] = useState("");
  const [counter, setCounter] = useState(0);
  const [noticeNumReport, setNoticeNumReport] = useState(0);
  const [noticeNumChat, setNoticeNumChat] = useState(0);
  const {hieghtScale,fontScale} = getscaledDimension() ;
  // const fontScale = getscaledDimension("font") ;
  // const vertScale = getscaledDimension("height") ;
  const styles = makeStyles(hieghtScale,fontScale/2); // pass in fontScale to the StyleSheet
  // const [role, setRole] = useState();

  const authCtx = useContext(AuthContext);
  // const dailyReportRoomsCtx = useContext(DailyReportRoomsContext);

  const token = authCtx.token;

  const isFocused = useIsFocused();
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 10000);
    async function getUnreadData() {
      const unreadNumReport = await fetchUnreadReportAll();
      // console.log(unreadNum);
      setNoticeNumReport(unreadNumReport ? unreadNumReport : 0);
      const unreadNumChat = await fetchUnreadChatAll();
      // console.log(unreadNumChat);
      setNoticeNumChat(unreadNumChat ? unreadNumChat : 0);
    }
    getUnreadData();

    return () => clearInterval(interval);
  }, [navigation]);

  function dailyReportHandler() {
    navigation.navigate("DailyReportScreen");
  }
  function profilePressHandler() {
    // authCtx.logout();
    navigation.navigate("UserProfileScreen");
  }
  function chatHandler() {
    navigation.navigate("GroupChatScreen");
  }
  function taskManagerHandler() {
    navigation.navigate("TaskManagerScreen");
  }
  function troubleshootingHandler() {
    navigation.navigate("TroubleshootingMainScreen");
  }
  function bulletinBoardHandler() {
    navigation.navigate("BulletinBoardScreen");
  }
  function storeManualHandler() {
    navigation.navigate("StoreManualScreen");
  }
  return (
    <>
      <View style={styles.titleContainerIcon}>
        <IconButton
          icon="person-circle-outline"
          color={GlobalStyles.colors.primary600}
          size={52}
          onPress={profilePressHandler}
        />
      </View>
      <View style={styles.rootContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
            <View style={styles.titleContainerText}>
              <Text style={styles.title}>ヨネノリンクス WEB</Text>
            </View>
          </View>
        </View>
        <View style={styles.bodyContainer}>
        <View style={styles.menuContainer}>
          <View style={styles.menuRowContainer}>
            {/* {(noticeNumReport > 0) ? (
              <View style={styles.notificationReportBadge}>
                <Text style={styles.textReport}>{noticeNumReport}</Text>
              </View>
            ):null} */}
            <MenuButton
              style={styles.buttonContainer}
              styleButton={styles.button}
              styleButtonText={styles.buttonText}
              onPress={dailyReportHandler}
              noticeNum={noticeNumReport}
            >
              {"日報"}
            </MenuButton>
            <MenuButton
              style={styles.buttonContainer}
              styleButton={styles.button}
              styleButtonText={styles.buttonText}
              onPress={chatHandler}
              noticeNum={noticeNumChat}
            >
              {"チャット"}
            </MenuButton>
            {/* {(noticeNumChat > 0 )? (
              <View style={styles.notificationChatBadge}>
                <Text style={styles.textChat}>{noticeNumChat}</Text>
              </View>
            ):null} */}
          </View>
          <View style={styles.menuRowContainer}>
            <MenuButton
              style={styles.buttonContainer}
              styleButton={[styles.button]}
              styleButtonText={styles.buttonText}
              onPress={taskManagerHandler}
            >
              {"タスク管理"}
            </MenuButton>
            <MenuButton
              style={styles.buttonContainer}
              styleButtonText={styles.buttonText}
              styleButton={[styles.button]}
              // styleButton={[styles.button, styles.buttonDisabled]}
              onPress={troubleshootingHandler}
              // disabled={true}
            >
              {"トラブルシュート"}
            </MenuButton>
          </View>
        </View>
        <View style={styles.subMenuContainer}>
          <MenuButton
            style={styles.subbuttonContainer}
            styleButton={[styles.subbutton]}
            styleButtonText={styles.subbuttonText}
            onPress={bulletinBoardHandler}
          >
            {"掲示板"}
          </MenuButton>
          <MenuButton
            style={styles.subbuttonContainer}
            styleButton={styles.subbutton}
            styleButtonText={styles.subbuttonText}
            onPress={storeManualHandler}
          >
            {"マニュアル"}
          </MenuButton>
        </View>
        </View>
      </View>
    </>
  );
}

export default HomeScreen;

const makeStyles = (hieghtScale,fontScale) => StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    marginHorizontal: "10%",
    // paddingHorizontal: "10%",
    // marginBottom: 50,
    // paddingBottom: "25%",
  },
  headerContainer: {
    // marginTop: "3%",
    flex: 2,
    flexDirection: "row",
  },
  bodyContainer: {
    flex: 10,
  },
  menuContainer: {
    flex: 10,
  },
  subMenuContainer: {
    flex: 2,
    marginTop: "2%",
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainerIcon: {
    position: "absolute",
    marginTop: (2*hieghtScale)+"%",
    right: "1%",
    zIndex: 5,
  },
  menuRowContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    // marginTop: "1%",
    // display: "flex",
    // flexwrap: "wrap",
    alignItems: "center",
    // alignContent:"stretch",
    // alignSelf:"stretch",
    height: "auto",
  },
  buttonContainer: {
    // marginHorizontal: "2.5%",
    width: "40%",
    // justifyContent: "center",
    // alignItems: "center",
    // alignSelf:"center",
    // alignContent:"center"
  },
  subbuttonContainer: {
    marginVertical: 10,
    width: "88%",
    alignSelf: "center",
    // marginTop: "2%",
  },
  button: {
    // marginTop: "5%",
    height: "100%",
    minHeight: 200,
  },
  buttonDisabled: {
    opacity: 0.75,
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  subbutton: {
    // minWidth: 341,
    // height: 65,
    height: "100%",
    minHeight: 65,
  },
  titleContainerText: {},
  title: {
    // fontSize: 32,
    fontSize: GlobalStyles.fonts.home_title*fontScale,
    marginBottom: 4,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  buttonText: {
    marginHorizontal: 16,
    // fontSize: 26,
    fontSize: GlobalStyles.fonts.home_button_text*fontScale,
  },
  subbuttonText: {
    // fontSize: 26,
    fontSize: GlobalStyles.fonts.home_button_text*fontScale,
  },
  notificationReportBadge: {
    position: "absolute",
    right: "35%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textReport: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
  notificationChatBadge: {
    position: "absolute",
    right: "5%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textChat: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
});
