import { Image, StyleSheet, Text, View } from "react-native";
import IconButton from "../UI/IconButton";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GlobalStyles } from "../../constant/styles";
import { useState } from "react";
import { fetchUserChatRooms, storeChatRooms } from "../../util/http";
import Button from "../UI/Button";
import { getscaledDimension } from "../../util/deviceUiInfo";
function UserModal({
  userName,
  userImage,
  userId,
  onPressCloseModal,
  openChatRoom,
}) {
  const [hadProfileImg, setHadProfileImg] = useState(userImage ? true : false);

  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale); // pass in fontScale to the StyleSheet

  return (
    <View style={styles.mainContainer}>
      <View style={styles.outterButtonContainer}>
        <IconButton
          icon="close-circle"
          size={36}
          color={GlobalStyles.colors.primary600}
          onPress={onPressCloseModal}
          styleButtonContainer={styles.buttonBox}
        />
      </View>
      <View style={styles.profileImageContainer}>
        <View style={styles.profilePicture}>
          {hadProfileImg && (
            <Image
              style={styles.profileImg}
              source={userImage ? { uri: `${userImage}` } : null}
            />
          )}
          {!hadProfileImg && userName && (
            <Text style={styles.textProfile}>{`${userName
              .charAt(0)
              .toUpperCase()}`}</Text>
          )}
        </View>
      </View>
      <View style={styles.profileNameContainer}>
        <Text style={styles.usernameText}>{userName}</Text>
        {/* <Text style={styles.roleText}>{userRole}</Text> */}
      </View>
      <View style={styles.buttonContainer}>
        <IconButton
          icon="chatbubble-ellipses"
          size={48}
          color={GlobalStyles.colors.primary600}
          onPress={openChatRoom}
          styleButtonContainer={styles.buttonBox}
        />
        <Text>Chat</Text>
      </View>
    </View>
  );
}

export default UserModal;
const makeStyles = (hieghtScale,fontScale) =>StyleSheet.create({
  mainContainer: {},
  profileImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  profileImg: {
    minWidth: 180*fontScale,
    minHeight: 180*fontScale,
    borderRadius: 1000,
  },
  profilePicture: {
    minWidth: 180*fontScale,
    minHeight: 180*fontScale,
    borderRadius: 1000,
    marginBottom: 6,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
  },
  textProfile: { fontSize: 100*fontScale, color: GlobalStyles.colors.neutral10 },

  profileNameContainer: { alignItems: "center", marginTop: "5%" },
  buttonContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  buttonBox: {},
  usernameText: { fontSize: 30*fontScale, fontWeight: "bold" },
  roleText: { fontSize: 18*fontScale },
  outterButtonContainer: {
    position: "absolute",
    zIndex: 100,
    right: "-5%",
    // top: "-5%",
  },
});
