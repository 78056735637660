import AsyncStorage from "@react-native-async-storage/async-storage";

import axios from "axios";
import { useState } from "react";
import { storage } from "../Firebase";
import "firebase/storage";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";
import { Platform } from "react-native";

const BACKEND_URL = "https://yonenoya.jp/houkoku/";
const API_KEY = "3c1e9184d7964b27bf410b3bd2ae5d14";

export async function fetchUsers() {
  const request_mode = "FETCH_USER";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const users = [];
  for (const key in response.data) {
    const userObj = {
      id: response.data[key].user_id ? response.data[key].user_id : key,
      userId: response.data[key].user_id,
      userName: response.data[key].name,
      userImage: response.data[key].image,
      userRole: response.data[key].user_role,
      notificationId: response.data[key].notification_id,
    };
    users.push(userObj);
  }
  return users;
}

export async function fetchUserRooms() {
  const request_mode = "FETCH_USER_WITH_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const userWithRooms = [];
  for (const key in response.data) {
    const userWithRoomObj = {
      id: response.data[key].dailyreport_participant_id
        ? response.data[key].dailyreport_participant_id
        : key,
      participantId: response.data[key].dailyreport_participant_id,
      userId: response.data[key].user_id,
      roomId: response.data[key].participant_dailyreport_room_id,
    };
    userWithRooms.push(userWithRoomObj);
  }
  return userWithRooms;
}

export async function fetchUserInRooms(roomId) {
  const request_mode = "FETCH_USER_IN_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&room_id=${roomId}`
  );
  const userInRooms = [];
  for (const key in response.data) {
    const userInRoomObj = {
      id: response.data[key].user_id ? response.data[key].user_id : key,
      userId: response.data[key].user_id,
      userName: response.data[key].name,
      userImage: response.data[key].image,
      notificationId: response.data[key].notification_id,
    };
    userInRooms.push(userInRoomObj);
  }
  // console.log(userInRooms);
  return userInRooms;
}

export async function fetchDailyReportRooms() {
  const request_mode = "FETCH";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const dailyReportRooms = [];
  for (const key in response.data) {
    const dailyReportRoomObj = {
      id: response.data[key].roomId ? response.data[key].roomId : key,
      roomId: response.data[key].roomId,
      roomName: response.data[key].roomName,
      roomImage: response.data[key].roomImage,
      userId: response.data[key].userId,
      priority: response.data[key].priority,
      latestUpdateTimestamp: response.data[key].latestUpdateTimestamp,
    };
    dailyReportRooms.push(dailyReportRoomObj);
  }
  return dailyReportRooms;
}

export async function storeDailyReportRooms(roomData) {
  const request_mode = "ADD";
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedToken = await AsyncStorage.getItem("token");
  const storedUID = await AsyncStorage.getItem("uid");

  if (!roomData.roomImage) {
    // console.log(!roomData.roomImage);
  } else {
      const createFormData = (photo, body = {}) => {
      const data = new FormData();
      const photoName_ = photo.uri.split("/");
      const photoName = photoName_[photoName_.length - 1];
      let photoType = photo.type;
      const fileType_ = photoName.split(".");

      if (photoType === "image" && photoName.search(".jpeg") > -1){
        photoType = "image/jpg";
      }
      else if(photo.type === "image" && photoName.search(".jpeg") === -1){
      photoType = "image/"+ fileType_[fileType_.length - 1];
      }
      data.append("photo", {
        name: photoName,
        type: photoType,
        size: photo.fileSize,
        uri:
          Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
      });

      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
      return data;
    };
    const formData = createFormData(roomData.roomImage, {
      userId: storedUID,
    });
    const responsePhotoUpload = axios.post(
      `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_upload_mode}&submit=submit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // .then((res) => {
    //   console.log(res.data);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    // console.log(photoURL);
    // console.log(formData);
  }

  // console.log(!roomData.roomImage);
  const photoURL_split = roomData.roomImage.uri?.split("/");
  // const photURL_slice = photoURL_[photoURL_.length - 1].split(".");
  // console.log(photoURL_);
  const photoURL_ = photoURL_split
    ? photoURL_split[photoURL_split.length - 1]
    : "NO";

  const photoURL =
    BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;

  const dailyReportRoomObj = {
    name: roomData.roomName,
    image: roomData.roomImage.uri ? photoURL : roomData.roomImage,
    user_id: roomData.userId,
  };
  // console.log(dailyReportRoomObj);
  const response = await axios.post(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    dailyReportRoomObj
  );
  const responseRooms = [];
  for (const key in response.data) {
    const responseRoomtObj = {
      id: response.data[key].dailyreport_room_id
        ? response.data[key].dailyreport_room_id
        : key,
    };
    responseRooms.push(responseRoomtObj);
  }
  // console.log(responseRooms);
  return responseRooms;
}

export async function updateDailyReportRooms(roomId, roomData) {
  let request_mode = "UPDATE_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedUID = await AsyncStorage.getItem("uid");

  if (!roomData.roomImage) {
    // console.log(!roomData.roomImage);
  } else {
    const createFormData = (photo, body = {}) => {
      const data = new FormData();
      const photoName_ = photo.uri.split("/");
      const photoName = photoName_[photoName_.length - 1];
      let photoType = photo.type;
      const fileType_ = photoName.split(".");

      if (photoType === "image" && photoName.search(".jpeg") > -1){
        photoType = "image/jpg";
      }
      else if(photo.type === "image" && photoName.search(".jpeg") === -1){
      photoType = "image/"+ fileType_[fileType_.length - 1];
      }
      data.append("photo", {
        name: photoName,
        type: photoType,
        size: photo.fileSize,
        uri:
          Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
      });

      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
      return data;
    };
    const formData = createFormData(roomData.roomImage, {
      userId: storedUID,
    });
    const responsePhotoUpload = axios.post(
      `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_upload_mode}&submit=submit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // .then((res) => {
    //   console.log(res.data);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    // console.log(photoURL);
    // console.log(formData);
  }

  // console.log(!roomData.roomImage);
  const photoURL_split = roomData.roomImage.uri?.split("/");
  // const photURL_slice = photoURL_[photoURL_.length - 1].split(".");
  // console.log(photoURL_);
  const photoURL_ = photoURL_split
    ? photoURL_split[photoURL_split.length - 1]
    : "NO";

  const photoURL =
    BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;

  const updateRoomObj = {
    dailyreport_room_id: roomId,
    name: roomData.roomName,
    image: roomData.roomImage.uri ? photoURL : roomData.roomImage,
  };
  const response_update_room = await axios.put(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    updateRoomObj
  );
  request_mode = "ADD_PARTICIPANT";
  const addParticipantObj = {
    participant_dailyreport_room_id: roomId,
    user_id: roomData.userId,
  };
  const response_add_participant = await axios.put(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    addParticipantObj
  );
  request_mode = "DELETE_PARTICIPANT";
  const delParticipantObj = {
    participant_dailyreport_room_id: roomId,
    user_id: roomData.deleteUserId,
  };
  const response_delete_participant = await axios.post(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    delParticipantObj
  );
}

export async function updateLatestUpdatedTimeDailyReportRooms(roomId) {
  let request_mode = "UPDATE_TIME_STAMP";
  const storedToken = await AsyncStorage.getItem("token");

  const updateRoomObj = {
    dailyreport_room_id: roomId,
  };
  const response_update_room = await axios.put(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    updateRoomObj
  );
}
// export async function updateDailyReportRoomsAddParticipant(roomId, userId) {
//   const request_mode = "ADD_PARTICIPANT";
//   const storedToken = await AsyncStorage.getItem("token");
//   const addParticipantObj = {
//     participant_dailyreport_room_id: roomId,
//     user_id: userId,
//   };
//   const response = await axios.put(
//     `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
//     addParticipantObj
//   );
// }

// export async function updateDailyReportRoomsDeleteParticipant(roomId, userId) {
//   const request_mode = "DELETE_PARTICIPANT";
//   const storedToken = await AsyncStorage.getItem("token");
//   const delParticipantObj = {
//     participant_dailyreport_room_id: roomId,
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
//     delParticipantObj
//   );
// }

export async function deleteDailyReportRooms(roomId) {
  const request_mode = "DELETE";
  const storedToken = await AsyncStorage.getItem("token");
  const dailyReportRoomDelObj = { dailyreport_room_id: roomId };
  const response = await axios.post(
    `${BACKEND_URL}/dailyreport.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    dailyReportRoomDelObj
  );
}

export async function fetchDailyReports(roomId) {
  const request_mode = "FETCH";
  const storedToken = await AsyncStorage.getItem("token");

  const response = await axios.get(
    `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&roomId=${roomId}&mode=${request_mode}`
  );
  const dailyReports = [];
  for (const key in response.data) {
    const dailyReportObj = {
      id: response.data[key].dailyreport_id
        ? response.data[key].dailyreport_id
        : key,
      dailyreportId: response.data[key].dailyreport_id,
      dailyreportRoomId: response.data[key].dailyreport_room_id,
      userId: response.data[key].user_id,
      dailyreportType: response.data[key].dailyreport_type,
      dailyReport: response.data[key].daily_report,
      troubleReport: response.data[key].trouble_report,
      excitedReport: response.data[key].excited_report,
      tomorrowTargetReport: response.data[key].tomorrow_target_report,
      totalIncome: response.data[key].total_income,
      totalWaste: response.data[key].total_waste,
      wasteAmountTaiyaki: response.data[key].waste_amount_taiyaki,
      wasteAmountDango: response.data[key].waste_amount_dango,
      wasteAmountTakoyaki: response.data[key].waste_amount_takoyaki,
      wasteAmountOkonomiyaki: response.data[key].waste_amount_okonomiyaki,
      wasteAmountYakisoba: response.data[key].waste_amount_yakisoba,
      dailyreportImage: response.data[key].dailyreport_image,
      dailyreportDate: response.data[key].dailyreport_date,
      dailyreportCreatedTimestamp:
        response.data[key].dailyreport_created_timestamp,
      dailyreportUpdatedTimestamp:
        response.data[key].dailyreport_updated_timestamp,
      userName: response.data[key].user_name,
      userImage: response.data[key].user_image,
      rejiCost: response.data[key].reji_cost,
      weatherStatus: response.data[key].weather_status,
      needAttentionReport: response.data[key].need_attention_report,
      clockInTime: response.data[key].clock_in_time,
      clockOutTime: response.data[key].clock_out_time,
      commentNums: response.data[key].comment_nums,
    };
    dailyReports.push(dailyReportObj);
  }
  // console.log(dailyReports);
  return dailyReports;
}

export async function storeDailyReport(dailyReportData, roomId) {
  // console.log("storeDailyReport");
  // console.log(dailyReportData);

  const request_mode = "ADD";
  const request_upload_mode = "ADD_IMAGE_DR";

  const storedToken = await AsyncStorage.getItem("token");
  const storedUID = await AsyncStorage.getItem("uid");

  // const [isUpload, setIsUpload] = useState(true);
  // const [progress, setProgress] = useState(0);
  let uploadedSuccessful = true;
  // let photoURL;

  if (!dailyReportData.dailyreportImage) {
  } else {
    // try {
    // const test = await handleUpload(dailyReportData.dailyreportImage);
    // } catch (error) {}
  }
  //___________________PHOTO_XSERVER__________________//
  // const createFormData = (photo, body = {}) => {
  //   const data = new FormData();
  //   const photoName_ = photo.uri.split("/");
  //   const photoName = photoName_[photoName_.length - 1];
  //   uploadedSuccessful = false;
  //   data.append("photo", {
  //     name: photoName,
  //     type: photo.type,
  //     size: photo.fileSize,
  //     uri:
  //       Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
  //   });
  //   Object.keys(body).forEach((key) => {
  //     data.append(key, body[key]);
  //   });
  //   return data;
  // };
  // const formData = createFormData(dailyReportData.dailyreportImage, {
  //   userId: storedUID,
  // });
  // const responsePhotoUpload = await axios.post(
  //   `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&roomId=${roomId}&mode=${request_upload_mode}&submit=submit`,
  //   formData,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   }
  // );
  // if (
  //   responsePhotoUpload.data.split("}")[
  //     responsePhotoUpload.data.split("}").length - 1
  //   ] === "UPLOAD_SUCCESSFUL"
  // ) {
  //   uploadedSuccessful = true;
  // }
  // .then((res) => {
  //   console.log(res.data);
  // })
  // .catch((err) => {
  //   console.log(err);
  // });
  // console.log(prhotoURL);
  // console.log(formData);
  // }
  // const photoURL_split = dailyReportData.dailyreportImage
  //   ? dailyReportData.dailyreportImage.uri.split("/")
  //   : "";
  // const photURL_slice = photoURL_[photoURL_.length - 1].split(".");
  // console.log(photoURL_split);
  // const photoURL_ = photoURL_split
  //   ? photoURL_split[photoURL_split.length - 1]
  //   : "NO";

  // const photoURL =
  //   BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;
  //___________________PHOTO_XSERVER__________________//
  // console.log("photoURL");
  // console.log(photoURL);
  const dailyReportObjPost = {
    dailyreport_room_id: roomId,
    dailyreport_type: dailyReportData.dailyreportType,
    daily_report: dailyReportData.dailyReport,
    trouble_report: dailyReportData.troubleReport,
    excited_report: dailyReportData.excitedReport,
    tomorrow_target_report: dailyReportData.tomorrowTargetReport,
    dailyreport_date: dailyReportData.dailyreportDate,
    ///////////////////////////////////////////////
    dailyreport_image: dailyReportData.dailyreportImage,
    ///////////////////////////////////////////////
    total_income: dailyReportData.totalIncome,
    total_waste: dailyReportData.totalWaste,
    waste_amount_taiyaki: dailyReportData.wasteAmountTaiyaki,
    waste_amount_dango: dailyReportData.wasteAmountDango,
    waste_amount_takoyaki: dailyReportData.wasteAmountTakoyaki,
    waste_amount_okonomiyaki: dailyReportData.wasteAmountOkonomiyaki,
    waste_amount_yakisoba: dailyReportData.wasteAmountYakisoba,
    reji_cost: dailyReportData.rejiCost,
    weather_status: dailyReportData.weatherStatus,
    need_attention_report: dailyReportData.needAttentionReport,
    clock_in_time: dailyReportData.clockInTime,
    clock_out_time: dailyReportData.clockOutTime,
  };

  console.log(dailyReportObjPost);
  // console.log(dailyReportObjPost.dailyreport_image);
  console.log(uploadedSuccessful);
  if (uploadedSuccessful == true || !dailyReportData.dailyreportImage) {
    const response = await axios.post(
      `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&roomId=${roomId}&mode=${request_mode}`,
      dailyReportObjPost
    );
    const responseReports = [];
    for (const key in response.data) {
      const responseReportObj = {
        id: response.data[key].dailyreport_id
          ? response.data[key].dailyreport_id
          : key,
        timestamp: response.data[key].timestamp,
      };
      responseReports.push(responseReportObj);
    }
    return responseReports;
  } else {
    return "UPLOAD_FAILED";
  }
}

export async function updateDailyReport(id, dailyReportData, roomId) {
  const request_mode = "UPDATE";
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedToken = await AsyncStorage.getItem("token");
  const storedUID = await AsyncStorage.getItem("uid");
  // const [uploadedSuccessful, setUploadedSuccessful] = useState(true);
  let uploadedSuccessful = true;
  // if (!dailyReportData.dailyreportImage) {
  //   // console.log(!dailyReportData.dailyreportImage);
  // } else {
  //   const createFormData = (photo, body = {}) => {
  //     const data = new FormData();
  //     const photoName_ = photo.uri.split("/");
  //     const photoName = photoName_[photoName_.length - 1];
  //     // setUploadedSuccessful(false);
  //     uploadedSuccessful = false;
  //     data.append("photo", {
  //       name: photoName,
  //       type: photo.type,
  //       size: photo.fileSize,
  //       uri:
  //         Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
  //     });

  //     Object.keys(body).forEach((key) => {
  //       data.append(key, body[key]);
  //     });

  //     return data;
  //   };
  //   const formData = createFormData(dailyReportData.dailyreportImage, {
  //     userId: storedUID,
  //   });
  //   const responsePhotoUpload = axios.post(
  //     `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&roomId=${roomId}&mode=${request_upload_mode}&submit=submit`,
  //     formData,
  //     {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }
  //   );
  //   if (responsePhotoUpload.data === "UPDATE_SUCCESSFUL") {
  //     // setUploadedSuccessful(true);
  //     uploadedSuccessful = false;
  //   }
  //   // .then((res) => {
  //   //   console.log(res.data);
  //   // })
  //   // .catch((err) => {
  //   //   console.log(err);
  //   // });
  //   // console.log(photoURL);
  //   // console.log(formData);
  // }
  // const photoURL_split = dailyReportData.dailyreportImage.uri?.split("/");
  // // const photURL_slice = photoURL_[photoURL_.length - 1].split(".");
  // // console.log(photoURL_);
  // const photoURL_ = photoURL_split
  //   ? photoURL_split[photoURL_split.length - 1]
  //   : "NO";

  // const photoURL =
  //   BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;

  const dailyReportObjPost = {
    dailyreport_id: id,
    dailyreport_type: dailyReportData.dailyreportType,
    daily_report: dailyReportData.dailyReport,
    trouble_report: dailyReportData.troubleReport,
    excited_report: dailyReportData.excitedReport,
    tomorrow_target_report: dailyReportData.tomorrowTargetReport,
    dailyreport_date: dailyReportData.dailyreportDate,
    dailyreport_image: dailyReportData.dailyreportImage,
    // ? photoURL
    // : dailyReportData.dailyreportImage,
    total_income: dailyReportData.totalIncome,
    total_waste:
      dailyReportData.totalWaste == "NaN" ? "" : dailyReportData.totalWaste,
    waste_amount_taiyaki: dailyReportData.wasteAmountTaiyaki,
    waste_amount_dango: dailyReportData.wasteAmountDango,
    waste_amount_takoyaki: dailyReportData.wasteAmountTakoyaki,
    waste_amount_okonomiyaki: dailyReportData.wasteAmountOkonomiyaki,
    waste_amount_yakisoba: dailyReportData.wasteAmountYakisoba,
    reji_cost: dailyReportData.rejiCost,
    weather_status: dailyReportData.weatherStatus,
    need_attention_report: dailyReportData.needAttentionReport,
    clock_in_time: dailyReportData.clockInTime,
    clock_out_time: dailyReportData.clockOutTime,
  };
  console.log(dailyReportObjPost);
  if (uploadedSuccessful == true || !dailyReportData.dailyreportImage) {
    const response = await axios.put(
      `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&roomId=${roomId}&mode=${request_mode}`,
      dailyReportObjPost
    );
  } else {
    return "UPLOAD_FAILED";
  }
}

export async function deleteDailyReport(id) {
  const request_mode = "DELETE";
  const storedToken = await AsyncStorage.getItem("token");
  const dailyReportDelObj = { dailyreport_id: id };
  const response = await axios.post(
    `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    dailyReportDelObj
  );
  // console.log(response);
}

export async function updateUserProfile(userData) {
  const request_mode = "UPDATE_USER";
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedUID = await AsyncStorage.getItem("uid");
  const storedToken = await AsyncStorage.getItem("token");

  if (!userData.userImage) {
    console.log(!userData.userImage);
  } else {
    const createFormData = (photo, body = {}) => {
      const data = new FormData();
      const photoName_ = photo.uri.split("/");
      const photoName = photoName_[photoName_.length - 1];
      let photoType = photo.type;
      const fileType_ = photoName.split(".");

      if (photoType === "image" && photoName.search(".jpeg") > -1){
        photoType = "image/jpg";
      }
      else if(photo.type === "image" && photoName.search(".jpeg") === -1){
      photoType = "image/"+ fileType_[fileType_.length - 1];
      }
      data.append("photo", {
        name: photoName,
        type: photoType,
        size: photo.fileSize,
        uri:
          Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
      });

      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
      return data;
    };
    const formData = createFormData(userData.userImage, {
      userId: storedUID,
    });
    const responsePhotoUpload = axios.post(
      `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_upload_mode}&submit=submit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // .then((res) => {
    //   console.log(res.data);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    // console.log(photoURL);
    // console.log(formData);
  }
  // console.log(userData);
  // console.log(userData);
  const photoURL_split = userData.userImage?.uri.split("/");
  // console.log(photoURL_split);
  const photoURL_ = photoURL_split
    ? photoURL_split[photoURL_split.length - 1]
    : "NO";
  // console.log(photoURL_);
  const photoURL =
    BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;
  // console.log(photoURL);
  const userUpdateObj = {
    user_id: userData.userId,
    name: userData.userName,
    image: userData.userImage ? photoURL : userData.userImage,
  };
  // console.log(userUpdateObj);

  const response = await axios.put(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    userUpdateObj
  );
  // console.log(response);
}

export async function fetchUserAuth() {
  const request_mode = "FETCH_AUTH";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const userAuths = [];
  for (const key in response.data) {
    const userAuthObj = {
      id: response.data[key].authentication_id
        ? response.data[key].authentication_id
        : key,
      authenticationId: response.data[key].authentication_id,
      username: response.data[key].username,
      password: response.data[key].password,
      userRole: response.data[key].user_role,
      notificationId: response.data[key].notification_id,
    };
    userAuths.push(userAuthObj);
  }
  return userAuths;
}

export async function updateUserAuth(userAuthData) {
  const request_mode = "UPDATE_AUTH";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(userAuthData);
  const userAuthUpdateObj = {
    authentication_id: userAuthData.authenticationId,
    username: userAuthData.username,
    password: userAuthData.password,
    user_role: userAuthData.userRole,
  };
  const response = await axios.put(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    userAuthUpdateObj
  );
  // console.log(response);
}

export async function updateUserPushAuth(userAuthId, notificationId) {
  const request_mode = "UPDATE_AUTH";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(userAuthData);
  const userAuthUpdateObj = {
    authentication_id: userAuthId,
    notification_id: notificationId,
  };
  // console.log(userAuthUpdateObj);
  const response = await axios.put(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    userAuthUpdateObj
  );
  // console.log(response);
}

export async function deleteUserAuth(userAuthData) {
  const request_mode = "DELETE_AUTH";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(userAuthData.authenticationId);
  const deleteUserAuthObj = {
    authentication_id: userAuthData.authenticationId,
    username: userAuthData.username,
    password: userAuthData.password,
    user_role: userAuthData.userRole,
  };
  const response = await axios.post(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteUserAuthObj
  );
  // console.log(response);
}

export async function logoutUserAuth(userAuthData) {
  const request_mode = "LOGOUT_AUTH";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(userAuthData.authenticationId);
  const logoutUserAuthObj = {
    authentication_id: userAuthData.uid,
    // username: userAuthData.username,
    // password: userAuthData.password,
    // user_role: userAuthData.userRole,
  };
  const response = await axios.post(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    logoutUserAuthObj
  );
  // console.log(response);
}

// export async function updateUserPushAuth(notificationId) {
//   const request_mode = "UPDATE_AUTH";
//   const storedToken = await AsyncStorage.getItem("token");
//   // console.log(userAuthData);
//   const userAuthUpdateObj = {
//     notification_id: notificationId,
//   };
//   const response = await axios.put(
//     `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
//     userAuthUpdateObj
//   );
//   // console.log(response);
// }

export async function fetchUserChatRooms() {
  const request_mode = "FETCH_USER_WITH_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const userWithRooms = [];
  for (const key in response.data) {
    const userWithRoomObj = {
      id: response.data[key].chat_participant_id
        ? response.data[key].chat_participant_id
        : key,
      participantId: response.data[key].chat_participant_id,
      userId: response.data[key].user_id,
      roomId: response.data[key].participant_chat_room_id,
    };
    userWithRooms.push(userWithRoomObj);
  }
  return userWithRooms;
}

export async function fetchUserInChatRooms(roomId) {
  const request_mode = "FETCH_USER_IN_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(roomId);
  const response = await axios.get(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&room_id=${roomId}`
  );
  const userInRooms = [];
  for (const key in response.data) {
    const userInRoomObj = {
      id: response.data[key].user_id ? response.data[key].user_id : key,
      userId: response.data[key].user_id,
      userName: response.data[key].name,
      userImage: response.data[key].image,
      notificationId: response.data[key].notification_id,
    };
    userInRooms.push(userInRoomObj);
  }
  // console.log(userInRooms);fetchChatRooms
  return userInRooms;
}

export async function fetchChatRooms() {
  const request_mode = "FETCH";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const chatRooms = [];
  for (const key in response.data) {
    const chatRoomObj = {
      id: response.data[key].roomId ? response.data[key].roomId : key,
      roomId: response.data[key].roomId,
      roomName: response.data[key].roomName,
      roomImage: response.data[key].roomImage,
      roomLastestChat: response.data[key].roomLastestChat,
      roomLastestTime: response.data[key].roomLastestTime,
      userId: response.data[key].userId,
    };
    chatRooms.push(chatRoomObj);
  }
  // console.log(response.data);
  return chatRooms;
}

export async function storeChatRooms(roomData) {
  // console.log(roomData);
  const request_mode = "ADD";
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedToken = await AsyncStorage.getItem("token");
  const storedUID = await AsyncStorage.getItem("uid");

  if (!roomData.roomImage) {
    // console.log(!roomData.roomImage);
  } else {
    const createFormData = (photo, body = {}) => {
      const data = new FormData();
      const photoName_ = photo.uri.split("/");
      const photoName = photoName_[photoName_.length - 1];
      let photoType = photo.type;
      const fileType_ = photoName.split(".");

      if (photoType === "image" && photoName.search(".jpeg") > -1){
        photoType = "image/jpg";
      }
      else if(photo.type === "image" && photoName.search(".jpeg") === -1){
      photoType = "image/"+ fileType_[fileType_.length - 1];
      }
      data.append("photo", {
        name: photoName,
        type: photoType,
        size: photo.fileSize,
        uri:
          Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
      });

      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
      return data;
    };
    const formData = createFormData(roomData.roomImage, {
      userId: storedUID,
    });
    const responsePhotoUpload = axios.post(
      `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_upload_mode}&submit=submit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  const photoURL_split = roomData.roomImage.uri?.split("/");
  const photoURL_ = photoURL_split
    ? photoURL_split[photoURL_split.length - 1]
    : "NO";

  const photoURL =
    BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;

  const chatRoomObj = {
    name: roomData.roomName,
    image: roomData.roomImage.uri ? photoURL : roomData.roomImage,
    user_id: roomData.userId,
  };
  // console.log(chatRoomObj);
  const response = await axios.post(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    chatRoomObj
  );
  const responseRooms = [];
  for (const key in response.data) {
    const responseRoomtObj = {
      id: response.data[key].chat_room_id,
    };
    responseRooms.push(responseRoomtObj);
  }
  // console.log(responseRooms);
  return responseRooms;
}

export async function updateChatRooms(roomId, roomData) {
  let request_mode = "UPDATE_ROOM";
  const storedToken = await AsyncStorage.getItem("token");
  const request_upload_mode = "ADD_IMAGE_DR";
  const storedUID = await AsyncStorage.getItem("uid");

  if (!roomData.roomImage) {
    // console.log(!roomData.roomImage);
  } else {
    const createFormData = (photo, body = {}) => {
      const data = new FormData();
      const photoName_ = photo.uri.split("/");
      const photoName = photoName_[photoName_.length - 1];
      let photoType = photo.type;
      const fileType_ = photoName.split(".");

      if (photoType === "image" && photoName.search(".jpeg") > -1){
        photoType = "image/jpg";
      }
      else if(photo.type === "image" && photoName.search(".jpeg") === -1){
      photoType = "image/"+ fileType_[fileType_.length - 1];
      }
      data.append("photo", {
        name: photoName,
        type: photoType,
        size: photo.fileSize,
        uri:
          Platform.OS === "ios" ? photo.uri.replace("file://", "") : photo.uri,
      });

      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
      return data;
    };
    const formData = createFormData(roomData.roomImage, {
      userId: storedUID,
    });
    const responsePhotoUpload = axios.post(
      `${BACKEND_URL}/upload.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_upload_mode}&submit=submit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  const photoURL_split = roomData.roomImage.uri?.split("/");
  const photoURL_ = photoURL_split
    ? photoURL_split[photoURL_split.length - 1]
    : "NO";

  const photoURL =
    BACKEND_URL + "uploads/user_id_" + storedUID + "_" + photoURL_;

  const updateRoomObj = {
    chat_room_id: roomId,
    name: roomData.roomName,
    image: roomData.roomImage.uri ? photoURL : roomData.roomImage,
    // image: roomData.roomImage,
  };
  const response_update_room = await axios.put(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    updateRoomObj
  );
  request_mode = "ADD_PARTICIPANT";
  const addParticipantObj = {
    participant_chat_room_id: roomId,
    user_id: roomData.userId,
  };
  const response_add_participant = await axios.put(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    addParticipantObj
  );
  request_mode = "DELETE_PARTICIPANT";
  const delParticipantObj = {
    participant_chat_room_id: roomId,
    user_id: roomData.deleteUserId,
  };
  const response_delete_participant = await axios.post(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    delParticipantObj
  );
}

export async function deleteChatRooms(roomId) {
  const request_mode = "DELETE";
  const storedToken = await AsyncStorage.getItem("token");
  const chatRoomDelObj = { chat_room_id: roomId };
  const response = await axios.post(
    `${BACKEND_URL}/chat_group.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    chatRoomDelObj
  );
}

export async function fetchWasteItemCost() {
  const request_mode = "FETCH_PRICE";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/item_price.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  const itemCosts = [];
  for (const key in response.data) {
    const itemCostObj = {
      id: response.data[key].waste_item_price_id
        ? response.data[key].waste_item_price_id
        : key,
      wasteItemPriceId: response.data[key].waste_item_price_id,
      wasteItemName: response.data[key].waste_item_name,
      wasteItemPrice: response.data[key].waste_item_price,
    };
    itemCosts.push(itemCostObj);
  }
  // console.log(itemCosts);
  return itemCosts;
}

export async function updateWasteItemCost() {
  const request_mode = "UPDATE_PRICE";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(userAuthData);
  const userAuthUpdateObj = {
    authentication_id: userAuthData.authenticationId,
    username: userAuthData.username,
    password: userAuthData.password,
    user_role: userAuthData.userRole,
  };
  const response = await axios.put(
    `${BACKEND_URL}/user.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    userAuthUpdateObj
  );
}

export async function fetchUnreadReport(roomId) {
  const request_mode = "FETCH_UNREAD";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&roomId=${roomId}`
  );
  const unreadItems = [];
  for (const key in response.data) {
    const unreadItemObj = {
      unreadNum: response.data[key].unread_num,
    };
    unreadItems.push(unreadItemObj);
  }
  return unreadItems;
}
export async function fetchUnreadReportAll() {
  const request_mode = "FETCH_UNREAD_ALL";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response.data);0
  const unreadItems = [];
  for (const key in response.data) {
    // const unreadItemObj = {
    //   unreadNum: response.data[key].unread_num,
    // };
    unreadItems.push(response.data[key].unread_num);
  }
  const returnUnread = unreadItems.reduce((a, b) => a + +b, 0);
  return returnUnread;
}

export async function fetchUnreadChat(roomId) {
  const request_mode = "FETCH_UNREAD";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&roomId=${roomId}`
  );
  const unreadItems = [];
  for (const key in response.data) {
    const unreadItemObj = {
      unreadNum: response.data[key].unread_num,
    };
    unreadItems.push(unreadItemObj);
  }
  return unreadItems;
}
export async function fetchUnreadChatAll() {
  const request_mode = "FETCH_UNREAD_ALL";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response.data);0
  const unreadItems = [];
  for (const key in response.data) {
    // const unreadItemObj = {
    //   unreadNum: response.data[key].unread_num,
    // };
    unreadItems.push(response.data[key].unread_num);
  }
  const returnUnread = unreadItems.reduce((a, b) => a + +b, 0);
  return returnUnread;
}

export async function clearUnreadChat(roomId) {
  const request_mode = "CLEAR_UNREAD";
  const storedToken = await AsyncStorage.getItem("token");
  const updateReadObj = {
    room_id: roomId,
  };
  const response = await axios.put(
    `${BACKEND_URL}/chat.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&roomId=${roomId}`,
    updateReadObj
  );
  return response.data;
}

export async function addUnreadChat(roomId) {
  const request_mode = "ADD";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/chat.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&roomId=${roomId}`
  );
}
export async function updateLastestChat(roomId, message) {
  const request_mode = "UPDATE_LASTEST";
  const storedToken = await AsyncStorage.getItem("token");
  const updateChatObj = {
    lastest_chat_text: message[0].text,
  };
  // console.log(updateChatObj.lastest_chat_text);
  const response = await axios.post(
    `${BACKEND_URL}/chat.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&roomId=${roomId}`,
    updateChatObj
  );
}

//________________________MANUAL_PDF_____________________________//

export async function fetchPdfList() {
  const request_mode = "FETCH_PDF";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/pdf.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const pdfList = [];
  for (const key in response.data) {
    // console.log(response.data[key].manual_id);
    const pdfListObj = {
      manualId: response.data[key].manual_id
        ? response.data[key].manual_id
        : key,
      manualName: response.data[key].manual_name,
      manualDetail: response.data[key].manual_detail,
      manualUri: response.data[key].manual_uri,
      manualVideoUri: response.data[key].manual_video_uri,
      manualTag: response.data[key].manual_tag,
      manualEditedTimeStamp: response.data[key].manual_edited_timestamp,
    };

    pdfList.push(pdfListObj);
  }
  // console.log(pdfList);
  return pdfList;
}

export async function addPdf(manualData) {
  const request_mode = "ADD_PDF";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(manualData);
  const pdfObj = {
    manual_name: manualData.manualName,
    manual_detail: manualData.manualDetail,
    manual_uri: manualData.manualUri,
    manual_video_uri: manualData.manualVideoUri,
    manual_tag: manualData.manualTag,
  };
  const response = await axios.post(
    `${BACKEND_URL}/pdf.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    pdfObj
  );
  return response;
}

export async function updatePdf(manualData) {
  const request_mode = "UPDATE_PDF";
  const storedToken = await AsyncStorage.getItem("token");
  const pdfObj = {
    manual_id: manualData.manualId,
    manual_name: manualData.manualName,
    manual_detail: manualData.manualDetail,
    manual_uri: manualData.manualUri,
    manual_video_uri: manualData.manualVideoUri,
    manual_tag: manualData.manualTag,
  };
  const response = await axios.post(
    `${BACKEND_URL}/pdf.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    pdfObj
  );
  return response;
}

export async function deletePdf(manualData) {
  const request_mode = "DELETE_PDF";
  const storedToken = await AsyncStorage.getItem("token");
  const deletePdfObj = {
    manual_id: manualData.manualId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/pdf.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deletePdfObj
  );
  return response;
}

//________________________BULLETIN_BOARD_____________________________//
export async function fetchNewsList() {
  const request_mode = "FETCH_NEWS";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/news.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const newsList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const newsListObj = {
      newsId: response.data[key].news_id,
      // ? response.data[key].news_id
      // : key+1,
      newsAuthorId: response.data[key].news_author_id,
      newsTitle: response.data[key].news_title,
      newsDescription: response.data[key].news_description,
      newsUri: response.data[key].news_uri,
      newsUriImage: response.data[key].news_uri_image,
      newsTag: response.data[key].news_tag,
      newsEditedTimeStamp: response.data[key].news_edited_timestamp,
      newsAuthorName: response.data[key].news_author_name,
      newsAuthorImage: response.data[key].news_author_image,
    };

    newsList.push(newsListObj);
  }
  // console.log(newsList);
  return newsList;
}

export async function addNews(newsData) {
  const request_mode = "ADD_NEWS";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(newsData);
  const newsObj = {
    news_title: newsData.newsTitle,
    news_description: newsData.newsDescription,
    news_uri: newsData.newsUri,
    news_uri_image: newsData.newsUriImage,
    news_tag: newsData.newsTag,
  };
  const response = await axios.post(
    `${BACKEND_URL}/news.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    newsObj
  );
  return response;
}

export async function updateNews(newsData) {
  const request_mode = "UPDATE_NEWS";
  const storedToken = await AsyncStorage.getItem("token");
  const newsObj = {
    news_id: newsData.newsId,
    news_title: newsData.newsTitle,
    news_description: newsData.newsDescription,
    news_uri: newsData.newsUri,
    news_uri_image: newsData.newsUriImage,
    news_tag: newsData.newsTag,
  };
  const response = await axios.post(
    `${BACKEND_URL}/news.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    newsObj
  );
  return response;
}

export async function deleteNews(newsData) {
  const request_mode = "DELETE_NEWS";
  const storedToken = await AsyncStorage.getItem("token");
  const deleteNewsObj = {
    news_id: newsData.newsId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/news.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteNewsObj
  );
  return response;
}

//_______________________TASK_MANAGER_____________________________//
//_______________________FETCH_TASK_MANAGER_____________________________//
export async function fetchTaskList() {
  const request_mode = "FETCH_TASK";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const taskList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const taskListObj = {
      taskId: response.data[key].task_id,
      // ? response.data[key].news_id
      // : key+1,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      taskTitle: response.data[key].task_title,
      taskDescription: response.data[key].task_description,
      status: response.data[key].status,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      plannedStartDate: response.data[key].planned_start_date,
      plannedEndDate: response.data[key].planned_end_date,
      actualStartDate: response.data[key].actual_start_date,
      actualEndDate: response.data[key].actual_end_date,
      content: response.data[key].content,
      workhours: response.data[key].workhours,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
      assignedUserId: response.data[key].assigned_user_id,
      assignedUserName: response.data[key].assigned_user_name,
      assignedUserImage: response.data[key].assigned_user_image,
    };

    taskList.push(taskListObj);
  }
  // console.log(taskList);
  return taskList;
}

export async function fetchTaskUserCreatedList() {
  const request_mode = "FETCH_TASK_USER_CREATED";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const taskList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const taskListObj = {
      taskId: response.data[key].task_id,
      // ? response.data[key].news_id
      // : key+1,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      taskTitle: response.data[key].task_title,
      taskDescription: response.data[key].task_description,
      status: response.data[key].status,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      plannedStartDate: response.data[key].planned_start_date,
      plannedEndDate: response.data[key].planned_end_date,
      actualStartDate: response.data[key].actual_start_date,
      actualEndDate: response.data[key].actual_end_date,
      content: response.data[key].content,
      workhours: response.data[key].workhours,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
      // assignedUserId: response.data[key].assigned_user_id,
      // assignedUserName: response.data[key].assigned_user_name,
      // assignedUserImage: response.data[key].assigned_user_image,
    };

    taskList.push(taskListObj);
  }
  // console.log(taskList);
  return taskList;
}

export async function fetchTaskAssignedToMeList() {
  const request_mode = "FETCH_TASK_ASSIGNED_TO_ME";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const taskList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const taskListObj = {
      taskId: response.data[key].task_id,
      // ? response.data[key].news_id
      // : key+1,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      taskTitle: response.data[key].task_title,
      taskDescription: response.data[key].task_description,
      status: response.data[key].status,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      plannedStartDate: response.data[key].planned_start_date,
      plannedEndDate: response.data[key].planned_end_date,
      actualStartDate: response.data[key].actual_start_date,
      actualEndDate: response.data[key].actual_end_date,
      content: response.data[key].content,
      workhours: response.data[key].workhours,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
    };

    taskList.push(taskListObj);
  }
  // console.log(taskList);
  return taskList;
}

export async function fetchTaskCommentList(taskData) {
  const request_mode = "FETCH_TASK_COMMENT";
  const storedToken = await AsyncStorage.getItem("token");
  const fetchCommentObj = {
    task_id: taskData.taskId,
  };
  const response = await axios.get(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    fetchCommentObj
  );
  // console.log(response);
  const taskCommentList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const taskCommentListObj = {
      commentId: response.data[key].comment_id,
      // ? response.data[key].news_id
      // : key+1,
      taskId: response.data[key].task_id,
      activityId: response.data[key].activity_id,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      commentTitle: response.data[key].comment_title,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      content: response.data[key].content,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
    };

    taskCommentList.push(taskCommentListObj);
  }
  // console.log(taskCommentList);
  return taskCommentList;
}

export async function fetchTaskUserList() {
  const request_mode = "FETCH_USER_IN_TASK";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const taskCommentList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const taskCommentListObj = {
      assignedTaskId: response.data[key].assigned_task_id,
      taskId: response.data[key].task_id,
      userId: response.data[key].user_id,
      unread: response.data[key].unread,
      assignedUserName: response.data[key].assigned_user_name,
      assignedUserImage: response.data[key].assigned_user_image,
    };
    taskCommentList.push(taskCommentListObj);
  }
  // console.log("fetchTaskUserList");
  // console.log(taskCommentList);
  return taskCommentList;
}

//_______________________ADD_TASK_MANAGER_____________________________//

export async function addTask(taskData) {
  const request_mode = "ADD_TASK";
  const storedToken = await AsyncStorage.getItem("token");
  console.log(taskData);
  const taskObj = {
    task_title: taskData.taskTitle,
    task_description: taskData.taskDescription,
    status: taskData.status.value,
    planned_start_date: taskData.plannedStartDate,
    planned_end_date: taskData.plannedEndDate,
    actual_start_date: taskData.actualStartDate,
    actual_end_date: taskData.actualEndDate,
    content: taskData.content,
    workhours: taskData.workhours,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  const responseTasks = [];
  for (const key in response.data) {
    const responseTaskObj = {
      taskId: response.data[key].task_id,
    };
    responseTasks.push(responseTaskObj);
  }
  return responseTasks;
}

export async function addTaskAssignment(taskData, taskId) {
  // console.log("addTaskAssignment");
  // console.log(taskData);
  // console.log(taskId);

  const request_mode = "ADD_TASK_ASSIGNMENT";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(taskData);
  const taskObj = {
    task_id: taskId,
    user_id: taskData.addUserId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  return response;
}

export async function addTaskComment(taskData) {
  const request_mode = "ADD_TASK_COMMENT";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(taskData);
  const taskObj = {
    task_id: taskData.taskId,
    activity_id: taskData.activityId,
    comment_title: taskData.commentTitle,
    content: taskData.content,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  return response;
}

//_______________________UPDATE_TASK_MANAGER_____________________________//
export async function updateTask(taskId, taskData) {
  const request_mode = "UPDATE_TASK";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log("updateTask");
  // console.log(taskData);
  const taskObj = {
    task_id: taskId,
    task_title: taskData.taskTitle,
    task_description: taskData.taskDescription,
    status: taskData.status.value,
    planned_start_date: taskData.plannedStartDate,
    planned_end_date: taskData.plannedEndDate,
    actual_start_date: taskData.actualStartDate,
    actual_end_date: taskData.actualEndDate,
    content: taskData.content,
    workhours: taskData.workhours,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  return response;
}
export async function updateTaskStatus(taskData) {
  const request_mode = "UPDATE_TASK";
  const storedToken = await AsyncStorage.getItem("token");
  console.log("updateTask");
  console.log(taskData);
  const taskObj = {
    task_id: taskData.taskId,
    status: taskData.status,
    actual_start_date: taskData.actualStartDate ? taskData.actualStartDate : "",
    actual_end_date: taskData.actualEndDate ? taskData.actualEndDate : "",
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  return response;
}

export async function updateTaskComment(taskData) {
  const request_mode = "UPDATE_TASK_COMMENT";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(taskData);
  const taskObj = {
    comment_id: taskData.commentId,
    task_id: taskData.taskId,
    activity_id: taskData.activityId,
    comment_title: taskData.commentTitle,
    content: taskData.content,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    taskObj
  );
  return response;
}

//_______________________DELETE_TASK_MANAGER_____________________________//
export async function deleteTask(taskData) {
  const request_mode = "DELETE_TASK";
  // console.log("DELETE_TASK");
  // console.log(taskData);
  const storedToken = await AsyncStorage.getItem("token");
  const deleteTaskObj = {
    task_id: taskData.taskId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteTaskObj
  );
  return response;
}
export async function deleteTaskAssignment(taskData) {
  const request_mode = "DELETE_TASK_ASSIGNMENT";
  const storedToken = await AsyncStorage.getItem("token");
  const deleteTaskObj = {
    task_id: taskData.taskId,
    user_id: taskData.userId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteTaskObj
  );
  return response;
}

export async function deleteTaskComment(taskData) {
  const request_mode = "DELETE_TASK_COMMENT";
  const storedToken = await AsyncStorage.getItem("token");
  const deleteTaskObj = {
    comment_id: taskData.commentId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteTaskObj
  );
  return response;
}

//_______________________UNREAD_TASK_MANAGER_____________________________//

export async function addUnreadTask(taskData, assignedUser) {
  const request_mode = "ADD_UNREAD";
  const storedToken = await AsyncStorage.getItem("token");
  const clearUnreadObj = {
    task_id: taskData,
    user_id: assignedUser,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    clearUnreadObj
  );
  return response;
}

export async function clearUnreadTask(taskData) {
  const request_mode = "CLEAR_UNREAD";
  const storedToken = await AsyncStorage.getItem("token");
  const clearUnreadObj = {
    task_id: taskData,
  };
  const response = await axios.post(
    `${BACKEND_URL}/task_manager.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    clearUnreadObj
  );
  return response;
}

//_______________________TROUBLESHOOTING_____________________________//
//_______________________FETCH_TROUBLESHOOTING_____________________________//
export async function fetchProblemList() {
  const request_mode = "FETCH_PROBLEM";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`
  );
  // console.log(response);
  const problemList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const problemListObj = {
      problemId: response.data[key].problem_id,
      // ? response.data[key].news_id
      // : key+1,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      problemTitle: response.data[key].problem_title,
      problemDescription: response.data[key].problem_description,
      problemType: response.data[key].problem_type,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      imgUri: response.data[key].img_uri,
      videoUri: response.data[key].video_uri,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
      deletedFlg: response.data[key].deleted_flg,
    };
    problemList.push(problemListObj);
  }
  // console.log(problemList);
  return problemList;
}
export async function fetchProblemListWithTroubleshooting(problemId) {
  const request_mode = "FETCH_PROBLEM_WITH_TROUBLESHOOTING";
  const storedToken = await AsyncStorage.getItem("token");
  const problemObj = {
    problem_id: problemId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    problemObj
  );
  // console.log(response);
  const problemList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const problemListObj = {
      problemId: response.data[key].problem_id,
      // ? response.data[key].news_id
      // : key+1,
      createdBy: response.data[key].created_by,
      updatedBy: response.data[key].updated_by,
      problemTitle: response.data[key].problem_title,
      problemDescription: response.data[key].problem_description,
      problemType: response.data[key].problem_type,
      createdAt: response.data[key].created_at,
      updatedAt: response.data[key].updated_at,
      imgUri: response.data[key].img_uri,
      videoUri: response.data[key].video_uri,
      createdByName: response.data[key].created_by_name,
      createdByImage: response.data[key].created_by_image,
      updatedByName: response.data[key].updated_by_name,
      updatedByImage: response.data[key].updated_by_image,
      troubleshootingStepId: response.data[key].troubleshooting_step_id,
      troubleshootingStepInstruction:
        response.data[key].troubleshooting_step_instruction,
      troubleshootingStepStepStage:
        response.data[key].troubleshooting_step_step_stage,
      choiceLocalId: response.data[key].choice_local_id,
      choiceAction: response.data[key].choice_action,
      choiceNextStep: response.data[key].choice_next_step,
    };
    problemList.push(problemListObj);
  }
  // console.log(problemList);
  return problemList;
}
//_______________________ADD_TROUBLESHOOTING_____________________________//
export async function addProblem(problemData) {
  const request_mode = "ADD_PROBLEM";
  const storedToken = await AsyncStorage.getItem("token");

  const problemObj = {
    // problem_title: problemData.problemTitle,
    problem_title: problemData.problemTitle,
    // problem_description: problemData.problemDescription,
    problem_type: problemData.problemType,
    // img_uri: problemData.imgUri,
    // video_uri: problemData.videoUri,
    phase_detail: problemData.phaseDetail,
    choice: problemData.action,
  };
  // console.log(problemObj);

  // console.log(
  //   Object.values(problemData.phaseDetail)
  //     .map((phase) => phase.choiceData)
  //     .flat()
  // );
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    problemObj
  );
  const responseProblems = [];
  for (const key in response.data) {
    const responseProblemObj = {
      problemId: response.data[key].problem_id,
    };
    responseProblems.push(responseProblemObj);
  }
  return responseProblems;
}

//_______________________UPDATE_TROUBLESHOOTING_____________________________//
export async function updateProblem(problemData) {
  const request_mode = "UPDATE_PROBLEM";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(problemData);
  const problemObj = {
    problem_id: problemData.problemId,
    problem_title: problemData.problemTitle,
    problem_description: problemData.problemDescription,
    problem_type: problemData.problemType,
    img_uri: problemData.imgUri,
    video_uri: problemData.videoUri,
    phase_detail: problemData.phaseDetail,
    choice: problemData.action,
  };
  // console.log(problemObj);

  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    problemObj
  );
  return response;
  // return 1;
}

//_______________________DELETET_TROUBLESHOOTING_____________________________//
export async function deleteProblem(problemData) {
  const request_mode = "DELETE_PROBLEM";
  // console.log("DELETE_PROBLEM");
  // console.log(problemData);
  const storedToken = await AsyncStorage.getItem("token");
  const deleteProblemObj = {
    problem_id: problemData.problemId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteProblemObj
  );
  return response;
}

//fetch troubleshooting step
export async function fetchTroubleshootingStepList() {
  const request_mode = "FETCH_TROUBLESHOOTING_STEP";
  const storedToken = await AsyncStorage.getItem("token");
  const response = await axios.get(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}&problem_id=${problemId}`
  );
  // console.log(response);
  const troubleshootingStepList = [];
  for (const key in response.data) {
    // console.log(response.data[key].news_id);
    const troubleshootingStepListObj = {
      troubleshootingStepId: response.data[key].troubleshooting_step_id,
      problemId: response.data[key].problem_id,
      instruction: response.data[key].instruction,
      stepStage: response.data[key].step_stage,
      imgUri: response.data[key].img_uri,
      videoUri: response.data[key].video_uri,
    };
    troubleshootingStepList.push(troubleshootingStepListObj);
  }
  // console.log(problemStepList);
  return troubleshootingStepList;
}
//add troubleshooting step
export async function addTroubleshootingStep(troubleshootingStepData) {
  const request_mode = "ADD_TROUBLESHOOTING_STEP";
  const storedToken = await AsyncStorage.getItem("token");
  console.log(troubleshootingStepData);
  const troubleshootingStepObj = {
    problem_id: troubleshootingStepData.problemId,
    instruction: troubleshootingStepData.instruction,
    step_stage: troubleshootingStepData.stepStage,
    img_uri: troubleshootingStepData.imgUri,
    video_uri: troubleshootingStepData.videoUri,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    troubleshootingStepObj
  );
  const responseTroubleshootingSteps = [];
  for (const key in response.data) {
    const responseTroubleshootingStepObj = {
      troubleshootingStepId: response.data[key].troubleshooting_step_id,
    };
    responseTroubleshootingSteps.push(responseTroubleshootingStepObj);
  }
  return responseTroubleshootingSteps;
}
//update troubleshooting step
export async function updateTroubleshootingStep(
  troubleshootingStepId,
  troubleshootingStepData
) {
  const request_mode = "UPDATE_TROUBLESHOOTING_STEP";
  const storedToken = await AsyncStorage.getItem("token");
  // console.log(problemData);
  const troubleshootingStepObj = {
    troubleshooting_step_id: troubleshootingStepId,
    problem_id: troubleshootingStepData.problemId,
    instruction: troubleshootingStepData.instruction,
    step_stage: troubleshootingStepData.stepStage,
    img_uri: troubleshootingStepData.imgUri,
    video_uri: troubleshootingStepData.videoUri,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    troubleshootingStepObj
  );
  return response;
}
//delete troubleshooting step
export async function deleteTroubleshootingStep(troubleshootingStepData) {
  const request_mode = "DELETE_TROUBLESHOOTING_STEP";
  // console.log("DELETE_PROBLEM");
  // console.log(problemData);
  const storedToken = await AsyncStorage.getItem("token");
  const deleteTroubleshootingStepObj = {
    troubleshooting_step_id: troubleshootingStepData.troubleshootingStepId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteTroubleshootingStepObj
  );
  return response;
}

//_______________________ADD_TROUBLE_REPORT_____________________________//
export async function addTroubleReport(problemId, reportData, processSteps) {
  const request_mode = "ADD_TROUBLE_REPORT";
  const storedToken = await AsyncStorage.getItem("token");

  const reportObj = {
    problem_id: problemId,
    report_data: reportData.reportData,
    img_uri: reportData.troublereportImage,
    // video_uri: reportData.videoUri,
    process_steps: processSteps,
    customer_score: reportData.customerScore,
    staff_score: reportData.staffScore,
  };
  // console.log(reportObj);

  // console.log(
  //   Object.values(reportData.phaseDetail)
  //     .map((phase) => phase.choiceData)
  //     .flat()
  // );
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    reportObj
  );
  const responseReports = [];
  for (const key in response.data) {
    const responseReport = {
      reportId: response.data[key].trouble_report_id,
    };
    responseReports.push(responseReport);
  }
  return responseReports;
}

export async function deleteTroubleReport(reportData) {
  const request_mode = "DELETE_TROUBLE_REPORT";
  // console.log("DELETE_PROBLEM");
  // console.log(reportData);
  const storedToken = await AsyncStorage.getItem("token");
  const deleteReportObj = {
    trouble_report_id: reportData.troubleReportId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/troubleshooting.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    deleteReportObj
  );
  return response;
}

export async function addDailyRpCommentNums(dailyreportId) {
  const request_mode = "ADD_COMMENT_NUMS";
  const storedToken = await AsyncStorage.getItem("token");
  const addCommentNumObj = {
    dailyreport_id: dailyreportId,
  };
  const response = await axios.post(
    `${BACKEND_URL}/dailyreport_detail.php?&key=${API_KEY}&idToken=${storedToken}&mode=${request_mode}`,
    addCommentNumObj
  );
  return response;
}
