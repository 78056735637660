import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import ProfileOutput from "../component/Profile/ProfileOutput";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { UsersContext } from "../store/user-context";
import { fetchUsers, updateUserProfile } from "../util/http";
import { AuthContext } from "../store/auth-context";

function UserProfile({ navigation }) {
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();

  const [error, setError] = useState();
  const authCtx = useContext(AuthContext);

  const userCtx = useContext(UsersContext);
  const isFocused = useIsFocused();
  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        userCtx.setUser(userList);
      } catch (error) {
        setError("Could not fetch user lists!");
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      const storedUid = await AsyncStorage.getItem("uid");
      setUsingId(storedUid);
    }
    getUsersList();
    fetchUserData();
  }, [navigation, isSubmitting]);
  async function confirmHandler(userData) {
    // setIsSubmitting(true);
    try {
      console.log(userData);
      // authCtx.setOwnerUserName(userData.userName);
      await updateUserProfile(userData);
      const userList = await fetchUsers();
      userCtx.setUser(userList);
      // userCtx.updateUser(selectedUser.id, userData);
    } catch (error) {
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      //   setIsSubmitting(false);
    }
  }
  if (error && !isFetching) {
    return (
      <>
        <ErrorOverlay
          message={error}
          onPress={() => {
            setIsSubmitting(true);
            setError();
          }}
        />
      </>
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }
  const selectedUser = userCtx.users.find((users) => users.userId === usingId);
  //   console.log(selectedUser);

  return (
    <ProfileOutput
      userData={selectedUser}
      fallbackText={"ユーザー内容が取得できません "}
      onConfirm={confirmHandler}
    />
  );
}
export default UserProfile;
