import { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Modal,
  Dimensions,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import IconButton from "../component/UI/IconButton";
import { GlobalStyles } from "../constant/styles";
// import Pdf from "react-native-pdf";
// import SQLite from "react-native-sqlite-storage";
import {
  addPdf,
  addProblem,
  deletePdf,
  deleteProblem,
  fetchProblemList,
  fetchProblemListWithTroubleshooting,
  updatePdf,
  updateProblem,
} from "../util/http";
import StoreManualEditForm from "../component/StoreManual/StoreManualEditForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../store/auth-context";
import NetInfo from "@react-native-community/netinfo";
import { Link } from "@react-navigation/native";
import { auth, storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";
import PdfItem from "../component/StoreManual/PdfItem";
import { SwipeListView } from "react-native-swipe-list-view";
import { Pressable } from "react-native";
import TroubleshootingEditForm from "../component/Troubleshooting/TroubleshootingEditForm";
import ProblemItem from "../component/Troubleshooting/ProblemItem";
import { verifyDelete } from "../util/verifyDelete";
import { getscaledDimension } from "../util/deviceUiInfo";
import alert from "../util/alertWeb";

function TroubleShootingMainScreen({ navigation }) {
  const [problemList, setProblemList] = useState([]);
  const [isOnline, setIsOnline] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("トラブルシュート追加");
  const [isProblemEditing, setIsProblemEditing] = useState(false);
  const [isAbleToEdit, setIsAbleToEdit] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(false);
  const [userRole, setUserRole] = useState();
  const [pdfData, setPdfData] = useState({});
  const [problemData, setProblemData] = useState({});
  const { widthScreen, heightScreen } = Dimensions.get("window");
  const [curPdf, setCurPdf] = useState({});
  const [problemDataWithTroubleshooting, setProblemDataWithTroubleshooting] =
    useState([]);
  // pdfData;
  // console.log(userData);
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale*0.7); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32*fontScale*0.7;
  useEffect(() => {
    // Check if the device is online
    const handleConnectivityChange = (isConnected) => {
      setIsOnline(isConnected);
    };
    NetInfo.addEventListener(handleConnectivityChange);
    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      setUserRole(storedRole);
      switch (storedRole) {
        case "admin":
          setIsAbleToEdit(true);
          break;
        case "manager":
          setIsAbleToEdit(true);
          break;
        case "store":
          setIsAbleToEdit(false);
          break;
        default:
          setIsAbleToEdit(true);
      }
    }
    fetchRole();
    return () => {
      // SQLite.close();
      // NetInfo.removeEventListener(handleConnectivityChange);
    };
  }, [navigation]);

  useEffect(() => {
    if (isOnline) {
      fetchProblem();
    }
  }, [isOnline]);

  const fetchProblem = async () => {
    try {
      const response = await fetchProblemList();
      const filteredResponse = response.filter((obj) => obj.deletedFlg === "0");
      setProblemList(filteredResponse);
      // console.log(filteredResponse);
      // savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchEditingProblem = async (index) => {
    try {
      const response = await fetchProblemListWithTroubleshooting(index);
      setProblemDataWithTroubleshooting(response);
      // console.log(problemDataWithTroubleshooting);
      // console.log(response);
      // savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };
  const renderItem = ({ item }) => {
    // console.log(item);
    return (
      <View key={item.problemId}>
        <Pressable
          style={({ pressed }) => [pressed && styles.pressed]}
          onPress={() => handleProblemPress(item)}
        >
          <ProblemItem item={item} />
        </Pressable>
        {/* {isEditMode &&(
        <View>
        <PdfItem  item ={item} editingMode={isEditMode}/>
        <View>
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={editManualPressHandler(item)}
          />
          <IconButton
            icon="trash"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={deleteManualPressHandler(item)}
          />
          </View>
          </View>)} */}
      </View>
    );
  };
  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...problemList];
    const prevIndex = problemList.findIndex((item) => item.id === rowKey);
    newData.splice(prevIndex, 1);
    setProblemList(newData);
  };

  const renderHiddenItem = (itemData, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => {
          editPressHandler(itemData.item);
          // console.log(itemData);
          closeRow(rowMap, itemData.item.problemId);
        }}
      >
        <Text style={styles.backTextWhite}>編集</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={async () => {
          const confirmDel = await verifyDelete("トラブルシュートの削除");
          if (confirmDel) {
            // console.log(itemData.item);
            deleteProblem(itemData.item);
            // deleteManualPressHandler(itemData.item);
            deleteRow(rowMap, itemData.item.problemId);
          }
        }}
      >
        <Text style={styles.backTextWhite}>削除</Text>
      </TouchableOpacity>
    </View>
  );

  const handleProblemPress = async (item) => {
    // setPdfModalVisible(!pdfModalVisible);
    navigation.navigate("TroubleShootingGuideScreen", {
      problemId: item.problemId,
      problemTitle: item.problemTitle,
    });
    // setCurPdf(pdf);
    // console.log(pdf.manualUri);
  };

  async function troubleEditSubmitHandler(problemData) {
    setModalVisible(!modalVisible);
    if (isProblemEditing) {
      try {
        const respponse = await updateProblem(problemData);
        // console.log(respponse);
        // console.log(problemData);
      } catch (err) {
        console.log(err);
      }
      // fetchProblem();
    } else {
      try {
        // console.log(problemData);
        const respponse = await addProblem(problemData);
        // console.log(respponse);
      } catch (err) {
        console.log(err);
      }
      // fetchProblem();
    }
  }

  function troubleEditCancelHandler() {
    setModalVisible(!modalVisible);
  }

  const addProblemPressHandler = () => {
    setProblemData();
    setProblemDataWithTroubleshooting();
    setModalVisible(!modalVisible);
    setIsProblemEditing(false);
    setModalTitle("トラブルシュート追加");
  };

  // const editModeManualPressHandler = () => {
  //   setIsEditMode(!isEditMode);
  // };

  const editPressHandler = (problem) => {
    setModalVisible(!modalVisible);
    fetchEditingProblem(problem.problemId);
    setProblemData(problem);
    setIsProblemEditing(true);
    setModalTitle("トラブルシュート編集");
  };

  const deleteManualPressHandler = async (manualData) => {
    try {
      const respponse = await deletePdf(manualData);
      // console.log(respponse);
    } catch (err) {
      console.log(err);
    }
    fetchProblem();
  };

  const staffEvalPressHandler = () => {};

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };
  // const fallbackText ="サーバーの問題でマニュアルリストを表示できません　- 後でもう一度試してください";
  let problemListContent = (
    <Text style={styles.infoText}>
      <Text style={styles.infoTextHeader}>{`サーバーと接続中...`}</Text>
      {`
    
    サーバーの問題が発生する時もありので。一定期間、お知らせが出なかったら後でもう一度試してください`}
    </Text>
  );

  // const handlePress = () => {};
  if (problemList.length > 0) {
    if (isAbleToEdit) {
      problemListContent = (
        <SwipeListView
          useFlatList={true}
          data={problemList}
          renderItem={renderItem}
          keyExtractor={(item) => item.problemId}
          renderHiddenItem={renderHiddenItem}
          rightOpenValue={-150}
          onRowDidOpen={onRowDidOpen}
          disableRightSwipe={true}
          closeOnScroll={true}
        />
      );
    } else {
      problemListContent = (
        <FlatList
          data={problemList}
          renderItem={renderItem}
          keyExtractor={(item) => item.problemId}
        />
      );
    }
  }
  return (
    <>
      <View style={styles.titleContainerIconLeft}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <IconButton
            icon="home"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={() => {
              navigation.goBack();
            }}
            styleButtonContainer={styles.homeIconContainer}
          ></IconButton>
          <View style={styles.iconLeftText}>
            <Text style={styles.textHome}>ホームへ</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.titleContainerIcon}>
        {isAbleToEdit && (
          <IconButton
            icon="add-circle"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={addProblemPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )}
        {/* {!isAbleToEdit && (
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={32}
            onPress={editModeManualPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )} */}
        {/* <IconButton
          icon="create"
          color={GlobalStyles.colors.neutral500}
          size={32}
          onPress={staffEvalPressHandler}
          styleButtonContainer={styles.evalIconContainer}
          disabled={true}
        /> */}
      </View>
      <View style={styles.rootContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
            <View style={styles.titleContainerText}>
              <Text style={styles.title}>トラブルシュート</Text>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          {problemListContent}

          {modalVisible && (
            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                alert("ページが閉じる");
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={styles.modalTitleContainer}>
                    <Text style={styles.titleText}>{modalTitle}</Text>
                  </View>
                  <View>
                    <TroubleshootingEditForm
                      onSubmit={troubleEditSubmitHandler}
                      onCancel={troubleEditCancelHandler}
                      defaultValues={problemData}
                      defaultWithDetails={problemDataWithTroubleshooting}
                      userRole={userRole}
                      isModalOpen={modalVisible}
                    />
                  </View>
                </View>
              </View>
            </Modal>
          )}
        </View>
      </View>

    </>
  );
}

export default TroubleShootingMainScreen;

const makeStyles = (hieghtScale,fontScale) => StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    marginHorizontal: "3%",
    marginBottom: 50,
  },
  headerContainer: {
    marginTop: "3%",
    flex: 1/(hieghtScale),
    flexDirection: "row",
  },
  contentContainer: {
    flex: 10,
  },
  pdfContainer: {
    flex: 1,
  },
  pdf: {
    // flex: 1,
    width: Dimensions.get("window").width,
    // height: "80%",
    height: Dimensions.get("window").height * 0.8,
  },
  subMenuContainer: {
    flex: 2,
    // marginBottom: 50,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainerIcon: {
    flexDirection: "row",
    position: "absolute",
    marginTop: "3%",
    right: "2%",
    zIndex: 5,
  },
  titleContainerIconLeft: {
    position: "absolute",
    marginTop: "3%",
    left: "4%",
    zIndex: 5,
  },
  closePdfModalContainer: {
    // position: "absolute",
  },
  iconContainer: {
    marginHorizontal: 1,
    paddingLeft: 1,
    paddingRight: 6,
  },
  evalIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
  },
  homeIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
    paddingBottom: 0,
  },
  menuRowContainer: {
    flex: 1,
    flexDirection: "row",
  },
  buttonContainer: {
    marginHorizontal: "2.5%",
    width: "45%",
    height: "100%",
  },
  subbuttonContainer: {
    marginVertical: 10,
    width: "95%",
    alignSelf: "center",
  },
  button: {
    marginTop: "5%",
    height: "95%",
  },
  buttonDisabled: {
    opacity: 0.75,
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  subbutton: {
    minWidth: 341,
    height: 65,
  },
  titleContainerText: {},
  title: {
    fontSize: 28*fontScale,
    marginBottom: 4,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  buttonText: {
    marginHorizontal: 16,
    fontSize: 26*fontScale,
  },
  subbuttonText: {
    fontSize: 26*fontScale,
  },
  textReport: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16*fontScale,
  },
  textHome: {
    color: GlobalStyles.colors.primary600,
    fontSize: 16*fontScale,
  },


  centeredView: {
    // justifyContent: "center",
    // alignItems: "center",
    marginTop: 1,
  },
  modalView: {
    marginHorizontal: 20,
    marginTop: "20%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    paddingVertical: 10,
    // paddingHorizontal: 14,
    // alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxHeight: "90%",
    overflow: "visible",
  },
  modalTopContainer: {
    flexDirection: "row",
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
    alignItems: "center",
  },
  pdfModalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    alignContent: "center",
    alignItems: "center",
    flex: 20,
    marginVertical: 10,
  },
  modalCloseContainer: {
    alignContent: "center",
    alignItems: "center",
    flex: 1,
  },
  pdfCloseIconContainer: {
    marginHorizontal: 0,
    marginVertical: 10,
    padding: 0,
    alignContent: "center",
    alignItems: "center",
  },
  pdfCenteredView: {
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  pdfModalView: {
    margin: 20,
    marginTop: "15%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  titleText: {
    fontSize: 20*fontScale,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  pdfContainer: {
    marginHorizontal: "5%",
    marginBottom: 15,
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginHorizontal: "5%",
    marginBottom: 15,
    borderRadius: 10,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16*fontScale,
  },
  pressed: {
    opacity: 0.9,
  },
  // infoText: {
  //   color: GlobalStyles.colors.primary600,
  //   fontSize: 22,
  //   textAlign: "center",
  //   marginTop: 32,
  // },
  infoText: {
    color: GlobalStyles.colors.primary600,
    textAlign: "center",
  },
  infoTextHeader: {
    color: GlobalStyles.colors.primary600,
    fontSize: 20*fontScale,
    fontWeight: "600",
  },
});
