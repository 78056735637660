import { View, TextInput, StyleSheet, Text, Alert } from "react-native";
import { useState } from "react";
import Button from "../UI/Button";
import { getFormattedDate } from "../../util/date";
import { GlobalStyles } from "../../constant/styles";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoadingOverlay from "../UI/LoadingOverlay";
import ErrorOverlay from "../UI/ErrorOverlay";
import "firebase/storage";
import { storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";
import * as Progress from "react-native-progress";
import Input from "../DailyReport/Input";
import ImagePicker from "../DailyReport/ImagePicker";
import StarRating from "react-native-star-rating-widget";
import { getscaledDimension } from "../../util/deviceUiInfo";

function TroubleReportForm({
  submitButtonLabel,
  headerFormLabel,
  onCancel,
  onSubmit,
  defaultValues,
  recordActionList,
}) {
  // const [userType, setUserType] = useState("PERSON");
  const [isFetching, setIsFetching] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(
    "しばらくお待ちください、アップロードしています。。。"
  );
  const [troubleReportImageURL, setTroubleReportImageURL] = useState(0);
  // const [rating, setRating] = useState(0);
  const [UID, setUID] = useState("");
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale*0.7); // pass in fontScale to the StyleSheet
  let sumWasteCost = 0;
  let calWasteCost = 0;

  const authCtx = useContext(AuthContext);
  // console.log(defaultValues);
  const [inputs, setInput] = useState({
    reportData: {
      value: defaultValues ? defaultValues.reportData : "",
      isValid: true,
    },
    troubleReport: {
      value: defaultValues ? defaultValues.troubleReport : "",
      isValid: true,
    },
    troublereportImage: {
      value: defaultValues ? defaultValues.troublereportImage : "",
      isValid: true,
    },
    troublereportDate: {
      value: defaultValues
        ? defaultValues.troublereportDate
        : getFormattedDate(new Date()),
      isValid: true,
    },
    customerScore: {
      value: defaultValues ? defaultValues.customerScore : "",
      isValid: true,
    },
    staffScore: {
      value: defaultValues ? defaultValues.staffScore : "",
      isValid: true,
    },
  });
  useEffect(() => {
    // async function loadData() {
    //   // console.log("READ storedValues");
    //   const storedValues = await AsyncStorage.getItem("storedFormValues");
    //   // console.log("storedValues");
    //   // console.log(storedValues);
    //   // console.log(typeof defaultValues === "undefined");
    //   if (storedValues && typeof defaultValues === "undefined") {
    //     setInput(JSON.parse(storedValues));
    //   } else {
    //   }
    // }
    async function fetchRole() {
      setIsFetching(true);
      const uid = await AsyncStorage.getItem("uid");
      setUID(uid);
      const storedRole = await AsyncStorage.getItem("role");
      // if (storedRole === "store") {
      //   setUserType("STORE");
      // } else {
      //   setUserType("PERSON");
      // }
      // inputChangedHandler("dailyreportType", userType);
      setIsFetching(false);
    }

    fetchRole();
    // loadData();
  }, []);

  async function inputChangedHandler(inputIdentifier, enteredValue) {
    // console.log(inputIdentifier + enteredValue);
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      };
    });
    await AsyncStorage.setItem("storedFormValues", JSON.stringify(inputs));
    // console.log("SET storedValues");
    // console.log(inputs);
  }

  const clearData = async () => {
    try {
      await AsyncStorage.removeItem("storedFormValues");
      console.log("Data cleared successfully");
    } catch (error) {
      console.log("Error clearing data", error);
    }
  };
  function submitHandler() {
    const troubleReportData = {
      reportData: inputs.reportData.value,
      troubleReport: inputs.troubleReport.value,
      troublereportImage: inputs.troublereportImage.value,
      customerScore: inputs.customerScore.value,
      staffScore: inputs.staffScore.value,
    };

    // if (!dateIsValid) {
    //   // show feedback
    //   //   Alert.alert("Invalid input", "Please check your input values");
    //   setInput((curInputs) => {
    //     return {
    //       reportData: {
    //         value: curInputs.reportData.value,
    //       },
    //       troubleReport: {
    //         value: curInputs.troubleReport.value,
    //       },
    //       troublereportImage: {
    //         value: curInputs.troublereportImage.value,
    //       },
    //     };
    //   });
    //   return;
    // }
    clearData();
    onSubmit(troubleReportData);
  }
  const formIsInvalid = !inputs.reportData.isValid;

  async function onTakenImageHandler(onTakenImage) {
    const handleUpload = async (result) => {
      try {
        // console.log("result");
        // console.log(result);
        setIsUpload(true);
        setProgress(0);
        setProgressStatus(
          "しばらくお待ちください、アップロードしています。。。"
        );
        const imageUri = result.uri.split("/");
        const lastImageUri = imageUri[imageUri.length - 1];
        const newsRef = ref(
          storage,
          `troublereport_picture/UID_${UID}_${lastImageUri}`
        );

        // Create a File object from the selected file
        const blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            reject(new TypeError("Network request failed"));
          };
          xhr.responseType = "blob";
          xhr.open("GET", result.uri, true);
          xhr.send(null);
        });

        // Get metadata properties
        getMetadata(newsRef)
          .then((metadata) => {
            // Metadata now contains the metadata for 'images/forest.jpg'
            // console.log(metadata);
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });

        const metadata = {
          contentType: "image/jpeg",
        };

        // Create a task to upload the PDF file
        const task = uploadBytesResumable(newsRef, blob, metadata);
        // console.log("task");
        // console.log(task);
        // Monitor the task's progress
        task.on(
          "state_changed",
          (snapshot) => {
            // Get the task progress, including the number of bytes uploaded and the total number of bytes
            // console.log(snapshot);
            // const progress =  Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
            // console.log(`Upload is  ${progress} % done`);
            // setProgress(progress);
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              // console.log("File available at", downloadURL);
              setTroubleReportImageURL(downloadURL);
              inputChangedHandler("troublereportImage", downloadURL);
              // downloadURL;
              // return outURL;
            });
            setProgress(1);
            setProgressStatus("アップロードが完了しました。");
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
    handleUpload(onTakenImage);
    // console.log(troubleReportImageURL);
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }
  return (
    <View style={styles.form}>
      <Text style={styles.title}>{headerFormLabel}</Text>
      {recordActionList}
      <View style={styles.ratingContainer}>
        <View style={styles.ratingTextContainer}>
          <Text style={styles.ratingText}>顧客満足度</Text>
        </View>
        <View style={styles.ratingStarContainer}>
          <StarRating
            rating={inputs.customerScore.value}
            onChange={inputChangedHandler.bind(this, "customerScore")}
            color={GlobalStyles.colors.primary600}
          />
        </View>
      </View>
      <View style={styles.ratingContainer}>
        <View style={styles.ratingTextContainer}>
          <Text style={styles.ratingText}>店員満足度</Text>
        </View>
        <View style={styles.ratingStarContainer}>
          <StarRating
            rating={inputs.staffScore.value}
            onChange={inputChangedHandler.bind(this, "staffScore")}
            color={GlobalStyles.colors.primary600}
          />
        </View>
      </View>
      <Text style={styles.remarkText}>
        ＊星の数が多い評価ほど、より高い評価となります。
      </Text>
      <Input
        label="コメント"
        // invalid={!inputs.dailyReport.isValid}
        invalid={false}
        styleLabel={{
          color: GlobalStyles.colors.primary600,
          fontWeight: "bold",
        }}
        textInputConfig={{
          multiline: true,
          onChangeText: inputChangedHandler.bind(this, "reportData"),
          value: inputs.reportData.value,
          color: GlobalStyles.colors.primary600,
        }}
      />
      <ImagePicker
        textInput={"画像を取得する"}
        onTakenImage={onTakenImageHandler}
      />
      {isUpload ? (
        <View style={styles.progressBarContainer}>
          <Text style={[styles.progressText, styles.textBase]}>
            {progressStatus}
          </Text>
          <Progress.Bar
            progress={progress}
            width={300}
            color={"rgba(251, 254, 238, 1)"}
          />
        </View>
      ) : null}
      {formIsInvalid && (
        <Text style={styles.errorText}>Invalid input data</Text>
      )}

      <View style={styles.buttons}>
        <Button
          style={styles.button}
          mode="flat"
          onPress={onCancel}
          styleText={{ color: GlobalStyles.colors.primary700 }}
        >
          キャンセル
        </Button>
        <Button
          style={styles.button}
          onPress={submitHandler}
          styleButton={{ backgroundColor: GlobalStyles.colors.primary700 }}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </View>
  );
}
export default TroubleReportForm;

const makeStyles = (hieghtScale,fontScale) => StyleSheet.create({
  form: { flex: 1 },

  title: {
    fontSize: 28*fontScale,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
    marginTop: 12,
    marginBottom: 24,
    textAlign: "center",
  },
  inputsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowInput: {
    flex: 1,
  },
  errorText: {
    textAlign: "center",
    color: GlobalStyles.colors.error500,
    margin: 8,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },
  wasteTitle: {
    fontSize: 20*fontScale,
    fontWeight: "bold",
    color: "white",
  },
  wasteListContainer: {},
  wasteItemContainer: { flexDirection: "row" },
  progressBarContainer: { alignItems: "center" },
  progressText: {
    fontSize: 12*fontScale,
    marginVertical: 10,
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  ratingContainer: {
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 12,
  },
  ratingTextContainer: { flex: 1 },
  ratingText: {
    fontSize: 16*fontScale,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
  },
  ratingStarContainer: { flex: 2 },
  remarkText: {
    color: GlobalStyles.colors.error600,
    fontSize: 12*fontScale,
  },
});
