import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;
const desiredWidth = screenWidth * 0.8; // Set this to whatever fraction of the screen width you prefer

const screenContainerWeb = ({ children }) => (
  <View style={[styles.container, { width: desiredWidth }]}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center', // Centers the container
    flex: 1, // Takes full height of the screen
    alignItems: 'center', // Aligns children components in the center
    justifyContent: 'center', // Centers children components vertically
  }
});

export default screenContainerWeb;
