import { Pressable, Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";

function Button({
  children,
  onPress,
  mode,
  style,
  styleText,
  styleButton,
  disabled = false,
}) {
  return (
    // <View style={style}>
    <View
      style={[
        styles.button,
        mode === "flat" && styles.flat,
        style,
        styleButton,
      ]}
    >
      <Pressable
        onPress={onPress}
        style={({ pressed }) => pressed && styles.pressed}
        disabled={disabled}
      >
        <Text
          style={[
            styles.buttonText,
            mode === "flat" && styles.flatText,
            styleText,
          ]}
        >
          {children}
        </Text>
      </Pressable>
    </View>
  );
}
export default Button;

const styles = StyleSheet.create({
  button: {
    borderRadius: 100,
    padding: 8,
    backgroundColor: GlobalStyles.colors.primary600,
  },
  flat: {
    backgroundColor: "transparent",
  },
  buttonText: {
    color: "white",
    textAlign: "center",
  },
  flatText: {
    color: GlobalStyles.colors.primary10,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 100,
  },
});
