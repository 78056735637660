import { useIsFocused } from "@react-navigation/native";
import { useContext, useLayoutEffect, useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import UserOutput from "../component/User/UserOutput";
import { GlobalStyles } from "../constant/styles";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
import { UsersContext } from "../store/user-context";
import { fetchUserInRooms, fetchUsers } from "../util/http";

function DailyRoomMember({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const userCtx = useContext(UsersContext);
  const curRoomId = route.params?.params.roomId;
  const isFocused = useIsFocused();

  useLayoutEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUserInRooms(curRoomId);
        userCtx.setUser(userList);
      } catch (error) {
        setError("Could not fetch user lists!");
      }
      setIsFectching(false);
    }
    getUsersList();
  }, [curRoomId]);

  return (
    <>
      <View style={styles.form}>
        <View style={styles.containerHeader}>
          <Text style={styles.title}>{"グループのメンバー表"}</Text>
        </View>
        <View style={styles.container}>
          <UserOutput
            isMemberlist={true}
            users={userCtx.users}
            fallbackText="登録されたユーザーがありません"
            onCheck={() => {}}
            defaultUser={""}
            styleContainer={styles.userOutputContainer}
          />
        </View>
      </View>
    </>
  );
}

export default DailyRoomMember;

const styles = StyleSheet.create({
  form: { flex: 1 },
  containerHeader: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    paddingTop: 24,
  },
  container: {
    flex: 10,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.primary600,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    marginVertical: 12,
    textAlign: "center",
  },
  userOutputContainer: {
    marginTop: 2,
  },
});
