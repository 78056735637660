import { View, Text, TextInput, StyleSheet } from "react-native";

import { GlobalStyles } from "../../constant/styles";
import { InputOutline } from "react-native-input-outline";

function Input({
  label,
  keyboardType,
  secure,
  onUpdateValue,
  value,
  isInvalid,
  placeholderValue,
  labelStyle,
  multiline,
  numberOfLines = 1,
}) {
  return (
    <View style={styles.inputContainer}>
      <Text
        style={[styles.label, isInvalid && styles.labelInvalid, labelStyle]}
      ></Text>
      <InputOutline
        style={[styles.input, isInvalid && styles.inputInvalid]}
        autoCapitalize="none"
        keyboardType={keyboardType}
        secureTextEntry={secure}
        onChangeText={onUpdateValue}
        value={value}
        placeholder={placeholderValue}
        backgroundColor={GlobalStyles.colors.neutral10}
        activeColor={GlobalStyles.colors.primary500}
        inactiveColor={GlobalStyles.colors.primary600}
        fontColor={GlobalStyles.colors.primary600}
        multiline={multiline}
        numberOfLines={numberOfLines}
      />
    </View>
  );
}

export default Input;

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 2,
  },
  label: {
    color: "white",
    marginBottom: 4,
    color: GlobalStyles.colors.primary600,
  },
  labelInvalid: {
    color: GlobalStyles.colors.error500,
  },
  input: {
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: GlobalStyles.colors.neutral0,
    borderRadius: 4,
    fontSize: 20,
    color: GlobalStyles.colors.primary600,
    minWidth: 250,
    width: "100%",
  },
  inputInvalid: {
    backgroundColor: GlobalStyles.colors.error100,
  },
});
