import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getscaledDimension } from "../../util/deviceUiInfo";
// import LinearGradient from 'react-native-linear-gradient';
function NewsItem({ item, editingMode = false }) {
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(fontScale*0.7); // pass in fontScale to the StyleSheet

  return (
    <View
      style={[
        styles.newsListContainer,
        editingMode === true && styles.editingMode,
      ]}
    >
      <Image
        source={{
          uri:
            item?.newsUriImage ??
            "https://firebasestorage.googleapis.com/v0/b/manager-app-be1e3.appspot.com/o/news_picture%2Fbase_icon.png?alt=media&token=64f9ca4d-1687-4036-a67d-3098b361b8a1",
          cache: "force-cache",
        }}
        resizeMode={"cover"}
        style={styles.image}
      />
      <View style={styles.newsListTilteLayout}>
        <View style={styles.newsListTilteContainer}>
          <Text style={styles.newsListTilte}>{item.newsTitle}</Text>
          <Text
            style={styles.newsListDetail}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {item.newsDescription}
          </Text>
        </View>
        <View style={styles.dateContainer}>
          <Text style={[styles.textBase, styles.reportDate]}>
            {`${item.newsEditedTimeStamp.split(" ")[1].slice(0, 5)}`}
          </Text>
          <Text style={[styles.textBase, styles.reportMonthYear]}>
            {`${item.newsEditedTimeStamp.split(" ")[0].split("-")[2]}日${
              item.newsEditedTimeStamp.split("-")[1]
            }月${item.newsEditedTimeStamp.split("-")[0]}年`}
          </Text>
        </View>
      </View>
    </View>
  );
}
export default NewsItem;

const boxShadow = Platform.select({
  ios: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
  android: { elevation: 6 },
});

const makeStyles = (fontScale) =>StyleSheet.create({
  newsListContainer: {
    marginHorizontal: "5%",
    marginBottom: 15,
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
    height: 400,
    // height: 600,
    ...boxShadow,
  },
  // imageContainer: { flex: 1 },
  image: {
    flex: 1,
    borderRadius: 10,
    resizeMode:"contain",
    height: 300,
  },
  newsListTilteLayout: {
    // flexDirection: "row",
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    // height: 100,
    paddingLeft: 16,
    paddingRight: 10,
    alignItems: "flex-start",
    backgroundColor: "rgba(251, 254, 238, 0.8)",
  },
  newsListTilteContainer: {
    // justifyContent: 'flex-end',
    // marginHorizontal: "5%",
    // marginVertical: "2%",
    // opacity: 0.5,
  },
  newsListTilte: {
    fontSize: 20*fontScale,
    fontWeight: "600",
    color: GlobalStyles.colors.primary600,
    // paddingBottom: 24,
  },
  dateContainer: {
    marginTop: "2%",
    // marginHorizontal: "2%",
    // flex: 4,
    // position: "absolute",
    // right: 16,
    // bottom: 8,
    // alignItems: "flex-end",
    alignSelf:"flex-end"
    // marginHorizontal: "5%",
    // marginVertical: "2%",
    // flexDirection: "row",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  reportDate: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  reportMonthYear: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  newsListDetailContainer: {
    position: "absolute",
    // marginLeft: "8%",
    // marginRight: "5%",
    // width: '100%',
    left: 12,
    // bottom: 8,
    // marginBottom: "2%",
  },
  newsListDetail: {
    fontSize: 14*fontScale,
    color: GlobalStyles.colors.primary600,
  },
  editingMode: {
    flex:1,
    opacity: 0.3,
    backgroundColor: GlobalStyles.colors.neutral100,
  },
});
