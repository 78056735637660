import { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Modal,
  Dimensions,
  Button,
  Platform,
  KeyboardAvoidingView,
  Alert,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import IconButton from "../component/UI/IconButton";
import { GlobalStyles } from "../constant/styles";
// import Pdf from "react-native-pdf";
// import SQLite from "react-native-sqlite-storage";
import { addNews, deleteNews, fetchNewsList, updateNews } from "../util/http";
import StoreManualEditForm from "../component/StoreManual/StoreManualEditForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../store/auth-context";
import NetInfo from "@react-native-community/netinfo";
import { Link } from "@react-navigation/native";
import { auth, storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";
// import PdfItem from "../component/StoreManual/PdfItem";
import { SwipeListView } from "react-native-swipe-list-view";
import { Pressable } from "react-native";
import NewsEditForm from "../component/BulletinBoard/NewsEditForm";
import NewsItem from "../component/BulletinBoard/NewsItem";
import { Image } from "react-native";
import { useCallback } from "react";
import { ScrollView } from "react-native";
import * as Linking from "expo-linking";
import { verifyDelete } from "../util/verifyDelete";
import { getscaledDimension } from "../util/deviceUiInfo";
import alert from "../util/alertWeb";

function BulletinBoardScreen({ navigation }) {
  const [newsList, setNewsList] = useState([]);
  const [isOnline, setIsOnline] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [newsModalVisible, setNewsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("マニュアル追加");
  const [isNewsEditing, setIsNewsEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(false);
  const [userRole, setUserRole] = useState();
  const [newsData, setNewsData] = useState({});
  const { widthScreen, heightScreen } = Dimensions.get("window");
  const [curPdf, setCurPdf] = useState({});
  // NewsData;
  // console.log(userData);
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale*0.7,widthScreen); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32*fontScale*0.7;
  useEffect(() => {
    // Check if the device is online
    const handleConnectivityChange = (isConnected) => {
      setIsOnline(isConnected);
    };
    NetInfo.addEventListener(handleConnectivityChange);
    // SQLite.openDatabase(
    //   { name: "mydb.db", location: "default", createFromLocation: "~mydb.db" },
    //   (db) => {
    //     db.transaction((tx) => {
    //       tx.executeSql(
    //         "SELECT * FROM pdfs",
    //         [],
    //         (_, { rows: { _array } }) => {
    //           if (_array?.length > 0) {
    //             setNewsList(_array);
    //           } else {
    //             fetchNews();
    //           }
    //         },
    //         (_, error) => console.log(error)
    //       );
    //     });
    //   },
    //   (error) => console.log(error)
    // );

    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      setUserRole(storedRole);
      switch (storedRole) {
        case "admin":
          setIsAdmin(true);
          break;
        case "manager":
          setIsAdmin(true);
          break;
        default:
          setIsAdmin(false);
      }
    }
    fetchRole();
    return () => {
      // SQLite.close();
      // NetInfo.removeEventListener(handleConnectivityChange);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      fetchNews();
    }
  }, [isOnline]);

  const fetchNews = async () => {
    try {
      const response = await fetchNewsList();
      savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };

  const savePdfsToDb = (pdfs) => {
    setNewsList(pdfs);
    // // console.log("savePdfsToDb");
    // SQLite.openDatabase(
    //   { name: "mydb.db", location: "default", createFromLocation: "~mydb.db" },
    //   (db) => {
    //     db.executeSql(
    //       "CREATE TABLE IF NOT EXISTS news (manual_id INTEGER PRIMARY KEY, manual_name TEXT NULL, manual_detail TEXT NULL, manual_uri TEXT NULL, manual_video_uri TEXT NULL, manual_tag INT NULL DEFAULT 0, manual_edited_timestamp TIMESTAMP NULL)",
    //       [],
    //       () => {
    //         console.log("pdfs table created!");
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //     db.transaction((tx) => {
    //       pdfs.forEach((pdf) => {
    //         // console.log("savePdfsToDb forEach");
    //         // console.log(pdf);
    //         tx.executeSql(
    //           "INSERT INTO pdfs (manual_id,manual_name, manual_detail, manual_uri,manual_video_uri,manual_tag,manual_edited_timestamp) VALUES (?, ?, ?, ?, ?, ?, ?)",
    //           [
    //             pdf.newsId,
    //             pdf.manualName,
    //             pdf.manualDetail,
    //             pdf.manualUri,
    //             pdf.manualVideoUri,
    //             pdf.manualTag,
    //             pdf.manualEditedTimeStamp,
    //           ],
    //           (_, result) => {
    //             // console.log("savePdfsToDbResult1");
    //             console.log(result);
    //             // console.log("savePdfsToDbResult2");
    //           },
    //           (_, error) => console.log(error)
    //         );
    //       });
    //     });
    //   },
    //   (error) => console.log(error)
    // );
  };

  const renderItem = ({ item }) => {
    // console.log(item);
    return (
      <View>
        <Pressable
          style={({ pressed }) => [pressed && styles.pressed]}
          onPress={() => handlePdfPress(item)}
        >
          <NewsItem item={item} />
        </Pressable>
        {/* {isEditMode &&(
        <View>
        <PdfItem  item ={item} editingMode={isEditMode}/>
        <View>
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={editNewsPressHandler(item)}
          />
          <IconButton
            icon="trash"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={deleteNewsPressHandler(item)}
          />
          </View>
          </View>)} */}
      </View>
    );
  };
  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...newsList];
    const prevIndex = newsList.findIndex((item) => item.id === rowKey);
    newData.splice(prevIndex, 1);
    setNewsList(newData);
  };

  const renderHiddenItem = (itemData, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => {
          editNewsPressHandler(itemData.item);
          // console.log(itemData);
          closeRow(rowMap, itemData.item.newsId);
        }}
      >
        <Text style={styles.backTextWhite}>編集</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={async () => {
          const confirmDel = await verifyDelete("お知らせの削除");
          if (confirmDel) {
            deleteNewsPressHandler(itemData.item);
            deleteRow(rowMap, itemData.item.newsId);
          }
        }}
      >
        <Text style={styles.backTextWhite}>削除</Text>
      </TouchableOpacity>
    </View>
  );

  const handlePdfPress = async (pdf) => {
    setNewsModalVisible(!newsModalVisible);
    setCurPdf(pdf);
    // console.log(pdf.manualUri);
  };

  async function manualEditSubmitHandler(manualData) {
    setModalVisible(!modalVisible);
    if (isNewsEditing) {
      try {
        const response = await updateNews(manualData);
        // console.log(response);
      } catch (err) {
        console.log(err);
      }
      fetchNews();
    } else {
      try {
        const response = await addNews(manualData);
        // console.log(response);
      } catch (err) {
        console.log(err);
      }
      fetchNews();
    }
  }

  function newsEditCancelHandler() {
    setModalVisible(!modalVisible);
  }

  const addNewsPressHandler = () => {
    setNewsData();
    setModalVisible(!modalVisible);
    setIsNewsEditing(false);
    setModalTitle("お知らせ公開");
  };

  // const editModeManualPressHandler = () => {
  //   setIsEditMode(!isEditMode);
  // };

  const editNewsPressHandler = (news) => {
    setNewsData(news);
    setModalVisible(!modalVisible);
    setIsNewsEditing(true);
    setModalTitle("お知らせ内容編集");
  };

  const deleteNewsPressHandler = async (manualData) => {
    try {
      const response = await deleteNews(manualData);
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
    fetchNews();
  };

  // const staffEvalPressHandler = () => {};

  const closeNewsModal = () => {
    setNewsModalVisible(false);
  };

  const OpenURLButton = ({ url, children }) => {
    const handlePress = useCallback(async () => {
      // Checking if the link is supported for links with custom URL scheme.
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL(url);
      } else {
        alert(`ウェブサイトが開けません: ${url}`);
      }
    }, [url]);

    return (
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.linkText}>
          {" "}
          参照資料：
          <Text style={styles.linkTextUnderline}>{children}</Text>
        </Text>
      </TouchableOpacity>
    );
  };

  // const fallbackText =;
  let newsListContent = (
    <Text style={styles.infoText}>
      <Text style={styles.infoTextHeader}>{`サーバーと接続中...`}</Text>
      {`

      サーバーの問題が発生する時もありので。一定期間、お知らせが出なかったら後でもう一度試してください`}
    </Text>
  );

  // const handlePress = () => {};
  if (newsList.length > 0) {
    if (isAdmin) {
      newsListContent = (
        <SwipeListView
          useFlatList={true}
          data={newsList}
          renderItem={renderItem}
          keyExtractor={(item) => item.newsId}
          renderHiddenItem={renderHiddenItem}
          rightOpenValue={-150}
          onRowDidOpen={onRowDidOpen}
          disableRightSwipe={true}
          closeOnScroll={true}
        />
      );
    } else {
      newsListContent = (
        <FlatList
          data={newsList}
          renderItem={renderItem}
          keyExtractor={(item) => item.newsId}
        />
      );
    }
  }
  return (
    <View>
      <View style={styles.titleContainerIconLeft}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <IconButton
            icon="home"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={() => {
              navigation.goBack();
            }}
            styleButtonContainer={styles.homeIconContainer}
          ></IconButton>
          <View style={styles.iconLeftText}>
            <Text style={styles.textHome}>ホームへ</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.titleContainerIcon}>
        {isAdmin && (
          <IconButton
            icon="add-circle"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={addNewsPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )}
        {/* {!isAdmin && (
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={32}
            onPress={editModeManualPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )} */}
        {/* <IconButton
          icon="create"
          color={GlobalStyles.colors.primary600}
          size={32}
          onPress={staffEvalPressHandler}
          styleButtonContainer={styles.evalIconContainer}
        /> */}
      </View>
      <View style={styles.rootContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
            <View style={styles.titleContainerText}>
              <Text style={styles.title}>掲示板</Text>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          {newsListContent}
          {modalVisible && (
            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                alert("ページが閉じる");
                setModalVisible(!modalVisible);
              }}
            >
              <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                // style={styles.container}
                // extraScrollHeight={70}
                // extraHeight={135}
              >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <View style={styles.modalTitleContainer}>
                      <Text style={styles.titleText}>{modalTitle}</Text>
                    </View>
                    <View>
                      <ScrollView
                        contentContainerStyle={{ flexGrow: 1 }}
                        keyboardShouldPersistTaps="handled"
                      >
                        <NewsEditForm
                          onSubmit={manualEditSubmitHandler}
                          onCancel={newsEditCancelHandler}
                          defaultValues={newsData}
                          userRole={userRole}
                        />
                      </ScrollView>
                    </View>
                  </View>
                </View>
              </KeyboardAvoidingView>
            </Modal>
          )}
        </View>
      </View>
      {newsModalVisible && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={newsModalVisible}
          onRequestClose={() => {
            alert("PDFページが閉じる");
            setModalVisible(!newsModalVisible);
          }}
        >
          <View style={styles.pdfCenteredView}>
            <View style={styles.newsModalView}>
              <View style={styles.modalTopContainer}>
                <View style={styles.modalCloseContainer}>
                  <IconButton
                    icon="close"
                    color={GlobalStyles.colors.primary600}
                    size={24}
                    onPress={() => {
                      closeNewsModal();
                    }}
                    styleButtonContainer={styles.pdfCloseIconContainer}
                  ></IconButton>
                </View>
                <View>
                <Image
                  source={{
                    uri: curPdf.newsUriImage
                      ? curPdf.newsUriImage
                      : "https://firebasestorage.googleapis.com/v0/b/manager-app-be1e3.appspot.com/o/news_picture%2Fbase_icon.png?alt=media&token=64f9ca4d-1687-4036-a67d-3098b361b8a1" }}
                  resizeMode={"cover"}
                  style={styles.image}
                />
                </View>
              </View>
              <View style={styles.newsModalTitleContainer}>
                <Text style={styles.titleModalText}>{curPdf.newsTitle}</Text>
              </View>
              <View style={styles.newsModalTimeContainer}>
                <Text style={[styles.timeModalText, styles.reportDate]}>
                  {`${curPdf.newsEditedTimeStamp.split(" ")[1].slice(0, 5)}`}
                </Text>
                <Text style={[styles.timeModalText, styles.reportMonthYear]}>
                  {`${
                    curPdf.newsEditedTimeStamp.split(" ")[0].split("-")[2]
                  }日${curPdf.newsEditedTimeStamp.split("-")[1]}月${
                    curPdf.newsEditedTimeStamp.split("-")[0]
                  }年`}
                </Text>
                {/* <Text style={styles.timeModalText}>{curPdf.newsTitle}</Text> */}
              </View>
              <ScrollView style={styles.newsModalDetailsContainer}>
                <Text style={styles.newsModalText}>
                  {"        "}
                  {curPdf.newsDescription}
                </Text>
              </ScrollView>
              {curPdf.newsUri ? (
                <View style={styles.newsModalUriContainer}>
                  {/* <Text style={styles.uriModalText}>{}</Text> */}
                  <OpenURLButton url={curPdf.newsUri}>
                    {curPdf.newsTitle}
                  </OpenURLButton>
                </View>
              ) : null}
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
}

export default BulletinBoardScreen;

const makeStyles = (hieghtScale,fontScale,widthScreen) => StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    marginHorizontal: "3%",
    marginBottom: 50,
  },
  headerContainer: {
    // marginTop: "5%",
    flex: 1,
    flexDirection: "row",
  },
  contentContainer: {
    flex: 10,
    // justifyContent: "center",
    // alignSelf: "center",
  },
  pdfContainer: {
    flex: 1,
    // justifyContent: "flex-start",
    // alignItems: "center",
    // marginTop: 25,
    // position: "absolute",
    // zIndex: 10,
  },
  pdf: {
    // flex: 1,
    width: Dimensions.get("window").width,
    // height: "80%",
    height: Dimensions.get("window").height * 0.8,
  },
  subMenuContainer: {
    flex: 2,
    // marginBottom: 50,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainerIcon: {
    flexDirection: "row",
    position: "absolute",
    marginTop: "3%",
    right: "2%",
    zIndex: 5,
  },
  titleContainerIconLeft: {
    position: "absolute",
    marginTop: "3%",
    left: "4%",
    zIndex: 5,
  },
  closeNewsModalContainer: {
    // position: "absolute",
  },
  iconContainer: {
    marginHorizontal: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  evalIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
  },
  homeIconContainer: {
    marginHorizontal: 2,
    marginRight: 4,
    paddingBottom: 0,
  },
  menuRowContainer: {
    flex: 1,
    flexDirection: "row",
  },
  buttonContainer: {
    marginHorizontal: "2.5%",
    width: "45%",
    height: "100%",
  },
  subbuttonContainer: {
    marginVertical: 10,
    width: "95%",
    alignSelf: "center",
  },
  button: {
    marginTop: "5%",
    height: "95%",
  },
  buttonDisabled: {
    opacity: 0.75,
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  subbutton: {
    minWidth: 341,
    height: 65,
  },
  titleContainerText: {},
  title: {
    fontSize: 32*fontScale,
    marginBottom: 4,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  buttonText: {
    marginHorizontal: 16,
    fontSize: 26*fontScale,
  },
  subbuttonText: {
    fontSize: 26*fontScale,
  },
  notificationReportBadge: {
    position: "absolute",
    right: "35%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textReport: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16*fontScale,
  },
  textHome: {
    color: GlobalStyles.colors.primary600,
    fontSize: 16*fontScale,
  },
  notificationChatBadge: {
    position: "absolute",
    right: "5%",
    top: "7%",
    width: 35,
    height: 35,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
  },
  textChat: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16*fontScale,
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "5%",
  },
  modalView: {
    margin: 20,
    marginTop: "10%",
    height: "100%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    overflow: "scroll",
  },
  modalTopContainer: {
    flexDirection: "row",
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
    alignItems: "center",
  },
  newsModalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    // alignContent: "center",
    alignItems: "center",
    // alignSelf: "center",
    flex: 1/(hieghtScale+0.2),
    marginTop: 10,
  },
  titleModalText: {
    fontSize: 20*fontScale,
    fontWeight: "600",
    // marginBottom: 8,
    // textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  newsModalTimeContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    // alignContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    flex: 1/(hieghtScale+0.2),
    marginTop: -10,
    marginBottom: 8,
  },
  timeModalText: {
    fontSize: 12*fontScale,
    fontWeight: "300",
    marginRight: 20,
    // textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  newsModalDetailsContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    // alignSelf: "flex-start",
    flex: 10/(hieghtScale+0.2),
    minHeight:(28*hieghtScale)+"%",
    // marginVertical: 6,
  },
  newsModalText: {
    fontSize: 16*fontScale,
    fontWeight: "300",
    marginHorizontal: "2%",
    marginTop:"1%",
    paddingTop:"1%",
    // marginBottom: 8,
    // textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  newsModalUriContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    // alignContent: "flex-start",
    // alignItems: "flex-start",
    alignSelf: "flex-start",
    flexDirection: "row",
    // justifyContent: "flex-start",
    flex: 2,
    // marginVertical: 6,
  },
  // uriModalText: {
  //   fontSize: 12*fontScale,
  //   fontWeight: "300",
  //   marginHorizontal: 20,
  //   // flex: 4,
  //   // marginBottom: 8,
  //   // textAlign: "center",
  //   color: GlobalStyles.colors.primary600,
  // },
  linkText: {
    fontSize: 12*fontScale,
    fontWeight: "300",
    marginHorizontal: 20,
    // flex: 8,
    // marginBottom: 8,
    // textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  linkTextUnderline: {
    fontSize: 12*fontScale,
    fontWeight: "300",
    marginHorizontal: 20,
    // flex: 8,
    // marginBottom: 8,
    // textAlign: "center",
    color: GlobalStyles.colors.primary600,
    textDecorationLine: "underline",
  },
  modalCloseContainer: {
    alignContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 20,
    top: 20,
    zIndex: 2,
    borderRadius: 100,
    backgroundColor: "rgba(251, 254, 238, 0.5)",
  },
  pdfCloseIconContainer: {
    marginHorizontal: 2,
    // marginVertical: 10,
    padding: 0,
    alignContent: "center",
    alignItems: "center",
  },
  pdfCenteredView: {
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
    marginHorizontal: "10%",
    marginTop: "5%",
  },
  newsModalView: {
    margin: 20,
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    height:"100%",
    // paddingVertical: 10,
    // paddingHorizontal: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  titleText: {
    fontSize: 20*fontScale,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  pdfContainer: {
    marginHorizontal: "5%",
    marginBottom: 15,
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginHorizontal: "5%",
    marginBottom: 15,
    borderRadius: 10,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16*fontScale,
  },
  pressed: {
    opacity: 0.9,
  },

  image: {
    flex: 1,
    borderRadius: 20,
    height: 300,
    width: 800,
  },
  infoText: {
    color: GlobalStyles.colors.primary600,
    textAlign: "center",
  },
  infoTextHeader: {
    // color: GlobalStyles.colors.primary600,
    // alignSelf: "center",
    fontSize: 20*fontScale,
    fontWeight: "600",
  },
});
