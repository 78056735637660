import { StatusBar } from "expo-status-bar";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import LoginScreen from "./screens/LoginScreen";
import SignupScreen from "./screens/SignupScreen";
import HomeScreen from "./screens/HomeScreen";
import { GlobalStyles } from "./constant/styles";
import IconButton from "./component/UI/IconButton";
import { useContext, useEffect, useState } from "react";

import AuthContextProvider, { AuthContext } from "./store/auth-context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Welcome from "./screens/Welcome";
import DailyReportsScreen from "./screens/DailyReportScreen";
import DailyReportRoomsContextProvider from "./store/dailyreport-room-context";
import DailyReportDetailScreen from "./screens/DailyReportDetailScreen";
import DailyReportsContextProvider from "./store/dailyreport-context";
import ManageDailyReport from "./screens/ManageDailyReport";
import Button from "./component/UI/Button";
import { View, Text, TextInput } from "react-native";
import ManageDailyRoom from "./screens/ManageDailyRoom";
import UsersContextProvider from "./store/user-context";
import DailyRoomMember from "./screens/DailyReportRoomMemberScreen";
import UserProfile from "./screens/UserProfileScreen";
import ManageUser from "./screens/ManageUser";
import UserAuthsContextProvider from "./store/userauth-context";
import ChatScreen from "./screens/ChatScreen";
import GroupChatScreen from "./screens/GroupChatScreen";
import ChatRoomsContextProvider from "./store/chat-room-context";
import ManageChatRoom from "./screens/ManageChatRoom";
import ChatRoomMember from "./screens/ChatRoomMemberScreen";
import ManageChatRoomOneOnOne from "./screens/ManageChatOneOnOne";
import {
  clearUnreadChat,
  fetchDailyReports,
  updateUserPushAuth,
} from "./util/http";

import OneSignal from "react-native-onesignal";
// // import Constants from "expo-constants";
import * as Linking from "expo-linking";
import StoreManualScreen from "./screens/StoreManualScreen";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import TaskManagerScreen from "./screens/TaskManagerScreen";
import BulletinBoardScreen from "./screens/BulletinBoardScreen";
import ManageTaskManagerScreen from "./screens/ManageTaskManagerScreen";
import TaskManagerDetailScreen from "./screens/TaskManagerDetailScreen";
import TaskContextProvider from "./store/task-context";
import TaskManagerCommentScreen from "./screens/TaskManagerCommentScreen";
import DailyReportCommentScreen from "./screens/DailyReportCommentScreen";
import DailyReportDetailModalScreen from "./screens/DailyReportDetailModalScreen";
import MyTaskContextProvider from "./store/mytask-context";
import TroubleShootingMainScreen from "./screens/TroubleShootingMainScreen";
import TroubleShootingGuideScreen from "./screens/TroubleShootingGuideScreen";
import TroubleShootingReportScreen from "./screens/TroubleShootingReportScreen";
import TroubleReportContextProvider from "./store/troublereport-context";
import screenContainer from "./util/screenContainerWeb";

const prefix = Linking.createURL("/");
const Stack = createNativeStackNavigator();
const BottomTabs = createBottomTabNavigator();

OneSignal.setAppId("6af9bf0a-51f6-48bd-8025-4ef199aa5403");
// OneSignal.setAppId(Constants.manifest.extra.oneSignalAppId);
// promptForPushNotificationsWithUserResponse will show the native iOS or Android notification permission prompt.
// We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 8)
// OneSignal.promptForPushNotificationsWithUserResponse(response => {
//   console.log("Prompt response:", response);
// });

//Method for handling notifications received while app in foreground
OneSignal.setNotificationWillShowInForegroundHandler(
  (notificationReceivedEvent) => {
    // console.log(
    //   "OneSignal: notification will show in foreground:",
    //   notificationReceivedEvent
    // );
    let notification = notificationReceivedEvent.getNotification();
    // console.log("notification: ", notification);
    const data = notification.additionalData;
    // console.log("additionalData: ", data);
    // Complete with null means don't show a notification.
    notificationReceivedEvent.complete(notification);
  }
);

//Method for handling notifications opened
OneSignal.setNotificationOpenedHandler(({ notification }) => {
  // console.log("OneSignal: notification opened:", notification);
  // console.log(notification);
});

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

function DailyReportFormStack() {
  return (
    <Stack.Navigator
      name="ManageDailyReport"
      screenOptions={{
        headerShown: false,
        headerStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
        headerTintColor: {
          backgroundColor: GlobalStyles.colors.primary600,
        },
        contentStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
      }}
    >
      <Stack.Screen
        name="ManageDailyReportFormOpen"
        component={ManageDailyReport}
        options={({ route }) => ({
          headerTitle: route.params.titleName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        })}
      />
      <Stack.Screen
        name="ManageDailyReport"
        component={ManageDailyReport}
        options={({ route }) => ({
          headerTitle: route.params.titleName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        })}
      />
      <Stack.Screen
        name="ManageDailyReportRoom"
        component={ManageDailyRoom}
        options={{
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
    </Stack.Navigator>
  );
}
function ChatFormStack() {
  return (
    <Stack.Navigator
      name="ManageChat"
      screenOptions={{
        headerShown: false,
        headerStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
        headerTintColor: {
          backgroundColor: GlobalStyles.colors.primary600,
        },
        contentStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
      }}
    >
      <Stack.Screen
        name="ManageChatRoom"
        component={ManageChatRoom}
        options={{
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ManageChatRoomOneOnOne"
        component={ManageChatRoomOneOnOne}
        options={{
          headerTitle: "友だちリスト",
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
    </Stack.Navigator>
  );
}

function AuthStack() {
  return (
    <Stack.Navigator
      name="Auth"
      screenOptions={{
        headerShown: false,
        contentStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
      }}
    >
      <Stack.Screen name="WelcomeHome" component={Welcome} />
      <Stack.Screen name="Login" component={LoginScreen} />
    </Stack.Navigator>
  );
}

function AuthenticatedStack() {
  // return(<View><text>Welcome To HomePage</text></View>)}
  const authCtx = useContext(AuthContext);
  // const [editButton, setEditButton] = useState("追加");
  const [isEditing, setIsEditing] = useState(false);
  return (
    <Stack.Navigator
      name="Authed"
      screenOptions={{
        headerShown: false,
        headerStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
        headerTintColor: {
          backgroundColor: GlobalStyles.colors.primary600,
        },
        contentStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
      }}
    >
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          headerRight: ({ tintColor }) => (
            <IconButton
              icon="exit"
              color={tintColor}
              size={24}
              onPress={authCtx.logout}
            />
          ),
        }}
      />
      <Stack.Screen
        name="DailyReportScreen"
        component={DailyReportsScreen}
        options={({ navigation, route }) => ({
          headerTitle: "日報表 WEB",
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          headerTitleStyle: { fontSize: 20 },
        })}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={({ navigation, route }) => ({
          title: route.params.chatRoomName
            ? route.params.chatRoomName
            : route.params.params.chatRoomName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          headerTitleStyle: { fontSize: 20 },
          headerLeft: ({ tintColor }) => (
            <IconButton
              icon="chevron-back-outline"
              size={24}
              color={tintColor}
              onPress={() => {
                async function clearUnreadNum() {
                  const response = await clearUnreadChat(
                    route.params.chatRoomId
                      ? route.params.chatRoomId
                      : route.params.params.chatRoomId
                  );
                }
                clearUnreadNum();
                navigation.goBack();
              }}
            />
          ),
          headerRight: ({ tintColor }) => (
            <IconButton
              icon="people"
              size={28}
              color={tintColor}
              onPress={() => {
                navigation.navigate("ChatRoomMemberScreen", {
                  params: { roomId: route.params.params.chatRoomId },
                });
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="GroupChatScreen"
        component={GroupChatScreen}
        options={({ navigation, route }) => ({
          headerTitle: "チャットグループ",
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          headerTitleStyle: { fontSize: 20 },
        })}
      />
      <Stack.Screen
        name="ChatFormStack"
        component={ChatFormStack}
        options={{
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="DailyReportStack"
        component={DailyReportStack}
        options={{
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
        }}
      />
      <Stack.Screen
        name="DailyReportFormStack"
        component={DailyReportFormStack}
        options={{
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="DailyReportRoomMember"
        component={DailyRoomMember}
        options={{
          headerTitle: "グループメンバー一覧表",
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChatRoomMemberScreen"
        component={ChatRoomMember}
        options={{
          headerTitle: "グループメンバー一覧表",
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="UserProfileScreen"
        component={UserProfile}
        options={{
          headerTitle: "プロファイルページ",
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
        }}
      />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="ManageUser" component={ManageUser} />
      <Stack.Screen name="StoreManualScreen" component={StoreManualScreen} />
      <Stack.Screen name="TaskManagerScreen" component={TaskManagerScreen} />
      <Stack.Screen
        name="ManageTaskManagerScreen"
        component={ManageTaskManagerScreen}
        options={({ route }) => ({
          headerTitle: route.params.titleName,
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        })}
      />
      <Stack.Screen
        name="BulletinBoardScreen"
        component={BulletinBoardScreen}
      />
      <Stack.Screen
        name="TaskManagerDetailScreen"
        component={TaskManagerDetailScreen}
        options={({ route }) => ({
          // headerTitle: route.params.titleName,
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          // presentation: "modal",
        })}
      />
      <Stack.Screen
        name="TaskManagerCommentScreen"
        component={TaskManagerCommentScreen}
        options={({ navigation, route }) => ({
          headerTitle: route.params.params.titleName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          // presentation: "modal",
          headerLeft: ({ tintColor }) => (
            <IconButton
              icon="chevron-back-outline"
              size={24}
              color={tintColor}
              onPress={() => {
                // async function clearUnreadNum() {
                //   const response = await clearUnreadChat(
                //     route.params.chatRoomId
                //       ? route.params.chatRoomId
                //       : route.params.params.chatRoomId
                //   );
                // }
                // clearUnreadNum();
                navigation.goBack();
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DailyReportDetailModalScreen"
        component={DailyReportDetailModalScreen}
        options={({ navigation, route }) => ({
          headerTitle: route.params.titleName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          // presentation: "modal",
          headerLeft: ({ tintColor }) => (
            <IconButton
              icon="chevron-back-outline"
              size={24}
              color={tintColor}
              onPress={() => {
                // async function clearUnreadNum() {
                //   const response = await clearUnreadChat(
                //     route.params.chatRoomId
                //       ? route.params.chatRoomId
                //       : route.params.params.chatRoomId
                //   );
                // }
                // clearUnreadNum();
                navigation.goBack();
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DailyReportCommentScreen"
        component={DailyReportCommentScreen}
        options={({ navigation, route }) => ({
          headerTitle: route.params.params.titleName,
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
          headerLeft: ({ tintColor }) => (
            <IconButton
              icon="chevron-back-outline"
              size={24}
              color={tintColor}
              onPress={() => {
                // async function clearUnreadNum() {
                //   const response = await clearUnreadChat(
                //     route.params.chatRoomId
                //       ? route.params.chatRoomId
                //       : route.params.params.chatRoomId
                //   );
                // }
                // clearUnreadNum();
                navigation.goBack();
              }}
            />
          ),
        })}
      />
      <Stack.Screen
        name="TroubleshootingMainScreen"
        component={TroubleShootingMainScreen}
      />
      <Stack.Screen
        name="TroubleShootingGuideScreen"
        component={TroubleShootingGuideScreen}
      />
      <Stack.Screen
        name="TroubleShootingReportScreen"
        component={TroubleShootingReportScreen}
        options={({ route }) => ({
          // headerTitle: route.params.titleName,
          headerShown: false,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          presentation: "modal",
        })}
      />
    </Stack.Navigator>
  );
}

function Navigation() {
  const authCtx = useContext(AuthContext);
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        GroupChatScreen: {
          path: "gchat",
        },
        ChatScreen: {
          path: "chat/:chatRoomName/:chatRoomId",
          parse: {
            chatRoomName: (chatRoomName) => decodeURIComponent(chatRoomName),
          },
        },
        DailyReportStack: {
          screens: {
            DailyReportDetailScreen: {
              path: "dailyreport/:dailyReportRoomName/:dailyReportRoomId",
              parse: {
                dailyReportRoomName: (dailyReportRoomName) =>
                  decodeURIComponent(dailyReportRoomName),
              },
            },
          },
        },
        // DailyReportScreen: "dailyreport",
        DailyReportDetailModalScreen: "test",
        // DailyReportStack: {
        //   screens: {
        //     DailyReportDetailScreen: {
        //       screens: {
        DailyReportDetailModalScreen: {
          path: "dailyreportModal/:titleName/:dailyreportId/:dailyReport/:needAttentionReport/:troubleReport/:excitedReport/:tomorrowTargetReport/:totalIncome/:totalWaste/:wasteAmountTaiyaki/:wasteAmountDango/:wasteAmountTakoyaki/:wasteAmountOkonomiyaki/:wasteAmountYakisoba/:dailyreportImage/:dailyreportDate/:dailyreportCreatedTimestamp/:dailyreportUpdatedTimestamp/:userName/:userImage/:hadProfileImg/:hadReportImg/:rejiCost/:weatherStatus/:clockInTime/:clockOutTime/:curRoomId/:curRoomName",
          // path: "ok/:dailyReportRoomName/:dailyReportRoomId/:dailyreportId/:dailyReport/:needAttentionReport/:troubleReport/:excitedReport/:tomorrowTargetReport/:totalIncome/:totalWaste/:wasteAmountTaiyaki/:wasteAmountDango/:wasteAmountTakoyaki/:wasteAmountOkonomiyaki/:wasteAmountYakisoba/:dailyreportImage/:dailyreportDate/:dailyreportCreatedTimestamp/:dailyreportUpdatedTimestamp/:userName/:userImage/:hadProfileImg/:hadReportImg/:rejiCost/:weatherStatus/:clockInTime/:clockOutTime/:curRoomId/:curRoomName/:",
          // path: "dailyreportModal/:dailyreportId/:dailyReport/:needAttentionReport/:troubleReport/:excitedReport/:tomorrowTargetReport/:totalIncome/:totalWaste/:wasteAmountTaiyaki/:wasteAmountDango/:wasteAmountTakoyaki/:wasteAmountOkonomiyaki/:wasteAmountYakisoba/:dailyreportImage/:dailyreportDate/:dailyreportCreatedTimestamp/:dailyreportUpdatedTimestamp/:userName/:userImage/:hadProfileImg/:hadReportImg/:rejiCost/:weatherStatus/:clockInTime/:clockOutTime/:curRoomId/:curRoomName/:",
          parse: {
            dailyReportRoomName: (dailyReportRoomName) =>
              decodeURIComponent(dailyReportRoomName),
            dailyReport: (dailyReport) => decodeURIComponent(dailyReport),
            needAttentionReport: (needAttentionReport) =>
              decodeURIComponent(needAttentionReport),
            troubleReport: (troubleReport) => decodeURIComponent(troubleReport),
            excitedReport: (excitedReport) => decodeURIComponent(excitedReport),
            tomorrowTargetReport: (tomorrowTargetReport) =>
              decodeURIComponent(tomorrowTargetReport),
            totalIncome: (totalIncome) => decodeURIComponent(totalIncome),
            totalWaste: (totalWaste) => decodeURIComponent(totalWaste),
            wasteAmountTaiyaki: (wasteAmountTaiyaki) =>
              decodeURIComponent(wasteAmountTaiyaki),
            wasteAmountDango: (wasteAmountDango) =>
              decodeURIComponent(wasteAmountDango),
            wasteAmountTakoyaki: (wasteAmountTakoyaki) =>
              decodeURIComponent(wasteAmountTakoyaki),
            wasteAmountOkonomiyaki: (wasteAmountOkonomiyaki) =>
              decodeURIComponent(wasteAmountOkonomiyaki),
            wasteAmountYakisoba: (wasteAmountYakisoba) =>
              decodeURIComponent(wasteAmountYakisoba),
            dailyreportImage: (dailyreportImage) =>
              decodeURIComponent(dailyreportImage),
            dailyreportDate: (dailyreportDate) =>
              decodeURIComponent(dailyreportDate),
            dailyreportCreatedTimestamp: (dailyreportCreatedTimestamp) =>
              decodeURIComponent(dailyreportCreatedTimestamp),
            dailyreportUpdatedTimestamp: (dailyreportUpdatedTimestamp) =>
              decodeURIComponent(dailyreportUpdatedTimestamp),
            userName: (userName) => decodeURIComponent(userName),
            userImage: (userImage) => decodeURIComponent(userImage),
            hadProfileImg: (hadProfileImg) => decodeURIComponent(hadProfileImg),
            hadReportImg: (hadReportImg) => decodeURIComponent(hadReportImg),
            rejiCost: (rejiCost) => decodeURIComponent(rejiCost),
            weatherStatus: (weatherStatus) => decodeURIComponent(weatherStatus),
            clockInTime: (clockInTime) => decodeURIComponent(clockInTime),
            clockOutTime: (clockOutTime) => decodeURIComponent(clockOutTime),
            curRoomId: (curRoomId) => decodeURIComponent(curRoomId),
            curRoomName: (curRoomName) => decodeURIComponent(curRoomName),
          },
          // path: "dailyreportComment/:dailyReportRoomName/:dailyReportRoomId/:dailyReportId",
          // parse: {
          //   dailyReportRoomName: (dailyReportRoomName) =>
          //     decodeURIComponent(dailyReportRoomName),
          //   dailyReportId: (dailyReportId) => dailyReportId,
          // },
          //       },
          //     },
          //   },
          // },
        },
      },
    },
  };

  return (
    <View
      style={{
        width: "75%",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: GlobalStyles.colors.neutral10,
        minHeight: 650,
      }}
    >
      <NavigationContainer linking={linking}>
        {!authCtx.isAuthenticated && <AuthStack />}
        {authCtx.isAuthenticated && <AuthenticatedStack />}
      </NavigationContainer>
    </View>
  );
}

function Root() {
  const [isTryingLogin, setIsTryingLogin] = useState(true);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    async function fetchToken() {
      // const notificationId = await OneSignal.getDeviceState().userId;
      const state = await OneSignal.getDeviceState();
      // console.log(state.userId);
      const storedToken = await AsyncStorage.getItem("token");
      console.log("storedToken : " + storedToken);
      console.log("state : " + state);
      const storedId = await AsyncStorage.getItem("uid");
      OneSignal.addTrigger("isAskForPushPrompt", 1);
      if (storedToken) {
        // updateUserPushAuth(storedId, state.userId);
        authCtx.authenticate(storedToken);
      }

      setIsTryingLogin(false);
    }

    fetchToken();
  }, []);

  if (isTryingLogin) {
    // return <LoadingOverlay />;
  }

  return <Navigation />;
}

function DailyReportStack({ route }) {
  let routeRoomId = route.params.params.dailyReportRoomId;
  let routeRoomName = route.params.params.dailyReportRoomName;
  const [error, setError] = useState();

  return (
    <BottomTabs.Navigator
      screenOptions={({ navigation, route }) => ({
        headerStyle: { backgroundColor: GlobalStyles.colors.neutral10 },
        headerTintColor: "white",
        headerTitleStyle: { fontSize: 20 },
        tabBarStyle: {
          backgroundColor: GlobalStyles.colors.neutral10,
        },
        tabBarActiveTintColor: GlobalStyles.colors.accent500,
        headerShown: true,
        headerLeft: ({ tintColor }) => (
          <IconButton
            icon="chevron-back-outline"
            size={24}
            color={tintColor}
            onPress={() => {
              navigation.goBack();
              async function getDailyReports() {
                try {
                  const dailyReports = await fetchDailyReports(routeRoomId);
                } catch (error) {
                  setError(
                    "サーバーの問題で日報レポートを表示できません　- 後でもう一度試してください"
                  );
                }
                getDailyReports();
              }
            }}
          />
        ),
        headerRight: ({ tintColor }) => (
          <IconButton
            icon="people"
            size={28}
            color={tintColor}
            onPress={() => {
              navigation.navigate("DailyReportRoomMember", {
                params: { roomId: route.params.dailyReportRoomId },
              });
            }}
          />
        ),
        tabBarLabelStyle: { display: "none" },
      })}
    >
      <BottomTabs.Screen
        name="DailyReportDetailScreen"
        component={DailyReportDetailScreen}
        options={({ route }) => ({
          title: route.params.dailyReportRoomName,
          headerBackTitleVisible: false,
          headerTintColor: GlobalStyles.colors.primary600,
          tabBarIconStyle: { display: "none" },
          tabBarButton: () => {
            return null;
          },
        })}
      />
      <BottomTabs.Screen
        name="GotoManageDailyReport"
        component={ManageDailyReport}
        options={({ navigation, route }) => ({
          presentation: "modal",
          tabBarButton: () => (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flex: 1,
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Button
                styleText={{
                  fontSize: 20,
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                onPress={() => {
                  // console.log(route.params);
                  navigation.navigate("DailyReportFormStack", {
                    screen: "ManageDailyReport",
                    params: {
                      titleName: "日報レポート作成",
                      dailyReportRoomId: routeRoomId,
                      dailyReportRoomName: routeRoomName,
                    },
                  });
                }}
              >
                {"レポート提出"}
              </Button>
            </View>
          ),
        })}
      />
    </BottomTabs.Navigator>
  );
}

export default function App() {
  return (
    <>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <StatusBar style="dark" />
        <AuthContextProvider>
          <DailyReportsContextProvider>
            <DailyReportRoomsContextProvider>
              <UsersContextProvider>
                <UserAuthsContextProvider>
                  <ChatRoomsContextProvider>
                    <TaskContextProvider>
                      <MyTaskContextProvider>
                        <TroubleReportContextProvider>
                          <Root />
                          {/* <text>WEB TESTTTTTTTTTTTTTTTT</text> */}
                        </TroubleReportContextProvider>
                      </MyTaskContextProvider>
                    </TaskContextProvider>
                  </ChatRoomsContextProvider>
                </UserAuthsContextProvider>
              </UsersContextProvider>
            </DailyReportRoomsContextProvider>
          </DailyReportsContextProvider>
        </AuthContextProvider>
      </GestureHandlerRootView>
    </>
  );
}
