import { useEffect } from "react";
import { useState } from "react";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import Input from "../Auth/Input";
import ImagePicker from "../DailyReport/ImagePicker";
import Button from "../UI/Button";
import { getscaledDimension } from "../../util/deviceUiInfo";
import DropDownPicker from "./DropDownPicker";

function ProfileEditForm({
  onSubmit,
  onCancel,
  defaultValues,
  userRole,
  visible,
  onClose,
}) {
  const [enteredUsername, setEnteredUsername] = useState(
    defaultValues ? defaultValues.userName : ""
  );
  const [enteredUserRole, setEnteredUserRole] = useState("");
  const [enteredUserImage, setEnteredUserImage] = useState(
    defaultValues ? defaultValues.userImage : ""
  );
  const [enteredUserImageForUpload, setEnteredUserImageForUpload] = useState();

  const [hadProfileImg, setHadProfileImg] = useState(
    defaultValues.userImage ? true : false
  );
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(hieghtScale, fontScale * 0.7); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32 * fontScale * 0.7;
  // let hadProfileImg = defaultValues.userImage ? true : false;

  useEffect(() => {}, [enteredUserImage]);

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "username":
        setEnteredUsername(enteredValue);
        break;
      case "userImage":
        setEnteredUserImage(enteredValue || enteredUserImage);
        break;
      case "userImageforUpload":
        setEnteredUserImageForUpload(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      userId: defaultValues.userId,
      userName: enteredUsername,
      userImage: enteredUserImageForUpload,
    });
  }

  function onTakenImageHandler(onTakenImage) {
    updateInputValueHandler("userImage", onTakenImage.uri);
    updateInputValueHandler("userImageforUpload", onTakenImage);
    setHadProfileImg(true);
  }
  const data = [
    { label: "管理者（アドミン）", value: "admin" },
    { label: "エリアマネージャー", value: "manager" },
    { label: "社員", value: "officer" },
    { label: "店舗", value: "store" },
  ];

  const [isFocus, setIsFocus] = useState(false);
  return (
    <View style={styles.modalView}>
      <View style={styles.profilePictureContainer}>
        <View style={styles.profilePicture}>
          {hadProfileImg && (
            <Image
              style={styles.profileImg}
              source={enteredUserImage ? { uri: `${enteredUserImage}` } : null}
            />
          )}
          {!hadProfileImg && defaultValues.userName && (
            <Text style={styles.textProfile}>{`${defaultValues.userName
              .charAt(0)
              .toUpperCase()}`}</Text>
          )}
        </View>
        <ImagePicker
          textInput={"プロファイル写真を更新する"}
          onTakenImage={onTakenImageHandler}
          userPreview={false}
        />
      </View>
      <View>
        <Input
          label="ユーザー名"
          onUpdateValue={updateInputValueHandler.bind(this, "username")}
          value={enteredUsername}
          keyboardType="email-address"
          placeholderValue="ユーザー名"
          styleText
        />
      </View>
      <View style={styles.container}>
        <Text style={styles.dropdownText}>ユーザーの担当</Text>
        <DropDownPicker
          data={data}
          value={enteredUserRole}
          onValueChange={(value) => setEnteredUserRole(value)}
          placeholder="Select User Role"
          disabled={false}
        />
        <Text style={styles.remarkText}>
          ＊管理者やマネジャー以外はユーザーの担当が変更できません
        </Text>
      </View>
      <View style={styles.buttons}>
        <Button
          style={styles.button}
          mode="flat"
          onPress={onCancel}
          styleText={[styles.buttonText, styles.textBase]}
        >
          キャンセル
        </Button>
        <Button
          style={styles.button}
          onPress={submitHandler}
          styleButton={{
            backgroundColor: GlobalStyles.colors.primary700,
          }}
          styleText={styles.buttonText}
        >
          {"編集"}
        </Button>
      </View>
    </View>
  );
}

export default ProfileEditForm;

const makeStyles = (hieghtScale, fontScale) =>
  StyleSheet.create({
    form: {},
    container: {
      padding: 16,
      minWidth: "80%",
    },
    dropdown: {
      height: 50,
      borderColor: "gray",
      borderWidth: 0.5,
      borderRadius: 8,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    label: {
      position: "absolute",
      backgroundColor: "white",
      left: 22,
      top: 8 * hieghtScale,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14 * fontScale,
    },
    placeholderStyle: {
      fontSize: 16 * fontScale,
    },
    selectedTextStyle: {
      fontSize: 16 * fontScale,
    },
    iconStyle: {
      width: 20 * hieghtScale,
      height: 20 * hieghtScale,
    },
    inputSearchStyle: {
      height: 40 * hieghtScale,
      fontSize: 16 * fontScale,
    },
    profilePictureContainer: {
      marginVertical: 18,
      alignItems: "center",
      justifyContent: "center",
    },
    profilePicture: {
      width: 120 * hieghtScale,
      height: 120 * hieghtScale,
      borderRadius: 1000,
      marginBottom: 6,
      backgroundColor: GlobalStyles.colors.primary700,
      alignItems: "center",
      justifyContent: "center",
    },
    textBase: { color: GlobalStyles.colors.primary600 },
    textProfile: {
      color: GlobalStyles.colors.neutral0,
      fontSize: 36 * fontScale,
    },
    profileImg: {
      width: 120 * hieghtScale,
      height: 120 * hieghtScale,
      borderRadius: 1000,
    },
    remarkText: {
      color: GlobalStyles.colors.error600,
      fontSize: 9 * fontScale,
    },
    dropdownText: {
      color: GlobalStyles.colors.primary600,
      fontSize: 12 * fontScale,
      marginBottom: 6,
    },
    buttons: {
      marginVertical: 24 * hieghtScale,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: GlobalStyles.colors.neutral100,
    },
    button: {
      minWidth: 120,
      marginHorizontal: 18,
    },

    buttonText: {
      fontSize: 10 * fontScale,
    },
    pressed: {
      opacity: 0.75,
      borderRadius: 100,
    },
    modalView: {
      // margin: 20,
      // backgroundColor: "white",
      // borderRadius: 20,
      // padding: 35,
      // alignItems: "center",
      // shadowColor: "#000",
      // shadowOffset: {
      //   width: 0,
      //   height: 2,
      // },
      // shadowOpacity: 0.25,
      // shadowRadius: 4,
      // elevation: 5,
    },
  });
