export function getFormattedDate(date) {
  // console.log(date);
  if (typeof date === "string") {
    date = new Date(date);
  }
  let offset = date?.getTimezoneOffset() * 60 * 1000;
  let localDate = date ? new Date(date?.getTime() - offset) : null;

  // console.log(localDate);

  let returnDate = localDate
    ? localDate.toISOString().slice(0, 10)
    : "0000-00-00";
  return returnDate;
  // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getDateMinusDays(date, days) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - days);
}

export function getFormattedDateJp(date_in) {
  const today = new Date();
  const posted_time = new Date(today - date_in);
  //年
  const year = date_in.getFullYear();

  //月
  //1月が0、12月が11。そのため+1をする。
  const month = date_in.getMonth() + 1;

  //日
  const date = date_in.getDate();

  //曜日
  //日曜が0、土曜日が6。配列を使い曜日に変換する。
  // const dateT = ["日", "月", "火", "水", "木", "金", "土"];
  // const day = dateT[date_in.getDay()];

  //時
  const hours = date_in.getHours();

  //分
  const minutes = String(date_in.getMinutes()).padStart(2, "0");

  //秒
  // const seconds = date_in.getSeconds();
  // console.log(`${year}年 ${month}月 ${date}日 ${hours}:${minutes}`);
  const date_time = `${year}年 ${month}月 ${date}日 ${hours}:${minutes}`;
  // const output_time =
  //   posted_time.getHours() > 24
  //     ? `${posted_time.getHours()}　時間前`
  //     : date_time;
  // console.log(output_time);
  return date_time;
}

export function getChatDateJp(date_in) {
  const today = new Date();
  const posted_time = new Date(today - date_in);
  // console.log(posted_time.getHours() > 24);
  //年
  const year = date_in.getFullYear();

  //月
  //1月が0、12月が11。そのため+1をする。
  const month = date_in.getMonth() + 1;

  //日
  const date = date_in.getDate();

  //曜日
  //日曜が0、土曜日が6。配列を使い曜日に変換する。
  // const dateT = ["日", "月", "火", "水", "木", "金", "土"];
  // const day = dateT[date_in.getDay()];

  //時
  const hours = date_in.getHours();

  //分
  const minutes = String(date_in.getMinutes()).padStart(2, "0");

  //秒
  // const seconds = date_in.getSeconds();
  // console.log(`${year}年 ${month}月 ${date}日 ${hours}:${minutes}`);
  const date_time = `${year}年 ${month}月 ${date}日 ${hours}:${minutes}`;
  const output_time =
    posted_time.getHours() > 24
      ? posted_time.getFullYear() > 1
        ? `${year}年 ${month}月 ${date}日`
        : `${month}月 ${date}日`
      : `${hours}:${minutes}`;
  // console.log(output_time);
  return output_time;
}

export function getFormatDateForNotification(inputDate) {
  const now = new Date();
  const input = new Date(inputDate);

  // Calculate the time difference in milliseconds
  const timeDifference = now - input;
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const oneWeek = 7 * oneDay; // One week in milliseconds

  if (timeDifference < oneDay) {
    // Within one day, show hh::mm
    const hours = input.getHours().toString().padStart(2, '0');
    const minutes = input.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  } else if (timeDifference < 2 * oneDay) {
    // Within 2 days, show "yesterday"
    return 'Yesterday';
  } else if (timeDifference < oneWeek) {
    // More than 2 days but less than a week, use mon-sun
    const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
    return daysOfWeek[input.getDay()];
  } else if (timeDifference < 365 * oneDay) {
    // More than 1 week but less than 1 year, show MM/DD
    const month = (input.getMonth() + 1).toString().padStart(2, '0');
    const day = input.getDate().toString().padStart(2, '0');
    return `${month}/${day}`;
  } else {
    // More than 1 year, show YYYY/MM/DD
    const year = input.getFullYear();
    const month = (input.getMonth() + 1).toString().padStart(2, '0');
    const day = input.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  }
}