import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { SafeAreaView, ScrollView } from "react-native";
// import { SQLErrors } from "react-native-sqlite-storage";
import TaskManagerOutput from "../component/TaskManager/TaskManagerOutput";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { UsersContext } from "../store/user-context";
import {
  fetchTaskAssignedToMeList,
  fetchTaskUserCreatedList,
  fetchTaskUserList,
  fetchUsers,
  updateUserProfile,
} from "../util/http";
// import * as SQLite from "expo-sqlite";
import { TaskContext } from "../store/task-context";
import { MyTaskContext } from "../store/mytask-context";

function TaskManagerScreen({ navigation }) {
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();

  const [error, setError] = useState();
  const todoTaskCtx = useContext(TaskContext);
  const myTaskCtx = useContext(MyTaskContext);

  const userCtx = useContext(UsersContext);
  const isFocused = useIsFocused();
  const [taskAssignedToMeList, setTaskAssignedToMeList] = useState([]);
  const [taskUserCreatedList, setTaskUserCreatedList] = useState([]);
  const [date, setDate] = useState(new Date().toLocaleDateString("ja-JP"));

  // const db = SQLite.openDatabase("db");

  function createTable() {
    // db.transaction((tx) => {
    //   tx.executeSql(
    //     // 実行したいSQL文
    //     `create table if not exists assigned_to_user (assigned_task_id integer primary key not null, task_id integer, user_id integer,unread integer,assigned_user_name text,assigned_user_image text);`,
    //     // SQL文の引数
    //     // 必要ないときは空のまま
    //     [],
    //     // 成功時のコールバック関数
    //     () => {
    //       console.log("create table success");
    //     },
    //     () => {
    //       // 失敗時のコールバック関数
    //       console.log("create table faile");
    //       return false;
    //     }
    //   );
    // });
  }

  function updateLocalDatabase(onlineDatabase) {
    // db.transaction((tx) => {
    //   tx.executeSql("DELETE FROM assigned_to_user", []);
    //   onlineDatabase.forEach((item) => {
    //     tx.executeSql(
    //       "insert into assigned_to_user (assigned_task_id, task_id, user_id, unread, assigned_user_name, assigned_user_image) values (?,?,?,?,?,?)",
    //       [
    //         item.assignedTaskId,
    //         item.taskId,
    //         item.userId,
    //         item.unread,
    //         item.assignedUserName,
    //         item.assignedUserImage,
    //       ],
    //       // 成功時のコールバック関数
    //       () => {
    //         // console.log("database input done success");
    //       },
    //       () => {
    //         // 失敗時のコールバック関数
    //         console.log("database input done faile");
    //         return false;
    //       }
    //     );
    //   });
    // });
  }

  const fetchTask = async () => {
    try {
      const responseTaskAssignedToMeList = await fetchTaskAssignedToMeList();
      const responseTaskUserCreatedList = await fetchTaskUserCreatedList();
      const responseUserInTaskList = await fetchTaskUserList();
      // taskAssignedCtx.setTask(responseTaskAssignedToMeList);
      // taskUserCreatedCtx.setTask(responseTaskUserCreatedList);

      // savePdfsToDb(response);
      // console.log(responseTaskAssignedToMeList);
      saveTaskToList(responseTaskAssignedToMeList);
      // db.transaction((tx) => {
      //   tx.executeSql("SELECT * FROM assigned_to_user", [], (_, { rows }) => {
      //     // console.log(JSON.stringify(rows));
      //     const localDatabase = JSON.stringify(rows);
      //     const onlineDatabaseString = JSON.stringify(responseUserInTaskList);
      //     if (localDatabase !== onlineDatabaseString) {
      //       updateLocalDatabase(responseUserInTaskList);
      //     }
      //   });
      // });
      // console.log(responseUserInTaskList);
      saveTaskToCreatedList(responseTaskUserCreatedList);
    } catch (err) {
      console.log(err);
    }
  };

  useFocusEffect(
    useCallback(() => {
      // Run your effect when the screen is focused
      // const value = navigation.getParam("value");
      // setValue(value);
      fetchTask();
      console.log("print use effect");
    }, [navigation])
  );
  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        userCtx.setUser(userList);
      } catch (error) {
        setError("Could not fetch user lists!");
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      const storedUid = await AsyncStorage.getItem("uid");
      setUsingId(storedUid);
    }
    createTable();
    fetchTask();
    getUsersList();
    fetchUserData();
  }, []);

  async function confirmHandler(userData) {
    setIsSubmitting(true);
    try {
      await updateUserProfile(userData);
      // console.log(userData);
      // console.log(selectedUser);
      userCtx.updateUser(selectedUser.id, userData);
    } catch (error) {
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }
  const saveTaskToList = (task) => {
    todoTaskCtx.setTask(task);
    setTaskAssignedToMeList(task);
  };
  const saveTaskToCreatedList = (task) => {
    myTaskCtx.setTask(task);
    setTaskUserCreatedList(task);
  };
  if (error && !isFetching) {
    return (
      <>
        <ErrorOverlay
          message={error}
          onPress={() => {
            setIsSubmitting(true);
            setError();
          }}
        />
      </>
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }
  const selectedUser = userCtx.users.find((users) => users.userId === usingId);

  return (
    <TaskManagerOutput
      userData={selectedUser}
      taskAssignedToMeList={todoTaskCtx.task}
      taskUserCreatedList={myTaskCtx.task}
      // taskAssignedToMeList={taskAssignedToMeList}
      // taskUserCreatedList={taskUserCreatedList}
      fallbackText={"ユーザー内容が取得できません "}
      onConfirm={confirmHandler}
    />
  );
}
export default TaskManagerScreen;
