import { StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getscaledDimension } from "../../util/deviceUiInfo";

function PdfItem({ item, editingMode = false }) {
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(fontScale*0.7); // pass in fontScale to the StyleSheet
  return (
    <View
      style={[
        styles.pdfListContainer,
        editingMode === true && styles.editingMode,
      ]}
    >
      <View style={styles.pdfListTilteLayout}>
        <View style={styles.pdfListTilteContainer}>
          <Text style={styles.pdfListTilte}>{item.manualName}</Text>
        </View>
        <View style={styles.dateContainer}>
          <Text style={[styles.textBase, styles.reportDate]}>
            {`${item.manualEditedTimeStamp.split(" ")[1].slice(0, 5)}`}
          </Text>
          <Text style={[styles.textBase, styles.reportMonthYear]}>
            {`${item.manualEditedTimeStamp.split(" ")[0].split("-")[2]}日${
              item.manualEditedTimeStamp.split("-")[1]
            }月${item.manualEditedTimeStamp.split("-")[0]}年`}
          </Text>
        </View>
      </View>
      <View style={styles.pdfListDetailContainer}>
        <Text style={styles.pdfListDetail}>詳細内容: {item.manualDetail}</Text>
      </View>
    </View>
  );
}
export default PdfItem;

const makeStyles = (fontScale) => StyleSheet.create({
  pdfListContainer: {
    marginHorizontal: "5%",
    marginBottom: 15,
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
  },
  pdfListTilteLayout: {
    flexDirection: "row",
  },
  pdfListTilteContainer: {
    marginHorizontal: "5%",
    marginVertical: "2%",
    flex: 8,
  },
  pdfListTilte: {
    fontSize: 20*fontScale,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
  },
  dateContainer: {
    marginTop: "2%",
    marginHorizontal: "2%",
    flex: 4,
    // marginHorizontal: "5%",
    // marginVertical: "2%",
    // flexDirection: "row",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  reportDate: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  reportMonthYear: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  pdfListDetailContainer: {
    marginLeft: "8%",
    marginRight: "5%",

    marginBottom: "2%",
  },
  pdfListDetail: {
    fontSize: 14*fontScale,
    color: GlobalStyles.colors.primary600,
  },
  editingMode: {
    opacity: 0.3,
    backgroundColor: GlobalStyles.colors.neutral100,
  },
});
