import { Pressable, View, Text, StyleSheet, Image } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getFormattedDate } from "../../util/date";
import { useNavigation } from "@react-navigation/native";
import Button from "../UI/Button";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useState } from "react";
import Checkbox from "expo-checkbox";
import IconButton from "../UI/IconButton";

function UserAuthItem({
  authenticationId,
  username,
  password,
  userRole,
  onSetting,
  defaultUser,
}) {
  // const hadProfileImg = userImage !== null ? true : false;
  const authCtx = useContext(AuthContext);
  // const isOwned = creatorId === authCtx.userID ? true : false;
  //   const [isSelected, setSelection] = useState(false);
  const [inputs, setInput] = useState({
    // selectedUserId: userId,
    // isSelected: defaultUser?.userId?.find((user_id) => user_id === userId)
    //   ? true
    //   : false,
    // addOrDelete: defaultUser?.userId?.find((user_id) => user_id === userId)
    //   ? true
    //   : false,
  });
  const navigation = useNavigation();

  function inputChangedHandler(inputIdentifier, enteredValue) {
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: enteredValue,
      };
    });
  }

  function editPressHandler() {
    const userAuthData = {
      authenticationId: authenticationId,
      username: username,
      password: password,
      userRole: userRole,
    };
    onSetting(userAuthData);
  }

  return (
    <Pressable onPress={""} style={({ pressed }) => pressed && styles.pressed}>
      <View style={styles.userListItem}>
        <View style={styles.idContainer}>
          <Text style={[styles.textBase, styles.description]}>
            {authenticationId}
          </Text>
        </View>
        <View style={styles.userNameContainer}>
          <Text style={[styles.textBase, styles.description]}>{username}</Text>
        </View>
        <View style={styles.userNameContainer}>
          <Text style={[styles.textBase, styles.description]}>{password}</Text>
        </View>
        <View style={styles.userNameContainer}>
          <Text style={[styles.textBase, styles.description]}>{userRole}</Text>
        </View>
        <View style={styles.iconContainer}>
          <IconButton
            icon="settings-outline"
            size={32}
            color={GlobalStyles.colors.primary600}
            onPress={editPressHandler}
            styleButtonContainer={styles.buttonBox}
          />
        </View>
      </View>
    </Pressable>
  );
}

export default UserAuthItem;

const styles = StyleSheet.create({
  pressed: {
    opacity: 0.7,
  },
  userListItem: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.neutral10,
    justifyContent: "space-evenly",
    borderBottomWidth: 0.25,
    borderTopWidth: 0.25,
    borderColor: GlobalStyles.colors.primary1000,
    flexDirection: "row",
    borderRadius: 6,
  },
  textBase: {
    marginLeft: 6,
    color: GlobalStyles.colors.primary600,
  },
  description: {
    fontSize: 16,
    marginBottom: 4,
  },

  checkbox: {
    alignSelf: "center",
    borderRadius: 6,
  },
  profilePictureContainer: {
    flex: 1,
    justifyContent: "center",
  },
  idContainer: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  userNameContainer: {
    flex: 3,
    alignSelf: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    flex: 2,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonBox: {
    marginRight: "1%",
  },
  profilePicture: {
    width: 36,
    height: 36,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16,
  },
  profileImg: {
    width: 36,
    height: 36,
    borderRadius: 1000,
  },
});
