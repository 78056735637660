import {
  Text,
  TextInput,
  View,
  StyleSheet,
  DatePickerIOSBase,
  SafeAreaView,
  Modal,
  Pressable,
} from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { InputOutline } from "react-native-input-outline";
import IconButton from "../UI/IconButton";
// import DateTimePickerModal from "react-native-modal-datetime-picker";
// import DatePicker from "react-native-date-picker";

import { getFormattedDate } from "../../util/date";
import { useState } from "react";
import Input from "./Input";
import Button from "../UI/Button";
import { Platform } from "react-native";
import { TimePickerModal } from "react-native-paper-dates";
function TimeInput({
  label,
  invalid,
  style,
  textInputConfig,
  inputValueTime,
  getTimeFromTextInput,
  inputType,
}) {
  let inputStyles = [styles.input];
  // if (textInputConfig && textInputConfig.multiline) {
  //   inputStyles.push(styles.inputMultiline);
  // }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [dateValue, setDateValue] = useState();
  // const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isTimeChange, setIsTimeChange] = useState(false);
  const [timeValue, setTimeValue] = useState(new Date());
  const [timeShowValue, setTimeShowValue] = useState();

  const setTimeInput = (event, time) => {
    // console.log(event);
    if (Platform.OS === "ios") {
      if (event.type == "set") {
        // const showTime = `${time.getHours()}:${time.getMinutes()} `;
        const showTime = time.toTimeString().slice(0, 5);
        // console.log(showTime);
        setTimeShowValue(showTime);
        setTimeValue(time);
        // console.log(timeValue);
        //send out date data
        getTimeFromTextInput(inputType, time);
        setIsTimeChange(true);
        //ok button clicked
      }
    } else {
      if (event.type == "set") {
        // const showTime = `${time.getHours()}:${time.getMinutes()} `;
        const showTime = time.toTimeString().slice(0, 5);
        // console.log(showTime);
        // console.log("time -> " + showTime);
        setOpen(false);
        setTimeShowValue(showTime);
        setTimeValue(time);
        // console.log(timeValue);
        //send out date data
        getTimeFromTextInput(inputType, time);
        setIsTimeChange(true);

        //ok button clicked
      } else if (event.type == "dismissed") {
        setOpen(false);
        //cancel button clicked
      }
    }

    // console.log(time.toISOString().split("T")[1].slice(0, 5));
    // console.log("test  " + getFormattedDate(time));

    // console.log(time.getMonth() + 1);
    // console.log(time.getDate());
    // const saveDate = getFormattedDate(time);
    // console.log(time.getDate());
  };
  const outputTime = (text) => {
    // console.log("SUBTOP!!!" + time);
    //send out date data
    // let formattedText = text;
    // if (text.length === 2) {
    //   formattedText += ":";
    // }
    // console.log(text);
    getTimeFromTextInput(inputType, text);
  };
  const inputPressHandler = () => {
    setOpen(true);
  };

  const onDismiss = () => {
    setOpen(false);
  };
  return (
    <>
      <View style={[styles.inputContainer, style]}>
        <Pressable onPress={() => inputPressHandler()}>
          <Text style={[styles.label, invalid && styles.invalidLabel]}>
            {label}
          </Text>
          <View style={styles.inputBox}>
            <TextInput
              // onFocus={() => setOpen(true)}
              style={styles.input}
              value={
                isTimeChange
                  ? timeShowValue
                  : inputValueTime !== "00:00:00" && inputValueTime
                  ? inputValueTime.slice(0, 5)
                  : ""
              }
              // editable={false}
              keyboardType="numeric"
              showSoftInputOnFocus={false}
              maxLength={5}
              onChangeText={outputTime}
              {...textInputConfig}
            />
            <IconButton
              icon={"time-outline"}
              size={24}
              color={GlobalStyles.colors.neutral0}
              onPress={() => {
                setOpen(true);
              }}
            />
          </View>
        </Pressable>
        <TimePickerModal
          visible={open}
          use24HourClock={true}
          onDismiss={onDismiss}
          onConfirm={({ hours, minutes }) => {
            setOpen(false);
            // console.log({ hours, minutes });
            const today = new Date();
            today.setHours(hours);
            today.setMinutes(minutes);
            today.setSeconds(0); // Optionally, set seconds to 0 if you want precise time without seconds
            const formatTime = (hours, minutes) => {
              // Ensure leading zeros for single-digit hours and minutes
              const formattedHours = hours < 10 ? "0" + hours : hours;
              const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
              return `${formattedHours}:${formattedMinutes}`;
            };
            setTimeShowValue(formatTime(hours, minutes));
            setTimeValue(today);
            // console.log(timeValue);
            //send out date data
            getTimeFromTextInput(inputType, today);
            setIsTimeChange(true);
          }}
          hours={12}
          minutes={0}
        />
        {/* {open && (
          <View style={{ flexDirection: "row" }}>
            <View style={styles.clockContainer}>
            </View>
            {Platform.OS === "ios" ? (
              <Button
                onPress={() => {
                  setOpen(!setOpen);
                }}
                styleButton={styles.button}
                styleText={styles.buttonText}
              >
                {"指定"}
              </Button>
            ) : null}
          </View>
        )} */}
      </View>
    </>
  );
}
export default TimeInput;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  inputBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  label: {
    fontSize: 12,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 4,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary700,
    padding: 10,
    borderRadius: 6,
    fontSize: 14,
    flex: 1,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  container: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    padding: 20,
  },
  clockContainer: {
    backgroundColor: GlobalStyles.colors.neutral10,
    // flex: 1,
    // justifyContent: "flex-start",
    // alignItems: "flex-start",
    // alignSelf: "flex-start",
    // padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 10,
  },
  clock: {
    // backgroundColor: GlobalStyles.colors.neutral10,
    // colors: GlobalStyles.colors.primary1000,
    alignSelf: "center",
    borderRadius: 20,
    // flex: 1,
    // padding: 10,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 4,
    // elevation: 5,
  },
  button: {
    // marginTop: "15%",
    backgroundColor: GlobalStyles.colors.primary700,
    borderRadius: 10,
    marginLeft: 15,
  },
  buttonText: {
    fontSize: 14,
    paddingHorizontal: 5,
  },
});
