import { createContext, useReducer } from "react";

export const UserAuthsContext = createContext({
  users: [],
  addUser: ({
    authenticationId,
    username,
    password,
    userRole,
    notificationId,
  }) => {},
  setUser: (userAuths) => {},
  deleteUser: (id) => {},
  updateUser: (
    id,
    { authenticationId, username, password, userRole, notificationId }
  ) => {},
});

function userAuthsReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      const listed = action.payload;
      return listed;
    case "UPDATE":
      const updatableUserIndex = state.findIndex(
        (user) => user.id === action.payload.id
      );
      const updatableUser = state[updatableUserIndex];
      const updatedItem = { ...updatableUser, ...action.payload.data };
      const updatedUsers = [...state];
      updatedUsers[updatableUserIndex] = updatedItem;
      return updatedUsers;
    case "DELETE":
      return state.filter((user) => user.id !== action.payload);
    default:
      return state;
  }
}

function UserAuthsContextProvider({ children }) {
  const [usersState, dispatch] = useReducer(userAuthsReducer, []);

  function addUser(userAuthData) {
    dispatch({ type: "ADD", payload: userAuthData });
  }
  function setUser(userAuths) {
    dispatch({ type: "SET", payload: userAuths });
  }
  function deleteUser(id) {
    dispatch({ type: "DELETE", payload: id });
  }
  function updateUser(id, userAuthData) {
    dispatch({ type: "UPDATE", payload: { id: id, data: userAuthData } });
  }

  const value = {
    users: usersState,
    setUser: setUser,
    addUser: addUser,
    deleteUser: deleteUser,
    updateUser: updateUser,
  };

  return (
    <UserAuthsContext.Provider value={value}>
      {children}
    </UserAuthsContext.Provider>
  );
}
export default UserAuthsContextProvider;
