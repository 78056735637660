import { createContext, useReducer } from "react";

export const DailyReportsContext = createContext({
  dailyReports: [],
  addDailyReport: (
    id,
    {
      dailyreportId,
      dailyreportRoomId,
      userId,
      dailyreportType,
      dailyReport,
      troubleReport,
      excitedReport,
      tomorrowTargetReport,
      totalIncome,
      totalWaste,
      wasteAmountTaiyaki,
      wasteAmountDango,
      wasteAmountTakoyaki,
      wasteAmountOkonomiyaki,
      wasteAmountYakisoba,
      dailyreportImage,
      dailyreportDate,
      dailyreportCreatedTimestamp,
      dailyreportUpdatedTimestamp,
      userName,
      userImage,
      rejiCost,
      weatherStatus,
      needAttentionReport,
      clockInTime,
      clockOutTime,
      commentNums,
    }
  ) => {},
  setDailyReport: (dailyReports) => {},
  deleteDailyReport: (id) => {},
  updateDailyReport: (
    id,
    {
      dailyreportId,
      dailyreportRoomId,
      userId,
      dailyreportType,
      dailyReport,
      troubleReport,
      excitedReport,
      tomorrowTargetReport,
      totalIncome,
      totalWaste,
      wasteAmountTaiyaki,
      wasteAmountDango,
      wasteAmountTakoyaki,
      wasteAmountOkonomiyaki,
      wasteAmountYakisoba,
      dailyreportImage,
      dailyreportDate,
      dailyreportCreatedTimestamp,
      dailyreportUpdatedTimestamp,
      userName,
      userImage,
      rejiCost,
      weatherStatus,
      needAttentionReport,
      clockInTime,
      clockOutTime,
      commentNums,
    }
  ) => {},
});

function dailyReportsReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      // console.log(state);
      const inverted = action.payload.reverse();
      return inverted;
    case "UPDATE":
      const updatableDailyReportIndex = state.findIndex(
        (dailyreport) =>
          dailyreport.dailyreportRoomId === action.payload.dailyreportRoomId
      );

      // console.log(updatableDailyReportIndex);
      const updatableDailyReport = state[updatableDailyReportIndex];
      const updatedItem = { ...updatableDailyReport, ...action.payload };
      const updatedDailyReports = [...state];
      updatedDailyReports[updatableDailyReportIndex] = updatedItem;
      return updatedDailyReports;
    case "DELETE":
      return state.filter((dailyreport) => dailyreport.id !== action.payload);
    default:
      return state;
  }
}

function DailyReportsContextProvider({ children }) {
  const [dailyReportsState, dispatch] = useReducer(dailyReportsReducer, []);

  function addDailyReport(dailyReportData) {
    dispatch({ type: "ADD", payload: dailyReportData });
  }
  function setDailyReport(dailyReports) {
    dispatch({ type: "SET", payload: dailyReports });
  }
  function deleteDailyReport(id) {
    dispatch({ type: "DELETE", payload: id });
  }
  function updateDailyReport(id, dailyReportData) {
    dispatch({ type: "UPDATE", payload: { id: id, data: dailyReportData } });
  }

  const value = {
    dailyReports: dailyReportsState,
    setDailyReport: setDailyReport,
    addDailyReport: addDailyReport,
    deleteDailyReport: deleteDailyReport,
    updateDailyReport: updateDailyReport,
  };

  return (
    <DailyReportsContext.Provider value={value}>
      {children}
    </DailyReportsContext.Provider>
  );
}
export default DailyReportsContextProvider;
