import { View, TextInput, StyleSheet, Text, Alert } from "react-native";
import Input from "./Input";
import { useState } from "react";
import Button from "../UI/Button";
import { getFormattedDate } from "../../util/date";
import { GlobalStyles } from "../../constant/styles";
import ImagePicker from "./ImagePicker";
import DateInput from "./DateInput";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import InputStore from "./InputStore";
import WasteInput from "./WasteInput";
import { fetchWasteItemCost } from "../../util/http";
import LoadingOverlay from "../UI/LoadingOverlay";
import ErrorOverlay from "../UI/ErrorOverlay";
import InputRejiMinus from "./InputRejiMinus";
import InputWeather from "./InputWeather";
import TimeInput from "./TimeInput";
import "firebase/storage";
import { storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";
import * as Progress from "react-native-progress";
import { getscaledDimension } from "../../util/deviceUiInfo";

function DailyReportForm({
  submitButtonLabel,
  headerFormLabel,
  onCancel,
  onSubmit,
  defaultValues,
}) {
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(hieghtScale, fontScale); // pass in fontScale to the StyleSheet
  const [userType, setUserType] = useState("PERSON");
  const [sumWaste, setSumWaste] = useState();
  const defaultWasteItem = "test";
  const [totalValue, setTotalValue] = useState();
  const [amountWaste, setAmountWaste] = useState();
  const [taiyakiCost, setTaiyakiCost] = useState();
  const [dangoCost, setDangoCost] = useState();
  const [okonomiyakiCost, setOkonomiyakiCost] = useState();
  const [takoyakiCost, setTakoyakiCost] = useState();
  const [yakisobaCost, setYakisobaCost] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(
    "しばらくお待ちください、アップロードしています。。。"
  );
  const [dailyReportImageURL, setDailyReportImageURL] = useState(0);

  const [UID, setUID] = useState("");
  const font_size = 18;
  const next_line_size = font_size*1.25;
  let sumWasteCost = 0;
  let calWasteCost = 0;

  const authCtx = useContext(AuthContext);
  // console.log(defaultValues);
  const [inputs, setInput] = useState({
    dailyreportType: {
      value: defaultValues ? defaultValues.dailyreportType : userType,
      isValid: true,
    },
    dailyReport: {
      value: defaultValues ? defaultValues.dailyReport : "",
      isValid: true,
    },
    troubleReport: {
      value: defaultValues ? defaultValues.troubleReport : "",
      isValid: true,
    },
    excitedReport: {
      value: defaultValues ? defaultValues.excitedReport : "",
      isValid: true,
    },
    tomorrowTargetReport: {
      value: defaultValues ? defaultValues.tomorrowTargetReport : "",
      isValid: true,
    },
    totalIncome: {
      value: defaultValues ? defaultValues.totalIncome : "",
      isValid: true,
    },
    totalWaste: {
      value: defaultValues ? defaultValues.totalWaste : "",
      isValid: true,
    },
    // wasteAmount: {
    //   value: defaultValues ? defaultValues.wasteAmount : "",
    //   isValid: true,
    // },
    wasteAmount: {
      value: {
        taiyaki: defaultValues ? defaultValues.wasteAmount : "",
        dango: defaultValues ? defaultValues.wasteAmount : "",
        okonomiyaki: defaultValues ? defaultValues.wasteAmount : "",
        takoyaki: defaultValues ? defaultValues.wasteAmount : "",
        yakisoba: defaultValues ? defaultValues.wasteAmount : "",
      },
      cost: {
        taiyaki: defaultValues ? defaultValues.wasteAmount : "",
        dango: defaultValues ? defaultValues.wasteAmount : "",
        okonomiyaki: defaultValues ? defaultValues.wasteAmount : "",
        takoyaki: defaultValues ? defaultValues.wasteAmount : "",
        yakisoba: defaultValues ? defaultValues.wasteAmount : "",
      },
      isValid: true,
    },

    dailyreportImage: {
      value: defaultValues ? defaultValues.dailyreportImage : "",
      isValid: true,
    },
    dailyreportDate: {
      value: defaultValues
        ? defaultValues.dailyreportDate
        : getFormattedDate(new Date()),
      isValid: true,
    },
    rejiCost: {
      value: defaultValues ? defaultValues.rejiCost : "",
      isValid: true,
    },
    weatherStatus: {
      value: defaultValues ? defaultValues.weatherStatus : "",
      isValid: true,
    },
    needAttentionReport: {
      value: defaultValues ? defaultValues.needAttentionReport : "",
      isValid: true,
    },
    clockInTime: {
      value: defaultValues ? defaultValues.clockInTime : "",
      isValid: true,
    },
    clockOutTime: {
      value: defaultValues ? defaultValues.clockOutTime : "",
      isValid: true,
    },
  });
  useEffect(() => {
    async function loadData() {
      // console.log("READ storedValues");
      const storedValues = await AsyncStorage.getItem("storedFormValues");
      // console.log("storedValues");
      // console.log(storedValues);
      // console.log(typeof defaultValues === "undefined");

      if (storedValues && typeof defaultValues === "undefined") {
        setInput(JSON.parse(storedValues));
      } else {
      }
    }
    async function fetchRole() {
      const uid = await AsyncStorage.getItem("uid");
      setUID(uid);
      const storedRole = await AsyncStorage.getItem("role");
      if (storedRole === "store") {
        setUserType("STORE");
      } else {
        setUserType("PERSON");
      }
      inputChangedHandler("dailyreportType", userType);
    }
    async function fetchWasteCost() {
      setIsFetching(true);
      const itemCosts = await fetchWasteItemCost();
      // console.log(itemCosts);
      for (const itemId in itemCosts) {
        switch (itemCosts[itemId].wasteItemName) {
          case "taiyaki":
            setTaiyakiCost(itemCosts[itemId].wasteItemPrice);
          case "dango":
            setDangoCost(itemCosts[itemId].wasteItemPrice);
          case "okonomiyaki":
            setOkonomiyakiCost(itemCosts[itemId].wasteItemPrice);
          case "takoyaki":
            setTakoyakiCost(itemCosts[itemId].wasteItemPrice);
          case "yakisoba":
            setYakisobaCost(itemCosts[itemId].wasteItemPrice);
          default:
            break;
        }
      }
      setIsFetching(false);
    }
    fetchRole();
    fetchWasteCost();
    loadData();
  }, []);

  async function inputChangedHandler(inputIdentifier, enteredValue) {
    // console.log(inputIdentifier + enteredValue);
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      };
    });
    await AsyncStorage.setItem("storedFormValues", JSON.stringify(inputs));
    // console.log("SET storedValues");
    // console.log(inputs);
  }
  async function inputMultiObjectChangedHandler(
    inputIdentifier,
    inputSubIdentifier,
    objectValue,
    enteredValue
  ) {
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: {
          ...curInputValues[inputIdentifier],
          [objectValue]: {
            ...curInputValues[inputIdentifier][objectValue],
            [inputSubIdentifier]: enteredValue,
          },
          isValid: true,
        },
      };
    });
    // console.log("SET storedValues");
    // console.log(JSON.stringify(inputs));
    await AsyncStorage.setItem("storedFormValues", JSON.stringify(inputs));
  }
  const clearData = async () => {
    try {
      await AsyncStorage.removeItem("storedFormValues");
      console.log("Data cleared successfully");
    } catch (error) {
      console.log("Error clearing data", error);
    }
  };
  function submitHandler() {
    const dailyReportData = {
      dailyreportType: inputs.dailyreportType.value,
      dailyReport: inputs.dailyReport.value,
      troubleReport: inputs.troubleReport.value,
      excitedReport: inputs.excitedReport.value,
      tomorrowTargetReport: inputs.tomorrowTargetReport.value,
      totalIncome: inputs.totalIncome.value,
      totalWaste: inputs.totalWaste.value,
      wasteAmountTaiyaki: inputs.wasteAmount.value.taiyaki,
      wasteAmountDango: inputs.wasteAmount.value.dango,
      wasteAmountTakoyaki: inputs.wasteAmount.value.takoyaki,
      wasteAmountOkonomiyaki: inputs.wasteAmount.value.okonomiyaki,
      wasteAmountYakisoba: inputs.wasteAmount.value.yakisoba,
      dailyreportImage: inputs.dailyreportImage.value,
      dailyreportDate: new Date(inputs.dailyreportDate.value),
      rejiCost: inputs.rejiCost.value,
      weatherStatus: inputs.weatherStatus.value,
      needAttentionReport: inputs.needAttentionReport.value,
      clockInTime: inputs.clockInTime.value,
      clockOutTime: inputs.clockOutTime.value,
    };
    // const description1IsValid = dailyReportData.description1.trim().length > 0;
    // const description2IsValid = dailyReportData.description2.trim().length > 0;
    const dateIsValid =
      dailyReportData.dailyreportDate.toString() !== "Invalid Date";
    // || !description1IsValid || !description2IsValid
    if (!dateIsValid) {
      // show feedback
      //   Alert.alert("Invalid input", "Please check your input values");
      setInput((curInputs) => {
        return {
          dailyreportType: {
            value: curInputs.dailyreportType.value,
          },
          dailyReport: {
            value: curInputs.dailyReport.value,
          },
          troubleReport: {
            value: curInputs.troubleReport.value,
          },
          excitedReport: {
            value: curInputs.excitedReport.value,
          },
          tomorrowTargetReport: {
            value: curInputs.tomorrowTargetReport.value,
          },
          totalIncome: {
            value: curInputs.totalIncome.value,
          },
          totalWaste: {
            value: curInputs.totalWaste.value,
          },
          wasteAmount: {
            value: curInputs.wasteAmount.value,
          },
          dailyreportImage: {
            value: curInputs.dailyreportImage.value,
          },
          // description2: {
          //   value: curInputs.description2.value,
          //   isValid: description2IsValid,
          // },
          dailyreportDate: {
            value: new Date(curInputs.dailyreportDate.value),
            isValid: dateIsValid,
          },
          rejiCost: {
            value: curInputs.rejiCost.value,
          },
          weatherStatus: {
            value: curInputs.weatherStatus.value,
          },
          needAttentionReport: {
            value: curInputs.needAttentionReport.value,
          },
          clockInTime: {
            value: curInputs.clockInTime.value,
          },
          clockOutTime: {
            value: curInputs.clockOutTime.value,
          },
        };
      });
      return;
    }
    clearData();
    onSubmit(dailyReportData);
  }
  const formIsInvalid = !inputs.dailyreportDate.isValid;
  // ||
  // !inputs.description1.isValid ||
  // !inputs.description2.isValid;
  // console.log(inputs.dailyreportType.value);
  async function onTakenImageHandler(onTakenImage) {
    const handleUpload = async (result) => {
      try {
        // console.log("result");
        // console.log(result);
        setIsUpload(true);
        setProgress(0);
        setProgressStatus(
          "しばらくお待ちください、アップロードしています。。。"
        );
        const imageUri = result.uri.split("/");
        const lastImageUri = imageUri[imageUri.length - 1];
        const newsRef = ref(
          storage,
          `dailyreport_picture/UID_${UID}_${lastImageUri}`
        );

        // Create a File object from the selected file
        const blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function () {
            reject(new TypeError("Network request failed"));
          };
          xhr.responseType = "blob";
          xhr.open("GET", result.uri, true);
          xhr.send(null);
        });

        // Get metadata properties
        getMetadata(newsRef)
          .then((metadata) => {
            // Metadata now contains the metadata for 'images/forest.jpg'
            // console.log(metadata);
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });

        const metadata = {
          contentType: "image/jpeg",
        };

        // Create a task to upload the PDF file
        const task = uploadBytesResumable(newsRef, blob, metadata);
        // console.log("task");
        // console.log(task);
        // Monitor the task's progress
        task.on(
          "state_changed",
          (snapshot) => {
            // Get the task progress, including the number of bytes uploaded and the total number of bytes
            // console.log(snapshot);
            // const progress =  Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
            // console.log(`Upload is  ${progress} % done`);
            // setProgress(progress);
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              // console.log("File available at", downloadURL);
              setDailyReportImageURL(downloadURL);
              inputChangedHandler("dailyreportImage", downloadURL);
              // downloadURL;
              // return outURL;
            });
            setProgress(1);
            setProgressStatus("アップロードが完了しました。");
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
    handleUpload(onTakenImage);
    // console.log(dailyReportImageURL);
  }

  function onAddCost(refInputs) {
    // console.log(refInputs);
    const rejiCost = refInputs.reduce((a, b) => a + "_" + b);
    // console.log(rejiCost);
    inputChangedHandler("rejiCost", rejiCost);
    // console.log(inputs.rejiCost.value);
  }
  function onCheckWeather(weatherInputs) {
    // console.log(refInputs);
    // console.log(weatherInputs);
    let weatherStatus = 0;
    const weatherMask = 1 << weatherInputs.weatherValue;
    // console.log(weatherMask);
    const weatherCurrentStatus = inputs.weatherStatus.value;
    weatherStatus = weatherCurrentStatus ^ weatherMask;
    // console.log(weatherStatus);
    inputChangedHandler("weatherStatus", weatherStatus);
    // console.log(inputs.weatherStatus.value);
  }
  function getDateInput(date) {
    // console.log("TOP!!!" + date);
    // const date_local = date.toTimeString();

    inputChangedHandler("dailyreportDate", date);
  }
  function getTimeInput(type, time) {
    // console.log("TOP!!!" + type);
    // console.log("TOP!!!" + time);
    // console.log(time.toString());
    // console.log(new Date(time.getTime()));
    // const year = time.getFullYear();
    // const month = String(time.getMonth() + 1).padStart(2, "0");
    // const date = time.getDate();
    const hours = time.getHours();
    const mins = String(time.getMinutes()).padStart(2, "0");

    // const time_local = `${year}-${month}-${date}T${hours}:${mins}:00Z`;
    const time_local = `${hours}:${mins}:00`;
    inputChangedHandler(type, time_local);
  }

  // function sumWasteItemHandler(wasteCost) {
  //   sumWasteCost += wasteCost;
  //   setSumWaste(sumWasteCost);
  // }
  // function wasteAmountHandler(amount, label) {
  //   inputChangedHandler("wasteAmount", {
  //     wasteItem: label,
  //     wasteAmount: amount,
  //   });
  // }
  if (isFetching) {
    return <LoadingOverlay />;
  }
  return (
    <View style={styles.form}>
      <Text style={styles.title}>{headerFormLabel}</Text>
      {/* <Input
        label="日報タイプ"
        invalid={!inputs.dailyreportType.isValid}
        textInputConfig={{
          onChangeText: inputChangedHandler.bind(this, "dailyreportType"),
          value: inputs.dailyreportType.value,
          color: GlobalStyles.colors.primary700,
          editable: false,
        }}
      />  */}
      {userType === "PERSON" && (
        <Input
          label="返答・アドバイス・許可がほしいこと"
          invalid={!inputs.needAttentionReport.isValid}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(this, "needAttentionReport"),
            value: inputs.needAttentionReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleTextInput={{
            height: 75
          }}
          style={{
            height: Math.max(60,inputs.needAttentionReport.value.split('\n').length * next_line_size + 60)
          }}
        />
      )}
      {userType === "PERSON" && (
        <Input
          label="本日の業務内容"
          invalid={!inputs.dailyReport.isValid}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(this, "dailyReport"),
            value: inputs.dailyReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleTextInput={{
            height: 270
          }}
          isRequired={true}
          style={{
            height: Math.max(60,inputs.dailyReport.value.split('\n').length * next_line_size + 60)
          }}
        />
      )}
      {userType === "PERSON" && (
        <Input
          label="業務での課題や悩み"
          invalid={!inputs.troubleReport.isValid}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(this, "troubleReport"),
            value: inputs.troubleReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleTextInput={{
            height: 100
          }}
          style={{
            height: Math.max(60,inputs.troubleReport.value.split('\n').length * next_line_size + 60)
          }}
          isRequired={true}
        />
      )}
      {userType === "PERSON" && (
        <Input
          label="本日のわくわく"
          invalid={!inputs.excitedReport.isValid}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(this, "excitedReport"),
            value: inputs.excitedReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          style={{
            height: Math.max(60,inputs.excitedReport.value.split('\n').length * next_line_size + 60)
          }}
        />
      )}
      {userType === "PERSON" && (
        <Input
          label="明日の予定"
          invalid={!inputs.tomorrowTargetReport.isValid}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(
              this,
              "tomorrowTargetReport"
            ),
            value: inputs.tomorrowTargetReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleTextInput={{
            height: 75
          }}
          style={{
            height: Math.max(60,inputs.tomorrowTargetReport.value.split('\n').length * next_line_size + 60)
          }}
          isRequired={true}
        />
      )}
      {userType === "STORE" && (
        <InputStore
          label="本日の売り上げ（円）"
          invalid={!inputs.totalIncome.isValid}
          textInputConfig={{
            onChangeText: inputChangedHandler.bind(this, "totalIncome"),
            value: inputs.totalIncome.value,
            placeholder: "10000",
            // color: GlobalStyles.colors.primary700,
          }}
        />
      )}
      {userType === "STORE" && (
        <View>
          <Text style={styles.wasteTitle}>廃棄物を記入してください</Text>
          <View style={styles.wasteListContainer}>
            <WasteInput
              label_text="たい焼き"
              label_code="taiyaki"
              // invalid={!inputs.totalIncome.isValid}
              itemUnit="個"
              textInputConfigCost={{
                editable: false,
                onChangeText: inputMultiObjectChangedHandler.bind(
                  this,
                  "wasteAmount",
                  "taiyaki",
                  "cost"
                ),
                value: inputs.wasteAmount.cost.taiyaki,
                backgroundColor: GlobalStyles.colors.neutral200,
              }}
              textInputConfigItemAmount={{
                onChangeText: (amount_input) => {
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "taiyaki",
                    "value",
                    amount_input
                  );
                  let cal = amount_input * taiyakiCost;
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "taiyaki",
                    "cost",
                    cal.toString()
                  );
                },
                value: inputs.wasteAmount.value.taiyaki,
              }}
            />
            <WasteInput
              label_text="団子"
              label_code="dango"
              // invalid={!inputs.totalIncome.isValid}
              itemUnit="個"
              textInputConfigCost={{
                editable: false,
                onChangeText: inputMultiObjectChangedHandler.bind(
                  this,
                  "wasteAmount",
                  "dango",
                  "cost"
                ),
                value: inputs.wasteAmount.cost.dango,
                backgroundColor: GlobalStyles.colors.neutral200,
              }}
              textInputConfigItemAmount={{
                onChangeText: (amount_input) => {
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "dango",
                    "value",
                    amount_input
                  );
                  let cal = amount_input * dangoCost;
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "dango",
                    "cost",
                    cal.toString()
                  );
                },
                value: inputs.wasteAmount.value.dango,
              }}
            />
            <WasteInput
              label_text="お好み焼き"
              label_code="okonomiyaki"
              // invalid={!inputs.totalIncome.isValid}
              itemUnit="個"
              textInputConfigCost={{
                editable: false,
                onChangeText: inputMultiObjectChangedHandler.bind(
                  this,
                  "wasteAmount",
                  "okonomiyaki",
                  "cost"
                ),
                value: inputs.wasteAmount.cost.okonomiyaki,
                backgroundColor: GlobalStyles.colors.neutral200,
              }}
              textInputConfigItemAmount={{
                onChangeText: (amount_input) => {
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "okonomiyaki",
                    "value",
                    amount_input
                  );
                  let cal = amount_input * okonomiyakiCost;
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "okonomiyaki",
                    "cost",
                    cal.toString()
                  );
                },
                value: inputs.wasteAmount.value.okonomiyaki,
              }}
            />
            <WasteInput
              label_text="たこ焼き"
              label_code="takoyaki"
              // invalid={!inputs.totalIncome.isValid}
              itemUnit="個"
              textInputConfigCost={{
                editable: false,
                onChangeText: inputMultiObjectChangedHandler.bind(
                  this,
                  "wasteAmount",
                  "takoyaki",
                  "cost"
                ),
                value: inputs.wasteAmount.cost.takoyaki,
                backgroundColor: GlobalStyles.colors.neutral200,
              }}
              textInputConfigItemAmount={{
                onChangeText: (amount_input) => {
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "takoyaki",
                    "value",
                    amount_input
                  );
                  let cal = amount_input * takoyakiCost;
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "takoyaki",
                    "cost",
                    cal.toString()
                  );
                },
                value: inputs.wasteAmount.value.takoyaki,
              }}
            />
            <WasteInput
              label_text="焼きそば"
              label_code="yakisoba"
              // invalid={!inputs.totalIncome.isValid}
              itemUnit="個"
              textInputConfigCost={{
                editable: false,
                onChangeText: inputMultiObjectChangedHandler.bind(
                  this,
                  "wasteAmount",
                  "yakisoba",
                  "cost"
                ),
                value: inputs.wasteAmount.cost.yakisoba,
                backgroundColor: GlobalStyles.colors.neutral200,
              }}
              textInputConfigItemAmount={{
                onChangeText: (amount_input) => {
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "yakisoba",
                    "value",
                    amount_input
                  );
                  let cal = amount_input * yakisobaCost;
                  inputMultiObjectChangedHandler(
                    "wasteAmount",
                    "yakisoba",
                    "cost",
                    cal.toString()
                  );
                },
                value: inputs.wasteAmount.value.yakisoba,
              }}
            />
          </View>
        </View>
      )}
      {userType === "STORE" && (
        <InputStore
          label="廃棄物費用（自動算出）"
          invalid={!inputs.totalWaste.isValid}
          styleInputWithPrefixContainer={{
            backgroundColor: GlobalStyles.colors.neutral200,
          }}
          textInputConfig={{
            editable: false,
            onChangeText: inputChangedHandler.bind(this, "totalWaste"),
            value: (inputs.totalWaste.value = (
              +inputs.wasteAmount.cost.taiyaki +
              +inputs.wasteAmount.cost.dango +
              +inputs.wasteAmount.cost.okonomiyaki +
              +inputs.wasteAmount.cost.takoyaki +
              +inputs.wasteAmount.cost.yakisoba
            ).toString()),
            color: GlobalStyles.colors.primary700,
            backgroundColor: GlobalStyles.colors.neutral200,
          }}
          styleLabel={{ fontSize: 18 * fontScale }}
        />
      )}
      {userType === "STORE" && (
        <InputRejiMinus
          label="レジマイナス"
          // invalid={!inputs.dailyReport.isValid}
          onAddCost={onAddCost}
          textInputConfig={{
            // onChangeText: inputChangedHandler.bind(this, "dailyReport"),
            // value: inputs.excitedReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleLabel={{ fontSize: 18 * fontScale }}
        />
      )}
      {userType === "STORE" && (
        // <Input
        //   // invalid={!inputs.dailyReport.isValid}
        //   invalid={false}
        //   textInputConfig={{
        //     multiline: true,
        //     onChangeText: inputChangedHandler.bind(this, "dailyReport"),
        //     value: inputs.dailyReport.value,
        //     color: GlobalStyles.colors.primary700,
        //   }}
        // />
        <InputWeather
          label="今日の天気"
          onCheckWeahter={onCheckWeather}
          defaultWeatherData={inputs.weatherStatus.value}
        />
      )}

      {userType === "STORE" && (
        <Input
          label="コメント"
          // invalid={!inputs.dailyReport.isValid}
          invalid={false}
          textInputConfig={{
            multiline: true,
            onChangeText: inputChangedHandler.bind(this, "dailyReport"),
            value: inputs.dailyReport.value,
            color: GlobalStyles.colors.primary700,
          }}
          styleTextInput={{
            height: 75
          }}
          style={{
            height: Math.max(60,inputs.dailyReport.value.split('\n').length * next_line_size + 60)
          }}
        />
      )}
      {userType === "PERSON" && (
        <View style={styles.inputsRow}>
          <TimeInput
            style={styles.rowInput}
            label="出勤時間"
            inputType="clockInTime"
            invalid={!inputs.clockInTime.isValid}
            textInputConfig={{
              placeholder: "時間: HH:MM",
              // onChangeText: inputChangedHandler.bind(this, "date"),
              // value: inputs.clockInTime.value,
            }}
            getTimeFromTextInput={getTimeInput}
            inputValueTime={inputs.clockInTime.value}
          />
          <TimeInput
            style={styles.rowInput}
            label="退勤時間"
            inputType="clockOutTime"
            invalid={!inputs.clockOutTime.isValid}
            textInputConfig={{
              placeholder: "時間: HH:MM",
              // onChangeText: inputChangedHandler.bind(this, "date"),
              // value: inputs.clockInTime.value,
            }}
            getTimeFromTextInput={getTimeInput}
            inputValueTime={inputs.clockOutTime.value}
          />
        </View>
      )}
      <View style={styles.inputsRow}>
        <DateInput
          style={styles.rowInput}
          label="日付"
          invalid={!inputs.dailyreportDate.isValid}
          textInputConfig={{
            placeholder: "日付: YYYY-MM-DD",
            // onChangeText: testDateInput,
            // onChangeText: inputChangedHandler.bind(this, "dailyreportDate"),
          }}
          getDataFromTextInput={getDateInput}
          inputValueDate={inputs.dailyreportDate.value}
        />
      </View>
      <ImagePicker
        textInput={"画像を取得する"}
        onTakenImage={onTakenImageHandler}
      />
      {isUpload ? (
        <View style={styles.progressBarContainer}>
          <Text style={[styles.progressText, styles.textBase]}>
            {progressStatus}
          </Text>
          <Progress.Bar
            progress={progress}
            width={300}
            color={"rgba(251, 254, 238, 1)"}
          />
        </View>
      ) : null}
      {formIsInvalid && (
        <Text style={styles.errorText}>Invalid input data</Text>
      )}

      <View style={styles.buttons}>
        <Button style={styles.button} mode="flat" onPress={onCancel}>
          キャンセル
        </Button>
        <Button
          style={styles.button}
          onPress={submitHandler}
          styleButton={{ backgroundColor: GlobalStyles.colors.primary700 }}
        >
          {submitButtonLabel}
        </Button>
      </View>
    </View>
  );
}
export default DailyReportForm;

const makeStyles = (hieghtScale, fontScale) =>
  StyleSheet.create({
    form: { flex: 1 },
    title: {
      fontSize: 24 * fontScale,
      fontWeight: "bold",
      color: "white",
      marginVertical: 12,
      textAlign: "center",
    },
    inputsRow: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    rowInput: {
      flex: 1,
    },
    errorText: {
      textAlign: "center",
      color: GlobalStyles.colors.error500,
      margin: 8,
    },
    buttons: {
      marginVertical: 24,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      minWidth: 120,
      marginHorizontal: 18,
    },
    wasteTitle: {
      fontSize: 20 * fontScale,
      fontWeight: "bold",
      color: "white",
    },
    wasteListContainer: {},
    wasteItemContainer: { flexDirection: "row" },
    progressBarContainer: { alignItems: "center" },
    progressText: {
      fontSize: 12 * fontScale,
      marginVertical: 10,
    },
    textBase: { color: GlobalStyles.colors.neutral10 },
  });
