import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  useFocusEffect,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { useCallback, useContext, useEffect, useState } from "react";

import { KeyboardAvoidingView, SafeAreaView, Text } from "react-native";
import DailyReportsOutput from "../component/DailyReportOutput/DailyReportsOutput";
import DailyReportRoomsOutput from "../component/DailyReportRoom/RoomsOutput";
import TaskItemDetail from "../component/TaskManager/TaskItemDetail";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { DailyReportsContext } from "../store/dailyreport-context";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
import { MyTaskContext } from "../store/mytask-context";
import { TaskContext } from "../store/task-context";
import { getDateMinusDays } from "../util/date";
import {
  deleteTask,
  fetchDailyReportRooms,
  fetchDailyReports,
  updateTaskStatus,
} from "../util/http";

function TaskManagerDetailScreen({ route, navigation }) {
  const [isFetching, setIsFectching] = useState(true);
  const [error, setError] = useState();
  const dailyReportsCtx = useContext(DailyReportsContext);
  const [counter, setCounter] = useState(0);
  const isFocused = useIsFocused();
  const task = route.params.params.item;
  const userData = route.params.params.userData;
  const todoTask = route.params.params.todoTask;

  const todoTaskCtx = useContext(TaskContext);
  const myTaskCtx = useContext(MyTaskContext);
  let taskIndex;
  if (todoTask) {
    taskIndex = todoTaskCtx.task.findIndex(
      (taskItem) => taskItem.taskId === task.taskId
    );
  } else {
    taskIndex = myTaskCtx.task.findIndex(
      (taskItem) => taskItem.taskId === task.taskId
    );
  }

  // useFocusEffect(
  //   useCallback(() => {
  //     // Run your effect when the screen is focused
  //     console.log("print use effect");
  //     if (todoTask) {
  //       taskIndex = todoTaskCtx.task.findIndex(
  //         (taskItem) => taskItem.taskId === task.taskId
  //       );
  //     } else {
  //       taskIndex = myTaskCtx.task.findIndex(
  //         (taskItem) => taskItem.taskId === task.taskId
  //       );
  //     }
  //   }, [navigation])
  // );
  // console.log("todoTaskCtx");
  // console.log(
  //   todoTaskCtx.task.findIndex((taskItem) => taskItem.taskId === task.taskId)
  // );
  // console.log("myTaskCtx");
  // console.log(myTaskCtx);

  // console.log("TaskManagerDetailScreen");
  // console.log(task);
  // console.log(route.params);

  // const roomId = route.params?.dailyReportRoomId;
  // console.log(roomId);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter + 1);
  //   }, 30000);
  async function statusUpdateHandler(taskData) {
    try {
      // console.log("statusUpdateHandler");
      // console.log(taskData);
      const updateResponseData = await updateTaskStatus(taskData);
      if (todoTask) {
        console.log("update");
        todoTaskCtx.updateTask(taskIndex, taskData);
        myTaskCtx.updateTask(taskIndex, taskData);
        // console.log(todoTaskCtx.task[taskIndex]);
      } else {
        todoTaskCtx.updateTask(taskIndex, taskData);
        myTaskCtx.updateTask(taskIndex, taskData);
      }
    } catch (error) {
      console.log(error);
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
    }
  }
  async function deleteTaskHandler(taskData) {
    try {
      // console.log("statusUpdateHandler");
      // console.log(taskData);
      const deleteResponseData = await deleteTask(taskData);
      // if (todoTask) {
      //   console.log("update");
      //   todoTaskCtx.updateTask(taskIndex, taskData);
      //   myTaskCtx.updateTask(taskIndex, taskData);
      // console.log(todoTaskCtx.task[taskIndex]);
      // } else {
      todoTaskCtx.deleteTask(taskData);
      myTaskCtx.deleteTask(taskData);
      // }
    } catch (error) {
      console.log(error);
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
    }
  }
  // console.log(todoTaskCtx.task[taskIndex]);

  return (
    <TaskItemDetail
      task={todoTask ? todoTaskCtx.task[taskIndex] : myTaskCtx.task[taskIndex]}
      // task={task}
      onStatusUpdate={statusUpdateHandler}
      userData={userData}
      todoTask={todoTask}
      onDeleteTask={deleteTaskHandler}
    />
  );
}

export default TaskManagerDetailScreen;
