import { StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getscaledDimension } from "../../util/deviceUiInfo";

function ProblemItem({ item, editingMode = false }) {
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(fontScale*0.7); // pass in fontScale to the StyleSheet

  return (
    <View
      style={[
        styles.problemListContainer,
        editingMode === true && styles.editingMode,
      ]}
      key={item.key}
    >
      <View style={styles.problemListTilteLayout}>
        <View style={styles.problemListTilteContainer}>
          <Text style={styles.problemListTilte}>{item.problemTitle}</Text>
        </View>
        <View style={styles.dateContainer}>
          <Text style={[styles.textBase, styles.reportDate]}>
            {`${item.updatedAt.split(" ")[1].slice(0, 5)}`}
          </Text>
          <Text style={[styles.textBase, styles.reportMonthYear]}>
            {`${item.updatedAt.split(" ")[0].split("-")[2]}日${
              item.updatedAt.split("-")[1]
            }月${item.updatedAt.split("-")[0]}年`}
          </Text>
        </View>
      </View>
      <View style={styles.problemListDetailContainer}>
        <Text style={styles.problemListDetail}>
          トラブル種類: {item.problemType}
        </Text>
      </View>
    </View>
  );
}
export default ProblemItem;

const makeStyles = (fontScale) =>StyleSheet.create({
  problemListContainer: {
    marginHorizontal: "5%",
    marginBottom: 15,
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
  },
  problemListTilteLayout: {
    flexDirection: "row",
  },
  problemListTilteContainer: {
    marginHorizontal: "5%",
    marginVertical: "2%",
    flex: 8,
  },
  problemListTilte: {
    fontSize: 18*fontScale,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
  },
  dateContainer: {
    marginTop: "2%",
    marginHorizontal: "2%",
    flex: 4,
    // marginHorizontal: "5%",
    // marginVertical: "2%",
    // flexDirection: "row",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  reportDate: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  reportMonthYear: {
    fontSize: 12*fontScale,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  problemListDetailContainer: {
    marginLeft: "8%",
    marginRight: "5%",

    marginBottom: "2%",
  },
  problemListDetail: {
    fontSize: 14*fontScale,
    color: GlobalStyles.colors.primary600,
  },
  editingMode: {
    opacity: 0.3,
    backgroundColor: GlobalStyles.colors.neutral100,
  },
});
