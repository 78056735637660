import axios from "axios";
import OneSignal from "react-native-onesignal";

const API_KEY = "3c1e9184d7964b27bf410b3bd2ae5d14";
const BACKEND_URL = "https://yonenoya.jp/houkoku/";
async function authenticate(mode, username, password, user_role) {
  // const notification_id =
  //   mode === "signInWithPassword"
  //     ? await OneSignal.getDeviceState().userId
  //     : "";
  const response = await axios.post(
    `${BACKEND_URL}/authenticate.php?mode=${mode}&key=${API_KEY}`,
    {
      username: username,
      password: password,
      user_role: user_role,
      returnSecureToken: true,
      // notification_id: notification_id,
    }
  );

  // console.log(response.data);
  const token = response.data.idToken;
  const userRole = response.data.user_role;
  const userID = response.data.user_uid.toString();
  const pushID = response.data.notification_id.toString();
  const userName = response.data.owner_username.toString();

  return [token, userRole, userID, pushID,userName];
}

export function createUser(username, password, userRole) {
  return authenticate("signUp", username, password, userRole);
}

export function login(username, password, userRole) {
  return authenticate("signInWithPassword", username, password, userRole);
}
