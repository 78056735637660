import { useCameraPermissions } from "expo-image-picker";
import { useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
// import { Dropdown } from "react-native-element-dropdown";
import { GlobalStyles } from "../../constant/styles";
import Input from "../Auth/Input";
import Button from "../UI/Button";
import ButtonWithIcon from "../UI/ButtonWithIcon";
import { verifyDelete } from "../../util/verifyDelete";
import DropDownPicker from "../Profile/DropDownPicker";

function UserEditForm({ onSubmit, onCancel, onDelete, defaultValues }) {
  const [enteredUsername, setEnteredUsername] = useState(
    defaultValues ? defaultValues.username : ""
  );
  const [enteredPassword, setEnteredPassword] = useState(
    defaultValues ? defaultValues.password : ""
  );
  const [enteredUserRole, setEnteredUserRole] = useState(
    defaultValues ? defaultValues.userRole : ""
  );
  //   const [enteredUserImage, setEnteredUserImage] = useState(
  //     defaultValues ? defaultValues.userImage : ""
  //   );

  const hadProfileImg = defaultValues.userImage !== null ? true : false;
  const [pickedImage, setPickedImage] = useState();
  const [cameraPermissionInformation, requestPermission] =
    useCameraPermissions();
  const [gotImage, setGotImage] = useState();
  async function verifyPermissions() {
    if (cameraPermissionInformation.status === PermissionStatus.UNDETERMINED) {
      const permissionResponse = await requestPermission(false);
      return permissionResponse.granted;
    }
    if (cameraPermissionInformation.status === PermissionStatus.DENIED) {
      Alert.alert(
        "不十分な許可",
        "このアプリを使用するため、カメラの権限を許可する必要があります"
      );
      return false;
    }
    return true;
  }
  // async function takeImageHandler() {
  //   const hasPermission = await verifyPermissions();
  //   if (!hasPermission) {
  //     return;
  //   }
  //   const image = await launchCameraAsync({
  //     allowsEditing: true,
  //     aspect: [16, 9],
  //     quality: 0.5,
  //   });
  //   setPickedImage(image.uri);
  //   if ((await image.trim().length) > 0) setGotImage(true);
  // }

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "username":
        setEnteredUsername(enteredValue);
        break;
      case "password":
        setEnteredPassword(enteredValue);
        break;
      case "userRole":
        setEnteredUserRole(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      authenticationId: defaultValues.authenticationId,
      username: enteredUsername,
      password: enteredPassword,
      userRole: enteredUserRole,
    });
  }
  async function deleteHandler() {
    const confirmDel = await verifyDelete("ユーザーの削除");
    if (confirmDel) {
      onDelete({
        authenticationId: defaultValues.authenticationId,
        username: enteredUsername,
        password: enteredPassword,
        userRole: enteredUserRole,
      });
    }
  }
  const data = [
    { label: "管理者（アドミン）", value: "admin" },
    { label: "エリアマネージャー", value: "manager" },
    { label: "社員", value: "officer" },
    { label: "店舗", value: "store" },
  ];

  const [isFocus, setIsFocus] = useState(false);
  return (
    <>
      <View>
        <View>
          <Input
            label="ユーザー名"
            onUpdateValue={updateInputValueHandler.bind(this, "username")}
            value={enteredUsername}
            keyboardType="email-address"
            placeholderValue="ユーザー名"
            styleText
          />
        </View>
        <View>
          <Input
            label="パスワード"
            onUpdateValue={updateInputValueHandler.bind(this, "password")}
            value={enteredPassword}
            keyboardType="email-address"
            placeholderValue="パスワード"
            styleText
          />
        </View>
        <View style={styles.container}>
          <Text style={styles.dropdownText}>ユーザーの担当</Text>
          {/* <Dropdown
            style={[styles.dropdown, isFocus && { borderColor: "blue" }]}
            placeholderStyle={[styles.placeholderStyle, styles.textBase]}
            selectedTextStyle={[styles.selectedTextStyle, styles.textBase]}
            inputSearchStyle={[styles.inputSearchStyle, styles.textBase]}
            iconStyle={styles.iconStyle}
            data={data}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isFocus ? "Select User Role" : "..."}
            searchPlaceholder="Search..."
            value={enteredUserRole}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(item) => {
              setEnteredUserRole(item.value);
              setIsFocus(false);
            }}
          /> */}
                  <DropDownPicker
        data={data}
        value={enteredUserRole}
        onValueChange={(value) => setEnteredUserRole(value)}
        placeholder="Select User Role"
        disabled={false}
        />
          {/* <Text style={styles.remarkText}>
            ＊管理者やマネジャー以外はユーザーの担当が変更できません
          </Text> */}
        </View>
        <View style={styles.buttons}>
          <Button
            style={styles.button}
            mode="flat"
            onPress={onCancel}
            styleText={[styles.buttonText, styles.textBase]}
          >
            キャンセル
          </Button>
          <Button
            style={styles.button}
            onPress={submitHandler}
            styleButton={{
              backgroundColor: GlobalStyles.colors.primary600,
            }}
            styleText={styles.buttonText}
          >
            {"編集"}
          </Button>
        </View>
        <View>
          <ButtonWithIcon
            icon="trash"
            style={styles.button}
            onPress={deleteHandler}
            styleButton={{
              backgroundColor: GlobalStyles.colors.error400,
            }}
            styleText={styles.buttonText}
            size={20}
            children={"ユーザー項目を削除"}
          />
        </View>
      </View>
    </>
  );
}

export default UserEditForm;

const styles = StyleSheet.create({
  form: {},
  container: {
    padding: 16,
    minWidth: "80%",
  },
  dropdown: {
    height: 50,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  profilePictureContainer: {
    marginVertical: 18,
    alignItems: "center",
    justifyContent: "center",
  },
  profilePicture: {
    width: 120,
    height: 120,
    borderRadius: 1000,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
  },
  textBase: { color: GlobalStyles.colors.primary600 },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 36,
  },
  profileImg: {
    width: 120,
    height: 120,
    borderRadius: 1000,
  },
  remarkText: {
    color: GlobalStyles.colors.error600,
    fontSize: 9,
  },
  dropdownText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    marginBottom: 6,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },

  buttonText: {
    fontSize: 16,
  },
});
