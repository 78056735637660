import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Text, StyleSheet, Pressable, TextInput, View } from "react-native";
// import { database } from "../Firebase";
import {
  collection,
  addDoc,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { Avatar, Bubble, GiftedChat, Send } from "react-native-gifted-chat";
import ErrorOverlay from "../UI/ErrorOverlay";
import IconButton from "../UI/IconButton";
import LoadingOverlay from "../UI/LoadingOverlay";
import { GlobalStyles } from "../../constant/styles";
import { AuthContext } from "../../store/auth-context";
import { UsersContext } from "../../store/user-context";
// import { UserAuthsContext } from "../store/userauth-context";
import { Ionicons } from "@expo/vector-icons";
import userImg from "../../assets/user.png";
import OneSignal from "react-native-onesignal";
import { addUnreadTask, clearUnreadTask, fetchUsers } from "../../util/http";
import { database } from "../../Firebase";
import { Button } from "react-native";
function TaskComment({ taskId, assignedUser, readOnly = false }) {
  const [messages, setMessages] = useState([]);
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();
  const navigation = useNavigation();
  const [error, setError] = useState();
  // const taskId = route.params.chatRoomId
  //   ? route.params.chatRoomId
  //   : route.params.params.chatRoomId;
  // const curRoomName = route.params.chatRoomName
  //   ? route.params.chatRoomName
  //   : route.params.params.chatRoomName;
  const userCtx = useContext(UsersContext);
  let updateUnreadTo = [];
  if (assignedUser) {
    assignedUser.forEach((user) => updateUnreadTo.push(user.user_id));
  }
  // console.log(updateUnreadTo)
  // const onSend = useCallback((messages = []) => {
  //   setMessages((previousMessages) =>
  //     GiftedChat.append(previousMessages, messages)
  //   );
  // }, []);

  useEffect(() => {
    async function clearUnreadNum() {
      const response = await clearUnreadTask(taskId);
    }
    const collectionRef = collection(database, "task" + taskId);
    const q = query(collectionRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setMessages(
        querySnapshot.docs.map((doc) => ({
          _id: doc.data()._id,
          createdAt: doc.data().createdAt.toDate(),
          text: doc.data().text,
          user: doc.data().user,
        }))
      );
    });
    clearUnreadNum();

    return () => unsubscribe();
  }, []);
  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
    const { _id, createdAt, text, user } = messages[0];
    addDoc(collection(database, "task" + taskId), {
      _id,
      createdAt,
      text,
      user,
    });
  }, []);

  async function addUnread(taskId) {
    const response = await addUnreadTask(taskId, updateUnreadTo);
  }

  // async function updateLastestMsg(taskId, inputMessage) {
  //   const response = await updateLastestChat(taskId, inputMessage);
  // }

  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        userCtx.setUser(userList);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      try {
        const storedUid = await AsyncStorage.getItem("uid");
        setUsingId(storedUid);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
      }
    }
    getUsersList();
    fetchUserData();
  }, [navigation]);

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }
  if (isFetching) {
    return <LoadingOverlay />;
  }
  const selectedUser = userCtx.users.find((users) => users.userId === usingId);
  // async function addUnread(taskId) {
  //       const response = await addUnreadTask(taskId);
  // }
  async function sendPush(messages) {
    // const response = await addUnreadChat(taskId);
    // const senderData = userCtx.users.find((users) => users.userId === usingId);
    // const sendPushUserIdList = [];
    // const sendPushUserList = userCtx.users
    //   .filter((users) => users.userId !== usingId)
    //   .forEach((userData) => sendPushUserIdList.push(userData.notificationId));
    // const notificationObj = {
    //   headings: { en: `${selectedUser.userName} (${curRoomName})` },
    //   contents: { en: `${messages[0].text}` },
    //   include_player_ids: sendPushUserIdList,
    //   data: {
    //     type: "taskManagerDetail",
    //     page: "TaskManagerDetailScreen",
    //     screen: "",
    //     roomName: `${curRoomName}`,
    //     roomId: `${taskId}`,
    //   },
    //   url: `yonenolinks:///taskManagerDetail/${encodeURIComponent(
    //     curRoomName
    //   )}/${taskId}`,
    // };
    // // console.log(messages[0].text);
    // const jsonString = JSON.stringify(notificationObj);
    // OneSignal.postNotification(
    //   jsonString,
    //   (success) => {
    //     console.log("Success:", success);
    //   },
    //   (error) => {
    //     console.log("Error:", error);
    //   }
    // );
  }
  const gotoComment = () => {
    // textInputRef.current.focus();
    // Keyboard.dismiss();
    // console.log("gotoComment");
    navigation.navigate("TaskManagerCommentScreen", {
      params: {
        titleName: "タスクコメント",
        taskId: taskId,
        assignedUser: assignedUser,
      },
    });
  };

  return (
    <GiftedChat
      messages={messages}
      showUserAvatar={true}
      // showAvatarForEveryMessage={false}
      textInputStyle={styles.textInputStyle}
      renderUsernameOnMessage={true}
      onSend={(messages) => {
        // console.log(messages);
        onSend(messages);
        addUnread(taskId);
        sendPush(messages);
        // updateLastestMsg(taskId, messages);
      }}
      user={{
        _id: selectedUser.userId,
        name: selectedUser.userName,
        avatar: selectedUser.userImage ? selectedUser.userImage : userImg,
      }}
      placeholder="メッセージを記入する"
      // renderCustomView={({ user }) => (
      //   <View style={{ minHeight: 20, alignItems: "center" }}>
      //     <Text>
      //       Current user:
      //       {user.name}
      //     </Text>
      //     <Text>From CustomView</Text>
      //   </View>
      // )}
      // renderAvatar={(props) => (
      //   <Avatar
      //     {...props}
      //     containerStyle={{
      //       left: { borderWidth: 3, borderColor: "red" },
      //       right: {},
      //     }}
      //     imageStyle={{
      //       left: { borderWidth: 3, borderColor: "blue" },
      //       right: {},
      //     }}
      //   />
      // )}
      renderBubble={(props) => {
        return (
          <Bubble
            {...props}
            textStyle={{
              right: {
                color: "white",
              },
              left: {
                color: "white",
              },
            }}
            wrapperStyle={{
              left: {
                backgroundColor: GlobalStyles.colors.neutral500,
              },
              right: {
                backgroundColor: GlobalStyles.colors.primary600,
              },
            }}
          />
        );
      }}
      // renderUsername={()=> {
      //   const { currentMessage, user } = this.props
      //   if (this.props.renderUsernameOnMessage && currentMessage) {
      //     if (user && currentMessage.user._id === user._id) {
      //       return null
      //     }
      //     return (
      //       <View style={styles.content.usernameView}>
      //         <Text
      //           style={
      //             [styles.content.username, this.props.usernameStyle] as TextStyle
      //           }
      //         >
      //           ~ {currentMessage.user.name}
      //         </Text>
      //       </View>
      //     )
      //   }
      //   return null
      // }}

      renderSend={(props) => {
        return (
          <Send
            {...props}
            containerStyle={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.sendText}>送信</Text>
          </Send>
        );
      }}
      // bottomOffset={}
      renderComposer={(props) => {
        if (readOnly) {
          return (
            <View style={styles.gotoButton}>
              {/* <Pressable onPress={gotoComment}>
        <Text style={styles.sendText}>送信</Text></Pressable> */}
              <Button
                onPress={gotoComment}
                style={styles.gotoButton}
                color={GlobalStyles.colors.primary600}
                title="コメントを書きます"
              />
            </View>
          );
        }
      }}
      // bottomOffset={}
    />
    // <View></View>
  );
}
export default TaskComment;
const styles = StyleSheet.create({
  sendText: {
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  gotoButton: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  textInputStyle: { marginVertical: 20 },
});
