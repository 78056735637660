import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { StyleSheet, Text, View } from "react-native";
import ButtonWithIcon from "../component/UI/ButtonWithIcon";
import { GlobalStyles } from "../constant/styles";

import { AuthContext } from "../store/auth-context";
import { getscaledDimension } from "../util/deviceUiInfo";

function Welcome() {
  const [fetchedMessage, setFetchedMesssage] = useState("");
  const navigation = useNavigation();
  const authCtx = useContext(AuthContext);
  // const {height, width} = useWindowDimensions(); // import useWindowDimensions()
  // const fontSizeWidth = width*0.058;
  // console.log(fontSizeWidth);
  // const {fontScale} = useWindowDimensions(); // import useWindowDimensions()
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(fontScale * 0.8); // pass in fontScale to the StyleSheet
  const buttonFntSize = 24 * fontScale;
  function goToLoginHandler() {
    navigation.replace("Login");
  }
  return (
    <View style={styles.rootContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>ヨネノリンクス WEB</Text>
      </View>
      <View style={styles.subtitleContainer}>
        <Text style={styles.subtitle}>米乃家：会員企業の管理用アプリ</Text>
      </View>
      <ButtonWithIcon
        icon="person-circle-outline"
        size={buttonFntSize}
        onPress={goToLoginHandler}
        styleText={{ fontSize: buttonFntSize }}
      >
        {"ヨネのリンクスの会員 ID でログイン "}
      </ButtonWithIcon>
    </View>
  );
}

export default Welcome;

const makeStyles = (fontScale) =>
  StyleSheet.create({
    rootContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 32,
      height: "100%",
    },
    titleContainer: {
      // flex: 1,
    },
    subtitleContainer: {
      // flex: 1,
    },
    title: {
      fontSize: GlobalStyles.fonts.welcome_page_title * fontScale,
      fontWeight: "bold",
      marginBottom: 1,
      color: GlobalStyles.colors.primary700,
    },
    subtitle: {
      fontSize: GlobalStyles.fonts.welcome_page_subtitle * fontScale,
      marginBottom: 28,
      color: GlobalStyles.colors.secondary200,
    },
  });
