import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import {
  Modal,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import UserAuthOutput from "../component/UserTable/UserAuthOutput";
import UserEditForm from "../component/UserTable/UserEditForm";
import { GlobalStyles } from "../constant/styles";
import { UsersContext } from "../store/user-context";
import { UserAuthsContext } from "../store/userauth-context";
import {
  deleteUserAuth,
  fetchUserAuth,
  fetchUsers,
  updateUserAuth,
  updateUserProfile,
} from "../util/http";

function ManageUser({ navigation }) {
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [userAuth, setUserAuth] = useState(false);

  const [error, setError] = useState();

  const userAuthCtx = useContext(UserAuthsContext);
  const isFocused = useIsFocused();

  useEffect(() => {
    async function getUserAuthsList() {
      setIsFectching(true);
      try {
        const userAuthList = await fetchUserAuth();
        // console.log(userAuthList);
        userAuthCtx.setUser(userAuthList);
        // console.log(userAuthCtx);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    getUserAuthsList();
  }, [navigation, isSubmitting]);
  async function confirmHandler(userAuthData) {
    setIsSubmitting(true);
    try {
      await updateUserAuth(userAuthData);
      userAuthCtx.updateUser(userAuthData.authenticationId, userAuthData);
      setIsSubmitting(false);
    } catch (error) {
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }
  async function deleteHandler(userAuthData) {
    setIsSubmitting(true);
    try {
      await deleteUserAuth(userAuthData);
      userAuthCtx.deleteUser(userAuthData.authenticationId);
      setIsSubmitting(false);
    } catch (error) {
      setError(
        "削除リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }
  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }
  if (isFetching) {
    return <LoadingOverlay />;
  }

  if (error && !isSubmitting) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsSubmitting(true);
          setError();
        }}
      />
    );
  }
  if (isSubmitting) {
    return <LoadingOverlay />;
  }
  function profileEditSubmitHandler(userAuthData) {
    confirmHandler(userAuthData);
    setModalVisible(!modalVisible);
  }
  function profileEditCancelHandler() {
    setModalVisible(!modalVisible);
  }
  function profileEditDeleteHandler(userAuthData) {
    deleteHandler(userAuthData);
    setModalVisible(!modalVisible);
  }
  function onSettingHandler(userAuthData) {
    // console.log(use rAuthData);
    setModalVisible(!modalVisible);
    setUserAuth(userAuthData);
  }
  return (
    <>
      <View style={styles.container}>
        <View style={styles.backIconContainer}>
          <IconButton
            icon="chevron-back-outline"
            size={24}
            color={GlobalStyles.colors.neutral10}
            onPress={() => navigation.goBack()}
            styleButtonContainer={styles.buttonBox}
            label={`戻る`}
          />
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.headerText}>メンバー管理一覧</Text>
        </View>
        <View style={styles.bodyContainer}>
          <UserAuthOutput
            userAuths={userAuthCtx.users}
            fallbackText="ユーザー内容が取得できません"
            onSetting={onSettingHandler}
          />
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.modalTitleContainer}>
                <Text style={styles.titleText}>ユーザー情報編集</Text>
              </View>
              <View style={styles.userEditContent}>
                <UserEditForm
                  onSubmit={profileEditSubmitHandler}
                  onCancel={profileEditCancelHandler}
                  onDelete={profileEditDeleteHandler}
                  defaultValues={userAuth}
                />
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </>
  );
}
export default ManageUser;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.primary600,
  },
  titleContainer: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: "11%",
    color: GlobalStyles.colors.neutral10,
  },
  bodyContainer: { flex: 9 },
  deleteContainer: {
    paddingTop: 8,
    borderTopWidth: 2,
    borderTopColor: GlobalStyles.colors.neutral10,
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "row",
  },
  idContainer: {
    flex: 1,
  },
  contentContainer: {
    flex: 3,
  },
  iconContainer: {
    flex: 2,
  },
  backIconContainer: {
    position: "absolute",
    top: "5%",
    left: "3%",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonBox: { marginRight: 2, zIndex: 100 },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    marginTop: "30%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  userEditContent: {
    // backgroundColor: GlobalStyles.colors.neutral50,
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
});
