import { Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import DailyReportsList from "./DailyReportList";

function DailyReportsOutput({
  dailyReports,
  dailyReportsPeriod,
  fallbackText,
  roomId,
  roomName,
}) {
  let content = (
    <View style={styles.textContainer}>
      <Text style={styles.infoText}>{fallbackText}</Text>
    </View>
  );

  if (dailyReports.length > 0) {
    content = (
      <DailyReportsList
        dailyReports={dailyReports}
        roomId={roomId}
        roomName={roomName}
      />
    );
  }
  return <View style={styles.container}>{content}</View>;
}

export default DailyReportsOutput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 24,
    backgroundColor: GlobalStyles.colors.neutral100,
    flexDirection: "column-reverse",
  },
  infoText: {
    color: "white",
    fontSize: 22,
    textAlign: "center",
    marginTop: 32,
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
});
