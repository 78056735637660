import { FlatList } from "react-native";
import UserItem from "./UserItem";

function UserList({ users, onCheck, defaultUser, isMemberlist }) {
  function renderUesrsItem(itemData) {
    return (
      <UserItem
        {...itemData.item}
        onCheck={onCheck}
        defaultUser={defaultUser}
        isMemberlist={isMemberlist}
      />
    );
  }
  return (
    <FlatList
      data={users}
      renderItem={renderUesrsItem}
      keyExtractor={(item) => item.id}
      removeClippedSubviews={true}
    />
  );
}

export default UserList;
