import { Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import Button from "../UI/Button";
import UserList from "./UserList";

function UserOutput({
  users,
  fallbackText,
  onCheck,
  defaultUser,
  isMemberlist,
  styleContainer,
  forTaskManager = false,
  onCancel,
  onSubmit,
}) {
  let content = <Text style={styles.infoText}>{fallbackText}</Text>;
  // console.log(defaultUser);

  if (users.length > 0) {
    content = (
      <UserList
        users={users}
        onCheck={onCheck}
        defaultUser={defaultUser}
        isMemberlist={isMemberlist}
      />
    );
  }
  return (
    <>
      <View style={[styles.container, styleContainer]}>
        {forTaskManager ? (
          <Text style={styles.headerTitle}>タスク指定対象一覧</Text>
        ) : null}
        {content}
        {forTaskManager ? (
          <View style={styles.footerContainer}>
            <Button
              onPress={() => onCancel()}
              style={styles.footerButton}
              mode={"flat"}
              styleText={styles.footerButtonText}
            >
              キャンセル
            </Button>
            <Button onPress={() => onSubmit()} style={styles.footerButton}>
              情報送信
            </Button>
          </View>
        ) : null}
      </View>
    </>
  );
}

export default UserOutput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    // flexDirection: "column-reverse",
    marginTop: 18,
    borderRadius: 6,
  },
  infoText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    marginTop: 32,
  },
  footerContainer: { flexDirection: "row" },
  footerButton: {
    flex: 1,
    alignSelf: "center",
    marginHorizontal: 30,
    marginTop: 20,
  },
  footerButtonText: { color: GlobalStyles.colors.primary600 },
  headerTitle: {
    // flex: 1,
    alignSelf: "center",
    // marginHorizontal: 30,
    marginVertical: 20,
    color: GlobalStyles.colors.primary600,
    fontWeight: "600",
    fontSize: 24,
  },
});
