import { useState } from "react";
import { Alert, StyleSheet, View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import AuthForm from "./AuthForm";
import { GlobalStyles } from "../../constant/styles";
import { getscaledDimension } from "../../util/deviceUiInfo";
import alert from "../../util/alertWeb";

function AuthContent({ isLogin, onAuthenticate }) {
  const navigation = useNavigation();
  // const fontScale = getscaledDimension("font") ;
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(fontScale * 0.8); // pass in fontScale to the StyleSheet
  const [credentialsInvalid, setCredentialsInvalid] = useState({
    username: false,
    password: false,
    confirmUsername: false,
    confirmPassword: false,
  });

  // function switchAuthModeHandler() {
  //   if (isLogin) {
  //     navigation.replace("Signup");
  //   } else {
  //     navigation.replace("Login");
  //   }
  // }

  function submitHandler(credentials) {
    let { username, confirmUsername, password, confirmPassword, userRole } =
      credentials;

    username = username.trim();
    password = password.trim();
    userRole = userRole.trim();
    const usernameIsValid = username.length > 4;
    const passwordIsValid = password.length > 4;
    const usernamesAreEqual = username === confirmUsername;
    const passwordsAreEqual = password === confirmPassword;

    if (
      !usernameIsValid ||
      !passwordIsValid ||
      (!isLogin && (!usernamesAreEqual || !passwordsAreEqual))
    ) {
      alert("Invalid input", "Please check your entered credentials.");
      setCredentialsInvalid({
        username: !usernameIsValid,
        confirmUsername: !usernameIsValid || !usernamesAreEqual,
        password: !passwordIsValid,
        confirmPassword: !passwordIsValid || !passwordsAreEqual,
      });
      return;
    }
    onAuthenticate({ username, password, userRole });
  }

  return (
    <View style={styles.rootContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>ヨネノリンクス WEB</Text>
        </View>
        <View style={styles.subtitleContainer}>
          <Text style={styles.subtitle}>米乃家：会員企業の管理用アプリ</Text>
        </View>
      </View>
      <View style={styles.authContent}>
        <AuthForm
          isLogin={isLogin}
          onSubmit={submitHandler}
          credentialsInvalid={credentialsInvalid}
        />
        {/* <View style={styles.buttons}>
          <FlatButton onPress={switchAuthModeHandler}>
            {isLogin ? "Create a new user" : "Log in instead"}
          </FlatButton>
        </View> */}
      </View>
    </View>
  );
}

export default AuthContent;

const makeStyles = (fontScale) =>
  StyleSheet.create({
    authContent: {
      marginHorizontal: 32,
      padding: 16,
      borderRadius: 8,
      backgroundColor: GlobalStyles.colors.neutral10,
      alignItems: "center",
      flex: 6,
      minWidth: 300,
    },
    rootContainer: {
      flex: 1,
      // justifyContent: "center",
      // alignItems: "center",
      // padding: 32,
      marginTop: "10%",
    },
    headerContainer: {
      flex: 2,
      justifyContent: "center",
      alignItems: "center",
      // padding: 32,
      // marginTop: "20%",
    },
    titleContainer: {
      flex: 2,
      marginBottom: GlobalStyles.fonts.welcome_page_title * fontScale * 1.5,
    },
    subtitleContainer: {
      flex: 1,
      marginBottom: GlobalStyles.fonts.welcome_page_title * fontScale * 1.5,
    },
    title: {
      fontSize: GlobalStyles.fonts.welcome_page_title * fontScale,
      fontWeight: 800,
      color: GlobalStyles.colors.primary700,
    },
    subtitle: {
      fontSize: GlobalStyles.fonts.welcome_page_subtitle * fontScale,
      color: GlobalStyles.colors.secondary600,
    },
  });
