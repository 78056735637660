import { useEffect } from "react";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import DocumentPicker from "react-native-document-picker";
import { GlobalStyles } from "../../constant/styles";
import Input from "../Auth/Input";

import Button from "../UI/Button";
import * as Progress from "react-native-progress";
import firebase from "firebase/app";
import "firebase/storage";
import { storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";

function StoreManualEditForm({ onSubmit, onCancel, defaultValues, userRole }) {
  // let hadProfileImg = defaultValues.userImage ? true : false;
  const [progress, setProgress] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const [progressStatus, setProgressStatus] = useState(
    "しばらくお待ちください、アップロードしています。。。"
  );
  const [enteredManualId, setEnteredManualId] = useState(
    defaultValues ? defaultValues.manualId : ""
  );
  const [enteredManualName, setEnteredManualName] = useState(
    defaultValues ? defaultValues.manualName : ""
  );
  const [enteredManualDetail, setEnteredManualDetail] = useState(
    defaultValues ? defaultValues.manualDetail : ""
  );
  const [enteredManualUri, setEnteredManualUri] = useState(
    defaultValues ? defaultValues.manualUri : ""
  );
  const [enteredManualVideoUri, setEnteredManualVideoUri] = useState(
    defaultValues ? defaultValues.manualVideoUri : ""
  );
  const [enteredManualTag, setEnteredManualTag] = useState(
    defaultValues ? defaultValues.manualTag : ""
  );
  useEffect(() => {}, []);

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "manualName":
        setEnteredManualName(enteredValue);
        break;
      case "manualDetail":
        setEnteredManualDetail(enteredValue);
        break;
      case "manualUri":
        setEnteredManualUri(enteredValue);
        break;
      case "manualVideoUri":
        setEnteredManualVideoUri(enteredValue);
        break;
      case "manualTag":
        setEnteredManualTag(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      manualId: enteredManualId,
      manualName: enteredManualName,
      manualDetail: enteredManualDetail,
      manualUri: enteredManualUri,
      manualVideoUri: enteredManualVideoUri,
      manualTag: enteredManualTag,
    });
  }

  const handleUpload = async () => {
    try {
      setIsUpload(true);
      setProgress(0);
      setProgressStatus("しばらくお待ちください、アップロードしています。。。");
      // Open the document picker
      const result = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf],
      });
      // console.log("result[0]");
      // console.log(result[0]);
      // console.log(storage);
      // Get a reference to the PDF file in Firebase Storage
      // const pdfRef = firebase.storage().ref("pdfs").child(result[0].name);
      // Create a reference to 'mountains.jpg'
      const pdfsRef = ref(storage, `manual_pdf/${result[0].name}`);

      // Create a File object from the selected file
      // const file = await RNFS.readFile(result[0].uri, 'base64');
      // Fetch the file and get the data as a blob
      const response = await fetch(result[0].uri);
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], result[0].name, {
        type: "application/pdf",
      });
      // console.log("file");
      // console.log(file);

      // Get metadata properties
      getMetadata(pdfsRef)
        .then((metadata) => {
          // Metadata now contains the metadata for 'images/forest.jpg'
          // console.log(metadata);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });

      const metadata = {
        contentType: "application/pdf",
      };

      // Create a task to upload the PDF file
      const task = uploadBytesResumable(pdfsRef, file, metadata);
      // console.log("task");
      // console.log(task);
      // Monitor the task's progress
      task.on(
        "state_changed",
        (snapshot) => {
          // Get the task progress, including the number of bytes uploaded and the total number of bytes
          // console.log(snapshot);
          // const progress =  Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
          // console.log(`Upload is  ${progress} % done`);
          // setProgress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(task.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            setEnteredManualUri(downloadURL);
          });
          setProgress(1);
          setProgressStatus("アップロードが完了しました。");
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <View>
        <View>
          <Input
            label="マニュアル名"
            onUpdateValue={updateInputValueHandler.bind(this, "manualName")}
            value={enteredManualName}
            keyboardType="default"
            placeholderValue="マニュアル名"
            styleText
          />
        </View>
        <View>
          <Input
            label="マニュアルの内容"
            onUpdateValue={updateInputValueHandler.bind(this, "manualDetail")}
            value={enteredManualDetail}
            keyboardType="default"
            placeholderValue="マニュアルの内容"
            styleText
          />
        </View>
        <View>
          <Input
            label="マニュアルの動画リング"
            onUpdateValue={updateInputValueHandler.bind(this, "manualVideoUri")}
            value={enteredManualVideoUri}
            // value={enteredManualTag}
            keyboardType="default"
            placeholderValue="マニュアルの動画リング"
            styleText
          />
        </View>
        <View>
          <Input
            label="マニュアルのタッグ"
            onUpdateValue={updateInputValueHandler.bind(this, "manualTag")}
            value={enteredManualTag}
            // value={enteredManualVideoUri}
            keyboardType="number-pad"
            placeholderValue="マニュアルのタッグ"
            styleText
          />
          <Text style={styles.remarkText}>
            ＊マニュアルのタッグは優先度を設定します（優先度は値が小さいほど優先順位が高くなります）
          </Text>
        </View>
        <View>
          <View style={styles.uploadContainer}>
            <Button
              title="マニュアル選択"
              onPress={handleUpload}
              styleButton={{
                backgroundColor: GlobalStyles.colors.primary700,
              }}
            >
              マニュアルを選択してください
            </Button>
          </View>
          {isUpload && (
            <View style={styles.progressBarContainer}>
              <Text style={[styles.progressText, styles.textBase]}>
                {progressStatus}
              </Text>
              <Progress.Bar
                progress={progress}
                width={300}
                color={"rgba(84, 132, 0, 1)"}
              />
            </View>
          )}
        </View>
        <View style={styles.buttons}>
          <Button
            style={styles.button}
            mode="flat"
            onPress={onCancel}
            styleText={[styles.buttonText, styles.textBase]}
          >
            キャンセル
          </Button>
          <Button
            style={styles.button}
            onPress={submitHandler}
            styleButton={{
              backgroundColor: GlobalStyles.colors.primary700,
            }}
            styleText={styles.buttonText}
          >
            {"保存"}
          </Button>
        </View>
      </View>
    </>
  );
}

export default StoreManualEditForm;

const styles = StyleSheet.create({
  form: {},
  container: {
    padding: 16,
    minWidth: "80%",
  },
  dropdown: {
    height: 50,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },

  textBase: { color: GlobalStyles.colors.primary600 },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 36,
  },
  profileImg: {
    width: 120,
    height: 120,
    borderRadius: 1000,
  },
  remarkText: {
    color: GlobalStyles.colors.error600,
    fontSize: 9,
  },
  dropdownText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    marginBottom: 6,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },

  buttonText: {
    fontSize: 16,
  },
  progressText: {
    fontSize: 12,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 100,
  },
  uploadContainer: { marginVertical: "2%" },
  progressBarContainer: { alignItems: "center" },
});
