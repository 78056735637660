import { createContext, useReducer } from "react";

export const ChatRoomsContext = createContext({
  chatRooms: [],
  addChatRoom: (
    id,
    { roomId, roomName, roomImage, roomLastestChat, roomLastestTime, userId }
  ) => {},
  setChatRoom: (chatRooms) => {},
  deleteChatRoom: (roomId) => {},
  updateChatRoom: (
    id,
    { roomId, roomName, roomImage, roomLastestChat, roomLastestTime, userId }
  ) => {},
});

function chatRoomsReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      const inverted = action.payload;
      return inverted;
    case "UPDATE":
      const updatableChatRoomIndex = state.findIndex(
        (chatroom) => chatroom.id === action.payload.id
      );
      const updatableChatRoom = state[updatableChatRoomIndex];
      const updatedItem = {
        ...updatableChatRoom,
        ...action.payload.data,
      };
      const updatedChatRooms = [...state];
      updatedChatRooms[updatableChatRoomIndex] = updatedItem;
      return updatedChatRooms;
    case "DELETE":
      return state.filter((chatroom) => chatroom.roomId !== action.payload);
    default:
      return state;
  }
}

function ChatRoomsContextProvider({ children }) {
  const [chatRoomsState, dispatch] = useReducer(chatRoomsReducer, []);

  function addChatRoom(chatRoomData) {
    dispatch({ type: "ADD", payload: chatRoomData });
  }
  function setChatRoom(chatRooms) {
    dispatch({ type: "SET", payload: chatRooms });
  }
  function deleteChatRoom(roomId) {
    dispatch({ type: "DELETE", payload: roomId });
  }
  function updateChatRoom(roomId, chatData) {
    dispatch({
      type: "UPDATE",
      payload: { roomId: roomId, data: chatData },
    });
  }

  const value = {
    chatRooms: chatRoomsState,
    setChatRoom: setChatRoom,
    addChatRoom: addChatRoom,
    deleteChatRoom: deleteChatRoom,
    updateChatRoom: updateChatRoom,
  };

  return (
    <ChatRoomsContext.Provider value={value}>
      {children}
    </ChatRoomsContext.Provider>
  );
}
export default ChatRoomsContextProvider;
