import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// Firebase config
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWZoiaZCmYRYssHKmOK297shoBRwNQbTo",
  authDomain: "manager-app-be1e3.firebaseapp.com",
  databaseURL:
    "https://manager-app-be1e3-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "manager-app-be1e3",
  storageBucket: "gs://manager-app-be1e3.appspot.com/",
  messagingSenderId: "458546386365",
  appId: "1:458546386365:web:36597bd54210ebb9976ed4",
  measurementId: "G-XFX8XDXE7R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
signInWithEmailAndPassword(auth, "admin@yonenoya.com", "yonenoya_admin").then(
  () => console.log("Login success")
);
export const database = getFirestore(app);
// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

// export const analytics = getAnalytics();
