import { useState } from "react";
import { useRef } from "react";
import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import InputWithPrefix from "./InputWithPrefix";
function InputRejiMinus({
  label,
  invalid,
  textInputConfig,
  styleInputWithPrefixContainer,
  onAddCost,
  styleLabel,
}) {
  // this will be attached with each input onChangeText
  const [textValue, setTextValue] = useState("");
  // our number of inputs, we can add the length or decrease
  const [numInputs, setNumInputs] = useState(0);
  // all our input fields are tracked with this array
  const refInputs = useRef([textValue]);

  const setInputValue = (index, value) => {
    // first, we are storing input value to refInputs array to track them
    const inputs = refInputs.current;
    inputs[index] = value;
    // we are also setting the text value to the input field onChangeText
    setTextValue(value);
    onAddCost(refInputs.current);
  };

  let numsRejiInput = 0;
  let inputStyles = [styles.input];
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  let inputs = [];
  for (let i = 0; i < numInputs; i++) {
    inputs.push(
      <View key={i} style={styles.rejiContainer}>
        <View style={styles.rejiInputContainer}>
          <Text style={styles.label}>{i + 1} 回目:</Text>
        </View>
        <View style={styles.rejiTextInputContainer}>
          <InputWithPrefix
            // styleInputWithPrefixContainer={{
            //   backgroundColor: GlobalStyles.colors.neutral0,
            // }}
            style={{
              backgroundColor: GlobalStyles.colors.neutral0,
            }}
            prefix="￥"
            textInputConfig={{
              onChangeText: (value) => setInputValue(i, value),
              value: refInputs.current[i],
              color: GlobalStyles.colors.primary700,
              placeholder: "10000",
            }}
          />
        </View>
      </View>
    );
  }
  function onNumInputsHandler(num) {
    setNumInputs(num);
    numsRejiInput = num;
    //console.log(numsRejiInput);
  }
  return (
    <>
      <View style={[styles.numInputContainer]}>
        <View style={styles.numInputTitleContainer}>
          <Text style={[styles.label,styleLabel]}>レジマイナス(回数)</Text>
        </View>
        <View style={styles.numTextInputContainer}>
          <TextInput
            style={inputStyles}
            keyboardType="number-pad"
            underlineColorAndroid="transparent"
            autocomplete="off"
            onChangeText={onNumInputsHandler}
            value={numsRejiInput}
          />
        </View>
        <View style={styles.numInputUnitContainer}>
          <Text style={styles.unitText}>回</Text>
        </View>
      </View>
      <View>{inputs}</View>
    </>
  );
}
export default InputRejiMinus;
const styles = StyleSheet.create({
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary600,
    padding: 6,
    paddingLeft: 18,
    borderRadius: 6,
    fontSize: 18,
  },

  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  wasteItemContainer: { flex: 1, flexDirection: "row" },
  //   amountContainer: { flex: 3, justifyContent: "center", marginHorizontal: 4 },
  // unitContainer: {
  //   flex: 1,
  //   alignSelf: "center",
  //   marginHorizontal: 4,
  // },

  numInputContainer: {
    flexDirection: "row",
    borderRadius: 6,
    // alignContent: "center",
    // justifyContent: "flex-start",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral10,
  },
  numInputTitleContainer: {
    flex: 5,
  },
  numTextInputContainer: { flex: 4 },
  numInputUnitContainer: { flex: 1, marginHorizontal: 8 },

  label: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    // marginBottom: 4,
    // alignItems: "center",
    // alignContent: "center",
    // justifyContent: "center",
  },

  //   textInputContainer: { flex: 1 },

  unitText: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 4,
  },
  numInputTitleContainer: {
    flex: 5,
  },
  numTextInputContainer: { flex: 4 },

  rejiContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
  },
  rejiInputContainer: { flex: 2 },
  rejiTextInputContainer: { flex: 8 },
});
