import {
  Text,
  TextInput,
  View,
  StyleSheet,
  DatePickerIOSBase,
  SafeAreaView,
  Modal,
  Pressable,
} from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { InputOutline } from "react-native-input-outline";
import IconButton from "../UI/IconButton";
// import DateTimePickerModal from "react-native-modal-datetime-picker";
// import DatePicker from "react-native-date-picker";

import { getFormattedDate, getFormattedDateJp } from "../../util/date";
import { useState } from "react";
import DateTimePicker from "@react-native-community/datetimepicker";
// import Input from "./Input";
import Button from "../UI/Button";

function DateInputTask({
  label,
  invalid,
  style,
  textInputConfig,
  inputValueDate,
  getDataFromTextInput,
}) {
  let inputStyles = [styles.input];
  // if (textInputConfig && textInputConfig.multiline) {
  //   inputStyles.push(styles.inputMultiline);
  // }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  // console.log(inputValueDate);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [dateValue, setDateValue] = useState();
  const [date, setDate] = useState(
    inputValueDate ? new Date(inputValueDate) : new Date()
  );

  const [isDateChange, setIsDateChange] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // console.log(inputValueDate);
  // const showDatePicker = () => {
  //   setOpen(true);
  // };

  // const hideDatePicker = () => {
  //   setOpen(false);
  // };

  // const handleConfirm = (date) => {
  //   console.warn("A date has been picked: ", date);
  //   setDateValue(getFormattedDate(date));
  //   hideDatePicker();
  // };
  let calenderDate = "";
  const setDateInput = (event, date) => {
    setOpen(false);
    const currentDate = date;
    // console.log(currentDate.getFullYear());
    // console.log(currentDate.getMonth() + 1);
    // console.log(currentDate.getDate());
    const saveDate = getFormattedDate(currentDate);
    // console.log(saveDate);

    setDateValue(saveDate);

    //send out date data
    getDataFromTextInput(saveDate);
    setIsDateChange(true);
    // setOpen(false);
  };
  const outputDate = (time) => {
    // console.log("SUBTOP!!!" + time);
    //send out date data
    getDataFromTextInput(time);
    setOpen(false);
  };
  return (
    <>
      <View style={[styles.inputContainer, style]}>
        <Text style={[styles.label, invalid && styles.invalidLabel]}>
          {label}
        </Text>
        <View style={styles.inputBox}>
          <TextInput
            onFocus={() => setOpen(true)}
            style={styles.input}
            value={
              isDateChange
                ? dateValue
                : inputValueDate !== "0000-00-00 00:00:00" &&
                  typeof inputValueDate !== "undefined"
                ? getFormattedDate(inputValueDate)
                : ""
            }
            showSoftInputOnFocus={false}
            onChangeText={outputDate}
            {...textInputConfig}
          />
          <IconButton
            icon={"calendar"}
            size={24}
            color={GlobalStyles.colors.primary600}
            onPress={() => {
              setOpen(!open);
            }}
          />
        </View>
        {/* <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>Hello World!</Text>
  
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.textStyle}>Hide Modal</Text>
              </Pressable>
            </View>
          </View> */}
        {open && (
          <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode="date"
            onChange={setDateInput}
            display="default"
            style={styles.calendar}
            accentColor={GlobalStyles.colors.primary600}
          />
        )}
        {open && (
          <Button
            onPress={() => {
              setOpen(!setOpen);
              // setDateInput();
            }}
            styleButton={styles.button}
            styleText={styles.buttonText}
          >
            {"日付を指定"}
          </Button>
        )}
      </View>
    </>
  );
}
export default DateInputTask;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  inputBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  label: {
    fontSize: 14,
    fontWeight: "600",
    color: GlobalStyles.colors.primary600,
    marginBottom: 6,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary600,
    padding: 12,
    borderRadius: 10,
    fontSize: 14,
    flex: 1,
    borderColor: GlobalStyles.colors.primary600,
    borderWidth: 0.4,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  container: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    padding: 20,
  },
  datePickerStyle: {
    width: 200,
    marginTop: 20,
  },
  calendar: {
    backgroundColor: GlobalStyles.colors.neutral10,
    colors: GlobalStyles.colors.primary600,
    borderRadius: 20,
    // padding: 10,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 4,
    // elevation: 5,
    alignSelf: "flex-start",
    // alignContent: "center",
    // alignItems: "center",
    // position: "absolute",
  },
  button: {
    // flex: 2,
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: GlobalStyles.colors.primary600,
    marginTop: 10,
    alignSelf: "flex-start",
    // alignContent: "flex-end",
  },
  buttonText: {
    fontSize: 14,
    paddingHorizontal: 5,
  },
});
