import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import React, { useCallback, useState, useLayoutEffect } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Text, StyleSheet } from "react-native";
import { database } from "../Firebase";
import {
  collection,
  addDoc,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { Avatar, Bubble, GiftedChat, Send } from "react-native-gifted-chat";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { AuthContext } from "../store/auth-context";
import { UsersContext } from "../store/user-context";
import {
  addUnreadChat,
  clearUnreadChat,
  fetchUserInChatRooms,
  fetchUsers,
  updateLastestChat,
} from "../util/http";
// import { UserAuthsContext } from "../store/userauth-context";
import { Ionicons } from "@expo/vector-icons";
import userImg from "../assets/user.png";
import OneSignal from "react-native-onesignal";
function ChatScreen({ navigation, route }) {
  const [messages, setMessages] = useState([]);
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();

  const [error, setError] = useState();
  const curRoomId = route.params.chatRoomId
    ? route.params.chatRoomId
    : route.params.params.chatRoomId;
  const curRoomName = route.params.chatRoomName
    ? route.params.chatRoomName
    : route.params.params.chatRoomName;
  const userCtx = useContext(UsersContext);
  const isFocused = useIsFocused();

  // const onSend = useCallback((messages = []) => {
  //   setMessages((previousMessages) =>
  //     GiftedChat.append(previousMessages, messages)
  //   );
  // }, []);

  useEffect(() => {
    async function clearUnreadNum() {
      const response = await clearUnreadChat(curRoomId);
    }
    const collectionRef = collection(database, "chats" + curRoomId);
    const q = query(collectionRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setMessages(
        querySnapshot.docs.map((doc) => ({
          _id: doc.data()._id,
          createdAt: doc.data().createdAt.toDate(),
          text: doc.data().text,
          user: doc.data().user,
        }))
      );
    });
    clearUnreadNum();

    return () => unsubscribe();
  }, []);
  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
    const { _id, createdAt, text, user } = messages[0];
    addDoc(collection(database, "chats" + curRoomId), {
      _id,
      createdAt,
      text,
      user,
    });
  }, []);

  async function addUnread(curRoomId) {
    const response = await addUnreadChat(curRoomId);
  }

  async function updateLastestMsg(curRoomId, inputMessage) {
    const response = await updateLastestChat(curRoomId, inputMessage);
  }

  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUserInChatRooms(curRoomId);
        userCtx.setUser(userList);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      const storedUid = await AsyncStorage.getItem("uid");
      setUsingId(storedUid);
    }
    getUsersList();
    fetchUserData();
  }, [navigation]);

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }
  if (isFetching) {
    return <LoadingOverlay />;
  }
  const selectedUser = userCtx.users.find((users) => users.userId === usingId);

  async function sendPush(messages) {
    // const response = await addUnreadChat(curRoomId);
    // const senderData = userCtx.users.find((users) => users.userId === usingId);
    const sendPushUserIdList = [];
    const sendPushUserList = userCtx.users
      .filter((users) => users.userId !== usingId)
      .forEach((userData) => sendPushUserIdList.push(userData.notificationId));
    const notificationObj = {
      headings: { en: `${selectedUser.userName} (${curRoomName})` },
      contents: { en: `${messages[0].text}` },
      include_player_ids: sendPushUserIdList,
      data: {
        type: "chat",
        page: "ChatScreen",
        screen: "",
        roomName: `${curRoomName}`,
        roomId: `${curRoomId}`,
      },
      url: `yonenolinks:///chat/${encodeURIComponent(
        curRoomName
      )}/${curRoomId}`,
    };
    console.log("caht ID" + curRoomId);
    // console.log(messages[0].text);
    const jsonString = JSON.stringify(notificationObj);

    OneSignal.postNotification(
      jsonString,
      (success) => {
        console.log("Success:", success);
      },
      (error) => {
        console.log("Error:", error);
      }
    );
  }
  return (
    <GiftedChat
      messages={messages}
      showUserAvatar={true}
      // showAvatarForEveryMessage={false}
      textInputStyle={styles.textInputStyle}
      renderUsernameOnMessage={true}
      onSend={(messages) => {
        onSend(messages);
        addUnread(curRoomId);
        sendPush(messages);
        updateLastestMsg(curRoomId, messages);
      }}
      user={{
        _id: selectedUser.userId,
        name: selectedUser.userName,
        avatar: selectedUser.userImage ? selectedUser.userImage : userImg,
      }}
      placeholder="メッセージを記入する"
      // renderCustomView={({ user }) => (
      //   <View style={{ minHeight: 20, alignItems: "center" }}>
      //     <Text>
      //       Current user:
      //       {user.name}
      //     </Text>
      //     <Text>From CustomView</Text>
      //   </View>
      // )}
      // renderAvatar={(props) => (
      //   <Avatar
      //     {...props}
      //     containerStyle={{
      //       left: { borderWidth: 3, borderColor: "red" },
      //       right: {},
      //     }}
      //     imageStyle={{
      //       left: { borderWidth: 3, borderColor: "blue" },
      //       right: {},
      //     }}
      //   />
      // )}
      renderBubble={(props) => {
        return (
          <Bubble
            {...props}
            textStyle={{
              right: {
                color: "white",
              },
              left: {
                color: "white",
              },
            }}
            wrapperStyle={{
              left: {
                backgroundColor: GlobalStyles.colors.neutral500,
              },
              right: {
                backgroundColor: GlobalStyles.colors.primary600,
              },
            }}
          />
        );
      }}
      // renderUsername={()=> {
      //   const { currentMessage, user } = this.props
      //   if (this.props.renderUsernameOnMessage && currentMessage) {
      //     if (user && currentMessage.user._id === user._id) {
      //       return null
      //     }
      //     return (
      //       <View style={styles.content.usernameView}>
      //         <Text
      //           style={
      //             [styles.content.username, this.props.usernameStyle] as TextStyle
      //           }
      //         >
      //           ~ {currentMessage.user.name}
      //         </Text>
      //       </View>
      //     )
      //   }
      //   return null
      // }}

      renderSend={(props) => {
        return (
          <Send
            {...props}
            containerStyle={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.sendText}>送信</Text>
          </Send>
        );
      }}
    />
    // <View></View>
  );
}
export default ChatScreen;
const styles = StyleSheet.create({
  sendText: {
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  textInputStyle: { marginVertical: 20 },
});
