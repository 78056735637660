import AsyncStorage from "@react-native-async-storage/async-storage";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
  Alert,
} from "react-native";
import OneSignal from "react-native-onesignal";
import ErrorOverlay from "../component/UI/ErrorOverlay";

import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";

import { UsersContext } from "../store/user-context";
import {
  fetchUserInRooms,
  addTroubleReport,
  deleteTroubleReport,
  fetchUsers,
} from "../util/http";
import TroubleReportForm from "../component/Troubleshooting/TroubleReportForm";
import { TroubleReportContext } from "../store/troublereport-context";
import { FlatList } from "react-native";
import TroubleReportItem from "../component/Troubleshooting/TroubleReportItem";
function TroubleShootingReportScreen({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);

  const troubleReportsCtx = useContext(TroubleReportContext);
  const userCtx = useContext(UsersContext);

  const curProblemId = route.params?.problemId;
  const problemTitle = route.params.problemTitle;
  const actionRecord = route.params?.actionRecord;

  const editedTroubleReportId = route.params?.troubleReportId;
  const isEditing = !!editedTroubleReportId;

  let selectedTroubleReport;
  if (isEditing) {
    selectedTroubleReport = troubleReportsCtx.troubleReports.find(
      (troublereport) => troublereport.troublereportId === editedTroubleReportId
    );
  }
  let recordActionList = [];
  const renderItem = (item, keyId) => {
    console.log(item);
    return (
      <View key={keyId + "1"}>
        <TroubleReportItem item={item} keyId={keyId} />
      </View>
    );
  };
  //   console.log(actionRecord[0]);
  if (actionRecord.length > 0) {
    for (let i = 0; i < actionRecord.length; i++) {
      //   console.log(actionRecord[i]);
      recordActionList.push(renderItem(actionRecord[i], i + 1));
    }
    // recordActionList = (
    //   <FlatList
    //     data={actionRecord}
    //     renderItem={renderItem}
    //     keyExtractor={(item) => item.troubleshooting_step_step_stage}
    //   />
    // );
  }
  useLayoutEffect(() => {
    navigation.setOptions({
      title: isEditing ? "Edit TroubleReport" : "Add TroubleReport",
    });
  }, [navigation, isEditing]);

  async function deleteTroubleReportHandler() {
    setIsSubmitting(true);
    try {
      await deleteTroubleReport(selectedTroubleReport.troubleReportId);
      troubleReportsCtx.deleteTroubleReport(editedTroubleReportId);
      //   navigation.goBack();
      navigation.navigate("TroubleShootingMainScreen");
    } catch (error) {
      setError(
        "削除リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        userCtx.setUser(userList);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      const storedUid = await AsyncStorage.getItem("uid");
      setUsingId(storedUid);
    }
    getUsersList();
    fetchUserData();
  }, [navigation]);

  function cancelHandler() {
    // navigation.goBack();
    navigation.pop(2);
  }

  const sendPushUserIdList = [];
  const sendPushUserList = userCtx.users
    .filter((users) => users.userId !== usingId)
    .forEach((userData) => sendPushUserIdList.push(userData.notificationId));
  // console.log(sendPushUserIdList);

  async function confirmHandler(troubleReportData) {
    // console.log(troubleReportData);
    const clearData = async () => {
      try {
        await AsyncStorage.removeItem("storedTroubleFormValues");
        console.log("Data cleared successfully");
      } catch (error) {
        console.log("Error clearing data", error);
      }
    };

    setIsSubmitting(true);
    try {
      if (isEditing) {
        // console.log(editedTroubleReportId);
        // console.log(troubleReportData);
        // const updateResponseData = await updateTroubleReport(
        //   editedTroubleReportId,
        //   troubleReportData,
        //   roomId
        // );
        // console.log(updateResponseData);
        // troubleReportsCtx.updateTroubleReport(editedTroubleReportId, troubleReportData);
        clearData();
        navigation.goBack();
      } else {
        const responseData = await addTroubleReport(
          curProblemId,
          troubleReportData,
          actionRecord
        );
        console.log("troubleReportData Send");
        // console.log(troubleReportData);
        // console.log(responseData);
        clearData();
        if (typeof responseData === "string") {
          Alert.alert(
            "エラーが発生しました",
            "画像アップロードができません - 後でもう一度試してください",
            [
              {
                onPress: () => {
                  navigation.goBack();
                },
              },
            ]
          );

          // navigation.navigate("ManageTroubleReport");
        } else {
          const creatorData = userCtx.users.find(
            (users) => users.userId === usingId
          );
          troubleReportsCtx.addTroubleReport({
            ...troubleReportData,
            id: responseData[0].id,
            userName: creatorData.userName,
            userImage: creatorData.userImage,
          });

          //   function sendPushTroubleReport(reportData) {
          //     // const response = await addUnreadChat(curRoomId);
          //     const senderData = userCtx.users.find(
          //       (users) => users.userId === usingId
          //     );
          //     // const sendPushUserIdList = [];
          //     // const sendPushUserList = userCtx.users
          //     //   .filter((users) => users.userId !== usingId)
          //     //   .forEach((userData) =>
          //     //     sendPushUserIdList.push(userData.notificationId)
          //     //   );
          //     const sendPushUserIdList = [];
          //     const sendPushUserList = userCtx.users
          //       .filter((users) => users.userId !== usingId)
          //       .forEach((userData) =>
          //         sendPushUserIdList.push(userData.notificationId)
          //       );
          //     // console.log("!!!!!!!!!!!!!!!!!");

          //     // console.log(reportData.troublereportDate.getFullYear());
          //     const notificationObj = {
          //       headings: { en: `日報報告 : ${roomName}` },
          //       contents: {
          //         en: `${
          //           reportData.troublereportDate.getMonth() + 1
          //         }月 ${reportData.troublereportDate.getDate()}日 : ${
          //           senderData.userName
          //         } から日報レポートを提出しました`,
          //       },
          //       include_player_ids: sendPushUserIdList,
          //       data: {
          //         type: "troublereport",
          //         page: "TroubleReportStack",
          //         screen: "TroubleReportDetailScreen",
          //         roomName: `${roomName}`,
          //         roomId: `${roomId}`,
          //       },
          //       url: `yonenolinks:///troublereport/${encodeURIComponent(
          //         roomName
          //       )}/${roomId}`,
          //     };
          //     // console.log(notificationObj);

          //     const jsonString = JSON.stringify(notificationObj);

          //     OneSignal.postNotification(
          //       jsonString,
          //       (success) => {
          //         console.log("Success:", success);
          //       },
          //       (error) => {
          //         console.log("Error:", error);
          //       }
          //     );
          //   }
          //   sendPushTroubleReport(troubleReportData);
          navigation.pop(2);
        }
        // if (responseData != "UPLOAD_FAILED") {

        // }
        clearData();
      }
    } catch (error) {
      console.log(error);
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  if (error && !isSubmitting) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsSubmitting(true);
          setError();
        }}
      />
    );
  }
  if (isSubmitting) {
    return <LoadingOverlay />;
  }
  // console.log(selectedTroubleReport);
  // const keyboardVerticalOffset = Platform.OS === "ios" ? 40 : 0;
  return (
    <SafeAreaView>
      <ScrollView automaticallyAdjustKeyboardInsets={true}>
        <View style={styles.container}>
          {/* <Text>{problemTitle}</Text> */}
          {/* {recordActionList} */}
          <TroubleReportForm
            submitButtonLabel={isEditing ? "更新" : "送信"}
            headerFormLabel={
              isEditing ? "トラブルレポート編集" : "トラブルレポート提出"
            }
            recordActionList={recordActionList}
            onSubmit={confirmHandler}
            onCancel={cancelHandler}
            defaultValues={selectedTroubleReport}
          />

          {isEditing && (
            <View style={styles.deleteContainer}>
              <IconButton
                icon="trash"
                color={GlobalStyles.colors.neutral0}
                size={36}
                onPress={deleteTroubleReportHandler}
              />
              {/* <Text>削除</Text> */}
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default TroubleShootingReportScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: GlobalStyles.colors.neutral10,
  },

  deleteContainer: {
    paddingTop: 8,
    borderTopWidth: 2,
    borderTopColor: GlobalStyles.colors.neutral0,
    alignItems: "center",
  },
});
