import { createContext, useReducer } from "react";

export const DailyReportRoomsContext = createContext({
  dailyReportRooms: [],
  addDailyReportRoom: (id, { roomId, roomName, roomImage, userId, priority, latestUpdateTimestamp}) => {},
  setDailyReportRoom: (dailyReportRooms) => {},
  deleteDailyReportRoom: (roomId) => {},
  updateDailyReportRoom: (id, { roomId, roomName, roomImage, userId, priority, latestUpdateTimestamp }) => {},
});

function dailyReportRoomsReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      const inverted = action.payload;
      return inverted;
    case "UPDATE":
      const updatableDailyReportRoomIndex = state.findIndex(
        (dailyreportroom) => dailyreportroom.id === action.payload.id
      );
      const updatableDailyReportRoom = state[updatableDailyReportRoomIndex];
      const updatedItem = {
        ...updatableDailyReportRoom,
        ...action.payload.data,
      };
      const updatedDailyReportRooms = [...state];
      updatedDailyReportRooms[updatableDailyReportRoomIndex] = updatedItem;
      return updatedDailyReportRooms;
    case "DELETE":
      return state.filter(
        (dailyreportroom) => dailyreportroom.roomId !== action.payload
      );
    default:
      return state;
  }
}

function DailyReportRoomsContextProvider({ children }) {
  const [dailyReportRoomsState, dispatch] = useReducer(
    dailyReportRoomsReducer,
    []
  );

  function addDailyReportRoom(dailyReportRoomData) {
    dispatch({ type: "ADD", payload: dailyReportRoomData });
  }
  function setDailyReportRoom(dailyReportRooms) {
    dispatch({ type: "SET", payload: dailyReportRooms });
  }
  function deleteDailyReportRoom(roomId) {
    dispatch({ type: "DELETE", payload: roomId });
  }
  function updateDailyReportRoom(roomId, dailyReportData) {
    dispatch({
      type: "UPDATE",
      payload: { roomId: roomId, data: dailyReportData },
    });
  }

  const value = {
    dailyReportRooms: dailyReportRoomsState,
    setDailyReportRoom: setDailyReportRoom,
    addDailyReportRoom: addDailyReportRoom,
    deleteDailyReportRoom: deleteDailyReportRoom,
    updateDailyReportRoom: updateDailyReportRoom,
  };

  return (
    <DailyReportRoomsContext.Provider value={value}>
      {children}
    </DailyReportRoomsContext.Provider>
  );
}
export default DailyReportRoomsContextProvider;
