import { Pressable, StyleSheet, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { GlobalStyles } from "../../constant/styles";

function ButtonWithIcon({
  onPress,
  icon,
  children,
  styleButton,
  styleText,
  size,
}) {
  return (
    <Pressable
      style={({ pressed }) => [
        styles.button,
        pressed && styles.pressed,
        styleButton,
      ]}
      onPress={onPress}
    >
      <Ionicons
        style={styles.icon}
        name={icon}
        size={size}
        color={GlobalStyles.colors.primary0}
      />
      <Text style={[styles.text, styleText]}>{children}</Text>
    </Pressable>
  );
}

export default ButtonWithIcon;

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    margin: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: GlobalStyles.colors.primary600,
    borderRadius: 100,
  },
  pressed: {
    opacity: 0.7,
  },
  icon: {
    marginRight: 10,
  },
  text: {
    color: GlobalStyles.colors.primary0,
  },
});
