import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import {
  KeyboardAvoidingView,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from "react-native";
// import ChatsOutput from "../component/ChatOutput/ChatsOutput";
import ChatRoomsOutput from "../component/ChatRoom/RoomsOutput";
import Button from "../component/UI/Button";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { ChatsContext } from "../store/chat-context";
import { ChatRoomsContext } from "../store/chat-room-context";
import { fetchChatRooms, fetchChats } from "../util/http";

function GroupChatScreen({ navigation, route }) {
  const [isFetching, setIsFectching] = useState(true);
  const [error, setError] = useState();
  const chatRoomsCtx = useContext(ChatRoomsContext);
  const isFocused = useIsFocused();
  const [count, setCount] = useState(0);
  // const [isAdmin, setIsAdmin] = useState(true);
  const [userRole, setUserRole] = useState();
  // const [isStore, setIsStore] = useState(false);
  // const storedRole = route.params?.userRole;
  useEffect(() => {
    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      // setUserRole(storedRole);
      let isAdmin;
      let isStore;
      switch (storedRole) {
        case "admin":
          // setIsAdmin(true);
          isAdmin = true;
          break;
        // case "manager":
        // setIsAdmin(true);
        // isAdmin = true;
        // break;
        default:
          // setIsAdmin(false);
          isAdmin = false;
      }
      switch (storedRole) {
        case "store":
          // setIsStore(true);
          isStore = true;
          break;
        default:
          // setIsStore(false);
          isStore = false;
      }
      navigation.setOptions({
        headerLeft: () => (
          <IconButton
            icon="chevron-back-outline"
            size={24}
            color={GlobalStyles.colors.primary600}
            onPress={() => navigation.goBack()}
            title="Update count"
          />
        ),
        headerRight: ({ tintColor }) => (
          <View style={styles.rightTopContainer}>
            {isAdmin && (
              <IconButton
                icon="cog"
                size={28}
                color={GlobalStyles.colors.primary600}
                styleButtonContainer={styles.buttonContainer}
                onPress={() => {
                  navigation.navigate("ChatFormStack", {
                    screen: "ManageChatRoom",
                    // roomId:
                  });
                }}
              />
            )}
            {!isStore && (
              <IconButton
                icon="list"
                size={36}
                color={GlobalStyles.colors.primary600}
                styleButtonContainer={styles.buttonContainer}
                onPress={() => {
                  navigation.navigate("ChatFormStack", {
                    screen: "ManageChatRoomOneOnOne",
                    params: {
                      storedRole: storedRole,
                    },

                    // roomId:
                  });
                }}
                title="Update count"
              />
            )}
          </View>
        ),
      });
    }
    fetchRole();
    async function getChats() {
      setIsFectching(true);
      try {
        const chatRooms = await fetchChatRooms();
        // console.log(chatRooms);
        chatRoomsCtx.setChatRoom(chatRooms);
        // console.log(chatRoomsCtx.chatRooms);
      } catch (error) {
        setError(
          "サーバーの問題でグループチャットリストを表示できません　- 後でもう一度試してください"
        );
      }
      setIsFectching(false);
    }
    getChats();
  }, [navigation]);

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }
  return (
    <ChatRoomsOutput
      chatRooms={chatRoomsCtx.chatRooms}
      fallbackText="グループにはまだ指定していません "
    />
  );
}

export default GroupChatScreen;
const styles = StyleSheet.create({
  rightTopContainer: {
    flexDirection: "row",
    alignSelf: "flex-end",
    // justifyContent: "center",
    // alignContent: "center",
  },
  buttonContainer: {
    padding: 1,
    marginHorizontal: 6,
  },
});
