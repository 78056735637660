import { StyleSheet, View } from "react-native";
import DailyReportComment from "../component/DailyReportOutput/DailyReportComment";
import TaskComment from "../component/TaskManager/TaskComment";

function DailyReportCommentScreen({ navigation, route }) {
  const dailyReport = route.params.params;
  //   console.log(route.params);
  return (
    <View style={styles.taskCommentContainer}>
      <DailyReportComment
        dailyReportId={dailyReport.dailyReportId}
        assignedUser={dailyReport.assignedUser}
        curRoomName={dailyReport.curRoomName}
        dailyreportDate={dailyReport.dailyreportDate}
        dailyReportRoute={dailyReport.dailyReportRoute}
      />
    </View>
  );
}
export default DailyReportCommentScreen;

const styles = StyleSheet.create({
  taskCommentContainer: { flex: 1 },
});
