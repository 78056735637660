import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
// import { useEffect } from "react";
import { useContext, useLayoutEffect, useState } from "react";
import { View, StyleSheet, Text, Modal } from "react-native";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import UserModal from "../component/User/UserModal";
import UserOutput from "../component/User/UserOutput";
import { GlobalStyles } from "../constant/styles";
import { ChatRoomsContext } from "../store/chat-room-context";
import { UsersContext } from "../store/user-context";
import {
  fetchChatRooms,
  fetchUserChatRooms,
  fetchUserInChatRooms,
  fetchUsers,
  storeChatRooms,
} from "../util/http";

function ManageChatRoomOneOnOne({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [isFetching, setIsFectching] = useState(true);
  const userCtx = useContext(UsersContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState();
  const [chatRoomsData, setChatRoomsData] = useState([]);
  // const [selectedUser, setSelectedUser] = useState();
  // let testChat;
  console.log(route);
  const storedRole = route.params.storedRole;
  //   const curRoomId = route.params?.params.roomId;
  const isFocused = useIsFocused();
  // useEffect(() => {}, [navigation]);
  useLayoutEffect(() => {
    async function getChatRoomData() {
      try {
        const chatUserRoomdata = await fetchChatRooms();
        setChatRoomsData(chatUserRoomdata);
      } catch (error) {
        setError(
          "サーバーの問題で日報レポートを表示できません　- 後でもう一度試してください"
        );
      }
    }
    getChatRoomData();
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        let nonStoreUser = userList.filter(
          (user) =>
            user.userRole !== "store" && user.userRole !== "beauty_store"
        );
        if (storedRole == "admin") {
          nonStoreUser = userList.filter((user) => user.userRole !== "store");
        } else if (storedRole == "beauty_store") {
          nonStoreUser = userList.filter(
            (user) => user.userRole !== "store" && user.userRole !== "officer"
          );
        }
        const storedUID = await AsyncStorage.getItem("uid");

        userCtx.setUser(
          nonStoreUser.filter((user) => user.userId !== storedUID)
        );
      } catch (error) {
        setError("Could not fetch user lists!");
      }
      setIsFectching(false);
    }
    getUsersList();
    // getChatRoomData();
  }, [navigation]);

  function onPressCloseModalHandler() {
    setModalVisible(!modalVisible);
  }
  const onSelectedHandler = (userData) => {
    setModalVisible(!modalVisible);
    // setSelectedUser(userData);
    // console.log(selectedUser);
    const selectedUser = userCtx.users.find(
      (user) => user.userId === userData.selectedUserId
    );
    // console.log(selectedUser);
    setSelectedUserData(selectedUser);
  };

  async function chatRoomOpenHandler() {
    const storedUID = await AsyncStorage.getItem("uid");
    const ownerData = userCtx.users.find((user) => user.userId === storedUID);
    // console.log(ownerData);

    const roomDataForAdd = {
      roomName: `${ownerData.userName} と ${selectedUserData.userName}　`,
      roomImage: ownerData.userImage,
      userId: [selectedUserData.userId, storedUID],
      addUserId: [selectedUserData.userId, storedUID],
    };
    // console.log(roomDataForAdd);

    const filteredRoom = chatRoomsData
      .filter((roomData) => roomData.userId.length <= 2)
      .filter(
        (roomMoreData) =>
          JSON.stringify(roomMoreData.userId) ===
          JSON.stringify(roomDataForAdd.userId)
      );
    const isExisted = filteredRoom.length > 0 ? true : false;
    // console.log(filteredRoom);
    try {
      if (isExisted) {
        // console.log(filteredRoom);
        //// navigate to  that room /////
        // setModalVisible(!modalVisible);
        navigation.goBack();
        navigation.navigate("ChatScreen", {
          //   screen: "ChatDetailScreen",
          params: {
            chatRoomName: filteredRoom[0].roomName,
            chatRoomId: filteredRoom[0].roomId,
          },
        });
      } else {
        const responseRooms = await storeChatRooms(roomDataForAdd);
        // chatRoomsContext.addChatRoom({
        //   ...roomData,
        //   id: responseRooms[0].id + 1,
        // });
        // setIsSubmitting(false);
        navigation.goBack();
        navigation.navigate("ChatScreen", {
          params: {
            chatRoomName: roomDataForAdd.roomName,
            chatRoomId: responseRooms[0].id,
          },
        });
      }
    } catch (error) {
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
    }
  }
  // if (error && !isFetching) {
  //   return (
  //     <ErrorOverlay
  //       message={error}
  //       onPress={() => {
  //         setIsFectching(true);
  //         setError();
  //       }}
  //     />
  //   );
  // }

  // if (isFetching) {
  //   return <LoadingOverlay />;
  // }
  // console.log(userCtx.users.filter((user) => user.userId !== ownerData.userId));
  return (
    <>
      <View style={styles.form}>
        <View style={styles.containerHeader}>
          <Text style={styles.title}>{"連絡先一覧"}</Text>
        </View>
        <View style={styles.container}>
          <UserOutput
            isMemberlist={true}
            users={userCtx.users}
            fallbackText="登録されたユーザーがありません"
            onCheck={onSelectedHandler}
            defaultUser={""}
            styleContainer={styles.userOutputContainer}
          />
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* <View style={styles.modalTitleContainer}>
                <Text style={styles.titleText}>ユーザー情報編集</Text>
              </View> */}
              <View style={styles.userContent}>
                <UserModal
                  userName={selectedUserData?.userName}
                  userImage={selectedUserData?.userImage}
                  userId={selectedUserData?.userId}
                  onPressCloseModal={onPressCloseModalHandler}
                  openChatRoom={chatRoomOpenHandler}
                />
                {/* <ProfileEditForm
                onSubmit={chatRoomOpenHandler}
                onCancel={chatRoomCancelHandler}
                defaultValues={userData}
                userRole={userRole}
              /> */}
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </>
  );
}

export default ManageChatRoomOneOnOne;

const styles = StyleSheet.create({
  form: { flex: 1 },
  containerHeader: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral50,
    alignItems: "center",
    paddingTop: 24,
  },
  container: {
    flex: 10,
    paddingHorizontal: 10,
    backgroundColor: GlobalStyles.colors.neutral50,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
    marginVertical: 12,
    textAlign: "center",
  },
  userOutputContainer: {
    marginTop: 2,
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    marginTop: "5%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  userContent: {
    minWidth: "80%",

    // backgroundColor: GlobalStyles.colors.neutral50,
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
});
