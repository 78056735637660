import { useEffect } from "react";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import DocumentPicker from "react-native-document-picker";
import { GlobalStyles } from "../../constant/styles";
import Input from "../Auth/Input";
import { Dropdown } from "react-native-element-dropdown";

import Button from "../UI/Button";
import * as Progress from "react-native-progress";
import firebase from "firebase/app";
import "firebase/storage";
import { storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";
import { ScrollView } from "react-native";
import IconButton from "../UI/IconButton";
import { stringify } from "json5";
import { fetchProblemListWithTroubleshooting } from "../../util/http";

let phaseData = [{ label: "完了", value: -1 }];

function TroubleshootingEditForm({
  onSubmit,
  onCancel,
  defaultValues,
  defaultWithDetails,
  userRole,
  isModalOpen = false,
}) {
  const [enteredProblemId, setEnteredProblemId] = useState(
    defaultValues ? defaultValues.problemId : ""
  );
  const [enteredProblemTitle, setEnteredProblemTitle] = useState(
    defaultValues ? defaultValues.problemTitle : ""
  );

  const [enteredProblemType, setEnteredProblemType] = useState(
    defaultValues ? defaultValues.problemType : ""
  );

  //   const [parentCount, setParentCount] = useState(0);
  const [childCount, setChildCount] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [parentRefData, setParentRefData] = useState([]);
  //   const [enteredPhaseDetail, setEnteredPhaseDetail] = useState({
  //     phaseId: defaultValues ? defaultValues.phaseId : "",
  //     phaseDetail: defaultValues ? defaultValues.phaseDetail : "",
  //   });

  //   console.log(maxStage);

  const [enteredPhaseDetail, setEnteredPhaseDetail] = useState(
    defaultWithDetails ? "" : ""
  );
  const [enteredAction, setEnteredAction] = useState(
    defaultWithDetails ? "" : ""
  );
  const [fetchData, setFetchData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  // const stages = 1;
  let stages = 1;
  // stages = defaultWithDetails?.map((obj) => obj.troubleshootingStepStepStage);
  //   console.log(stages);
  //   const maxStage = Math.max(...stages);.
  let maxStage = 0;
  let children = [];
  if (stages && stages.length >= 1) {
    maxStage = Math?.max(...stages);
  }
  const fetchEditingProblem = async (index) => {
    setIsFetching(true);

    try {
      const response = await fetchProblemListWithTroubleshooting(index);
      // console.log(problemDataWithTroubleshooting);
      setFetchData(response);
      stages = response?.map((obj) => obj.troubleshootingStepStepStage);
      if (stages && stages.length >= 1) {
        maxStage = Math?.max(...stages);
        // const newChildCount = getDefaultChildCount(stages);
        // setChildCount(newChildCount);
        let defaultChildInput = [];
        let parentDataInput = [];

        // console.log(response);
        response?.forEach((data) => {
          // console.log(data);
          phaseData.push({
            label: data.troubleshootingStepStepStage,
            value: data.troubleshootingStepStepStage,
          });
          let index = data.troubleshootingStepStepStage - 1;

          defaultChildInput = [
            ...defaultChildInput,
            {
              parentIndex: index,
              childIndex: data.choiceLocalId,
              action: data.choiceAction,
              nextStep: data.choiceNextStep,
            },
          ];
          parentDataInput = [
            ...parentDataInput,
            {
              phaseId: data.troubleshootingStepStepStage,
              phaseDetail: data.troubleshootingStepInstruction,
            },
          ];
        });
        // console.log(defaultChildInput);
        parentDataInput = parentDataInput.filter((obj, index, self) => {
          return index === self.findIndex((t) => t.phaseId === obj.phaseId);
        });
        console.log(parentDataInput);
        setParentRefData(parentDataInput);
        let result = [];
        for (
          let inputNum = 0;
          inputNum < defaultChildInput.length;
          inputNum++
        ) {
          const parentIndex = defaultChildInput[inputNum].parentIndex;
          const childIndex = defaultChildInput[inputNum].childIndex;
          if (!result[parentIndex]) {
            result[parentIndex] = [];
          }
          result[parentIndex][childIndex] = defaultChildInput[inputNum];
        }

        // console.log(result);

        for (parentIndex = 0; parentIndex < result.length; parentIndex++) {
          let data = [];

          for (let i = 0; i < result[parentIndex].length; i++) {
            data.push({
              phaseId: result[parentIndex][i].parentIndex,
              choiceId: result[parentIndex][i].childIndex,
              action: result[parentIndex][i].action,
              nextStep:
                typeof result[parentIndex][i].nextStep === "undefined"
                  ? -1
                  : result[parentIndex][i].nextStep,
            });
            data.parentId = parentIndex + 1;
            updateInputValueHandler("action", data);
          }
          // let parentData = [];
          // const numberPhase = parentIndex + 1;
          // const identifierName = "phase" + numberPhase;
          // setParentRefData((curInputValues) => {
          //   return {
          //     ...curInputValues,
          //     [identifierName]: {
          //       phaseId: result[parentIndex][0].phaseId,
          //       phaseDetail: result[parentIndex][0].newText,
          //     },
          //   };
          // });
          // parentData.push({
          //   phaseId: result[parentIndex][0].phaseId,
          //   phaseDetail: result[parentIndex][0].newText,
          // });
        }
        // console.log(parentData.phase1.phaseDetail);

        // console.log(phaseData);
        phaseData = phaseData.filter((obj, index, self) => {
          return index === self.findIndex((t) => t.value === obj.value);
        });

        setChildCount(result);
      }
      console.log(maxStage);
      setParentCount(maxStage);
      // savePdfsToDb(response);
      setIsFetching(false);
      // setIsFectching(true);
    } catch (err) {
      console.log(err);
    }
  };

  function getDefaultChildCount(stages) {
    const uniqueSortedArr = Array.from(new Set(stages)).sort();
    // console.log(uniqueSortedArr);

    uniqueSortedArr.forEach((stage) => {
      //   console.log(stage);
      //   children = [...childCount];
      children = [...children];
      let index = stage - 1;
      children[index] = [...(children[index] || []), {}];
      //   console.log(children);
    });
    return children;
  }

  const [parentCount, setParentCount] = useState(
    defaultWithDetails ? maxStage : 0
  );

  useEffect(() => {
    if (defaultValues?.problemId) {
      fetchEditingProblem(defaultValues.problemId);
    }
  }, [isModalOpen]);

  const [isFocus, setIsFocus] = useState(false);

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "problemTitle":
        setEnteredProblemTitle(enteredValue);
        break;
      case "phaseDetail":
        setEnteredPhaseDetail({
          phaseId: enteredValue.phaseId,
          phaseDetail: enteredValue.phaseDetail,
        });
        break;
      case "action":
        // setEnteredAction(enteredValue);
        const identifierName = "phase" + stringify(enteredValue.parentId);
        // console.log(identifierName);
        inputChangedHandler(identifierName, enteredValue);
        break;
    }
    // console.log(enteredPhaseDetail);
  }
  async function inputChangedHandler(inputIdentifier, enteredValue) {
    // console.log(inputIdentifier + enteredValue);
    setEnteredAction((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: {
          choiceData: enteredValue,
        },
      };
    });
    // await AsyncStorage.setItem("storedFormValues", JSON.stringify(inputs));
    // console.log("SET storedValues");
    // console.log(inputs);
  }
  function submitHandler() {
    // console.log(
    //   Object.values(enteredAction)
    //     .map((phase) => phase.choiceData)
    //     .flat()
    // );
    const phaseKeys = Object.keys(parentData).filter((key) =>
      key.startsWith("phase")
    );
    const phases = phaseKeys.map((key) => parentData[key]);
    const action = Object.values(enteredAction)
      .map((phase) => phase.choiceData)
      .flat();
    // console.log(action);
    // console.log(phases);
    onSubmit({
      problemId: enteredProblemId,
      problemTitle: enteredProblemTitle,
      problemType: enteredProblemType,
      //   phaseDetail: parentData,
      //   action: enteredAction,
      phaseDetail: phases,
      action: action,
    });
  }

  const data = [
    { label: "ホール", value: "hallway" },
    { label: "キッチン", value: "kitchen" },
    { label: "レジ", value: "cashier" },
    { label: "その他", value: "other" },
  ];

  const addChild = (index) => {
    let children = [...childCount];
    children[index] = [...(children[index] || []), {}];
    setChildCount(children);
  };

  const removeChild = (parentIndex, childIndex) => {
    let children = [...childCount];
    children[parentIndex].splice(childIndex, 1);
    setChildCount(children);
  };

  const renderChild = (parentIndex, childIndex) => {
    let nextStep;
    let action;
    const choiceId = childIndex + 1;
    childCount[parentIndex][childIndex].childIndex = childIndex;
    childCount[parentIndex][childIndex].parentIndex = parentIndex + 1;
    // console.log(parentIndex);
    const outputActionData = () => {
      let data = [];
      for (let i = 0; i < childCount[parentIndex].length; i++) {
        data.push({
          phaseId: childCount[parentIndex][i].parentIndex,
          choiceId: childCount[parentIndex][i].childIndex,
          action: childCount[parentIndex][i].action,
          nextStep:
            typeof childCount[parentIndex][i].nextStep === "undefined"
              ? -1
              : childCount[parentIndex][i].nextStep,
        });
      }
      data.parentId = parentIndex + 1;
      updateInputValueHandler("action", data);
      //   console.log(enteredAction);
      //   updateParent(data);
      return data;
    };
    return (
      <View key={childIndex} style={styles.choiceInputContainer}>
        <View style={styles.choiceInputIconContainer}>
          <IconButton
            icon="trash"
            color={GlobalStyles.colors.primary700}
            size={32}
            onPress={() => removeChild(parentIndex, childIndex)}
            styleButtonContainer={styles.iconTrashContainer}
          />
        </View>
        <View style={styles.choiceInputTextContainer}>
          <Input
            label={"状況" + choiceId}
            // onUpdateValue={outputActionData.bind(this)}
            onUpdateValue={(newText) => {
              childCount[parentIndex][childIndex].action = newText;
              outputActionData();
            }}
            value={childCount[parentIndex][childIndex].action}
            keyboardType="default"
            placeholderValue={"状況" + choiceId}
            multiline={true}
            styleText
          />
        </View>
        <View style={styles.choiceInputDropdownContainer}>
          <Dropdown
            style={[styles.dropdown, isFocus && { borderColor: "green" }]}
            placeholderStyle={[styles.placeholderStyle, styles.textBase]}
            selectedTextStyle={[styles.selectedTextStyle, styles.textBase]}
            inputSearchStyle={[styles.inputSearchStyle, styles.textBase]}
            iconStyle={styles.iconPhaseStyle}
            data={phaseData}
            // disable={true}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={!isFocus ? "完了" : "..."}
            searchPlaceholder="Search..."
            value={childCount[parentIndex][childIndex].nextStep}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(item) => {
              //   setEnteredProblemType(item.value);
              childCount[parentIndex][childIndex].nextStep = item.value;
              outputActionData();
              setIsFocus(false);
            }}
          />
        </View>
      </View>
    );
  };

  const renderChildren = (index) => {
    const children = [];
    for (let i = 0; i < (childCount[index] || []).length; i++) {
      children.push(renderChild(index, i));
    }
    return children;
  };

  const addParent = () => {
    setParentCount(parentCount + 1);
    setParentRefData([...parentRefData, { phaseDetail: "" }]);
    phaseData.push({
      label: stringify(parentCount + 1),
      value: parentCount + 1,
    });
  };

  const renderParent = (index) => {
    const phaseId = index + 1;

    return (
      <View key={index}>
        {/* <Text>Parent {index + 1}</Text> */}
        <View style={styles.phaseInputContainer}>
          <View style={styles.phaseInputTextContainer}>
            <Input
              label={"対策内容フェイズ" + phaseId}
              //   onUpdateValue={updateInputValueHandler.bind(this, "phaseDetail")}
              onUpdateValue={(newText) => {
                let phaseData = { phaseId: phaseId, phaseDetail: newText };
                const identifierName = "phase" + stringify(phaseId);
                setParentData((curInputValues) => {
                  return {
                    ...curInputValues,
                    [identifierName]: {
                      phaseId: phaseId,
                      phaseDetail: newText,
                    },
                  };
                });
              }}
              value={
                enteredPhaseDetail.phaseDetail
                  ? enteredPhaseDetail.phaseDetail
                  : parentRefData[index].phaseDetail
              }
              // value={enteredPhaseDetail.phaseDetail}
              keyboardType="default"
              multiline={true}
              //   numberOfLines={4}
              placeholderValue={"対策内容フェイズ" + phaseId}
              styleText
            />
          </View>
          <View style={styles.phaseInputIconContainer}>
            <IconButton
              icon="add-circle"
              color={GlobalStyles.colors.primary700}
              size={32}
              onPress={() => addChild(index)}
              styleButtonContainer={styles.iconContainer}
            />
          </View>
        </View>
        {renderChildren(index)}
        {/* <Button
          title="Add Situation"
          styleButton={{
            backgroundColor: GlobalStyles.colors.primary700,
          }}
          onPress={() => addChild(index)}
        >
          Add Situation
        </Button> */}
      </View>
    );
  };

  const renderParents = () => {
    const parents = [];
    for (let i = 0; i < parentCount; i++) {
      parents.push(renderParent(i));
    }
    // console.log(parents);
    return parents;
  };

  return (
    <>
      <View style={styles.modalContainer}>
        <View>
          <Input
            label="トラブルシュート件名"
            onUpdateValue={updateInputValueHandler.bind(this, "problemTitle")}
            value={enteredProblemTitle}
            keyboardType="default"
            placeholderValue="トラブルシュート件名"
            styleText
          />
        </View>
        {/* <View style={styles.container}> */}
        <View style={styles.dropdownTypeContainer}>
          <View style={styles.dropdownTypeTextContainer}>
            <Text style={styles.dropdownText}>トラブル種類 : </Text>
          </View>
          <View style={styles.dropdownContainer}>
            <Dropdown
              style={[styles.dropdown, isFocus && { borderColor: "green" }]}
              placeholderStyle={[styles.placeholderStyle, styles.textBase]}
              selectedTextStyle={[styles.selectedTextStyle, styles.textBase]}
              inputSearchStyle={[styles.inputSearchStyle, styles.textBase]}
              iconStyle={styles.iconStyle}
              data={data}
              // disable={true}
              maxHeight={300}
              labelField="label"
              valueField="value"
              placeholder={!isFocus ? "トラブル種類を選択してください" : "..."}
              searchPlaceholder="Search..."
              value={enteredProblemType}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              onChange={(item) => {
                setEnteredProblemType(item.value);
                setIsFocus(false);
              }}
            />
          </View>
        </View>
        <ScrollView style={styles.scrollContainer}>
          <View>
            <Button
              title="Add Parent"
              onPress={addParent}
              styleButton={{
                backgroundColor: GlobalStyles.colors.primary700,
              }}
            >
              対策内容フェイズを追加
            </Button>
            {renderParents()}
          </View>
        </ScrollView>
        <View style={styles.buttons}>
          <Button
            style={styles.button}
            mode="flat"
            onPress={() => {
              onCancel();
              phaseData = [{ label: "完了", value: -1 }];
              setParentCount(0);
            }}
            styleText={[styles.buttonText, styles.textBase]}
          >
            キャンセル
          </Button>
          <Button
            style={styles.button}
            onPress={() => {
              submitHandler();
              phaseData = [{ label: "完了", value: -1 }];
            }}
            // onPress={submitHandler}
            styleButton={{
              backgroundColor: GlobalStyles.colors.primary700,
            }}
            styleText={styles.buttonText}
          >
            {"保存"}
          </Button>
        </View>
      </View>
    </>
  );
}

export default TroubleshootingEditForm;

const styles = StyleSheet.create({
  form: {},
  container: {
    padding: 16,
    minWidth: "80%",
  },
  dropdown: {
    height: 50,
    borderColor: GlobalStyles.colors.primary600,
    backgroundColor: GlobalStyles.colors.neutral0,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 14,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 14,
  },
  selectedTextStyle: {
    fontSize: 14,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  iconPhaseStyle: {
    width: 10,
    height: 10,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },

  textBase: { color: GlobalStyles.colors.primary600 },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 36,
  },
  profileImg: {
    width: 120,
    height: 120,
    borderRadius: 1000,
  },
  remarkText: {
    color: GlobalStyles.colors.error600,
    fontSize: 9,
  },
  dropdownText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    marginVertical: 8,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },

  buttonText: {
    fontSize: 16,
  },
  progressText: {
    fontSize: 12,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 100,
  },
  uploadContainer: { marginVertical: "2%" },
  progressBarContainer: { alignItems: "center" },
  dropdownTypeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8,
  },
  dropdownTypeTextContainer: { flex: 3 },
  dropdownContainer: { flex: 8 },
  phaseInputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 2,
  },
  phaseInputTextContainer: { flex: 9 },
  phaseInputIconContainer: { flex: 2 },
  iconContainer: {
    // marginHorizontal: 1,
    // paddingLeft: 1,
    // paddingRight: 6,
    alignItems: "center",
    marginTop: 16,
  },
  choiceInputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 2,
  },
  choiceInputIconContainer: { flex: 2 },
  choiceInputTextContainer: { flex: 9 },
  choiceInputDropdownContainer: {
    paddingLeft: 5,
    marginTop: 16,
    flex: 3,
  },
  iconTrashContainer: {
    marginHorizontal: 6,
    paddingLeft: 1,
    // paddingRight: 6,
    alignItems: "center",
    marginTop: 16,
  },
  modalContainer: {
    // justifyContent: "center",
    // alignItems: "center",
    paddingHorizontal: 12,
  },
  scrollContainer: { maxHeight: "70%" },
});
