import AsyncStorage from "@react-native-async-storage/async-storage";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
  Alert,
} from "react-native";
import OneSignal from "react-native-onesignal";
import DailyReportForm from "../component/DailyReport/DailyReportForm";
import ErrorOverlay from "../component/UI/ErrorOverlay";

import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { DailyReportsContext } from "../store/dailyreport-context";
import { UsersContext } from "../store/user-context";
import {
  deleteDailyReport,
  updateDailyReport,
  storeDailyReport,
  fetchUserInRooms,
  updateLatestUpdatedTimeDailyReportRooms,
} from "../util/http";
function ManageDailyReport({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFectching] = useState(true);

  const dailyReportsCtx = useContext(DailyReportsContext);
  const userCtx = useContext(UsersContext);

  const roomId = route.params?.dailyReportRoomId;
  const roomName = route.params?.dailyReportRoomName;

  const editedDailyReportId = route.params?.dailyReportId;
  const isEditing = !!editedDailyReportId;

  let selectedDailyReport;
  if (isEditing) {
    selectedDailyReport = dailyReportsCtx.dailyReports.find(
      (dailyreport) => dailyreport.dailyreportId === editedDailyReportId
    );
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: isEditing ? "Edit DailyReport" : "Add DailyReport",
    });
  }, [navigation, isEditing]);

  async function deleteDailyReportHandler() {
    setIsSubmitting(true);
    try {
      await deleteDailyReport(selectedDailyReport.dailyreportId);
      dailyReportsCtx.deleteDailyReport(editedDailyReportId);
      navigation.goBack();
    } catch (error) {
      setError(
        "削除リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    async function getUsersList() {
      setIsFectching(true);
      try {
        const userList = await fetchUserInRooms(roomId);
        userCtx.setUser(userList);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
      }
      setIsFectching(false);
    }
    async function fetchUserData() {
      const storedUid = await AsyncStorage.getItem("uid");
      setUsingId(storedUid);
    }
    getUsersList();
    fetchUserData();
  }, [navigation]);

  function cancelHandler() {
    navigation.goBack();
  }

  const sendPushUserIdList = [];
  const sendPushUserList = userCtx.users
    .filter((users) => users.userId !== usingId)
    .forEach((userData) => sendPushUserIdList.push(userData.notificationId));
  // console.log(sendPushUserIdList);

  async function confirmHandler(dailyReportData) {
    // console.log(dailyReportData);
    const clearData = async () => {
      try {
        await AsyncStorage.removeItem("storedFormValues");
        console.log("Data cleared successfully");
      } catch (error) {
        console.log("Error clearing data", error);
      }
    };

    setIsSubmitting(true);
    try {
      if (isEditing) {
        // console.log(editedDailyReportId);
        // console.log(dailyReportData);
        const updateResponseData = await updateDailyReport(
          editedDailyReportId,
          dailyReportData,
          roomId
        );
        // console.log(updateResponseData);
        dailyReportsCtx.updateDailyReport(editedDailyReportId, dailyReportData);
        clearData();
        navigation.goBack();
      } else {
        const responseData = await storeDailyReport(dailyReportData, roomId);
        const responseDataUpdate =
          updateLatestUpdatedTimeDailyReportRooms(roomId);
        console.log("dailyReportData Send");
        console.log(dailyReportData);
        console.log(responseData);
        clearData();
        if (typeof responseData === "string") {
          Alert.alert(
            "エラーが発生しました",
            "画像アップロードができません - 後でもう一度試してください",
            [
              {
                onPress: () => {
                  navigation.goBack();
                },
              },
            ]
          );

          // navigation.navigate("ManageDailyReport");
        } else {
          const creatorData = userCtx.users.find(
            (users) => users.userId === usingId
          );
          dailyReportsCtx.addDailyReport({
            ...dailyReportData,
            id: responseData[0].id,
            dailyreportId: responseData[0].id,
            dailyreportCreatedTimestamp: responseData[0].timestamp,
            dailyreportUpdatedTimestamp: "0000-00-00 00:00:00",
            userName: creatorData.userName,
            userImage: creatorData.userImage,
            userId: creatorData.userId,
          });
          console.log(dailyReportsCtx.dailyReports);

          function sendPushDailyReport(reportData) {
            // const response = await addUnreadChat(curRoomId);
            const senderData = userCtx.users.find(
              (users) => users.userId === usingId
            );
            // const sendPushUserIdList = [];
            // const sendPushUserList = userCtx.users
            //   .filter((users) => users.userId !== usingId)
            //   .forEach((userData) =>
            //     sendPushUserIdList.push(userData.notificationId)
            //   );
            const sendPushUserIdList = [];
            const sendPushUserList = userCtx.users
              .filter((users) => users.userId !== usingId)
              .forEach((userData) =>
                sendPushUserIdList.push(userData.notificationId)
              );
            // console.log("!!!!!!!!!!!!!!!!!");

            // console.log(reportData.dailyreportDate.getFullYear());
            const notificationObj = {
              headings: { en: `日報報告 : ${roomName}` },
              contents: {
                en: `${
                  reportData.dailyreportDate.getMonth() + 1
                }月 ${reportData.dailyreportDate.getDate()}日 : ${
                  senderData.userName
                } から日報レポートを提出しました`,
              },
              include_player_ids: sendPushUserIdList,
              data: {
                type: "dailyreport",
                page: "DailyReportStack",
                screen: "DailyReportDetailScreen",
                roomName: `${roomName}`,
                roomId: `${roomId}`,
              },
              url: `yonenolinks:///dailyreport/${encodeURIComponent(
                roomName
              )}/${roomId}`,
            };
            console.log(
              `yonenolinks:///dailyreport/${encodeURIComponent(
                roomName
              )}/${roomId}`
            );
            // console.log(notificationObj);

            const jsonString = JSON.stringify(notificationObj);

            OneSignal.postNotification(
              jsonString,
              (success) => {
                console.log("Success:", success);
              },
              (error) => {
                console.log("Error:", error);
              }
            );
          }
          sendPushDailyReport(dailyReportData);
          navigation.goBack();
        }
        // if (responseData != "UPLOAD_FAILED") {

        // }
        clearData();
      }
    } catch (error) {
      console.log(error);
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  if (error && !isSubmitting) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsSubmitting(true);
          setError();
        }}
      />
    );
  }
  if (isSubmitting) {
    return <LoadingOverlay />;
  }
  // console.log(selectedDailyReport);
  // const keyboardVerticalOffset = Platform.OS === "ios" ? 40 : 0;
  return (
    <SafeAreaView>
      <ScrollView automaticallyAdjustKeyboardInsets={true}>
        <View style={styles.container}>
          <DailyReportForm
            submitButtonLabel={isEditing ? "更新" : "送信"}
            headerFormLabel={
              isEditing ? "日報レポート編集" : "日報レポート提出"
            }
            onSubmit={confirmHandler}
            onCancel={cancelHandler}
            defaultValues={selectedDailyReport}
          />

          {isEditing && (
            <View style={styles.deleteContainer}>
              <IconButton
                icon="trash"
                color={GlobalStyles.colors.neutral0}
                size={36}
                onPress={deleteDailyReportHandler}
              />
              {/* <Text>削除</Text> */}
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default ManageDailyReport;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: GlobalStyles.colors.primary600,
  },

  deleteContainer: {
    paddingTop: 8,
    borderTopWidth: 2,
    borderTopColor: GlobalStyles.colors.neutral0,
    alignItems: "center",
  },
});
