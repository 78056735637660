import {
  View,
  Text,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
} from "react-native";

import { GlobalStyles } from "../../constant/styles";
import { InputOutline } from "react-native-input-outline";
import { DismissKeyboardView } from "../../util/DismissKeyboardView";

function InputTask({
  label,
  isInvalid,
  labelStyle,
  textInputConfig,
  styleTextInput,
  isRequired,
  isOptional,
}) {
  let inputStyles = [styles.input];
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (isInvalid) {
    inputStyles.push(styles.invalidInput);
  }
  inputStyles.push(styleTextInput);
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      <View style={styles.inputContainer}>
        <View style={styles.labelContainer}>
          <Text
            style={[styles.label, isInvalid && styles.labelInvalid, labelStyle]}
          >
            {label}
          </Text>
          {isRequired ? (
            // <View style={styles.requiredContainer}>
            <Text style={[styles.requiredLabel]}>*必要</Text>
          ) : // </View>
          null}
          {isOptional ? (
            // <View style={styles.requiredContainer}>
            <Text style={[styles.optionalLabel]}>{"　<任意>"}</Text>
          ) : // </View>
          null}
        </View>
        <TextInput
          style={[inputStyles, isInvalid && styles.inputInvalid]}
          {...textInputConfig}
          // autoCapitalize="none"
          // keyboardType={keyboardType}
          // secureTextEntry={secure}
          // onChangeText={onUpdateValue}
          // value={value}
          // placeholder={placeholderValue}
          // backgroundColor={GlobalStyles.colors.neutral0}
          // activeColor={GlobalStyles.colors.primary500}
          // inactiveColor={GlobalStyles.colors.primary600}
          // fontColor={GlobalStyles.colors.primary600}
        />
      </View>
    </KeyboardAvoidingView>
  );
}

export default InputTask;

const styles = StyleSheet.create({
  container: { flex: 1 },
  inputContainer: {
    marginVertical: 2,
    marginBottom: 8,
  },
  label: {
    // color: "white",
    marginBottom: 8,
    color: GlobalStyles.colors.primary600,
    fontWeight: "600",
  },
  labelInvalid: {
    color: GlobalStyles.colors.error500,
  },
  input: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    // backgroundColor: GlobalStyles.colors.neutral0,
    borderRadius: 4,
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
  },
  inputInvalid: {
    backgroundColor: GlobalStyles.colors.error100,
  },
  requiredContainer: {
    backgroundColor: GlobalStyles.colors.neutral10,
    // alignContent: "center",
    alignSelf: "center",
    // alignItems: "center",
    marginLeft: 8,
    borderRadius: 6,
  },
  requiredLabel: {
    color: GlobalStyles.colors.error500,
    marginHorizontal: 4,
    fontSize: 12,
  },
  optionalLabel: {
    color: GlobalStyles.colors.primary600,
    marginHorizontal: 4,
    fontSize: 12,
  },
  labelContainer: { flexDirection: "row" },
});
