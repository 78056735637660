import AsyncStorage from "@react-native-async-storage/async-storage";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Text,
  Alert,
} from "react-native";
import OneSignal from "react-native-onesignal";
import DailyReportForm from "../component/DailyReport/DailyReportForm";
import TaskForm from "../component/TaskManager/TaskForm";
import ErrorOverlay from "../component/UI/ErrorOverlay";

import IconButton from "../component/UI/IconButton";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { GlobalStyles } from "../constant/styles";
import { DailyReportsContext } from "../store/dailyreport-context";
import { UsersContext } from "../store/user-context";
import {
  deleteDailyReport,
  updateDailyReport,
  storeDailyReport,
  fetchUserInRooms,
  fetchUsers,
  addTask,
  addTaskAssignment,
  updateTask,
  deleteTask,
} from "../util/http";
import * as SQLite from "expo-sqlite";
import { TaskContext } from "../store/task-context";
import { MyTaskContext } from "../store/mytask-context";
function ManageTaskManagerScreen({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [usingId, setUsingId] = useState();
  const [isFetching, setIsFetching] = useState(true);
  // const [selectedTaskMember, setSelectedTaskMember] = useState();
  const [assignedUser, setAssignedUser] = useState();

  const todoTaskCtx = useContext(TaskContext);
  const myTaskCtx = useContext(MyTaskContext);

  const userCtx = useContext(UsersContext);

  // const roomId = route.params?.dailyReportRoomId;
  // const roomName = route.params?.dailyReportRoomName;

  // const editedDailyReportId = route.params?.dailyReportId;

  // const selectedDailyReport = taskCtx.dailyReports.find(
  //   (dailyreport) => dailyreport.dailyreportId === editedDailyReportId
  // );
  const selectedTask = route.params.params.item;
  const todoTask = route.params.params?.todoTask;

  // console.log("route.params.params.item");
  // console.log(route.params.params.item);
  const isEditing = !!selectedTask;

  const db = SQLite.openDatabase("db");
  function readTable(task) {
    db.transaction((tx) => {
      tx.executeSql(
        "select assigned_to_user.user_id from assigned_to_user where task_id = ?",
        [task.taskId],
        (_, { rows }) => {
          // console.log("database input done");
          // console.log(JSON.stringify(rows._array));
          setAssignedUser(JSON.parse(JSON.stringify(rows._array)));
        }
      );
    });
  }
  async function readIdTable(task) {
    let arrayId = [];
    db.transaction((tx) => {
      tx.executeSql(
        "select assigned_to_user.user_id from assigned_to_user where task_id = ?",
        [task.taskId],
        (_, { rows }) => {
          // console.log("database input done");
          // console.log(rows._array.forEach((item) => arrayId.push(item.user_id)));
          rows._array.forEach((item) => arrayId.push(item.user_id));
          // console.log(arrayId);

          // setAssignedUser(JSON.parse(JSON.stringify(rows._array)));
          setAssignedUser(JSON.parse(JSON.stringify(arrayId)));
        }
      );
    });
  }
  // console.log(selectedTask);
  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     title: isEditing ? "Edit DailyReport" : "Add DailyReport",
  //   });
  // }, [navigation, isEditing]);

  async function deleteTaskHandler() {
    setIsSubmitting(true);
    try {
      await deleteTask(selectedTask.taskId);
      // if (todoTask) {
      todoTaskCtx.deleteTask(selectedTask);
      // } else {
      myTaskCtx.deleteTask(selectedTask);
      // }
      // taskCtx.deleteDailyReport(editedDailyReportId);
      navigation.goBack();
    } catch (error) {
      setError(
        "削除リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }
  // async function deleteDailyReportHandler() {
  //   setIsSubmitting(true);
  //   try {
  //     await deleteDailyReport(selectedDailyReport.dailyreportId);
  //     taskCtx.deleteDailyReport(editedDailyReportId);
  //     navigation.goBack();
  //   } catch (error) {
  //     setError(
  //       "削除リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
  //     );
  //     setIsSubmitting(false);
  //   }
  // }
  useEffect(() => {
    async function getUsersList() {
      setIsFetching(true);
      try {
        const userList = await fetchUsers();
        // console.log(userList);
        userCtx.setUser(userList);
      } catch (error) {
        setError("Could not fetch user lists!");
      }
      setIsFetching(false);
    }
    const fetchUserIdInTask = async (task) => {
      try {
        // console.log("fetchUserInTask");
        const test = await readIdTable(task);
        // console.log("fetchUserInTask after");
        // console.log(assignedUser);
      } catch (err) {
        console.log(err);
      }
      setIsFetching(false);
    };
    async function fetchUserData() {
      try {
        const storedUid = await AsyncStorage.getItem("uid");
        setUsingId(storedUid);
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
      }
    }
    // getUsersList();
    fetchUserData();
    getUsersList();
    if (typeof selectedTask !== "undefined") {
      fetchUserIdInTask(selectedTask);
      // console.log("assignedUser");
      // console.log(assignedUser);
    }
  }, [navigation]);

  function cancelHandler() {
    navigation.goBack();
  }

  async function confirmHandler(taskData) {
    // console.log("confirmHandler");
    // console.log(taskData);

    setIsSubmitting(true);
    const fetchUserInTask = (task) => {
      try {
        // console.log("fetchUserInTask");
        readTable(task);
        // console.log("fetchUserInTask after");
        // console.log(assignedUser);
      } catch (err) {
        console.log(err);
      }
    };
    // if (typeof selectedTask !== "undefined") {
    //   fetchUserInTask(selectedTask);
    // }
    try {
      if (isEditing) {
        //     // console.log(editedDailyReportId);
        //     // console.log(dailyReportData);
        const updateResponseData = await updateTask(
          selectedTask.taskId,
          taskData
        );
        if (todoTask) {
          todoTaskCtx.updateTask(1, {
            ...taskData,
            taskId: selectedTask.taskId,
          });
        } else {
          myTaskCtx.updateTask(1, { ...taskData, taskId: selectedTask.taskId });
        }
        // console.log(taskData);
        //     taskCtx.updateDailyReport(editedDailyReportId, dailyReportData);
        //     navigation.goBack();
      } else {
        const responseData = await addTask(taskData);
        fetchUserInTask(taskData.addUserId);
        // console.log("responseData");
        // console.log(responseData);
        const responseRooms = await addTaskAssignment(
          taskData,
          responseData[0].taskId
        );
        // console.log("responseRooms");
        // console.log(responseRooms);
        sendPushAddedTask(taskData, responseData[0]);
        // console.log("responseData[0]");
        // console.log(responseData[0]);
        if (todoTask) {
          todoTaskCtx.addTask({
            ...taskData,
            taskId: responseData[0].taskId,
            status: taskData.status.value,
          });
        }
        // else {
        myTaskCtx.addTask({
          ...taskData,
          taskId: responseData[0].taskId,
          status: taskData.status.value,
        });
        // }
        // console.log("todoTaskCtx");
        // console.log(todoTaskCtx);
        // console.log("myTaskCtx");
        // console.log(myTaskCtx);
      }

      // console.log(dailyReportData);

      // if (typeof responseData === "string") {
      //   Alert.alert(
      //     "エラーが発生しました",
      //     "タスクアップロードができません - 後でもう一度試してください",
      //     [
      //       {
      //         onPress: () => {
      //           navigation.goBack();
      //         },
      //       },
      //     ]
      //   );

      //   // navigation.navigate("ManageDailyReport");
      // } else {
      //   taskCtx.addDailyReport({
      //     ...taskData,
      //     id: responseData[0].id,
      //     taskCreatedAt: responseData[0].timestamp,
      //     taskUpdatedAt: "0000-00-00 00:00:00",
      //   });}

      navigation.goBack();
    } catch (error) {
      console.log(error);
      setError(
        "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
      );
      setIsSubmitting(false);
    }
  }

  function sendPushAddedTask(taskData, taskResponse) {
    // const response = await addUnreadChat(curRoomId);
    const senderData = userCtx.users.find((users) => users.userId === usingId);

    // const sendPushUserIdList = [];
    const sendPushUserIdList = userCtx.users
      .filter((users) => taskData.addUserId.includes(users.userId))
      .map((userData) => userData.notificationId);
    console.log(sendPushUserIdList);
    const sendPushCreateAt = new Date();
    const notificationObj = {
      headings: { en: ` タスク展開: ${taskData.taskTitle}` },
      contents: {
        en: `${
          sendPushCreateAt.getMonth() + 1
        }月 ${sendPushCreateAt.getDate()}日 : ${
          senderData.userName
        }からの新しいタスクを受信しました`,
      },
      include_player_ids: sendPushUserIdList,
      data: {
        type: "task",
        page: "TaskManagerScreen",
        screen: "TaskManagerDetailScreen",
        taskTitle: `${taskData.taskTitle}`,
        taskId: `${taskResponse.taskId}`,
      },
      url: `yonenolinks:///task/${encodeURIComponent(taskData.taskTitle)}/${
        taskResponse.taskId
      }`,
    };
    // console.log(notificationObj);

    const jsonString = JSON.stringify(notificationObj);

    OneSignal.postNotification(
      jsonString,
      (success) => {
        console.log("Success:", success);
      },
      (error) => {
        console.log("Error:", error);
      }
    );
  }

  // function sendPushAlertTask(taskData) {
  //   // const response = await addUnreadChat(curRoomId);
  //   const senderData = userCtx.users.find((users) => users.userId === usingId);

  //   const sendPushUserIdList = [];
  //   const sendPushUserList = userCtx.users
  //     .filter((users) => users.userId !== usingId)
  //     .forEach((userData) => sendPushUserIdList.push(userData.notificationId));

  //   const sendPushCreateAt = new Date();
  //   const notificationObj = {
  //     headings: { en: ` タスク通信: タスク番号${taskData.taskId}` },
  //     contents: {
  //       en: `${
  //         sendPushCreateAt.getMonth() + 1
  //       }月 ${sendPushCreateAt.getDate()}日 : ${
  //         senderData.userName
  //       }からのタスクは終了日付が近づきます`,
  //     },
  //     include_player_ids: sendPushUserIdList,
  //     send_after: `${taskData.plannedEndDate} 09:00:00 GMT+9`,
  //     data: {
  //       type: "task",
  //       page: "TaskManagerScreen",
  //       screen: "TaskManagerDetailScreen",
  //       taskTitle: `${taskData.taskTitle}`,
  //       taskId: `${taskData.taskId}`,
  //     },
  //     url: `yonenolinks:///task/${encodeURIComponent(taskData.taskTitle)}/${
  //       taskData.taskId
  //     }`,
  //   };
  // console.log(notificationObj);

  //   const jsonString = JSON.stringify(notificationObj);

  //   OneSignal.postNotification(
  //     jsonString,
  //     (success) => {
  //       console.log("Success:", success);
  //     },
  //     (error) => {
  //       console.log("Error:", error);
  //     }
  //   );
  // }
  function removePushAlertTask(taskData) {
    // OneSignal.removeNotification();
  }
  if (error && !isSubmitting) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsSubmitting(true);
          setError();
        }}
      />
    );
  }
  if (isSubmitting) {
    return <LoadingOverlay />;
  }
  if (isFetching) {
    return <LoadingOverlay />;
  }
  console.log(isEditing);
  // const keyboardVerticalOffset = Platform.OS === "ios" ? 40 : 0;
  return (
    <SafeAreaView>
      <ScrollView automaticallyAdjustKeyboardInsets={true}>
        <View style={styles.container}>
          {/* <DailyReportForm
            submitButtonLabel={isEditing ? "更新" : "送信"}
            headerFormLabel={
              isEditing ? "日報レポート編集" : "日報レポート提出"
            }
            onSubmit={confirmHandler}
            onCancel={cancelHandler}
            defaultValues={selectedDailyReport}
          /> */}
          <TaskForm
            headerFormLabel={isEditing ? "タスク編集" : "タスク提出"}
            // submitButtonLabel={isEditing ? "更新" : "追加"}
            onSubmit={confirmHandler}
            onCancel={cancelHandler}
            defaultValues={selectedTask}
            users={userCtx.users}
            defaultUser={assignedUser}
            isEditing={isEditing}
          />
          {isEditing && (
            <View style={styles.deleteContainer}>
              <IconButton
                icon="trash"
                color={GlobalStyles.colors.neutral0}
                size={36}
                onPress={deleteTaskHandler}
              />
              {/* <Text>削除</Text> */}
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default ManageTaskManagerScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: GlobalStyles.colors.neutral10,
  },

  deleteContainer: {
    paddingTop: 8,
    borderTopWidth: 2,
    borderTopColor: GlobalStyles.colors.neutral0,
    alignItems: "center",
  },
});
