import { StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";

function ChoiceItem({ item, editingMode = false }) {
  return (
    <View
      style={[
        styles.choiceListContainer,
        editingMode === true && styles.editingMode,
      ]}
      key={item.key}
    >
      <View style={styles.choiceListTilteLayout}>
        <View style={styles.choiceListTilteContainer}>
          <Text style={styles.choiceListTilte}>{item.choiceAction}</Text>
        </View>
        {/* <View style={styles.dateContainer}>
          <Text style={[styles.textBase, styles.reportDate]}>
            {`${item.updatedAt.split(" ")[1].slice(0, 5)}`}
          </Text>
          <Text style={[styles.textBase, styles.reportMonthYear]}>
            {`${item.updatedAt.split(" ")[0].split("-")[2]}日${
              item.updatedAt.split("-")[1]
            }月${item.updatedAt.split("-")[0]}年`}
          </Text>
        </View> */}
      </View>
      {/* <View style={styles.choiceListDetailContainer}>
        <Text style={styles.choiceListDetail}>
          トラブル種類: {item.problemType}
        </Text>
      </View> */}
    </View>
  );
}
export default ChoiceItem;

const styles = StyleSheet.create({
  choiceListContainer: {
    marginHorizontal: "5%",
    marginBottom: "5%",
    backgroundColor: GlobalStyles.colors.neutral50,
    borderRadius: 10,
  },
  choiceListTilteLayout: {
    flexDirection: "row",
  },
  choiceListTilteContainer: {
    marginHorizontal: "5%",
    marginVertical: "2%",
    flex: 8,
  },
  choiceListTilte: {
    fontSize: 20,
    fontWeight: "bold",
    color: GlobalStyles.colors.primary600,
  },
  dateContainer: {
    marginTop: "2%",
    marginHorizontal: "2%",
    flex: 4,
    // marginHorizontal: "5%",
    // marginVertical: "2%",
    // flexDirection: "row",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  reportDate: {
    fontSize: 12,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  reportMonthYear: {
    fontSize: 12,
    marginBottom: 2,
    alignSelf: "flex-end",
  },
  choiceListDetailContainer: {
    marginLeft: "8%",
    marginRight: "5%",

    marginBottom: "2%",
  },
  choiceListDetail: {
    fontSize: 14,
    color: GlobalStyles.colors.primary600,
  },
  editingMode: {
    opacity: 0.3,
    backgroundColor: GlobalStyles.colors.neutral100,
  },
});
