import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { ScrollView } from "react-native";
import { Text, View, StyleSheet, Image, Modal, Pressable } from "react-native";
import { Agenda, Calendar, CalendarList } from "react-native-calendars";
import { Dropdown } from "react-native-element-dropdown";
import { Divider, Menu, Provider } from "react-native-paper";
import { GlobalStyles } from "../../constant/styles";
import { AuthContext } from "../../store/auth-context";
import {
  fetchTaskAssignedToMeList,
  fetchTaskUserCreatedList,
  logoutUserAuth,
} from "../../util/http";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
// import ProfileEditForm from "./ProfileEditForm";
import moment from "moment";
import "moment/locale/ja";
import TaskList from "./TaskList";
import { getscaledDimension } from "../../util/deviceUiInfo";
moment.locale("th");
function TaskManagerOutput({
  userData,
  taskAssignedToMeList,
  taskUserCreatedList,
  onConfirm,
  fallbackText,
}) {
  // const userData = userDataIn;
  // console.log(userData);
  const navigation = useNavigation();
  const hadProfileImg = userData.userImage !== null ? true : false;
  const [visible, setVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [userRole, setUserRole] = useState();

  // console.log(userData);
  const [date, setDate] = useState(new Date().toLocaleDateString("ja-JP"));
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(fontScale*0.7); // pass in fontScale to the StyleSheet

  const authCtx = useContext(AuthContext);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setDate(new Date().toLocaleDateString("ja-JP"));
  //   }, 10000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  async function fetchRole() {
    const storedRole = await AsyncStorage.getItem("role");
    setUserRole(storedRole);
    switch (storedRole) {
      case "admin":
        setIsAdmin(true);
        break;
      case "manager":
        setIsAdmin(true);
        break;
      default:
        setIsAdmin(false);
    }
  }
  const task = {
    id: 1,
    title: "Task 1",
    startDate: "2023-01-30",
    endDate: "2023-02-05",
  };
  const markedDates = {
    [task.startDate]: { startingDay: true, color: "green" },
    [task.endDate]: { endingDay: true, color: "red" },
  };
  const map_role = new Map();

  map_role.set("admin", "管理者（アドミン）");
  map_role.set("manager", "エリアマネージャー");
  map_role.set("officer", "社員");
  map_role.set("store", "店舗");

  function profileEditSubmitHandler(userData) {
    setModalVisible(!modalVisible);
    onConfirm(userData);
  }
  function profileEditCancelHandler() {
    setModalVisible(!modalVisible);
  }

  fetchRole();
  let markDateInCalendar = [];
  let markDateInCalendarObj = {};

  const openMenu = () => setVisible(!visible);

  const closeMenu = () => setVisible(false);
  let assignedTaskcontent = <Text style={styles.infoText}>{fallbackText}</Text>;
  let createdTaskcontent = <Text style={styles.infoText}>{fallbackText}</Text>;
  const handlePress = (item, userData, todoTask) => {
    // console.log("userData");
    // console.log(userData);
    navigation.navigate("TaskManagerDetailScreen", {
      params: { item, userData, todoTask },
    });
  };
  // const [markDateInCalendar, setMarkDateInCalendar] = useState({})

  if (taskAssignedToMeList.length > 0) {
    taskAssignedToMeList.forEach((task) => {
      const inputDate = new Date(task.plannedEndDate);
      const dateIn = task.plannedEndDate
        ? inputDate.toISOString().split("T")[0] !== "1970-01-01"
          ? inputDate.toISOString().split("T")[0]
          : "0000-00-00"
        : "0000-00-00";
      // const dateIn = task.plannedEndDate
      //   ? task.plannedEndDate.split(" ")[0]
      //   : "0000-00-00";
      markDateInCalendar.push(`${dateIn}`);
    });
    // console.log(JSON.parse(JSON.stringify(markDateInCalendar)));
    markDateInCalendarObj = markDateInCalendar.reduce(
      (a, v) => ({ ...a, [v]: { marked: true } }),
      {}
    );
    // console.log(markDateInCalendarObj);
    // {...markDateInCalendar,}

    assignedTaskcontent = (
      <TaskList
        taskList={taskAssignedToMeList}
        todoTask={true}
        handlePress={handlePress}
        horizontal={true}
        userData={userData}
      />
    );
  }
  if (taskUserCreatedList.length > 0) {
    createdTaskcontent = (
      <TaskList
        taskList={taskUserCreatedList}
        todoTask={false}
        handlePress={handlePress}
        userData={userData}
      />
    );
  }
  const addTaskPressHandler = () => {
    navigation.navigate("ManageTaskManagerScreen", {
      params: { titleName: "新たなタスク作成", todoTask: false },
    });
  };
  return (
    <View style={styles.container}>
      <View style={styles.containerTop}>
        <View style={styles.backIconContainer}>
          <IconButton
            icon="chevron-back-outline"
            size={20*fontScale}
            color={GlobalStyles.colors.primary600}
            onPress={() => navigation.goBack()}
            styleButtonContainer={styles.buttonBox}
            label={`ホームへ`}
          />
        </View>
        <View style={styles.profilePictureContainer}>
          <View style={styles.profilePicture}>
            {hadProfileImg && (
              <Image
                style={styles.profileImg}
                source={
                  userData.userImage ? { uri: `${userData.userImage}` } : null
                }
              />
            )}
            {!hadProfileImg && userData.userName && (
              <Text style={styles.textProfile}>{`${userData.userName
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
        </View>
        <View style={styles.userNameContainer}>
          <Text style={[styles.textBase, styles.usernameText]}>
            {userData.userName}
          </Text>
          <Text style={[styles.textBase, styles.dateText]}>
            {`${date.split("/")[0]}年${date.split("/")[1]}月${
              date.split("/")[2]
            }日`}
          </Text>
        </View>
      </View>

      <View style={styles.containerBottom}>
        {/* <View style={styles.userRoleContainer}>
          <Text style={[styles.textBase, styles.roleText]}>
            {`ユーザーの担当：${map_role.get(userRole)}`}
          </Text>
        </View> */}

        {/* <View style={styles.userCalendarContainer}> */}
        {/* <Calendar
            // Enable horizontal scrolling, default = false
            // horizontal={true}
            // Enable paging on horizontal, default = false
            // pagingEnabled={true}
            // Set custom calendarWidth.
            LocaleConfig={{
              locale: "ja",
            }}
          /> */}
        {false && (
          <Agenda
            // renderHeader={(date) => {
            //   // /*Return JSX*/ console.log();
            //   return (
            //     <Text>
            //       {date.toLocaleDateString().split("/")[2]}年
            //       {date.toLocaleDateString().split("/")[0]}月
            //     </Text>
            //   );
            // }}
            // taskAssignedToMeList
            loadItemsForMonth={(month) => {
              // console.log("trigger items loading");
              // console.log(month);
            }}
            onCalendarToggled={(calendarOpened) => {
              // console.log(calendarOpened);
            }}
            style={{ overflow: "hidden" }}
            markedDates={markDateInCalendarObj}
          />
        )}
        {/* </View> */}
        {/* <ScrollView> */}
        <View style={styles.userAssignedContainer}>
          <Text style={[styles.textBase, styles.textTopic]}>{`残タスク`}</Text>
          {assignedTaskcontent}
        </View>
        <View style={styles.userCreateContainer}>
          <Text
            style={[styles.textBase, styles.textTopic]}
          >{`依頼中タスク`}</Text>
          {createdTaskcontent}
        </View>
        {/* </ScrollView> */}
      </View>
      <IconButton
        icon="add-circle"
        color={GlobalStyles.colors.primary600}
        // addStyleContainer={{ backgroundColor: GlobalStyles.colors.primary700 }}
        size={54}
        onPress={addTaskPressHandler}
        styleButtonContainer={styles.iconContainer}
      />
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("ページが閉じる");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTitleContainer}>
              <Text style={styles.titleText}>ユーザー情報編集</Text>
            </View>
            <View style={styles.userEditContent}>
              {/* <ProfileEditForm
                onSubmit={profileEditSubmitHandler}
                onCancel={profileEditCancelHandler}
                defaultValues={userData}
                userRole={userRole}
              /> */}
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

export default TaskManagerOutput;

const makeStyles = (fontScale) => StyleSheet.create({
  container: {
    flex: 1,
  },
  containerTop: {
    flex: 2,
    backgroundColor: GlobalStyles.colors.neutral10,
    flexDirection: "row-reverse",
    // flexDirection: "column",
  },

  containerBottom: { flex: 15 },
  backIconContainer: {
    position: "absolute",
    top: "40%",
    left: "1%",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 10,
    // backgroundColor: GlobalStyles.colors.neutral1000,
  },
  optionIconContainer: {
    position: "absolute",
    top: "30%",
    right: "5%",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 1000,
  },
  optionMenuContainer: {
    position: "absolute",
    top: "20%",
    right: "1%",
  },
  profilePictureContainer: {
    flex: 1,
    // top: "40%",
    // alignItems: "flex-end",
    // justifyContent: "center",
    // alignSelf: "center",
    // position: "absolute",
    // top: "10%",
    // left: "1%",
    alignItems: "center",
    zIndex: 2,
    marginHorizontal: 10,
    marginTop: 40,
    marginBottom: 10,
    // backgroundColor: GlobalStyles.colors.error200,
    minwidth: 40,
    height: 50,
  },
  userNameContainer: {
    // marginTop: 110,
    flex: 5,
    // alignSelf: "flex-end",
    // alignItems: "flex-end",
    marginTop: 40,
    // justifyContent: "flex-end",
    // backgroundColor: GlobalStyles.colors.error400,
    // marginRight: 20,
    marginBottom: 20,
    height: 50,
    // paddingRight: 20,
  },
  userRoleContainer: {
    flex: 1,
    alignSelf: "center",
  },
  userCalendarContainer: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
    overflow: "hidden",
    // overflow:"visible"
  },
  userAssignedContainer: {
    flex: 3,
    alignSelf: "center",
    width: "100%",
  },
  userCreateContainer: {
    flex: 3,
    alignSelf: "center",
    width: "100%",
    backgroundColor: GlobalStyles.colors.neutral10,
  },
  buttonBox: { marginRight: 2 },
  profilePicture: {
    width: 50*fontScale,
    height: 50*fontScale,
    borderRadius: 1000,
    backgroundColor: GlobalStyles.colors.primary600,
    // alignItems: "center",
    // justifyContent: "center",
    // position: "absolute",
  },
  profileImg: {
    width: 50*fontScale,
    height: 50*fontScale,
    borderRadius: 1000,
  },
  textBase: { color: GlobalStyles.colors.primary600 },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 32*fontScale,
    alignSelf: "center",
    marginTop: 3,
  },

  usernameText: {
    fontSize: 24*fontScale,
    fontWeight: "bold",
    alignSelf: "flex-end",
  },
  dateText: {
    fontSize: 16*fontScale,
    fontWeight: "300",
    alignSelf: "flex-end",
  },
  roleText: { fontSize: 18*fontScale },

  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },

  buttonText: {
    fontSize: 16*fontScale,
  },

  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    marginTop: "30%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  userEditContent: {
    // backgroundColor: GlobalStyles.colors.neutral50,
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  titleText: {
    fontSize: 20*fontScale,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: GlobalStyles.colors.primary600,
  },
  textTopic: {
    fontSize: 20*fontScale,
    fontWeight: "600",
    marginLeft: 20,
    marginVertical: 10,
  },
  iconContainer: {
    marginHorizontal: 1,
    paddingLeft: 1,
    paddingRight: 1,
    position: "absolute",
    bottom: 20,
    right: 30,
  },
  infoText: { color: GlobalStyles.colors.primary600, marginLeft: 10 },
});
