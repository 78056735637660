import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import { KeyboardAvoidingView, SafeAreaView, Text } from "react-native";
import DailyReportsOutput from "../component/DailyReportOutput/DailyReportsOutput";
import DailyReportRoomsOutput from "../component/DailyReportRoom/RoomsOutput";
import ErrorOverlay from "../component/UI/ErrorOverlay";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { DailyReportsContext } from "../store/dailyreport-context";
import { DailyReportRoomsContext } from "../store/dailyreport-room-context";
import { UsersContext } from "../store/user-context";
import { getDateMinusDays } from "../util/date";
import {
  fetchDailyReportRooms,
  fetchDailyReports,
  fetchUserInRooms,
} from "../util/http";

function DailyReportDetailScreen({ route, navigation }) {
  const [isFetching, setIsFectching] = useState(true);
  const [error, setError] = useState();
  const dailyReportsCtx = useContext(DailyReportsContext);
  const [counter, setCounter] = useState(0);
  const isFocused = useIsFocused();
  const roomId = route.params?.dailyReportRoomId;
  const roomName = route.params?.dailyReportRoomName;

  // console.log(roomId);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 30000);

    async function getDailyReports() {
      setIsFectching(true);
      try {
        const dailyReports = await fetchDailyReports(roomId);
        // console.log(dailyReports);
        dailyReportsCtx.setDailyReport(dailyReports);
        // storeSeenData(dailyReports);
      } catch (error) {
        setError(
          "サーバーの問題で日報レポートを表示できません　- 後でもう一度試してください"
        );
      }
      // console.log(dailyReportsCtx.dailyReports);
      setIsFectching(false);
    }
    getDailyReports();
    // getData();

    return () => clearInterval(interval);
  }, [navigation]);

  if (error && !isFetching) {
    console.log(error);
    return (
      <ErrorOverlay
        message={error ? error : "エラーメッセージがありません"}
        onPress={() => {
          // setIsFectching(true);
          setError();
        }}
      />
    );
  }

  if (isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <DailyReportsOutput
      dailyReports={dailyReportsCtx.dailyReports}
      fallbackText="日報レポートはまだ登録していません "
      roomId={roomId}
      roomName={roomName}
    />
  );
}

export default DailyReportDetailScreen;
