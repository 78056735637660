import { View, ActivityIndicator, StyleSheet, Text, Modal } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import Button from "./Button";

function ErrorOverlay({ message, onPress }) {
  return (
    <>
      <View style={styles.container}>
        <Text style={[styles.text, styles.title]}>エラーが発生しました！</Text>
        <Text style={styles.text}>{message}</Text>
        <Button
          style={styles.button}
          styleText={styles.textButton}
          onPress={onPress}
        >
          戻る
        </Button>
      </View>
    </>
  );
}
export default ErrorOverlay;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    backgroundColor: GlobalStyles.colors.primary700,
  },
  text: {
    color: "white",
    textAlign: "center",
    marginBottom: 8,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  message: { fontSize: 14 },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },
  textButton: { fontSize: 18, fontWeight: "bold" },
});
