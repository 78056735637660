import Checkbox from "expo-checkbox";
import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import InputCheckBox from "./InputCheckBox";

function InputWeather({
  label,
  onCheckWeahter,
  defaultWeatherData,
  invalid,
  style,
  textInputConfig,
  styleLabel,
}) {
  let inputStyles = [styles.input];
  let todayWeather = 0;
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  // async function confirmHandler(weatherData) {
  //   let weatgherMask = 1 << weatherData.weatherType;
  //   todayWeather ^= weatgherMask;
  // setIsSubmitting(true);
  // try {
  //   if (isEditing) {
  //     await updateDailyReportRooms(editedRoomId, roomData);
  //     dailyReportRoomsContext.updateDailyReportRoom(editedRoomId, roomData);
  //     setIsSubmitting(false);
  //   } else {
  //     const responseRooms = await storeDailyReportRooms(roomData);
  //     dailyReportRoomsContext.addDailyReportRoom({
  //       ...roomData,
  //       id: responseRooms[0].id + 1,
  //     });
  //     setIsSubmitting(false);
  //   }
  //   navigation.goBack();
  // } catch (error) {
  //   setError(
  //     "リクエストされた内容をサーバーが理解できませんでした - 後でもう一度試してください"
  //   );
  //   setIsSubmitting(false);
  // }
  // }
  return (
    <View style={[styles.inputContainer, style]}>
      <View>
        <Text
          style={[styles.label, invalid && styles.invalidLabel, styleLabel]}
        >
          {label}
        </Text>
      </View>
      <View style={styles.weatherContainer}>
        <View>
          <InputCheckBox
            weatherStatus={"sunny"}
            onCheck={onCheckWeahter}
            defaultWeather={defaultWeatherData}
          />
        </View>
        <View>
          <InputCheckBox
            weatherStatus={"cloudy"}
            onCheck={onCheckWeahter}
            defaultWeather={defaultWeatherData}
          />
        </View>
        <View>
          <InputCheckBox
            weatherStatus={"rainy"}
            onCheck={onCheckWeahter}
            defaultWeather={defaultWeatherData}
          />
        </View>
      </View>
    </View>
  );
}
export default InputWeather;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  label: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 8,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.neutral0,
    padding: 6,
    borderRadius: 6,
    fontSize: 18,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  weatherContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});
