import { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  SafeAreaView,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";

import Button from "../UI/Button";
import Input from "./Input";

import { GlobalStyles } from "../../constant/styles";
// import { InputOutline } from "react-native-input-outline";
// import { Dropdown } from "react-native-element-dropdown";
import DropDownPicker from "../Profile/DropDownPicker";

function AuthForm({ isLogin, onSubmit, credentialsInvalid }) {
  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredConfirmUsername, setEnteredConfirmUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState("");
  const [enteredUserRole, setEnteredUserRole] = useState("");

  const {
    username: usernameIsInvalid,
    confirmUsername: usernamesDontMatch,
    password: passwordIsInvalid,
    confirmPassword: passwordsDontMatch,
  } = credentialsInvalid;

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "username":
        setEnteredUsername(enteredValue);
        break;
      case "confirmUsername":
        setEnteredConfirmUsername(enteredValue);
        break;
      case "password":
        setEnteredPassword(enteredValue);
        break;
      case "confirmPassword":
        setEnteredConfirmPassword(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      username: enteredUsername,
      confirmUsername: enteredConfirmUsername,
      password: enteredPassword,
      confirmPassword: enteredConfirmPassword,
      userRole: enteredUserRole,
    });
  }
  const data = [
    { label: "管理者（アドミン）", value: "admin" },
    { label: "エリアマネージャー", value: "manager" },
    { label: "社員", value: "officer" },
    { label: "店舗", value: "store" },
  ];

  const [isFocus, setIsFocus] = useState(false);

  return (
    <View>
      <View style={styles.form}>
        <View>
          <Input
            label="ユーザー名"
            onUpdateValue={updateInputValueHandler.bind(this, "username")}
            value={enteredUsername}
            keyboardType="email-address"
            isInvalid={usernameIsInvalid}
            placeholderValue="ユーザー名を記入してください"
          />
          {!isLogin && (
            <Input
              label="ユーザー名確認"
              onUpdateValue={updateInputValueHandler.bind(
                this,
                "confirmUsername"
              )}
              value={enteredConfirmUsername}
              keyboardType="email-address"
              isInvalid={usernamesDontMatch}
              placeholderValue="ユーザー名を再記入してください"
            />
          )}
          <Input
            label="パスワード"
            onUpdateValue={updateInputValueHandler.bind(this, "password")}
            secure
            value={enteredPassword}
            isInvalid={passwordIsInvalid}
            placeholderValue="パスワードを記入してください"
          />
          {!isLogin && (
            <Input
              label="パスワード確認"
              onUpdateValue={updateInputValueHandler.bind(
                this,
                "confirmPassword"
              )}
              secure
              value={enteredConfirmPassword}
              isInvalid={passwordsDontMatch}
              placeholderValue="パスワードを再記入してください"
            />
          )}
          {!isLogin && (
            <View style={styles.container}>
              {/* <Dropdown
                style={[styles.dropdown, isFocus && { borderColor: "blue" }]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={data}
                maxHeight={300}
                labelField="label"
                valueField="value"
                placeholder={!isFocus ? "Select User Role" : "..."}
                searchPlaceholder="Search..."
                value={enteredUserRole}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={(item) => {
                  setEnteredUserRole(item.value);
                  setIsFocus(false);
                }}
              /> */}
              <DropDownPicker
                data={data}
                value={enteredUserRole}
                onValueChange={(value) => setEnteredUserRole(value)}
                placeholder="Select User Role"
                disabled={false}
              />
            </View>
          )}
          <View style={styles.buttons}>
            <Button onPress={submitHandler} styleText={{ fontSize: 20 }}>
              {isLogin ? "ログイン" : "サインアップ（会員登録）"}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}

export default AuthForm;

const styles = StyleSheet.create({
  buttons: {
    marginTop: 24,
  },
  container: {
    padding: 16,
    width: "90%",
  },
  dropdown: {
    height: 50,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});
