import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import InputWithPrefix from "./InputWithPrefix";

function InputStore({
  label,
  invalid,
  style,
  textInputConfig,
  styleLabel,
  styleInputWithPrefixContainer,
}) {
  let inputStyles = [styles.input];
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  return (
    <View style={[styles.inputContainer, style]}>
      <View style={styles.labelContainer}>
        <Text
          style={[styles.label, invalid && styles.invalidLabel, styleLabel]}
        >
          {label}
        </Text>
      </View>
      <View style={styles.textInputContainer}>
        <InputWithPrefix
          style={styleInputWithPrefixContainer}
          prefix="￥"
          textInputConfig={textInputConfig}
        />
      </View>
    </View>
  );
}
export default InputStore;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  labelContainer: { flex: 2 },
  textInputContainer: { flex: 3 },
  label: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 4,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.neutral0,
    padding: 6,
    borderRadius: 6,
    fontSize: 18,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
});
