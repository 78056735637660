export const GlobalStyles = {
  colors: {
    primary0: "#FFFFFF",
    primary10: "#F9FFE8",
    primary50: "#D3FF98",
    primary100: "#B8F568",
    primary200: "#9DD84F",
    primary300: "#83BB35",
    primary400: "#6AA018",
    primary500: "#548400",
    primary600: "#548400",
    primary700: "#426900",
    // primary600: "#426900",
    // primary700: "#304F00",
    primary800: "#203600",
    primary900: "#112000",
    primary1000: "#000000",
    secondary0: "#FFFFFF",
    secondary10: "#F9FFE8",
    secondary50: "#EAF5D5",
    secondary100: "#DCE7C7",
    secondary200: "#C0CBAC",
    secondary300: "#A5AF92",
    secondary400: "#8A9479",
    secondary500: "#717B60",
    secondary600: "#586249",
    secondary700: "#414A33",
    secondary800: "#2A331E",
    secondary900: "#161E0B",
    secondary1000: "#000000",
    tertiary0: "#FFFFFF",
    tertiary10: "#F5FFF5",
    tertiary50: "#D2FBDE",
    tertiary100: "#C4ECD0",
    tertiary200: "#A8D0B4",
    tertiary300: "#8EB49A",
    tertiary400: "#749980",
    tertiary500: "#5A7F68",
    tertiary600: "#426650",
    tertiary700: "#2B4E39",
    tertiary800: "#133724",
    tertiary900: "#002111",
    tertiary1000: "#000000",
    error0: "#FFFFFF",
    error10: "#FFFBFF",
    error50: "#FFEDEA",
    error100: "#FFDAD6",
    error200: "#FFB4AB",
    error300: "#FF897D",
    error400: "#FF5449",
    error500: "#DE3730",
    error600: "#BA1A1A",
    error700: "#93000A",
    error800: "#690005",
    error900: "#410002",
    error1000: "#000000",
    neutral0: "#FFFFFF",
    neutral10: "#FDFCF5",
    neutral50: "#F2F1E9",
    neutral100: "#E3E3DB",
    neutral200: "#C7C7C0",
    neutral300: "#ACACA5",
    neutral400: "#91918B",
    neutral500: "#777771",
    neutral600: "#5E5F59",
    neutral700: "#464742",
    neutral800: "#30312C",
    neutral900: "#1B1C18",
    neutral1000: "#000000",
    neutral_variant0: "#FFFFFF",
    neutral_variant10: "#FBFEEE",
    neutral_variant50: "#EFF2E3",
    neutral_variant100: "#E1E4D5",
    neutral_variant200: "#C5C8B9",
    neutral_variant300: "#A9AD9F",
    neutral_variant400: "#8F9285",
    neutral_variant500: "#75796C",
    neutral_variant600: "#5C6054",
    neutral_variant700: "#44483D",
    neutral_variant800: "#2E3228",
    neutral_variant900: "#191D14",
    neutral_variant1000: "#000000",
    accent500: "#f7bc0c",
    // error50: "#fcc4e4",
    // error500: "#9b095c",
    gray500: "#39324a",
    gray700: "#221c30",
  },
  fonts:{
    welcome_page_title: 48,
    welcome_page_subtitle: 24,
    home_title: 32,
    home_button_text: 24,
  },

};
