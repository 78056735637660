import { useContext, useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Pressable,
  Alert,
  Dimensions,
} from "react-native";
import Lightbox from "react-native-lightbox";
import { GlobalStyles } from "../../constant/styles";
import { bitSize } from "../../util/cal";
import { getFormattedDateJp } from "../../util/date";
import Button from "../UI/Button";
import { Ionicons } from "@expo/vector-icons";
// import ImageViewing from "react-native-image-viewing";
import IconButton from "../UI/IconButton";
import DailyReportComment from "./DailyReportComment";
// import { CameraRoll } from "@react-native-camera-roll/camera-roll";
import { UsersContext } from "../../store/user-context";
import { fetchUserInRooms } from "../../util/http";
import { handleWebDownload } from "../../util/web_util";

function DailyReportModal({
  dailyReport,
  dailyreportId,
  troubleReport,
  excitedReport,
  tomorrowTargetReport,
  totalIncome,
  totalWaste,
  wasteAmountTaiyaki,
  wasteAmountDango,
  wasteAmountTakoyaki,
  wasteAmountOkonomiyaki,
  wasteAmountYakisoba,
  dailyreportImage,
  dailyreportDate,
  dailyreportCreatedTimestamp,
  dailyreportUpdatedTimestamp,
  rejiCost,
  weatherStatus,
  userName,
  userImage,
  hadProfileImg,
  hadReportImg,
  // onPress,
  // saveImage,
  needAttentionReport,
  clockInTime,
  clockOutTime,
  curRoomId,
  curRoomName,
  dailyReportRoute,
  // assignedUser,
}) {
  const [imageRealSize, setImageRealSize] = useState();
  const modifiedReport =
    dailyreportUpdatedTimestamp !== "0000-00-00 00:00:00" ? "編集" : "";
  const dailyreportInputTimestamp =
    dailyreportUpdatedTimestamp === "0000-00-00 00:00:00"
      ? dailyreportCreatedTimestamp
      : dailyreportUpdatedTimestamp;
  const a = dailyreportInputTimestamp.split(" ");
  const d = a[0].split("-");
  const t = a[1].split(":");
  const formatedDate = new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2]);
  const dailyreportTimestamp = getFormattedDateJp(formatedDate);
  // console.log("dailyreportId" + dailyreportId);
  // console.log(dailyreportDate);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [images, setImages] = useState("");
  const [visible, setIsVisible] = useState(false);
  const { width, height } = Dimensions.get("window");

  const saveImage = (uri) => {
    // Alert.alert("画像ダウンロード", "画像を保存しますか？", [
    //   {
    //     text: "はい",
    //     onPress: () => {

    const uri_raw = uri.split("/").slice(-1)
    const img_with_modify = uri_raw[0].split("?")
    const img_name_with_type = img_with_modify[0].split("%2F").slice(-1)
    const img_name = img_name_with_type[0].split(".")

    handleWebDownload(uri, img_name[0]+".png");
    // CameraRoll.save(uri);
    //       Alert.alert("画像を保存しました");
    //     },
    //   },
    //   {
    //     text: "いいえ",
    //     onPress: () => console.log("Cancel Pressed"),
    //     style: "cancel",
    //   },
    // ]);
  };

  const onSelect = (images, index) => {
    setImageIndex(index);
    setImages(images);
    setIsVisible(true);
  };

  const longPress = (uri) => {
    CameraRoll.saveToCameraRoll(uri);
  };
  const rejiAll = rejiCost?.split("_");
  const rejiNum = rejiAll.length;
  let inputs = [];
  for (let i = 0; i < rejiNum; i++) {
    inputs.push(
      <View key={i} style={styles.rejiContainer}>
        <View style={styles.rejiInputContainer}>
          <Text style={[styles.textBase, styles.reportDescription]}>
            {i + 1} 回目:
          </Text>
        </View>
        <View style={styles.rejiTextInputContainer}>
          <Text style={[styles.textBase, styles.rejiDescription]}>
            {rejiAll[i]} 円
          </Text>
        </View>
      </View>
    );
  }
  let weatherInputs = [];
  // let weatherName;
  for (
    let weatherType = 0;
    weatherType < bitSize(weatherStatus);
    weatherType++
  ) {
    let weatherName;
    let weatherNameJp;
    if ((weatherStatus & (1 << weatherType)) != 0) {
      // if (weatherStatus >> weatherType && 1) {
      switch (weatherType) {
        case 0:
          weatherName = "sunny";
          weatherNameJp = "晴";
          break;
        case 1:
          weatherName = "cloudy";
          weatherNameJp = "曇";
          break;
        case 2:
          weatherName = "rainy";
          weatherNameJp = "雨";
          break;
        case 3:
          weatherName = "typhoon";
          weatherNameJp = "台風";
          break;
        default:
          break;
      }
      weatherInputs.push(
        <View key={weatherType} style={styles.weatherContainer}>
          <View style={styles.weatherContainerIcon}>
            <Ionicons
              name={weatherName}
              size={24}
              color={GlobalStyles.colors.neutral10}
            />
          </View>
          <View style={styles.weatherContainerText}>
            <Text style={[styles.textBase, styles.weatherText]}>
              {weatherNameJp}
            </Text>
          </View>
        </View>
      );
    }
  }
  return (
    <View style={styles.modalMainContainer}>
      {/* <View style={styles.buttonContainer}>
        <Button
          onPress={() => {}}
          styleButton={styles.editButton}
          styleText={styles.buttonText}
        >
          {"X"}
        </Button>
      </View> */}
      <View style={styles.headerContainer}>
        <View style={styles.profilePictureContainer}>
          <View style={styles.profilePicture}>
            {hadProfileImg ? (
              <Image
                style={styles.profileImg}
                source={userImage ? { uri: `${userImage}` } : null}
                onLoad={({
                  nativeEvent: {
                    source: { width, height },
                  },
                }) => setImageRealSize({ width, height })}
              />
            ) : null}
            {!hadProfileImg && userName ? (
              <Text style={styles.textProfile}>{`${userName
                .charAt(0)
                .toUpperCase()}`}</Text>
            ) : null}
          </View>
        </View>
        <View style={styles.topicContainer}>
          <View style={styles.userInfoContainer}>
            <Text
              style={[styles.textBase, styles.profileName]}
            >{`${userName}`}</Text>

            <Text
              style={styles.textBase}
            >{`${dailyreportTimestamp} ${modifiedReport}`}</Text>
          </View>
          <View style={styles.dateContainer}>
            <Text style={[styles.textBase, styles.reportDate]}>
              {/* dailyreportDate.split("-")[0] */}
              {`${dailyreportDate.split("-")[2]}日`}
            </Text>
            <Text style={[styles.textBase, styles.reportMonthYear]}>
              {`${dailyreportDate.split("-")[1]}月${
                dailyreportDate.split("-")[0]
              }年`}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.bodyContainer}>
        <ScrollView style={styles.bodyContainer}>
          {needAttentionReport && needAttentionReport !== "NO-DATA"? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textAlert, styles.reportAlertTopic]}>
                {"返答・アドバイス・許可がほしいこと"}
              </Text>
              <Text style={[styles.textAlert, styles.reportAlertDescription]}>
                {needAttentionReport}
              </Text>
            </View>
          ) : null}
          {dailyReport && dailyReport !== "NO-DATA" ? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"本日の業務内容："}
              </Text>
              <Text style={[styles.textContextBase, styles.reportDescription]}>
                {dailyReport}
              </Text>
            </View>
          ) : null}
          {troubleReport && troubleReport !== "NO-DATA" ? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"業務での課題や悩み："}
              </Text>
              <Text style={[styles.textContextBase, styles.reportDescription]}>
                {troubleReport}
              </Text>
            </View>
          ) : null}
          {excitedReport && excitedReport !== "NO-DATA" ? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"本日のわくわく："}
              </Text>
              <Text style={[styles.textContextBase, styles.reportDescription]}>
                {excitedReport}
              </Text>
            </View>
          ): null}
          {tomorrowTargetReport && tomorrowTargetReport !== "NO-DATA" ? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"明日の予定："}
              </Text>
              <Text style={[styles.textContextBase, styles.reportDescription]}>
                {tomorrowTargetReport}
              </Text>
            </View>
          ) : null}
          {totalIncome && totalIncome !== "NO-DATA" ? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"今日の計算（円）："}
              </Text>
              <View style={styles.storeContentContainer}>
                <Text
                  style={[
                    styles.textContextBase,
                    styles.reportStoreDescription,
                  ]}
                >
                  {totalIncome}
                </Text>
              </View>
            </View>
          ) : null}
          {((wasteAmountTaiyaki && wasteAmountTaiyaki !== "NO-DATA") ||
          (wasteAmountDango && wasteAmountDango !== "NO-DATA") ||
          (wasteAmountTakoyaki && wasteAmountTakoyaki !== "NO-DATA") ||
          (wasteAmountOkonomiyaki && wasteAmountOkonomiyaki !== "NO-DATA") ||
          (wasteAmountYakisoba && wasteAmountYakisoba !== "NO-DATA")) ? (
            <View style={styles.dailyReportContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"廃棄物"}
              </Text>
            </View>
          ) : null}
          {wasteAmountTaiyaki && wasteAmountTaiyaki !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportSubTopic]}>
                {"たい焼き："}
              </Text>
              <Text
                style={[styles.textContextBase, styles.reportStoreDescription]}
              >
                {" " + wasteAmountTaiyaki + " 個"}
              </Text>
            </View>
          ) : null}
          {wasteAmountDango && wasteAmountDango !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportSubTopic]}>
                {"団子："}
              </Text>
              <Text
                style={[styles.textContextBase, styles.reportStoreDescription]}
              >
                {" " + wasteAmountDango + " 個"}
              </Text>
            </View>
          ) : null}
          {wasteAmountTakoyaki && wasteAmountTakoyaki !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportSubTopic]}>
                {"たこ焼き："}
              </Text>
              <Text
                style={[styles.textContextBase, styles.reportStoreDescription]}
              >
                {" " + wasteAmountTakoyaki + " 個"}
              </Text>
            </View>
          ) : null}
          {wasteAmountOkonomiyaki && wasteAmountOkonomiyaki !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportSubTopic]}>
                {"お好み焼き："}
              </Text>
              <Text
                style={[styles.textContextBase, styles.reportStoreDescription]}
              >
                {" " + wasteAmountOkonomiyaki + " 個"}
              </Text>
            </View>
          ) : null}
          {wasteAmountYakisoba && wasteAmountYakisoba !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportSubTopic]}>
                {"焼きそば："}
              </Text>
              <Text
                style={[styles.textContextBase, styles.reportStoreDescription]}
              >
                {" " + wasteAmountYakisoba + " 個"}
              </Text>
            </View>
          ) : null}
          {totalWaste && totalWaste !== "NO-DATA"? (
            <View style={styles.dailyReportStoreContainer}>
              <Text style={[styles.textBase, styles.reportTopic]}>
                {"廃棄物費用（円）："}
              </Text>
              <View style={styles.storeContentContainer}>
                <Text
                  style={[
                    styles.textContextBase,
                    styles.reportStoreDescription,
                  ]}
                >
                  {totalWaste}
                </Text>
              </View>
            </View>
          ) : null}
          {rejiCost && rejiCost !== "NO-DATA"? (
            <View>
              <View style={styles.dailyReportStoreContainer}>
                <Text style={[styles.textBase, styles.reportTopic]}>
                  {"レジマイナス（回数）："}
                </Text>
                <View style={styles.storeContentContainer}>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {rejiNum + " 回"}
                  </Text>
                </View>
              </View>
              {inputs}
            </View>
          ) : null}
          {clockInTime !== "00:00:00" && clockOutTime !== "00:00:00" ? (
            <View style={styles.reportTimeContainer}>
              {clockInTime ? (
                <View style={styles.reportTimeSubContainer}>
                  <Text style={[styles.textBase, styles.reportTimeTopic]}>
                    {"出勤: "}
                  </Text>
                  <Text style={[styles.textContextBase, styles.reportTime]}>
                    {clockInTime.slice(0, 5)}
                  </Text>
                </View>
              ) : null}
              {clockOutTime ? (
                <View style={styles.reportTimeSubContainer}>
                  <Text style={[styles.textBase, styles.reportTimeTopic]}>
                    {"退勤: "}
                  </Text>
                  <Text style={[styles.textContextBase, styles.reportTime]}>
                    {clockOutTime.slice(0, 5)}
                  </Text>
                </View>
              ) : null}
            </View>
          ) : null}
          {weatherStatus && weatherStatus !== "NO-DATA" && weatherStatus !== "0"? (
            <View>
              <View style={styles.dailyReportStoreContainer}>
                <Text style={[styles.textBase, styles.reportTopic]}>
                  {"今日の天気："}
                </Text>
              </View>
              <View style={styles.weatherContainerOverall}>
                {weatherInputs}
              </View>
            </View>
          ) : null}
          {hadReportImg && hadReportImg !== "NO-DATA" && dailyreportImage !== "NO-DATA"? (
              <View style={styles.imageContainer}>
              <Lightbox   
                springConfig={{ tension: 15, friction: 7 }}
                renderContent={() => (<Image
                                style={{ height: height*0.8,resizeMode: 'contain'}}
                                source={{ uri: dailyreportImage }}/>)}
              >
                <Image
                  style={styles.reportImg}
                  source={dailyreportImage ? { uri: dailyreportImage } : null}
                />
              </Lightbox>
                <View style={styles.downloadButtonContainer}>
                  <IconButton
                    icon="download"
                    color={GlobalStyles.colors.secondary300}
                    size={36}
                    onPress={() => saveImage(`${dailyreportImage}`)}
                  />
                </View>
              </View>
          ) : null}
        </ScrollView>
        <View style={styles.commentContainer}>
          <DailyReportComment
            dailyReportId={dailyreportId}
            // assignedUser={userCtx.users.userId}
            // assignedUser={assignedUser}
            readOnly={true}
            dailyreportDate={dailyreportDate}
            curRoomName={curRoomName}
            dailyReportRoute={dailyReportRoute}
          />
        </View>
      </View>
    </View>
  );
}
export default DailyReportModal;

const styles = StyleSheet.create({
  modalMainContainer: { flex: 1, paddingTop: 10 },
  pressed: {
    opacity: 0.75,
  },
  dailyReportItem: {
    padding: 12,
    marginVertical: 10,
    // overflow: "hidden",
    backgroundColor: GlobalStyles.colors.neutral10,
    // justifyContent: "space-between",
    borderRadius: 12,
    elevation: 3,
    shadowColor: GlobalStyles.colors.gray500,
    shadowRadius: 4,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.4,
    width: "95%",
    // justifyContent: "center",
    alignSelf: "center",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  textContextBase: {
    color: GlobalStyles.colors.neutral600,
  },
  description: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: "bold",
  },

  buttonContainer: {
    position: "absolute",
    top: "5%",
    right: "7%",
    // justifyContent: "end",
    // flexDirection: "row-reverse",
    // backgroundColor: "red",
  },

  headerContainer: {
    flexDirection: "row",
    // marginHorizontal: 10,
    paddingHorizontal: 10,
    flex: 1,
    minWidth: "100%",
  },
  bodyContainer: {
    flex: 8,
    // maxHeight: "100%",
    // height: 300,
    // height: "80%",
    overflow: "hidden",
    paddingHorizontal: 10,
  },
  imageContainer: {
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  profilePictureContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 8,
  },
  topicContainer: {
    marginLeft: 4,
    flex: 8,
    flexDirection: "row",
  },
  userInfoContainer: {
    marginLeft: 4,
    flex: 8,
  },
  dateContainer: {
    marginLeft: 4,
    flex: 4,
  },
  profilePicture: {
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 18,
  },
  profileName: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: "bold",
    color: GlobalStyles.colors.secondary700,
  },
  reportDate: {
    fontSize: 28,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "center",
  },
  reportMonthYear: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "center",
  },
  profileImg: {
    width: 50,
    height: 50,
    borderRadius: 1000,
  },
  reportImg: { marginVertical: 18, width: 300, height: 300 },
  dailyReportContainer: {},
  dailyReportStoreContainer: {
    flexDirection: "row",
  },
  storeContentContainer: {
    // alignContent: "center",
    alignSelf: "center",
    marginTop: 2,
    // alignItems: "center",
    // justifyContent: "center",
  },
  reportTopic: {
    marginTop: 10,
    marginBottom: 6,
    fontSize: 16,
    fontWeight: "bold",
  },
  reportSubTopic: {
    fontSize: 16,
    marginLeft: "10%",
  },
  reportDescription: {
    fontSize: 16,
    paddingLeft: 20,
  },
  flatText: { color: GlobalStyles.colors.primary600 },

  reportStoreDescription: { fontSize: 16, paddingLeft: 2 },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    marginTop: "30%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitleContainer: {
    // backgroundColor: GlobalStyles.colors.neutral200,
    marginTop: 24,
    alignContent: "center",
  },
  detailContent: {
    // backgroundColor: GlobalStyles.colors.neutral50,
  },
  editButton: {
    paddingHorizontal: 12,
    marginHorizontal: 6,
    borderRadius: 200,
    backgroundColor: GlobalStyles.colors.neutral100,
  },
  buttonText: {
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  rejiContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
  },
  rejiInputContainer: { flex: 2 },
  rejiTextInputContainer: { flex: 7 },
  rejiDescription: {
    fontSize: 16,
    paddingLeft: 10,
  },
  weatherContainerOverall: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  weatherContainer: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.primary600,
    borderRadius: 8,
    // justifyContent: "space-between",
    // borderColor: GlobalStyles.colors.primary1000,
  },
  weatherContainerIcon: {},
  weatherContainerText: {},
  weatherText: {
    fontSize: 16,
    marginTop: 8,
    fontWeight: "bold",
    alignSelf: "center",
    color: GlobalStyles.colors.neutral10,
  },
  downloadButtonContainer: {
    position: "absolute",
    bottom: "5%",
    zIndex: 5,
  },
  textAlert: {
    color: GlobalStyles.colors.error400,
  },
  reportAlertTopic: {
    marginTop: 10,
    marginBottom: 6,
    fontSize: 20,
    fontWeight: "bold",
  },
  reportAlertDescription: {
    fontSize: 20,
    paddingLeft: 20,
  },
  reportTimeContainer: {
    borderTopColor: GlobalStyles.colors.primary600,
    borderTopWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
    marginTop: 12,
    paddingTop: 10,
  },
  reportTimeSubContainer: {
    flex: 1,
    flexDirection: "row",
  },
  reportTimeTopic: {
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
    justifyContent: "flex-start",
  },
  reportTime: { flex: 2, fontSize: 16 },
  commentContainer: {
    // flex: 1,
    height: 250,
    borderTopColor: GlobalStyles.colors.primary600,
    borderTopWidth: 0.4,
  },
});
