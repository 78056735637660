import React, { useState, useContext, useEffect } from "react";
import { View, Text, Modal, TouchableOpacity, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons"; // Ensure Ionicons is installed using `expo install @expo/vector-icons`
import { logoutUserAuth } from "../../util/http";
import IconButton from "../UI/IconButton";
import { GlobalStyles } from "../../constant/styles";
import ProfileEditForm from "./ProfileEditForm";
import { getscaledDimension } from "../../util/deviceUiInfo";

function CustomMenu({
  isAdmin,
  navigation,
  authCtx,
  IconSize,
  onSubmitMenu,
  defaultValues,
  userRole,
}) {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(fontScale * 0.7); // pass in fontScale to the StyleSheet
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const logoutUser = () => {
    logoutUserAuth(authCtx); // Assuming logoutUserAuth is defined elsewhere
    authCtx.logout(); // Assuming there's a logout method in authCtx
    closeMenu();
  };
  function profileEditSubmitHandler(userData) {
    closeMenu();
    closeModal();
    onSubmitMenu(userData);
  }

  return (
    <View style={styles.optionMenuContainer}>
      {/* <TouchableOpacity onPress={openMenu} style={styles.buttonBox}>
        <Ionicons name="options-outline" size={buttonFontSize} color="#4a90e2" />
        
      </TouchableOpacity> */}
      <IconButton
        icon="options-outline"
        size={IconSize}
        color={GlobalStyles.colors.primary600}
        onPress={openMenu}
        styleButtonContainer={styles.buttonBox}
      />
      <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={closeMenu}
      >
        <TouchableOpacity style={styles.menuBackdrop} onPress={closeMenu}>
          <View style={styles.menuContent}>
            <TouchableOpacity
              style={styles.menuItem}
              onPress={() => {
                closeMenu();
                openModal(); /* Navigate or toggle state */
              }}
            >
              <Text style={styles.menuText}>プロファイル編集</Text>
            </TouchableOpacity>
            <View style={styles.divider} />
            {isAdmin && (
              <>
                <TouchableOpacity
                  style={styles.menuItem}
                  onPress={() => {
                    closeMenu();
                    navigation.navigate("ManageUser");
                  }}
                >
                  <Text style={styles.menuText}>ユーザー管理</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.menuItem}
                  onPress={() => {
                    closeMenu();
                    navigation.navigate("Signup");
                  }}
                >
                  <Text style={styles.menuText}>ユーザー登録</Text>
                </TouchableOpacity>
                <View style={styles.divider} />
              </>
            )}
            <TouchableOpacity style={styles.menuItem} onPress={logoutUser}>
              <Text style={styles.menuText}>ログアウト</Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </Modal>
      <Modal visible={modalVisible} animationType="slide">
        {/* <TouchableOpacity style={styles.menuBackdrop} onPress={closeModal}> */}
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTitleContainer}>
              <Text style={styles.titleText}>ユーザー情報編集</Text>
            </View>
            <View style={styles.userEditContent}>
              <ProfileEditForm
                onSubmit={profileEditSubmitHandler}
                onCancel={closeModal}
                defaultValues={defaultValues}
                userRole={userRole}
              />
            </View>
          </View>
        </View>
        {/* </TouchableOpacity> */}
      </Modal>
    </View>
  );
}

const makeStyles = (hieghtScale, fontScale) =>
  StyleSheet.create({
    optionMenuContainer: {
      position: "relative",
    },
    buttonBox: {
      padding: 10,
    },
    menuBackdrop: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    menuContent: {
      backgroundColor: "#fff",
      width: 200,
      borderRadius: 5,
      padding: 10,
    },
    menuItem: {
      paddingVertical: 10,
      paddingHorizontal: 15,
    },
    menuText: {
      color: GlobalStyles.colors.primary600,
      fontSize: 16,
    },
    divider: {
      height: 1,
      backgroundColor: "#ccc",
      marginVertical: 5,
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      zIndex: 1000,
    },
    titleText: {
      fontSize: 32 * fontScale,
      fontWeight: 800,
      color: GlobalStyles.colors.primary600,
    },
  });

export default CustomMenu;
