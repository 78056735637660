import {
  Pressable,
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Modal,
  Alert,
} from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getFormattedDate, getFormattedDateJp } from "../../util/date";
import { useNavigation } from "@react-navigation/native";
import Button from "../UI/Button";
import { useContext, useEffect, useState } from "react";
// import { AuthContext } from "../../store/auth-context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DailyReportModal from "./DailyReportModalDetail";
import { bitSize } from "../../util/cal";
import { Ionicons } from "@expo/vector-icons";
// import ImageViewing from "react-native-image-viewing";
// import { CameraRoll } from "@react-native-camera-roll/camera-roll";
import IconButton from "../UI/IconButton";
import { handleWebDownload } from "../../util/web_util";
import Lightbox from "react-native-lightbox";

function DailyReportItem({
  id,
  dailyreportId,
  dailyreportRoomId,
  userId,
  dailyreportType,
  dailyReport,
  troubleReport,
  excitedReport,
  tomorrowTargetReport,
  totalIncome,
  totalWaste,
  wasteAmountTaiyaki,
  wasteAmountDango,
  wasteAmountTakoyaki,
  wasteAmountOkonomiyaki,
  wasteAmountYakisoba,
  dailyreportImage,
  dailyreportDate,
  dailyreportCreatedTimestamp,
  dailyreportUpdatedTimestamp,
  rejiCost,
  weatherStatus,
  userName,
  userImage,
  needAttentionReport,
  clockInTime,
  clockOutTime,
  commentNums,
  roomName,
  isFirstUnread = false,
}) {
  const [UID, setUID] = useState();
  // const [visible, setIsVisible] = useState(false);
  const { width, height } = Dimensions.get("window");
  const [imageRealSize, setImageRealSize] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [currentImageIndex, setImageIndex] = useState(0);
  const [images, setImages] = useState("");
  const [visible, setIsVisible] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const onSelect = (images, index) => {
    setImageIndex(index);
    setImages(images);
    setIsVisible(true);
  };

  useEffect(() => {
    async function fetchRole() {
      const uid = await AsyncStorage.getItem("uid");
      setUID(uid);
    }
    fetchRole();
  }, []);

  const navigation = useNavigation();
  const isOwned = userId == UID ? true : false;
  const hadProfileImg = userImage ? true : false;
  const hadReportImg = dailyreportImage ? true : false;
  const modifiedReport =
    dailyreportUpdatedTimestamp !== "0000-00-00 00:00:00" ? "編集" : "";
  const dailyreportInputTimestamp =
    dailyreportUpdatedTimestamp !== "0000-00-00 00:00:00"
      ? dailyreportCreatedTimestamp
      : dailyreportCreatedTimestamp;
  // console.log(dailyreportInputTimestamp);
  const a = dailyreportInputTimestamp.split(" ");
  const d = a[0].split("-");
  const t = a[1].split(":");
  const formatedDate = new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2]);
  const dailyreportTimestamp = getFormattedDateJp(formatedDate);
  function dailyReportEditPressHandler() {
    navigation.navigate("DailyReportFormStack", {
      screen: "ManageDailyReportFormOpen",
      params: {
        dailyReportId: dailyreportId,
        titleName: "日報レポート編集",
        dailyReportRoomId: dailyreportRoomId,
      },
    });
  }
  function dailyReportPressHandler() {
    // navigation.navigate("ManageDailyReport", { dailyReportId: id });
  }
  function dailyReportDetailPressHandler() {
    // setModalVisible(!modalVisible);
    // setShowDetail(!showDetail);
    navigation.navigate("DailyReportDetailModalScreen", {
      dailyReportId: dailyreportId,
      titleName: "日報レポート",
      dailyReportRoomId: dailyreportRoomId,
      needAttentionReport: needAttentionReport,
      dailyReport: dailyReport,
      dailyreportId: dailyreportId,
      troubleReport: troubleReport,
      excitedReport: excitedReport,
      tomorrowTargetReport: tomorrowTargetReport,
      totalIncome: totalIncome,
      totalWaste: totalWaste,
      wasteAmountTaiyaki: wasteAmountTaiyaki,
      wasteAmountDango: wasteAmountDango,
      wasteAmountTakoyaki: wasteAmountTakoyaki,
      wasteAmountOkonomiyaki: wasteAmountOkonomiyaki,
      wasteAmountYakisoba: wasteAmountYakisoba,
      dailyreportImage: dailyreportImage,
      dailyreportDate: dailyreportDate_edited,
      dailyreportCreatedTimestamp: dailyreportCreatedTimestamp,
      dailyreportUpdatedTimestamp: dailyreportUpdatedTimestamp,
      userName: userName,
      userImage: userImage,
      hadProfileImg: hadProfileImg,
      hadReportImg: hadReportImg,
      rejiCost: rejiCost,
      weatherStatus: weatherStatus,
      clockInTime: clockInTime,
      clockOutTime: clockOutTime,
      curRoomId: dailyreportRoomId,
      curRoomName: roomName,
    });
  }

  const saveImage = (uri) => {
    // Alert.alert("画像ダウンロード", "画像を保存しますか？", [
    //   {
    //     text: "はい",
    //     onPress: () => {
    const uri_raw = uri.split("/").slice(-1)
    const img_with_modify = uri_raw[0].split("?")
    const img_name_with_type = img_with_modify[0].split("%2F").slice(-1)
    const img_name = img_name_with_type[0].split(".")

    handleWebDownload(uri, img_name[0]+".png");
    // CameraRoll.save(uri);
    //       Alert.alert("画像を保存しました");
    //     },
    //   },
    //   {
    //     text: "いいえ",
    //     onPress: () => console.log("Cancel Pressed"),
    //     style: "cancel",
    //   },
    // ]);
  };

  let dailyreportDate_edited;
  if (typeof dailyreportDate === "string") {
    dailyreportDate_edited = dailyreportDate;
  } else {
    const dates = new Date(dailyreportDate);
    dailyreportDate_edited = dates.toISOString().slice(0, 10);
  }

  const rejiAll = rejiCost?.split("_");
  const rejiNum = rejiAll.length;
  let inputs = [];
  for (let i = 0; i < rejiNum; i++) {
    inputs.push(
      <View key={i} style={styles.rejiContainer}>
        <View style={styles.rejiInputContainer}>
          <Text style={[styles.textBase, styles.reportDescription]}>
            {i + 1} 回目:
          </Text>
        </View>
        <View style={styles.rejiTextInputContainer}>
          <Text style={[styles.textBase, styles.rejiDescription]}>
            {rejiAll[i]} 円
          </Text>
        </View>
      </View>
    );
  }
  let weatherInputs = [];
  for (
    let weatherType = 0;
    weatherType < bitSize(weatherStatus);
    weatherType++
  ) {
    let weatherName;
    let weatherNameJp;
    // console.log("weatherType----> " + weatherType);
    // console.log("weatherStatus==== " + weatherStatus);
    // console.log("bit +++++ " + ((weatherStatus & (1 << weatherType)) != 0));

    if ((weatherStatus & (1 << weatherType)) != 0) {
      switch (weatherType) {
        case 0:
          weatherName = "sunny";
          weatherNameJp = "晴";
          break;
        case 1:
          weatherName = "cloudy";
          weatherNameJp = "曇";
          break;
        case 2:
          weatherName = "rainy";
          weatherNameJp = "雨";
          break;
        case 3:
          weatherName = "typhoon";
          weatherNameJp = "台風";
          break;
        default:
          break;
      }
      weatherInputs.push(
        <View key={weatherType} style={styles.weatherContainer}>
          <View style={styles.weatherContainerIcon}>
            <Ionicons
              name={weatherName}
              size={24}
              color={GlobalStyles.colors.neutral10}
            />
          </View>
          <View style={styles.weatherContainerText}>
            <Text style={[styles.textBase, styles.weatherText]}>
              {weatherNameJp}
            </Text>
          </View>
        </View>
      );
    }
  }

const renderLightBox = (uri) => (
  <View style={{ width: width, height: width }}>
    <Image
      style={{ flex: 1 }}
      resizeMode="contain"
      source={{ uri: uri }}
    />
  </View>
)
  return (
    // <Pressable
    //   onPress={dailyReportPressHandler}
    //   style={({ pressed }) => pressed && styles.pressed}
    // >
    <View style={{ flex: 1 }}>
      {isFirstUnread && (
        <View style={styles.bookmark}>
          <Text style={styles.bookmarkText}>ここから未読メッセージ</Text>
        </View>
      )}
      <View style={styles.dailyReportItem}>
        <View>
          <View>
            <View style={styles.headerContainer}>
              <View style={styles.profilePictureContainer}>
                <View style={styles.profilePicture}>
                  {hadProfileImg ? (
                    <Image
                      style={styles.profileImg}
                      source={userImage ? { uri: `${userImage}` } : null}
                      onLoad={({
                        nativeEvent: {
                          source: { width, height },
                        },
                      }) => setImageRealSize({ width, height })}
                    />
                  ) : null}
                  {!hadProfileImg && userName ? (
                    <Text style={styles.textProfile}>{`${userName
                      .charAt(0)
                      .toUpperCase()}`}</Text>
                  ) : null}
                </View>
              </View>
              <View style={styles.topicContainer}>
                <View style={styles.userInfoContainer}>
                  <Text
                    style={[styles.textBase, styles.profileName]}
                  >{`${userName}`}</Text>
                  <Text style={styles.textBase}>{`${dailyreportTimestamp}`}</Text>
                  {/* <Text
                  style={styles.textBase}
                >{`${dailyreportTimestamp} ${modifiedReport}`}</Text> */}
                </View>
                <View style={styles.dateContainer}>
                  <Text style={[styles.textBase, styles.reportDate]}>
                    {/* dailyreportDate.split("-")[0] */}
                    {`${dailyreportDate_edited.split("-")[2]}日`}
                  </Text>
                  <Text style={[styles.textBase, styles.reportMonthYear]}>
                    {`${dailyreportDate_edited.split("-")[1]}月${
                      dailyreportDate_edited.split("-")[0]
                    }年`}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.bodyContainer}>
              {needAttentionReport ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textAlert, styles.reportAlertTopic]}>
                    {"返答・アドバイス・許可がほしいこと"}
                  </Text>
                  <Text style={[styles.textAlert, styles.reportAlertDescription]}>
                    {needAttentionReport}
                  </Text>
                </View>
              ) : null}
              {dailyReport ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"本日の業務内容："}
                  </Text>
                  <Text
                    style={[styles.textContextBase, styles.reportDescription]}
                  >
                    {dailyReport}
                  </Text>
                </View>
              ) : null}
              {troubleReport ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"業務での課題や悩み："}
                  </Text>
                  <Text
                    style={[styles.textContextBase, styles.reportDescription]}
                  >
                    {troubleReport}
                  </Text>
                </View>
              ) : null}
              {excitedReport ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"本日のわくわく："}
                  </Text>
                  <Text
                    style={[styles.textContextBase, styles.reportDescription]}
                  >
                    {excitedReport}
                  </Text>
                </View>
              ) : null}
              {tomorrowTargetReport ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"明日の予定："}
                  </Text>
                  <Text
                    style={[styles.textContextBase, styles.reportDescription]}
                  >
                    {tomorrowTargetReport}
                  </Text>
                </View>
              ) : null}
              {totalIncome ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"本日の売り上げ（円）："}
                  </Text>
                  <View style={styles.storeContentContainer}>
                    <Text
                      style={[
                        styles.textContextBase,
                        styles.reportStoreDescription,
                      ]}
                    >
                      {totalIncome}
                    </Text>
                  </View>
                </View>
              ) : null}
              {(wasteAmountTaiyaki && wasteAmountTaiyaki !== 0) ||
              (wasteAmountDango && wasteAmountDango !== 0) ||
              (wasteAmountTakoyaki && wasteAmountTakoyaki !== 0) ||
              (wasteAmountOkonomiyaki && wasteAmountOkonomiyaki !== 0) ||
              (wasteAmountYakisoba && wasteAmountYakisoba !== 0) ? (
                <View style={styles.dailyReportContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"廃棄物"}
                  </Text>
                </View>
              ) : null}
              {wasteAmountTaiyaki && wasteAmountTaiyaki !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportSubTopic]}>
                    {"たい焼き："}
                  </Text>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {" " + wasteAmountTaiyaki + " 個"}
                  </Text>
                </View>
              ) : null}
              {wasteAmountDango && wasteAmountDango !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportSubTopic]}>
                    {"団子："}
                  </Text>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {" " + wasteAmountDango + " 個"}
                  </Text>
                </View>
              ) : null}
              {wasteAmountTakoyaki && wasteAmountTakoyaki !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportSubTopic]}>
                    {"たこ焼き："}
                  </Text>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {" " + wasteAmountTakoyaki + " 個"}
                  </Text>
                </View>
              ) : null}
              {wasteAmountOkonomiyaki && wasteAmountOkonomiyaki !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportSubTopic]}>
                    {"お好み焼き："}
                  </Text>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {" " + wasteAmountOkonomiyaki + " 個"}
                  </Text>
                </View>
              ) : null}
              {wasteAmountYakisoba && wasteAmountYakisoba !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportSubTopic]}>
                    {"焼きそば："}
                  </Text>
                  <Text
                    style={[
                      styles.textContextBase,
                      styles.reportStoreDescription,
                    ]}
                  >
                    {" " + wasteAmountYakisoba + " 個"}
                  </Text>
                </View>
              ) : null}
              {totalWaste && totalWaste !== 0 ? (
                <View style={styles.dailyReportStoreContainer}>
                  <Text style={[styles.textBase, styles.reportTopic]}>
                    {"廃棄物費用（円）："}
                  </Text>
                  <View style={styles.storeContentContainer}>
                    <Text
                      style={[
                        styles.textContextBase,
                        styles.reportStoreDescription,
                      ]}
                    >
                      {totalWaste}
                    </Text>
                  </View>
                </View>
              ) : null}
              {rejiCost ? (
                <View>
                  <View style={styles.dailyReportStoreContainer}>
                    <Text style={[styles.textBase, styles.reportTopic]}>
                      {"レジマイナス（回数）："}
                    </Text>
                    <View style={styles.storeContentContainer}>
                      <Text
                        style={[
                          styles.textContextBase,
                          styles.reportStoreDescription,
                        ]}
                      >
                        {rejiNum + " 回"}
                      </Text>
                    </View>
                  </View>
                  {inputs}
                </View>
              ) : null}
              {clockInTime !== "00:00:00" && clockOutTime !== "00:00:00" ? (
                <View style={styles.reportTimeContainer}>
                  {clockInTime ? (
                    <View style={styles.reportTimeSubContainer}>
                      <Text style={[styles.textBase, styles.reportTimeTopic]}>
                        {"出勤: "}
                      </Text>
                      <Text style={[styles.textContextBase, styles.reportTime]}>
                        {clockInTime.slice(0, 5)}
                      </Text>
                    </View>
                  ) : null}
                  {clockOutTime ? (
                    <View style={styles.reportTimeSubContainer}>
                      <Text style={[styles.textBase, styles.reportTimeTopic]}>
                        {"退勤: "}
                      </Text>
                      <Text style={[styles.textContextBase, styles.reportTime]}>
                        {clockOutTime.slice(0, 5)}
                      </Text>
                    </View>
                  ) : null}
                </View>
              ) : null}
              {weatherStatus ? (
                <View>
                  <View style={styles.dailyReportStoreContainer}>
                    <Text style={[styles.textBase, styles.reportTopic]}>
                      {"今日の天気："}
                    </Text>
                  </View>
                  <View style={styles.weatherContainerOverall}>
                    {weatherInputs}
                  </View>
                </View>
              ) : null}
              {hadReportImg ? (
                <View style={styles.imageContainer}>
                <Lightbox   
                  springConfig={{ tension: 15, friction: 7 }}
                  renderContent={() => (<Image
                                  style={{ height: height*0.8,resizeMode: 'contain'}}
                                  source={{ uri: dailyreportImage }}/>)}
                >
                  <Image
                    style={styles.reportImg}
                    source={dailyreportImage ? { uri: dailyreportImage } : null}
                  />
                </Lightbox>
                  <View style={styles.downloadButtonContainer}>
                    <IconButton
                      icon="download"
                      color={GlobalStyles.colors.secondary300}
                      size={36}
                      onPress={() => saveImage(`${dailyreportImage}`)}
                    />
                  </View>
                </View>
                
              ) : null}
            </View>
          </View>

          <View style={styles.footerContainer}>
            {commentNums ? (
              <View style={styles.commentNumContainer}>
                <Ionicons
                  name={"chatbox"}
                  size={20}
                  color={GlobalStyles.colors.secondary200}
                />
                <Text style={styles.commentNumText}>{commentNums}件</Text>
              </View>
            ) : null}
            <View style={styles.buttonContainer}>
              <Button
                onPress={dailyReportDetailPressHandler}
                styleButton={styles.editButton}
              >
                {"詳細"}
              </Button>
              {isOwned && (
                <Button
                  onPress={dailyReportEditPressHandler}
                  styleButton={styles.editButton}
                >
                  {"編集"}
                </Button>
              )}
            </View>
          </View>
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* <View style={styles.modalTitleContainer}>
              <Text style={styles.titleText}></Text>
            </View> */}
              <View style={styles.detailContent}>
                {/* <ProfileEditForm
                onSubmit={profileEditSubmitHandler}
                onCancel={profileEditCancelHandler}
                defaultValues={userData}
                userRole={userRole}
              /> */}
                <DailyReportModal
                  needAttentionReport={needAttentionReport}
                  dailyReport={dailyReport}
                  dailyreportId={dailyreportId}
                  troubleReport={troubleReport}
                  excitedReport={excitedReport}
                  tomorrowTargetReport={tomorrowTargetReport}
                  totalIncome={totalIncome}
                  totalWaste={totalWaste}
                  wasteAmountTaiyaki={wasteAmountTaiyaki}
                  wasteAmountDango={wasteAmountDango}
                  wasteAmountTakoyaki={wasteAmountTakoyaki}
                  wasteAmountOkonomiyaki={wasteAmountOkonomiyaki}
                  wasteAmountYakisoba={wasteAmountYakisoba}
                  dailyreportImage={dailyreportImage}
                  dailyreportDate={dailyreportDate_edited}
                  dailyreportCreatedTimestamp={dailyreportCreatedTimestamp}
                  dailyreportUpdatedTimestamp={dailyreportUpdatedTimestamp}
                  userName={userName}
                  userImage={userImage}
                  hadProfileImg={hadProfileImg}
                  hadReportImg={hadReportImg}
                  rejiCost={rejiCost}
                  weatherStatus={weatherStatus}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}
                  saveImage={saveImage}
                  clockInTime={clockInTime}
                  clockOutTime={clockOutTime}
                  curRoomId={dailyreportRoomId}
                  curRoomName={roomName}
                />
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
}

export default DailyReportItem;

const styles = StyleSheet.create({
  pressed: {
    opacity: 0.75,
  },
  dailyReportItem: {
    padding: 12,
    marginVertical: 12,
    // overflow: "hidden",
    backgroundColor: GlobalStyles.colors.neutral10,
    // justifyContent: "space-between",
    borderRadius: 12,
    elevation: 3,
    shadowColor: GlobalStyles.colors.gray500,
    shadowRadius: 4,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.4,
    width: "95%",
    // justifyContent: "center",
    alignSelf: "center",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  textContextBase: {
    color: GlobalStyles.colors.neutral600,
  },
  textAlert: {
    color: GlobalStyles.colors.error400,
  },
  description: {
    fontSize: 16,
    marginBottom: 4,
    fontWeight: "bold",
  },

  buttonContainer: {
    marginTop: 12,
    // justifyContent: "end",
    flexDirection: "row-reverse",
    flex: 12,
  },

  headerContainer: {
    flexDirection: "row",
    marginHorizontal: 2,
    flex: 1,
    justifyContent: "center",
    minHeight: 50,
    maxHeight: 50,
  },
  bodyContainer: {
    flex: 8,
    maxheight: 400,
    overflow: "hidden",
  },
  imageContainer: {
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    flex:1
    // marginVerticle:18
  },
  profilePictureContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 8,
  },
  topicContainer: {
    marginLeft: 4,
    flex: 8,
    flexDirection: "row",
  },
  userInfoContainer: {
    marginLeft: 4,
    flex: 8,
  },
  dateContainer: {
    marginLeft: 4,
    flex: 4,
    paddingRight: "2%",
  },
  profilePicture: {
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 18,
  },
  profileName: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: "bold",
    color: GlobalStyles.colors.secondary700,
  },
  reportDate: {
    fontSize: 28,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "end",
  },
  reportMonthYear: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "end",
  },
  profileImg: {
    width: 50,
    height: 50,
    borderRadius: 1000,
  },
  reportImg: {width:300,height: 300, resizeMode: 'contain', },
  dailyReportContainer: {},
  dailyReportStoreContainer: {
    flexDirection: "row",
  },
  storeContentContainer: {
    alignSelf: "center",
    marginTop: 2,
  },
  reportTopic: {
    marginTop: 10,
    marginBottom: 6,
    fontSize: 16,
    fontWeight: "bold",
  },
  reportAlertTopic: {
    marginTop: 10,
    marginBottom: 6,
    fontSize: 20,
    fontWeight: "bold",
  },
  reportAlertDescription: {
    fontSize: 20,
    paddingLeft: 20,
  },
  reportSubTopic: {
    fontSize: 16,
    marginLeft: "10%",
  },
  reportDescription: {
    fontSize: 16,
    paddingLeft: 20,
  },
  flatText: { color: GlobalStyles.colors.primary600 },
  editButton: { paddingHorizontal: 18, marginHorizontal: 6 },
  reportStoreDescription: { fontSize: 16, paddingLeft: 2 },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    marginTop: "10%",
    marginBottom: "5%",
    backgroundColor: GlobalStyles.colors.neutral10,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    width: "90%",
    borderStyle: "solid",
    elevation: 20,
    padding: 10,
  },
  modalTitleContainer: {
    marginTop: 24,
    alignContent: "center",
  },
  detailContent: {
    marginTop: 12,
  },
  rejiContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
  },
  rejiInputContainer: { flex: 2 },
  rejiTextInputContainer: { flex: 7 },
  rejiDescription: {
    fontSize: 16,
    paddingLeft: 10,
  },
  weatherContainerOverall: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  weatherContainer: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.primary600,
    borderRadius: 8,
  },
  weatherContainerIcon: {},
  weatherContainerText: {},
  weatherText: {
    fontSize: 16,
    marginTop: 8,
    fontWeight: "bold",
    alignSelf: "center",
    color: GlobalStyles.colors.neutral10,
  },
  downloadButtonContainer: {
    position: "absolute",
    bottom: "5%",
    zIndex: 5,
  },
  reportTimeContainer: {
    borderTopColor: GlobalStyles.colors.primary600,
    borderTopWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
    marginTop: 12,
    paddingTop: 10,
  },
  reportTimeSubContainer: {
    flex: 1,
    flexDirection: "row",
  },
  reportTimeTopic: {
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
    justifyContent: "flex-start",
  },
  reportTime: { flex: 2, fontSize: 16 },
  bookmark: {
    alignSelf: "center",
    borderRadius: 20,
    // backgroundColor: GlobalStyles.colors.neutral10,
    backgroundColor: "rgba(253, 252, 245, 0.9)",
    marginVertical: 5,
    paddingVertical: 2,
    // paddingHorizontal:40,
    width: "80%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 10,
  },
  bookmarkText: {
    alignSelf: "center",
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
  },
  commentNumContainer: {
    // justifyContent: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
    // alignContent: "center",
    marginLeft: 8,
    flexDirection: "row",
    flex: 5,
  },
  footerContainer: {
    flexDirection: "row",
  },
  commentNumText: {
    fontSize: 16,
    // color: GlobalStyles.colors.primary600,
    marginLeft: 5,
    color: GlobalStyles.colors.neutral600,
  },
});
