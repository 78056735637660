import { Pressable, View, Text, StyleSheet, Image } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import Checkbox from "expo-checkbox";
import { Ionicons } from "@expo/vector-icons";

function InputCheckBox({ weatherStatus, onCheck, defaultWeather }) {
  // const [weatherType, setWeatherType] = useState();
  // const [weatherName, setWeatherName] = useState();
  let weatherType;
  let weatherName;
  switch (weatherStatus) {
    case "sunny":
      weatherType = 0; // 0001
      weatherName = "晴";
      break;
    case "cloudy":
      weatherType = 1; // 0010
      weatherName = "曇";
      // weatherType = 2; // 0010
      // weatherName = "曇";
      break;
    case "rainy":
      weatherType = 2; // 0100
      weatherName = "雨";
      // weatherType = 4; // 0100
      // weatherName = "雨";
      break;
    case "typhoon":
      weatherType = 3; // 1000
      // weatherType = 8;
      break;
    default:
      break;
  }

  const weatherConst = weatherType; // 0 0 0 0 0 晴れ　曇り　雨　台風　非常
  const [inputs, setInput] = useState({
    selectedWeatherStatus: weatherStatus,
    isSelected: defaultWeather
      ? (defaultWeather >> weatherType) & 1
        ? true
        : false
      : false,
  });

  function checkBoxPressHandler() {
    setInput((currentState) => {
      return { ...currentState, isSelected: !inputs.isSelected };
    }); //necessary to create a new object because else the hook won't be updated

    const weatherData = {
      weatherValue: weatherType,
      isSelected: !inputs.isSelected,
    };
    onCheck(weatherData);
  }

  return (
    <Pressable
      onPress={checkBoxPressHandler}
      style={({ pressed }) => pressed && styles.pressed}
    >
      <View style={styles.weatherListItem}>
        <View style={styles.iconContainer}>
          <Ionicons
            name={weatherStatus}
            size={30}
            color={GlobalStyles.colors.primary600}
          />
        </View>
        <View style={styles.weatherNameContainer}>
          <Text style={[styles.textBase, styles.description]}>
            {weatherName}
          </Text>
        </View>
        <View style={styles.weatherCheckBoxContainer}>
          <Checkbox
            value={inputs.isSelected}
            onValueChange={checkBoxPressHandler}
            style={styles.checkbox}
            color={GlobalStyles.colors.primary600}
          />
        </View>
      </View>
    </Pressable>
  );
}

export default InputCheckBox;

const styles = StyleSheet.create({
  pressed: {
    opacity: 0.7,
  },
  weatherListItem: {
    paddingVertical: 10,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.neutral0,
    justifyContent: "space-between",
    borderBottomWidth: 0.25,
    borderTopWidth: 0.25,
    borderColor: GlobalStyles.colors.primary1000,
    // alignItems: "center",

    // flexDirection: "row",
    borderRadius: 12,
  },
  textBase: {
    // marginLeft: 6,
    marginVertical: 4,
    color: GlobalStyles.colors.primary600,
  },
  description: {
    fontSize: 18,
    marginBottom: 4,
    alignSelf: "center",
    fontWeight: "bold",
  },

  checkbox: {
    alignSelf: "center",
    borderRadius: 6,
  },
  weatherNameContainer: {
    // marginLeft: 16,
    // flex: 8,
    alignSelf: "center",
  },
  weatherCheckBoxContainer: {
    // marginLeft: 16,
    // flex: 8,
    alignSelf: "center",
  },
  iconContainer: {
    // marginLeft: 16,
    // flex: 8,
    alignSelf: "center",
  },
});
