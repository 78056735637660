import { Pressable, View, StyleSheet, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { GlobalStyles } from "../../constant/styles";
function IconButton({
  icon,
  size,
  color,
  onPress,
  label,
  styleButtonContainer,
  styleText,
  addStyleContainer,
  disabled =false
}) {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => pressed && styles.pressed}
      disabled={disabled}
    >
      <View style={[styles.container, addStyleContainer]}>
        <View style={[styles.buttonContainer, styleButtonContainer]}>
          <Ionicons name={icon} size={size} color={color} />
        </View>
        {label && <Text style={[styles.textBase, styleText]}>{label}</Text>}
      </View>
    </Pressable>
  );
}

export default IconButton;

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  buttonContainer: {
    padding: 6,
    marginHorizontal: 12,
    marginVertical: 2,
  },
  pressed: {
    opacity: 0.25,
  },
  textBase: { color: GlobalStyles.colors.primary600, fontSize: 18 },
});
