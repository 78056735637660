import { Pressable, Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";

function MenuButton({
  mode,
  children,
  onPress,
  style,
  styleButton,
  styleButtonText,
  disabled = false,
  noticeNum,
}) {
  return (
    <View style={style}>
      <Pressable
        disabled={disabled}
        onPress={onPress}
        style={({ pressed }) => pressed && styles.pressed}
      >
        <View
          style={[styles.button, mode === "flat" && styles.flat, styleButton]}
        >
          <Text
            style={[
              styles.buttonText,
              mode === "flat" && styles.flatText,
              styleButtonText,
            ]}
          >
            {children}
          </Text>
        </View>
      </Pressable>
      {(noticeNum > 0) ? (
              <View style={styles.notificationReportBadge}>
                <Text style={styles.textReport}>{noticeNum}</Text>
              </View>
            ):null}
    </View>
  );
}
export default MenuButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 14,
    padding: 2,
    backgroundColor: GlobalStyles.colors.primary600,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  flat: {
    backgroundColor: "transparent",
  },
  buttonText: {
    color: "white",
    textAlign: "center",
  },
  flatText: {
    color: GlobalStyles.colors.primary200,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 14,
  },
  notificationReportBadge: {
    position: "absolute",
    right: "5%",
    top: "7%",
    width: 40,
    height: 40,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    marginRight: 4,
    zIndex: 100,
    // shadowRadius: 4,
  },
  textReport: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
});
