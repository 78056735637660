import { useContext, useState } from "react";
import {
  Alert,
  KeyboardAvoidingView,
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";

import AuthContent from "../component/Auth/AuthContent";
import LoadingOverlay from "../component/UI/LoadingOverlay";
import { AuthContext } from "../store/auth-context";
import { login } from "../util/auth";
function LoginScreen() {
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const authCtx = useContext(AuthContext);

  async function loginHandler({ username, password, userRoleIn }) {
    setIsAuthenticating(true);
    try {
      const [token, userRole, userID, pushID,userName] = await login(
        username,
        password,
        userRoleIn
      );
      authCtx.authenticate(token);
      authCtx.setRole(userRole);
      authCtx.setID(userID);
      authCtx.setNotificationID(pushID);
      authCtx.setOwnerUserName(userName);
    } catch (error) {
      Alert.alert(
        "Authentication failed",
        "Could not log you in. Please check your credentials or try again later!"
      );
      console.error(error);
      setIsAuthenticating(false);
    }
  }

  if (isAuthenticating) {
    return <LoadingOverlay message="Logging you in..." />;
  }

  return (
    <KeyboardAvoidingView>
      <ScrollView>
        <AuthContent isLogin onAuthenticate={loginHandler} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
