import { useCallback, useEffect, useState } from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { GlobalStyles } from "../../constant/styles";
import Button from "../UI/Button";
// import * as SQLite from "expo-sqlite";
import { useNavigation } from "@react-navigation/native";
import TaskComment from "./TaskComment";
import {
  collection,
  addDoc,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { database } from "../../Firebase";
import { deleteTask } from "../../util/http";
import { verifyDelete } from "../../util/verifyDelete";
import { getscaledDimension } from "../../util/deviceUiInfo";

function TaskItemDetail({
  task,
  todoTask = true,
  onStatusUpdate,
  userData,
  onDeleteTask,
}) {
  const navigation = useNavigation();
  const [taskStatus, setTaskStatus] = useState("incomplete");
  const [assignedUser, setAssignedUser] = useState();
  // const db = SQLite.openDatabase("db");
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(fontScale*0.7); // pass in fontScale to the StyleSheet

  useEffect(() => {
    const fetchUserInTask = (task) => {
      try {
        // console.log("fetchUserInTask");
        readTable(task);
        // console.log("fetchUserInTask after");
        // console.log(assignedUser);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserInTask(task);
  }, []);

  function readTable(task) {
    // db.transaction((tx) => {
    //   tx.executeSql(
    //     "select * from assigned_to_user where task_id = ?",
    //     [task.taskId],
    //     (_, { rows }) => {
    //       // console.log("database input done");
    //       // console.log(JSON.stringify(rows._array));
    //       setAssignedUser(JSON.parse(JSON.stringify(rows._array)));
    //     }
    //   );
    // });
  }

  const mapStatus = new Map();
  mapStatus.set("incomplete", "未対応");
  mapStatus.set("in_progress", "対応中");
  mapStatus.set("complete", "完了");
  mapStatus.set("on_hold", "保留");
  const mapStatusStyle = new Map();
  mapStatusStyle.set("incomplete", GlobalStyles.colors.error400);
  mapStatusStyle.set("in_progress", GlobalStyles.colors.accent500);
  mapStatusStyle.set("complete", GlobalStyles.colors.primary700);
  mapStatusStyle.set("on_hold", GlobalStyles.colors.neutral600);
  let taskPlannedStartDate = "";
  let taskPlannedEndDate = "";
  let taskStartDate = "";
  let taskEndDate = "";

  if (task.plannedStartDate !== "0000-00-00 00:00:00") {
    const startPlanTask = new Date(task.plannedStartDate);
    taskPlannedStartDate = startPlanTask.toISOString().split("T")[0];
    if (taskPlannedStartDate === "1970-01-01") {
      taskPlannedStartDate = "";
    }
  }
  if (task.plannedEndDate !== "0000-00-00 00:00:00") {
    const endPlanTask = new Date(task.plannedEndDate);
    taskPlannedEndDate = endPlanTask.toISOString().split("T")[0];
    if (taskPlannedEndDate === "1970-01-01") {
      taskPlannedEndDate = "";
    }
  }

  if (task.actualStartDate !== "0000-00-00 00:00:00") {
    const startTask = new Date(task.actualStartDate);
    taskStartDate = startTask.toISOString().split("T")[0];
    if (taskStartDate === "1970-01-01") {
      taskStartDate = "";
    }
  }
  if (task.actualEndDate !== "0000-00-00 00:00:00") {
    const endTask = new Date(task.actualEndDate);
    taskEndDate = endTask.toISOString().split("T")[0];
    if (taskEndDate === "1970-01-01") {
      taskEndDate = "";
    }
  }

  let assignedTo = <Text style={styles.infoText}>{"担当者は未指定"}</Text>;
  if (typeof assignedUser !== "undefined" && assignedUser.length == 1) {
    assignedTo = (
      <View style={styles.assignedByNameContainer}>
        <View style={styles.profilePicture}>
          {assignedUser[0].assigned_user_image ? (
            <Image
              style={styles.profileImg}
              source={
                assignedUser[0].assigned_user_image
                  ? { uri: `${assignedUser[0].assigned_user_image}` }
                  : null
              }
            />
          ) : (
            <Text
              style={styles.textProfile}
            >{`${assignedUser[0].assigned_user_name
              .charAt(0)
              .toUpperCase()}`}</Text>
          )}
        </View>
        <Text style={styles.assignedByNameText}>
          {assignedUser[0].assigned_user_name}
        </Text>
      </View>
    );
  }
  if (typeof assignedUser !== "undefined" && assignedUser.length > 1) {
    assignedTo = [];
    assignedUser.forEach((user, index) => {
      // console.log(user);
      assignedTo.push(
        <View key={index} style={styles.assignedByNameContainer}>
          <View style={styles.profilePicture}>
            {user.assigned_user_image ? (
              <Image
                style={styles.profileImg}
                source={
                  user.assigned_user_image
                    ? { uri: `${user.assigned_user_image}` }
                    : null
                }
              />
            ) : (
              <Text style={styles.textProfile}>{`${user.assigned_user_name
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
          <Text style={styles.assignedByNameText}>
            {user.assigned_user_name}
          </Text>
        </View>
      );
    });
  }

  // { label: "未対応", value: "incomplete" },
  // { label: "対応中", value: "in_progress" },
  // { label: "完了", value: "complete" },
  // { label: "保留", value: "on_hold" },
  function startTask() {
    const taskData = {
      taskId: task.taskId,
      status: "in_progress",
      actualStartDate: new Date(),
    };
    onStatusUpdate(taskData);
  }
  function finishConfirmRequest() {
    // const onSend = useCallback((messages = []) => {
    //   const { _id, createdAt, text, user } = messages[0];
    //   addDoc(collection(database, "task" + task.taskId), {
    //     _id,
    //     createdAt,
    //     text,
    //     user,
    //   });
    // }, []);
    // const messageIn = [
    //   {
    //     _id: `finnishConfirmRequest-UID${userData.userId}-${
    //       task.taskId
    //     }-${Date.now()} `,
    //     createdAt: new Date(),
    //     text: "タスクが完了しました。ご確認お願いします。問題がなければ”タスク完了と判断する”ボタンを押してください",
    //     user: {
    //       _id: userData.userId,
    //       avatar: userData.userImage,
    //       name: userData.userName,
    //     },
    //   },
    // ];
    // onSend(messageIn);
    const taskData = {
      taskId: task.taskId,
      status: "complete",
      actualEndDate: new Date(),
    };
    onStatusUpdate(taskData);
    // onStatusUpdate();
  }
  function finishConfirm() {
    const taskData = {
      taskId: task.taskId,
      status: "complete",
      actualEndDate: new Date(),
    };
    onStatusUpdate(taskData);
  }
  function restartTask() {
    const taskData = {
      taskId: task.taskId,
      status: "incomplete",
      // actualStartDate: new Date(0),
      // actualEndDate: new Date(0),
      actualStartDate: "0000-00-00 00:00:00",
      actualEndDate: "0000-00-00 00:00:00",
    };
    onStatusUpdate(taskData);
  }
  function holdTask() {
    const taskData = {
      taskId: task.taskId,
      status: "on_hold",
    };
    onStatusUpdate(taskData);
  }
  function unholdTask() {
    const taskData = {
      taskId: task.taskId,
      status: "incomplete",
    };
    onStatusUpdate(taskData);
  }
  async function deleteTaskHandler() {
    const confirmDel = await verifyDelete("タスクの削除");
    if (confirmDel) {
      navigation.goBack();
      onDeleteTask(task);
      // onStatusUpdate();
    }
  }
  function updateTask() {
    navigation.navigate("ManageTaskManagerScreen", {
      params: {
        titleName: "タスク内容編集",
        item: task,
        todoTask: todoTask,
      },
    });
    // onStatusUpdate();
  }
  return (
    <View style={styles.taskItemContainer}>
      <ScrollView style={styles.taskScrollContainer}>
        <View style={styles.taskDetailContainer}>
          <View style={styles.taskTitleContainer}>
            <Text style={styles.taskTitleText}>{task.taskTitle}</Text>
          </View>
          <View style={styles.taskDescriptionContainer}>
            <Text style={styles.taskDescriptionText}>
              タスク内容：　
              {task.taskDescription ? task.taskDescription : "無し"}
            </Text>
          </View>
          <View style={styles.assignedByContainer}>
            <Text style={styles.assignedByText}>所有権</Text>
            <View style={styles.assignedByNameContainer}>
              <View style={styles.profilePicture}>
                {task.createdByImage ? (
                  <Image
                    style={styles.profileImg}
                    source={
                      task.createdByImage
                        ? { uri: `${task.createdByImage}` }
                        : null
                    }
                  />
                ) : (
                  <Text style={styles.textProfile}>{`${task.createdByName
                    .charAt(0)
                    .toUpperCase()}`}</Text>
                )}
              </View>
              <Text style={styles.assignedByNameText}>
                {task.createdByName}
              </Text>
            </View>
          </View>
          <View style={styles.selfCreatedAssignedToContainer}>
            <Text style={styles.assignedByText}>担当者</Text>
            {assignedTo}
          </View>
          {/* <View style={styles.dueDateContainer}>
          <Text>dueDate</Text>
        </View> */}
          {task.plannedStartDate && task.plannedEndDate ? (
            <View style={styles.plannedContainer}>
              <View style={styles.plannedTextContainer}>
                <Text style={styles.dueTimeText}>
                  開始予定日付: {taskPlannedStartDate}
                </Text>
              </View>
              <View style={styles.plannedTextContainer}>
                <Text style={styles.dueTimeText}>
                  終了予定日付: {taskPlannedEndDate}
                </Text>
              </View>
            </View>
          ) : null}
          {task.actualStartDate || task.actualEndDate ? (
            <View style={styles.actualContainer}>
              <View style={styles.actualTextContainer}>
                <Text style={styles.dueTimeText}>
                  実績開始日付: {taskStartDate}
                </Text>
              </View>
              <View style={styles.actualTextContainer}>
                <Text style={styles.dueTimeText}>
                  実績終了日付: {taskEndDate}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      </ScrollView>

      {/* <View style={styles.commentContainer}>
        <TaskComment
          taskId={task.taskId}
          assignedUser={assignedUser}
          readOnly={true}
        />
      </View> */}
      <View style={styles.footerContainer}>
        {!todoTask ? (
          <Button
            style={[styles.footerButton, styles.deleteButton]}
            onPress={deleteTaskHandler}
          >
            タスク削除
          </Button>
        ) : null}
        {!todoTask ? (
          <Button style={styles.footerButton} onPress={updateTask}>
            タスク更新
          </Button>
        ) : null}
      </View>
      <View style={styles.footerContainer}>
        <Button
          style={styles.footerButton}
          mode={"flat"}
          styleText={styles.footerButtonText}
          onPress={() => navigation.goBack()}
        >
          {"閉じる"}
        </Button>
        {todoTask && task.status == "incomplete" ? (
          <Button style={styles.footerButton} onPress={startTask}>
            タスク開始
          </Button>
        ) : null}
        {todoTask && task.status == "in_progress" ? (
          <Button style={styles.footerButton} onPress={finishConfirmRequest}>
            タスク完了
          </Button>
        ) : null}
        {todoTask && task.status == "on_hold" ? (
          <Button
            disable={true}
            style={[styles.footerButton, styles.disableButton]}
          >
            保留解放まで待機...
          </Button>
        ) : null}
        {todoTask && task.status == "complete" ? (
          <Button
            disable={true}
            style={[styles.footerButton, styles.disableButton]}
          >
            タスク完了
          </Button>
        ) : null}

        {/* {!todoTask && task.status == "in_progress" ? (
          <Button style={styles.footerButton} onPress={finishConfirm}>
            タスク完了と判断する
          </Button>
        ) : null} */}
        {!todoTask && task.status == "complete" ? (
          <Button style={styles.footerButton} onPress={restartTask}>
            タスクやり直し
          </Button>
        ) : null}
        {/* {!todoTask && task.status != "on_hold" ? (
          <Button
            style={[styles.footerButton, styles.holdButton]}
            onPress={holdTask}
          >
            保留
          </Button>
        ) : null}
        {!todoTask && task.status == "on_hold" ? (
          <Button
            style={[styles.footerButton, styles.holdButton]}
            onPress={unholdTask}
          >
            保留解放
          </Button>
        ) : null} */}
        {/* {!todoTask && task.status == "incomplete" ? (
          <Button
            disable={true}
            style={[styles.footerButton, styles.disableButton]}
          >
            開始待機...
          </Button>
        ) : null} */}
      </View>

      <View style={styles.rightTopContainer}>
        <View
          style={[
            styles.statusContainer,
            { backgroundColor: mapStatusStyle.get(task.status) },
          ]}
        >
          <Text style={styles.statusText}>{mapStatus.get(task.status)}</Text>
        </View>
      </View>
    </View>
  );
}
export default TaskItemDetail;

const makeStyles = (fontScale) =>StyleSheet.create({
  taskItemContainer: {
    padding: 12,
    marginVertical: 30,
    marginHorizontal: 20,
    backgroundColor: GlobalStyles.colors.neutral10,
    width: "90%",
    alignSelf: "center",
    flexDirection: "column",
  },
  taskScrollContainer: {
    height: "80%",
  },
  taskDetailContainer: {},
  assignedByContainer: {},
  assignedToContainer: {},
  dueDateContainer: {},
  commentContainer: {
    width: "100%",
    minHeight: 300,
    borderRadius: 10,
    borderWidth: 0.4,
    borderColor: GlobalStyles.colors.primary600,
  },
  plannedContainer: { flexDirection: "row" },
  plannedTextContainer: { flex: 1, marginVertical: 10 },
  actualContainer: { flexDirection: "row" },
  actualTextContainer: { flex: 1, marginVertical: 10 },
  footerContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    // overflow: "hidden",
  },
  footerButton: {
    alignSelf: "center",
    // marginHorizontal: 0,
    marginTop: 5,
    paddingHorizontal: 20,
  },
  footerButtonText: {
    color: GlobalStyles.colors.primary600,
  },
  rightTopContainer: {
    position: "absolute",
    top: "4%",
    right: "5%",
  },
  statusContainer: {
    borderRadius: 100,
  },
  statusText: {
    color: GlobalStyles.colors.neutral0,
    paddingHorizontal: 15,
    paddingVertical: 5,
    fontSize: 10*fontScale,
  },
  taskTitleContainer: {
    marginBottom: 20,
    width: "75%",
  },
  taskTitleText: {
    fontSize: 44*fontScale,
    fontWeight: "800",
    color: GlobalStyles.colors.primary600,
  },
  taskDescriptionContainer: { marginBottom: 20 },
  taskDescriptionText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 18*fontScale,
  },
  assignedByContainer: {},
  assignedByNameContainer: {
    marginVertical: 15,
    flexDirection: "row",
    marginLeft: 20,
  },
  assignedByText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14*fontScale,
    fontWeight: "600",
  },
  assignedByNameText: {
    paddingLeft: 5,
    color: GlobalStyles.colors.primary600,
    fontSize: 14*fontScale,
    alignItems: "center",
    alignSelf: "center",
  },
  profilePicture: {
    width: 40,
    height: 40,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 18*fontScale,
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: 1000,
  },
  dueTimeText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14*fontScale,
    fontWeight: "600",
  },
  deleteButton: {
    backgroundColor: GlobalStyles.colors.error500,
  },
  disableButton: {
    backgroundColor: GlobalStyles.colors.neutral600,
    opacity: 0.5,
  },
  holdButton: {
    backgroundColor: GlobalStyles.colors.accent500,
  },
});
