import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { FlatList, Pressable, StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { SwipeListView } from "react-native-swipe-list-view";
import { GlobalStyles } from "../../constant/styles";
import { MyTaskContext } from "../../store/mytask-context";
import { TaskContext } from "../../store/task-context";
import { deleteTask } from "../../util/http";
import TaskItem from "./TaskItem";
import { verifyDelete } from "../../util/verifyDelete";
import { getscaledDimension } from "../../util/deviceUiInfo";

function TaskList({
  taskList,
  handlePress,
  todoTask = true,
  horizontal = false,
  userData,
}) {
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale); // pass in fontScale to the StyleSheet
  const navigation = useNavigation();
  const todoTaskCtx = useContext(TaskContext);
  const myTaskCtx = useContext(MyTaskContext);
  const [roomList, setRoomList] = useState(taskList);
  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    // console.log(rowMap);
    // console.log(rowKey);
    closeRow(rowMap, rowKey);
    const newData = [...roomList];
    // console.log(roomList);
    const prevIndex = roomList.findIndex((item) => item.taskId === rowKey);
    newData.splice(prevIndex, 1);
    // console.log(newData.splice(prevIndex, 0));
    setRoomList(newData);
  };

  let addSetting = [];
  if (horizontal) {
    addSetting.push({ minHeight: 200, minWidth: 200 });
  } else {
    addSetting.push({ height: 155 });
  }
  // handlePress = (item) => {
  //   navigation.navigate("TaskManagerDetailScreen", {
  //     params: { task: item },
  //   });
  // };
  const renderTaskItem = ({ item }) => {
    // const [taskStatus, setTaskStatus] = useState("incomplete");
    // console.log("renderItem");
    // console.log(item);
    // let statusColor = [];
    return (
      <View>
        <Pressable
          style={({ pressed }) => [pressed && styles.pressed]}
          onPress={() => handlePress(item, userData, todoTask)}
        >
          <TaskItem
            task={item}
            // taskStatus={taskStatus}
            todoTask={todoTask}
            addTaskItemContainer={addSetting}
          />
        </Pressable>
      </View>
    );
  };
  // console.log("taskList");
  // console.log(taskList);
  const renderHiddenItem = (itemData, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => {
          navigation.navigate("ManageTaskManagerScreen", {
            params: {
              titleName: "タスク内容編集",
              item: itemData.item,
              todoTask: todoTask,

              // roomId: itemData.item.roomId,
              // roomName: itemData.item.roomName,
            },
          });
          closeRow(rowMap, itemData.item.taskId);
        }}
      >
        <Text style={styles.backTextWhite}>編集</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={async () => {
          const confirmDel = await verifyDelete("タスクの削除");
          if (confirmDel) {
            // console.log("room deleted");
            deleteRow(rowMap, itemData.item.taskId);
            deleteTask(itemData.item);
            todoTaskCtx.deleteTask(itemData.item);
            myTaskCtx.deleteTask(itemData.item);
          }
        }}
      >
        <Text style={styles.backTextWhite}>削除</Text>
      </TouchableOpacity>
    </View>
  );
  return (
    <>
      {!todoTask ? (
        <SwipeListView
          useFlatList={true}
          data={roomList}
          renderItem={renderTaskItem}
          keyExtractor={(item) => item.taskId}
          renderHiddenItem={renderHiddenItem}
          rightOpenValue={-150}
          onRowDidOpen={onRowDidOpen}
          disableRightSwipe={true}
          closeOnScroll={true}
          initialNumToRender={10}
        />
      ) : null}
      {todoTask ? (
        <FlatList
          data={taskList}
          renderItem={renderTaskItem}
          keyExtractor={(item) => item.taskId}
          removeClippedSubviews={true}
          horizontal={horizontal}
          initialNumToRender={10}
        />
      ) : null}
    </>
  );
}

export default TaskList;

const makeStyles = (hieghtScale,fontScale) =>StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16*fontScale,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
    marginLeft: 30,
    height: 155,
    marginRight: 12,
    overflow: "hidden",
    borderRadius: 12,
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral400,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
  },
});
