import { StyleSheet, View, Alert, Image, Text } from "react-native";
import Button from "../UI/Button";
import {
  launchCameraAsync,
  useCameraPermissions,
  useMediaLibraryPermissions,
  PermissionStatus,
  launchImageLibraryAsync,
} from "expo-image-picker";
import { useState } from "react";
import { GlobalStyles } from "../../constant/styles";
import OutlinedButton from "../UI/OutlinedButton";
import ButtonWithIcon from "../UI/ButtonWithIcon";

function ImagePicker({
  textInput,
  onTakenImage,
  userPreview = true,
  imageQuality = 0.5,
}) {
  const [pickedImage, setPickedImage] = useState();
  const [gotImage, setGotImage] = useState(false);
  const [cameraPermissionInformation, requestCameraPermission] =
    useCameraPermissions();
  const [mediaLibraryPermissionInformation, requestMediaLibraryPermission] =
    useMediaLibraryPermissions();
  async function verifyCameraPermissions() {
    if (cameraPermissionInformation.status === PermissionStatus.UNDETERMINED) {
      const permissionResponse = await requestCameraPermission(false);
      return permissionResponse.granted;
    }
    if (cameraPermissionInformation.status === PermissionStatus.DENIED) {
      Alert.alert(
        "不十分な許可",
        "このアプリを使用するため、カメラの権限を許可する必要があります"
      );
      return false;
    }
    return true;
  }

  async function verifyMediaLibraryPermissions() {
    if (
      mediaLibraryPermissionInformation.status === PermissionStatus.UNDETERMINED
    ) {
      const permissionResponse = await requestMediaLibraryPermission(false);
      return permissionResponse.granted;
    }
    if (mediaLibraryPermissionInformation.status === PermissionStatus.DENIED) {
      Alert.alert(
        "不十分な許可",
        "このアプリを使用するため、カメラの権限を許可する必要があります"
      );
      return false;
    }
    return true;
  }
  async function takeImageHandler(method) {
    let image;
    if (method == "camera") {
      const hasPermission = await verifyCameraPermissions();
      if (!hasPermission) {
        return;
      }
      image = await launchCameraAsync({
        allowsEditing: true,
        aspect: [4, 3],
        quality: imageQuality,
      });
    } else if (method == "library") {
      const hasPermission = await verifyMediaLibraryPermissions();
      // console.log("library");
      if (!hasPermission) {
        return;
      }
      // console.log("library1");
      image = await launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [4, 3],
        quality: imageQuality,
      });
    }
    // console.log(image);
    if (!image.assets[0].canceled) {
      setPickedImage(image.assets[0].uri);
      setGotImage(true);
      onTakenImage(image.assets[0]);
    }
    // if ((await image.trim().length) > 0) setGotImage(true);
  }
  const createImagePickerMethodSelect = () => takeImageHandler("library");
    // Alert.alert("画像アップロード", "画像を選択してください", [
    //   {
    //     text: "カメラで画像を撮影する",
    //     onPress: () => takeImageHandler("camera"),
    //   },
    //   {
    //     text: "キャンセル",
    //     onPress: () => console.log("Cancel Pressed"),
    //     style: "cancel",
    //   },
    //   {
    //     text: "ライブラリから画像を選択する",
    //     onPress: () => takeImageHandler("library"),
    //   },
    // ]);

  let imagePreview = <Text>No image taken yet.</Text>;

  if (pickedImage) {
    imagePreview = <Image style={styles.image} source={{ uri: pickedImage }} />;
  }

  return (
    <View>
      <ButtonWithIcon
        icon="camera"
        onPress={createImagePickerMethodSelect}
        styleButton={{ backgroundColor: GlobalStyles.colors.primary700 }}
        children={textInput}
        size={20}
      />
      {gotImage && userPreview && (
        <View style={styles.imagePreview}>{imagePreview}</View>
      )}
    </View>
  );
}
export default ImagePicker;

const styles = StyleSheet.create({
  imagePreview: {
    width: "100%",
    height: 200,
    marginVertical: 8,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: GlobalStyles.colors.neutral0,
    borderRadius: 4,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginHorizontal: 8,
  },
  image: {
    width: "100%",
    height: "100%",
  },
});
