import { createContext, useReducer } from "react";

export const UsersContext = createContext({
  users: [],
  addUser: (id, { userId, userName, userImage, userRole }) => {},
  setUser: (users) => {},
  deleteUser: (id) => {},
  updateUser: (id, { userId, userName, userImage, userRole }) => {},
});

function usersReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      const listed = action.payload;
      return listed;
    case "UPDATE":
      const updatableUserIndex = state.findIndex(
        (user) => user.id === action.payload.id
      );
      const updatableUser = state[updatableUserIndex];
      const updatedItem = { ...updatableUser, ...action.payload.data };
      const updatedUsers = [...state];
      updatedUsers[updatableUserIndex] = updatedItem;
      return updatedUsers;
    case "DELETE":
      return state.filter((user) => user.id !== action.payload);
    default:
      return state;
  }
}

function UsersContextProvider({ children }) {
  const [usersState, dispatch] = useReducer(usersReducer, []);

  function addUser(userData) {
    dispatch({ type: "ADD", payload: userData });
  }
  function setUser(users) {
    dispatch({ type: "SET", payload: users });
  }
  function deleteUser(id) {
    dispatch({ type: "DELETE", payload: id });
  }
  function updateUser(id, userData) {
    dispatch({ type: "UPDATE", payload: { id: id, data: userData } });
  }

  const value = {
    users: usersState,
    setUser: setUser,
    addUser: addUser,
    deleteUser: deleteUser,
    updateUser: updateUser,
  };

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
}
export default UsersContextProvider;
