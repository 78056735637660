import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { useState } from "react";
import { Text, View, StyleSheet, Image, Modal, Pressable } from "react-native";
import { Divider, Menu, Provider } from "react-native-paper";
import { GlobalStyles } from "../../constant/styles";
import { AuthContext } from "../../store/auth-context";
import { logoutUserAuth } from "../../util/http";
import Button from "../UI/Button";
import IconButton from "../UI/IconButton";
import ProfileEditForm from "./ProfileEditForm";
import { getscaledDimension } from "../../util/deviceUiInfo";
import alert from "../../util/alertWeb";
import CustomMenu from "./CustomMenu";

function ProfileOutput({ userData, onConfirm, fallbackText }) {
  const navigation = useNavigation();
  const hadProfileImg = userData.userImage !== null ? true : false;
  const [isAdmin, setIsAdmin] = useState(true);
  const [userRole, setUserRole] = useState();
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(hieghtScale, fontScale * 0.7); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32 * fontScale * 0.7;
  // console.log(userData);
  const authCtx = useContext(AuthContext);
  async function fetchRole() {
    const storedRole = await AsyncStorage.getItem("role");
    setUserRole(storedRole);
    switch (storedRole) {
      case "admin":
        setIsAdmin(true);
        break;
      case "manager":
        setIsAdmin(true);
        break;
      default:
        setIsAdmin(false);
    }
  }
  const map_role = new Map();

  map_role.set("admin", "管理者（アドミン）");
  map_role.set("manager", "エリアマネージャー");
  map_role.set("officer", "社員");
  map_role.set("store", "店舗");

  function profileEditSubmitHandler(userData) {
    onConfirm(userData);
  }
  // function profileEditCancelHandler() {
  //   setModalVisible(!modalVisible);
  // }
  // function showModalHanlder() {
  //   console.log("SHOW MODALLLLLLLLLLLLLLL");
  //   setModalVisible(!modalVisible);
  // }

  fetchRole();
  // const openMenu = () => setVisible(!visible);

  // const closeMenu = () => setVisible(false);

  return (
    <View style={styles.container}>
      <View style={styles.containerTop}>
        <View style={styles.backIconContainer}>
          <IconButton
            icon="chevron-back-outline"
            size={24 * fontScale}
            color={GlobalStyles.colors.primary600}
            onPress={() => navigation.goBack()}
            styleButtonContainer={styles.buttonBox}
            label={`戻る`}
          />
        </View>
        <View style={styles.optionIconContainer}>
          <CustomMenu
            isAdmin={isAdmin}
            navigation={navigation}
            authCtx={authCtx}
            IconSize={24 * fontScale}
            onSubmitMenu={profileEditSubmitHandler}
            defaultValues={userData}
            userRole={userRole}
          />
        </View>
        <View style={styles.profilePictureContainer}>
          <View style={styles.profilePicture}>
            {hadProfileImg && (
              <Image
                style={styles.profileImg}
                source={
                  userData.userImage ? { uri: `${userData.userImage}` } : null
                }
              />
            )}
            {!hadProfileImg && userData.userName && (
              <Text style={styles.textProfile}>{`${userData.userName
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
        </View>
      </View>
      <View style={styles.containerBottom}>
        <View style={styles.userDataContainer}>
          <View style={styles.userNameContainer}>
            <Text style={[styles.textBase, styles.usernameText]}>
              {userData.userName}
            </Text>
          </View>
          <View style={styles.userRoleContainer}>
            <Text style={[styles.textBase, styles.roleText]}>
              {`ユーザーの担当：${map_role.get(userRole)}`}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default ProfileOutput;

const makeStyles = (hieghtScale, fontScale) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    containerTop: {
      flex: 4 * hieghtScale,
      backgroundColor: GlobalStyles.colors.neutral100,
    },

    containerBottom: { flex: 8 * hieghtScale },
    backIconContainer: {
      position: "absolute",
      top: "10%",
      left: "3%",
      flexDirection: "row",
      alignItems: "center",
    },
    optionIconContainer: {
      position: "absolute",
      top: "10%",
      right: "5%",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 1000,
    },
    optionMenuContainer: {
      position: "absolute",
      top: "10%",
      right: "1%",
      zIndex: 1001,
    },
    profilePictureContainer: {
      flex: 1 * hieghtScale,
      top: "30%",
      alignItems: "center",
    },
    userNameContainer: {
      alignSelf: "center",
      minHeight: 10,
    },
    userRoleContainer: {
      marginTop: 10,
      alignSelf: "center",
    },
    buttonBox: { marginRight: 2 },
    profilePicture: {
      width: 200 * fontScale,
      height: 200 * fontScale,
      borderRadius: 1000,
      backgroundColor: GlobalStyles.colors.primary600,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
    },
    textBase: { color: GlobalStyles.colors.primary600 },
    textProfile: {
      color: GlobalStyles.colors.neutral0,
      fontSize: 80 * fontScale,
    },
    profileImg: {
      width: 200 * fontScale,
      height: 200 * fontScale,
      borderRadius: 1000,
    },
    usernameText: {
      fontSize: 32 * fontScale,
      fontWeight: "bold",
    },
    roleText: { fontSize: 18 * fontScale },

    button: {
      minWidth: 120,
      marginHorizontal: 18,
    },

    buttonText: {
      fontSize: 16 * fontScale,
    },

    userEditContent: {
      // backgroundColor: GlobalStyles.colors.neutral50,
    },
    modalTitleContainer: {
      // backgroundColor: GlobalStyles.colors.neutral200,
      marginTop: 24,
      alignContent: "center",
    },
    buttons: {
      marginVertical: 24,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: GlobalStyles.colors.neutral100,
    },
    titleText: {
      fontSize: 20 * fontScale,
      fontWeight: "bold",
      marginBottom: 8,
      textAlign: "center",
      color: GlobalStyles.colors.primary600,
    },
    userDataContainer: {
      // margin: 20,
      backgroundColor: "rgba(253,252,245,0.8)",
      // borderRadius: 20,
      // padding: 35,
      alignItems: "center",
      // shadowColor: "#000",
      // shadowOffset: {
      //   width: 0,
      //   height: 2,
      // },
      // shadowOpacity: 0.25,
      // shadowRadius: 4,
      // elevation: 5,
    },
  });
