import { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { fetchTaskUserList } from "../../util/http";
// import * as SQLite from "expo-sqlite";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getscaledDimension } from "../../util/deviceUiInfo";

function TaskItem({ task, todoTask = true, addTaskItemContainer }) {
  const [taskStatus, setTaskStatus] = useState("incomplete");
  const [isFetching, setIsFetching] = useState(true);
  const [assignedUser, setAssignedUser] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [UID, setUID] = useState();
  const {hieghtScale,fontScale} = getscaledDimension() ;
  const styles = makeStyles(hieghtScale,fontScale*0.7); // pass in fontScale to the StyleSheet

  // console.log("TaskItem");
  // console.log(task);
  useEffect(() => {
    async function fetchRole() {
      const uid = await AsyncStorage.getItem("uid");
      setUID(uid);
    }
    fetchRole();
    const fetchUserInTask = (task) => {
      try {
        // console.log("fetchUserInTask");
        readTable(task);
        // console.log("fetchUserInTask after");
        // console.log(assignedUser);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserInTask(task);
  }, []);
  function readTable(task) {
    // db.transaction((tx) => {
    //   tx.executeSql(
    //     "select * from assigned_to_user where task_id = ?",
    //     [task.taskId],
    //     (_, { rows }) => {
    //       // console.log("database input done");
    //       // console.log(JSON.stringify(rows._array));
    //       setAssignedUser(JSON.parse(JSON.stringify(rows._array)));
    //     }
    //   );
    // });
  }
  // const db = SQLite.openDatabase("db");
  let userInTaskList;
  let statusColor = [];
  let taskStatusTest = "incomplete";
  let dueDate = "";
  // console.log("task.plannedEndDate");
  // console.log(task.plannedEndDate);
  if (typeof task.plannedEndDate === "string") {
    dueDate = task.plannedEndDate.split(" ")[0];
  } else {
    const dates = new Date(task.plannedEndDate);
    dueDate = dates.toISOString().slice(0, 10);
  }
  const mapStatus = new Map();
  mapStatus.set("incomplete", "未対応");
  mapStatus.set("in_progress", "対応中");
  mapStatus.set("complete", "完了");
  mapStatus.set("on_hold", "保留");
  const mapStatusStyle = new Map();
  mapStatusStyle.set("incomplete", GlobalStyles.colors.error400);
  mapStatusStyle.set("in_progress", GlobalStyles.colors.accent500);
  mapStatusStyle.set("complete", GlobalStyles.colors.primary700);
  mapStatusStyle.set("on_hold", GlobalStyles.colors.neutral600);
  // const statusData = [
  //   { label: "未対応", value: "incomplete" },
  //   { label: "対応中", value: "in_progress" },
  //   { label: "完了", value: "complete" },
  //   { label: "保留", value: "on_hold" },
  // ];
  let assignedTo = <Text style={styles.infoText}>{"担当者は未指定"}</Text>;
  if (typeof assignedUser !== "undefined" && assignedUser.length == 1) {
    assignedTo = (
      <View style={styles.assignedByNameContainer}>
        <View style={styles.profilePicture}>
          {assignedUser[0].assigned_user_image ? (
            <Image
              style={styles.profileImg}
              source={
                assignedUser[0].assigned_user_image
                  ? { uri: `${assignedUser[0].assigned_user_image}` }
                  : null
              }
            />
          ) : (
            <Text
              style={styles.textProfile}
            >{`${assignedUser[0].assigned_user_name
              .charAt(0)
              .toUpperCase()}`}</Text>
          )}
        </View>
        <Text style={styles.assignedByNameText}>
          {assignedUser[0].assigned_user_name}
        </Text>
      </View>
    );
  }
  if (typeof assignedUser !== "undefined" && assignedUser.length > 1) {
    assignedTo = [];
    let addSmallPic = false;
    assignedUser.forEach((user, index) => {
      // console.log(user);

      // if (todoTask) {
      if (
        (user.user_id == UID && !addSmallPic) ||
        (index == assignedUser.length - 1 && !addSmallPic)
      ) {
        assignedTo.push(
          <View
            key={"smallpic"}
            style={{ flexDirection: "row", marginTop: 15 }}
          >
            <View style={[styles.profilePicture, { zIndex: 3 }]}>
              {user.assigned_user_image ? (
                <Image
                  style={styles.profileImg}
                  source={
                    user.assigned_user_image
                      ? { uri: `${user.assigned_user_image}` }
                      : null
                  }
                />
              ) : (
                <Text style={styles.textProfile}>
                  {`${user.assigned_user_name.charAt(0).toUpperCase()}`}
                </Text>
              )}
            </View>
            <View
              style={{
                width: 30*fontScale,
                height: 30*fontScale,
                borderRadius: 20,
                borderColor: "white",
                borderWidth: 1,
                backgroundColor: GlobalStyles.colors.primary600,
                marginLeft: -35,
                zIndex: 2,
              }}
            />
            <View
              style={{
                width: 30*fontScale,
                height: 30*fontScale,
                borderRadius: 20,
                borderColor: "white",
                borderWidth: 1,
                backgroundColor: GlobalStyles.colors.primary600,
                marginLeft: -30,
                zIndex: 1,
              }}
            />
          </View>
        );
        addSmallPic = true;
      }
      // } else {
      //   assignedTo.push(
      //     <View key={index} style={styles.assignedByNameContainer}>
      //       <View style={styles.profilePicture}>
      //         {user.assigned_user_image ? (
      //           <Image
      //             style={styles.profileImg}
      //             source={
      //               user.assigned_user_image
      //                 ? { uri: `${user.assigned_user_image}` }
      //                 : null
      //             }
      //           />
      //         ) : (
      //           <Text style={styles.textProfile}>{`${user.assigned_user_name
      //             .charAt(0)
      //             .toUpperCase()}`}</Text>
      //         )}
      //       </View>
      //       <Text style={styles.assignedByNameText}>
      //         {user.assigned_user_name}
      //       </Text>
      //     </View>
      //   );
      // }
    });
  }
  return (
    <View style={[styles.taskItemContainer, addTaskItemContainer]}>
      <View style={styles.taskDetailContainer}>
        <View style={styles.taskTitleContainer}>
          <Text style={styles.taskTitleText}>{task.taskTitle}</Text>
        </View>
        <View style={styles.taskDescriptionContainer}>
          <Text
            style={styles.taskDescriptionText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            タスク内容：　{task.taskDescription ? task.taskDescription : "無し"}
          </Text>
        </View>
        {todoTask ? (
          <View style={styles.assignedByContainer}>
            <Text style={styles.assignedByText}>所有権:</Text>
            <View style={styles.assignedByNameContainer}>
              <View style={styles.profilePicture}>
                {task.createdByImage ? (
                  <Image
                    style={styles.profileImg}
                    source={
                      task.createdByImage
                        ? { uri: `${task.createdByImage}` }
                        : null
                    }
                  />
                ) : (
                  <Text style={styles.textProfile}>{`${
                    task.createdByName
                      ? task.createdByName.charAt(0).toUpperCase()
                      : "ユ"
                  }`}</Text>
                )}
              </View>
              <Text style={styles.assignedByNameText}>
                {task.createdByName}
              </Text>
            </View>
          </View>
        ) : null}
        {/* {todoTask ? null : ( */}
        <View style={styles.selfCreatedAssignedToContainer}>
          <Text style={styles.assignedByText}>担当者:</Text>
          {assignedTo}
        </View>
        {/* )} */}
      </View>
      <View style={styles.dueDateContainer}>
        {/* <Text style={styles.dueDateDateText}>{task.plannedEndDate}</Text> */}
        <View style={styles.dateContainer}>
        {todoTask ? <Text style={styles.dueDateText}>終了日</Text>:null}
          <Text style={[styles.textBase, styles.reportDate]}>
            {/* dailyreportDate.split("-")[0] */}
            {`${dueDate.split("-")[2]}日`}
          </Text>
          <Text style={[styles.textBase, styles.reportMonthYear]}>
            {`${dueDate.split("-")[1]}月${dueDate.split("-")[0]}年`}
          </Text>
        </View>
      </View>
      <View style={styles.rightTopContainer}>
        <View
          style={[
            styles.statusContainer,
            { backgroundColor: mapStatusStyle.get(task.status) },
          ]}
        >
          <Text style={styles.statusText}>{mapStatus.get(task.status)}</Text>
        </View>
      </View>
    </View>
  );
}
export default TaskItem;

const makeStyles = (hieghtScale,fontScale) =>StyleSheet.create({
  taskItemContainer: {
    padding: 12,
    marginTop: 10*hieghtScale,
    marginLeft: 30,
    marginRight: 30,
    // overflow: "hidden",
    backgroundColor: GlobalStyles.colors.neutral10,
    // justifyContent: "space-between",
    borderRadius: 12,
    elevation: 3,
    shadowColor: GlobalStyles.colors.gray500,
    shadowRadius: 4,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.4,
    width: "100%",

    // minWidth: 150,
    // justifyContent: "center",
    alignSelf: "center",
  },
  taskDetailContainer: {},

  selfCreatedAssignedToContainer: {},
  assignedToContainer: { alignSelf: "flex-end" },
  rightTopContainer: {
    position: "absolute",
    top: "10%",
    right: "5%",
  },
  statusContainer: {
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.error400,
  },
  statusText: {
    color: GlobalStyles.colors.neutral0,
    paddingHorizontal: 10,
    fontSize: 10*fontScale,
  },
  dueDateContainer: {
    // alignSelf:"flex-end",
    position: "absolute",
    bottom: "10%",
    right: "5%",
    paddingHorizontal: 5,
    // paddingTop: 10,
    // flex: "flex-end",
  },
  dueDateText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 10*fontScale,
    alignSelf: "flex-end",
  },
  dueDateDateText: {
    color: GlobalStyles.colors.primary600,
    fontWeight: "600",
    fontSize: 14*fontScale,
  },
  taskTitleContainer: {
    marginBottom: 10*hieghtScale,
  },
  taskTitleText: {
    color: GlobalStyles.colors.primary600,
    fontWeight: "600",
    fontSize: 14*fontScale,
  },
  taskDescriptionContainer: { marginBottom: 10*hieghtScale },
  taskDescriptionText: { color: GlobalStyles.colors.primary600, fontSize: 10*fontScale },
  assignedByContainer: {},
  assignedByNameContainer: {
    marginTop: 15*hieghtScale,
    flexDirection: "row",
  },
  assignedByText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 10*fontScale,
  },
  assignedByNameText: {
    paddingLeft: 5,
    color: GlobalStyles.colors.primary600,
    fontSize: 10*fontScale,
    alignItems: "center",
    alignSelf: "center",
  },
  profilePicture: {
    width: 30*fontScale,
    height: 30*fontScale,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 18*fontScale,
  },
  profileImg: {
    width: 30*fontScale,
    height: 30*fontScale,
    borderRadius: 1000,
  },
  dateContainer: {
    marginLeft: 4,
    flex: 4,
  },
  reportDate: {
    fontSize: 20*fontScale,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "flex-end",
  },
  reportMonthYear: {
    fontSize: 8*fontScale,
    marginBottom: 4,
    fontWeight: "bold",
    alignSelf: "flex-end",
  },
  textBase: {
    color: GlobalStyles.colors.primary600,
  },
  infoText: { color: GlobalStyles.colors.primary600, marginLeft: 10 },
});
