import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";

function InputWithPrefix({
  prefix,
  invalid,
  style,
  textInputConfig,
  styleLabel,
  onChangeText,
}) {
  let inputStyles = [styles.input];
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  return (
    <View style={[styles.inputContainer, style]}>
      <Text style={styles.prefix}>{prefix}</Text>
      <TextInput
        style={inputStyles}
        keyboardType="number-pad"
        underlineColorAndroid="transparent"
        autocomplete="off"
        {...textInputConfig}
      />
    </View>
  );
}
export default InputWithPrefix;

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: GlobalStyles.colors.neutral0,
    borderRadius: 6,
  },
  labelContainer: { flex: 1 },
  textInputContainer: { flex: 1 },
  label: {
    fontSize: 20,
    color: GlobalStyles.colors.neutral10,
    marginBottom: 4,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary600,
    padding: 6,
    borderRadius: 6,
    fontSize: 20,
  },
  inputMultiline: {
    minHeight: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  prefix: {
    paddingHorizontal: 8,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
});
