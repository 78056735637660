import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Text, StyleSheet, Pressable, TextInput, View } from "react-native";
// import { database } from "../Firebase";
import {
  collection,
  addDoc,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { Avatar, Bubble, GiftedChat, Send } from "react-native-gifted-chat";
import ErrorOverlay from "../UI/ErrorOverlay";
import IconButton from "../UI/IconButton";
import LoadingOverlay from "../UI/LoadingOverlay";
import { GlobalStyles } from "../../constant/styles";
import { AuthContext } from "../../store/auth-context";
import { UsersContext } from "../../store/user-context";
// import { UserAuthsContext } from "../store/userauth-context";
import { Ionicons } from "@expo/vector-icons";
import userImg from "../../assets/user.png";
import OneSignal from "react-native-onesignal";
import {
  addDailyRpCommentNums,
  addUnreadTask,
  clearUnreadTask,
  fetchUsers,
} from "../../util/http";
import { database } from "../../Firebase";
import { Button } from "react-native";
function DailyReportComment({
  dailyReportId,
  assignedUser,
  dailyreportDate,
  curRoomName,
  dailyReportRoute,
  readOnly = false,
}) {
  const [messages, setMessages] = useState([]);
  const [usingId, setUsingId] = useState();
  const [userIdInComment, setUserIdInComment] = useState([]);
  const [isFetching, setIsFectching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState();
  const navigation = useNavigation();
  const [error, setError] = useState();
  const [selectedUser, setSelectedUser] = useState();
  // console.log(dailyReportRoute);

  // const dailyReportId = route.params.chatRoomId
  //   ? route.params.chatRoomId
  //   : route.params.params.chatRoomId;
  // const curRoomName = route.params.chatRoomName
  //   ? route.params.chatRoomName
  //   : route.params.params.chatRoomName;
  const userCtx = useContext(UsersContext);
  // let updateUnreadTo = [];
  // if (assignedUser) {
  //   assignedUser.forEach((user) => updateUnreadTo.push(user.user_id));
  // }
  // console.log(updateUnreadTo)
  // const onSend = useCallback((messages = []) => {
  //   setMessages((previousMessages) =>
  //     GiftedChat.append(previousMessages, messages)
  //   );
  // }, []);

  useEffect(() => {
    // async function clearUnreadNum() {
    //   const response = await clearUnreadTask(dailyReportId);
    // }
    const collectionRef = collection(database, "dailyReport" + dailyReportId);
    const q = query(collectionRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setMessages(
        querySnapshot.docs.map((doc) => ({
          _id: doc.data()._id,
          createdAt: doc.data().createdAt.toDate(),
          text: doc.data().text,
          user: doc.data().user,
        }))
      );
      setUserIdInComment(
        querySnapshot.docs
          .map((doc) => doc.data().user._id)
          .filter((item, i, arr) => arr.indexOf(item) === i)
      );
    });
    // clearUnreadNum();

    return () => unsubscribe();
  }, []);
  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
    const { _id, createdAt, text, user } = messages[0];
    addDoc(collection(database, "dailyReport" + dailyReportId), {
      _id,
      createdAt,
      text,
      user,
    });
  }, []);

  async function addCommentNums(dailyReportId) {
    const response = await addDailyRpCommentNums(dailyReportId);
    // addUnreadTask(dailyReportId, updateUnreadTo);
  }

  // async function updateLastestMsg(dailyReportId, inputMessage) {
  //   const response = await updateLastestChat(dailyReportId, inputMessage);
  // }

  useEffect(() => {
    async function getSelectedUser() {
      setIsFectching(true);
      try {
        const userList = await fetchUsers();
        userCtx.setUser(userList);
        // console.log("fetchUserData");
        // console.log(userList);
        const storedUid = await AsyncStorage.getItem("uid");
        setUsingId(storedUid);

        setSelectedUser(userList.find((users) => users.userId === storedUid));
      } catch (error) {
        setError(
          "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
        );
        setIsFectching(false);
        // setIsFectching(false);
      }
      setIsFectching(false);
    }
    // async function fetchUserData() {
    //   try {
    //     // console.log("fetchUserData");
    //     const storedUid = await AsyncStorage.getItem("uid");
    //     setUsingId(storedUid);
    //     setSelectedUser(
    //       userCtx.users.find((users) => users.userId === storedUid)
    //     );

    //     // setIsFectching(false);
    //     // console.log(selectedUser);
    //   } catch (error) {
    //     setError(
    //       "サーバーの問題でユーザーリストを表示できません　- 後でもう一度試してください"
    //     );
    //   }
    // }
    getSelectedUser();
    // getUsersList();
    // fetchUserData();
  }, [navigation]);

  if (error && !isFetching) {
    return (
      <ErrorOverlay
        message={error}
        onPress={() => {
          setIsFectching(true);
          setError();
        }}
      />
    );
  }
  if (isFetching) {
    return <LoadingOverlay />;
  }
  // const selectedUser = userCtx.users.find((users) => users.userId === usingId);

  async function sendPush(messages) {
    // const response = await addUnreadChat(dailyReportId);
    // console.log(userCtx.users);
    const senderData = userCtx.users.find((users) => users.userId === usingId);
    // console.log("userIdInComment" + userIdInComment);
    // console.log(
    //   userCtx.users
    //     .filter((users) => users.userId !== usingId)
    //     .filter((users) =>
    //       userIdInComment.find((cmtUid) => users.userId === cmtUid)
    //     )
    // );
    const sendPushUserIdList = [];
    const sendPushUserList = userCtx.users
      .filter((users) => users.userId !== usingId)
      .filter((users) =>
        userIdInComment.find((cmtUid) => users.userId === cmtUid)
      )
      .forEach((userData) => sendPushUserIdList.push(userData.notificationId));

    // console.log(sendPushUserIdList.length);

    const reporterName = dailyReportRoute.userName;
    const reporterInfo = userCtx.users.filter(
      (users) => users.userName === reporterName
    );
    console.log(reporterName);
    console.log(sendPushUserIdList);
    console.log(reporterInfo);
    // if (sendPushUserIdList.length > 0){

      if (
        senderData.userId !== reporterInfo.userId &&
        sendPushUserIdList.indexOf(reporterInfo.userId) === -1 &&
        sendPushUserIdList.indexOf(reporterInfo.notificationId) === -1){
    console.log("sendPushUserIdList");
    console.log(reporterInfo[0].notificationId);
    sendPushUserIdList.push(reporterInfo[0].notificationId);
      }
    // }
    // else{
      
    // }
    console.log(sendPushUserIdList);
    console.log(dailyreportDate);
    if (sendPushUserIdList.length > 0) {
      const notificationObj = {
        headings: {
          en: `日報コメント：${dailyreportDate.split("-")[1]}月 ${
            dailyreportDate.split("-")[2]
          }日(${curRoomName})`,
        },
        contents: {
          en: `${senderData.userName} からコメントしました：${messages[0].text}`,
        },
        include_player_ids: sendPushUserIdList,
        data: {
          type: "dailyreportModal",
          page: "AuthenticatedStack",
          screen: "DailyReportDetailModalScreen",
          roomName: `${curRoomName}`,
          dailyreportId: `${dailyReportId}`,
        },
        url: `yonenolinks:///dailyreportModal/"日報レポート"/${
          dailyReportRoute.dailyreportId
        }/${encodeURIComponent(dailyReportRoute.dailyReport) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.needAttentionReport) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.troubleReport) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.excitedReport) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.tomorrowTargetReport) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.totalIncome) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.totalWaste) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.wasteAmountTaiyaki) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.wasteAmountDango) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.wasteAmountTakoyaki) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.wasteAmountOkonomiyaki) ||
          "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.wasteAmountYakisoba) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.dailyreportImage) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.dailyreportDate) || "NO-DATA"
        }/${
          encodeURIComponent(dailyReportRoute.dailyreportCreatedTimestamp) ||
          "-"
        }/${
          encodeURIComponent(dailyReportRoute.dailyreportUpdatedTimestamp) ||
          "-"
        }/${encodeURIComponent(dailyReportRoute.userName) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.userImage) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.hadProfileImg) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.hadReportImg) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.rejiCost) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.weatherStatus) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.clockInTime) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.clockOutTime) || "NO-DATA"
        }/${encodeURIComponent(dailyReportRoute.curRoomId) || "NO-DATA"}/${
          encodeURIComponent(dailyReportRoute.curRoomName) || "NO-DATA"
        }`,
      };
      // console.log(
      //   `yonenolinks:///dailyreportModal/"日報レポート"/${
      //     dailyReportRoute.dailyreportId
      //   }/${encodeURIComponent(dailyReportRoute.dailyReport) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.needAttentionReport) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.troubleReport) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.excitedReport) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.tomorrowTargetReport) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.totalIncome) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.totalWaste) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.wasteAmountTaiyaki) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.wasteAmountDango) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.wasteAmountTakoyaki) || "NO-DATA"
      //   }/${
      //     encodeURIComponent(dailyReportRoute.wasteAmountOkonomiyaki) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.wasteAmountYakisoba) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.dailyreportImage) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.dailyreportDate) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.dailyreportCreatedTimestamp) ||
      //     "-"
      //   }/${
      //     encodeURIComponent(dailyReportRoute.dailyreportUpdatedTimestamp) ||
      //     "-"
      //   }/${encodeURIComponent(dailyReportRoute.userName) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.userImage) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.hadProfileImg) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.hadReportImg) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.rejiCost) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.weatherStatus) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.clockInTime) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.clockOutTime) || "NO-DATA"
      //   }/${encodeURIComponent(dailyReportRoute.curRoomId) || "NO-DATA"}/${
      //     encodeURIComponent(dailyReportRoute.curRoomName) || "NO-DATA"
      //   }`
      // );
      // console.log(messages[0].text);
      const jsonString = JSON.stringify(notificationObj);
      OneSignal.postNotification(
        jsonString,
        (success) => {
          console.log("Success:", success);
        },
        (error) => {
          console.log("Error:", error);
        }
      );
    }
  }
  const gotoComment = () => {
    // textInputRef.current.focus();
    // Keyboard.dismiss();
    console.log("gotoComment");
    navigation.navigate("DailyReportCommentScreen", {
      params: {
        titleName: "日報コメント",
        dailyReportId: dailyReportId,
        assignedUser: assignedUser,
        curRoomName: curRoomName,
        dailyreportDate: dailyreportDate,
        dailyReportRoute: dailyReportRoute,
      },
    });
  };

  return (
    <>
      {selectedUser ? (
        <GiftedChat
          messages={messages}
          showUserAvatar={true}
          // showAvatarForEveryMessage={false}
          textInputStyle={styles.textInputStyle}
          renderUsernameOnMessage={true}
          onSend={(messages) => {
            onSend(messages);
            // addUnread(dailyReportId);
            addCommentNums(dailyReportId);
            sendPush(messages);
            // updateLastestMsg(dailyReportId, messages);
          }}
          user={{
            _id: selectedUser.userId,
            name: selectedUser.userName,
            avatar: selectedUser.userImage ? selectedUser.userImage : userImg,
          }}
          placeholder="メッセージを記入する"
          renderBubble={(props) => {
            return (
              <Bubble
                {...props}
                textStyle={{
                  right: {
                    color: "white",
                  },
                  left: {
                    color: "white",
                  },
                }}
                wrapperStyle={{
                  left: {
                    backgroundColor: GlobalStyles.colors.neutral500,
                  },
                  right: {
                    backgroundColor: GlobalStyles.colors.primary600,
                  },
                }}
              />
            );
          }}
          renderSend={(props) => {
            return (
              <Send
                {...props}
                containerStyle={{
                  height: 60,
                  width: 60,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.sendText}>送信</Text>
              </Send>
            );
          }}
          renderComposer={(props) => {
            if (readOnly) {
              return (
                <View style={styles.gotoButton}>
                  {/* <Pressable onPress={gotoComment}>
    <Text style={styles.sendText}>送信</Text></Pressable> */}
                  <Button
                    onPress={gotoComment}
                    style={styles.gotoButton}
                    color={GlobalStyles.colors.primary600}
                    title="コメントを書きます"
                  />
                </View>
              );
            }
          }}
        />
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
}
export default DailyReportComment;
const styles = StyleSheet.create({
  sendText: {
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
  },
  gotoButton: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    fontSize: 16,
    color: GlobalStyles.colors.primary600,
    fontWeight: "bold",
    marginBottom: 15,
  },
  textInputStyle: { marginVertical: 20 },
});
