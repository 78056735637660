import { Text, TextInput, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";

function Input({
  label,
  invalid,
  style,
  textInputConfig,
  styleLabel,
  styleTextInput,
  isRequired = false,
}) {
  let inputStyles = [styles.input];
  if (textInputConfig && textInputConfig.multiline) {
    inputStyles.push(styles.inputMultiline);
  }
  if (invalid) {
    inputStyles.push(styles.invalidInput);
  }
  inputStyles.push(styleTextInput);
  return (
    <View style={[styles.inputContainer, style]}>
      <View style={styles.labelContainer}>
        <Text
          style={[styles.label, invalid && styles.invalidLabel, styleLabel]}
        >
          {" "}
          {label}
        </Text>
        {isRequired && (
          <View style={styles.requiredContainer}>
            <Text style={[styles.requiredLabel]}>*必要</Text>
          </View>
        )}
      </View>
      <TextInput style={inputStyles} {...textInputConfig} />
    </View>
  );
}
export default Input;

const styles = StyleSheet.create({
  inputContainer: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  label: {
    fontSize: 18,
    color: GlobalStyles.colors.neutral0,
    marginBottom: 8,
  },
  input: {
    backgroundColor: GlobalStyles.colors.neutral0,
    color: GlobalStyles.colors.primary700,
    padding: 6,
    borderRadius: 6,
    fontSize: 18,
  },
  inputMultiline: {
    flex: 1,
    height: 100,
    textAlignVertical: "top",
  },
  invalidLabel: {
    color: GlobalStyles.colors.error500,
  },
  invalidInput: {
    backgroundColor: GlobalStyles.colors.error50,
  },
  labelContainer: { flexDirection: "row" },
  requiredContainer: {
    backgroundColor: GlobalStyles.colors.neutral10,
    // alignContent: "center",
    alignSelf: "center",
    // alignItems: "center",
    marginLeft: 8,
    borderRadius: 6,
  },
  requiredLabel: {
    color: GlobalStyles.colors.error500,
    marginHorizontal: 4,
    fontSize: 12,
  },
});
