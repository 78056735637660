import { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Modal,
  Dimensions,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import IconButton from "../component/UI/IconButton";
import { GlobalStyles } from "../constant/styles";
// import Pdf from "react-native-pdf";
// import SQLite from "react-native-sqlite-storage";
import { addPdf, deletePdf, fetchPdfList, updatePdf } from "../util/http";
import StoreManualEditForm from "../component/StoreManual/StoreManualEditForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { AuthContext } from "../store/auth-context";
import NetInfo from "@react-native-community/netinfo";
// import { Link } from "@react-navigation/native";
// import { auth, storage } from "../Firebase";
// import { ref, getDownloadURL } from "firebase/storage";
import PdfItem from "../component/StoreManual/PdfItem";
import { SwipeListView } from "react-native-swipe-list-view";
import { Pressable } from "react-native";
import { verifyDelete } from "../util/verifyDelete";
import { getscaledDimension } from "../util/deviceUiInfo";
import alert from "../util/alertWeb";
import openPdfInNewWindow from "../util/web_util";

function StoreManualScreen({ navigation }) {
  const [pdfList, setPdfList] = useState([]);
  const [isOnline, setIsOnline] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("マニュアル追加");
  const [isManualEditing, setIsManualEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(false);
  const [userRole, setUserRole] = useState();
  const [pdfData, setPdfData] = useState({});
  const { widthScreen, heightScreen } = Dimensions.get("window");
  const [curPdf, setCurPdf] = useState({});
  // pdfData;
  // console.log(userData);
  const { hieghtScale, fontScale } = getscaledDimension();
  const styles = makeStyles(hieghtScale, fontScale * 0.7); // pass in fontScale to the StyleSheet
  const buttonFntSize = 32 * fontScale * 0.7;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    // Check if the device is online
    const handleConnectivityChange = (isConnected) => {
      setIsOnline(isConnected);
    };
    NetInfo.addEventListener(handleConnectivityChange);
    // SQLite.openDatabase(
    //   { name: "mydb.db", location: "default", createFromLocation: "~mydb.db" },
    //   (db) => {
    //     db.transaction((tx) => {
    //       tx.executeSql(
    //         "SELECT * FROM pdfs",
    //         [],
    //         (_, { rows: { _array } }) => {
    //           if (_array?.length > 0) {
    //             setPdfList(_array);
    //           } else {
    //             fetchPdf();
    //           }
    //         },
    //         (_, error) => console.log(error)
    //       );
    //     });
    //   },
    //   (error) => console.log(error)
    // );

    async function fetchRole() {
      const storedRole = await AsyncStorage.getItem("role");
      setUserRole(storedRole);
      switch (storedRole) {
        case "admin":
          setIsAdmin(true);
          break;
        case "manager":
          setIsAdmin(true);
          break;
        default:
          setIsAdmin(false);
      }
    }
    fetchRole();
    return () => {
      // SQLite.close();
      // NetInfo.removeEventListener(handleConnectivityChange);
    };
  }, [navigation]);

  useEffect(() => {
    if (isOnline) {
      fetchPdf();
    }
  }, [isOnline]);

  const fetchPdf = async () => {
    try {
      const response = await fetchPdfList();
      savePdfsToDb(response);
    } catch (err) {
      console.log(err);
    }
  };

  const savePdfsToDb = (pdfs) => {
    setPdfList(pdfs);
    console.log("savePdfsToDb");
    // SQLite.openDatabase(
    //   { name: "mydb.db", location: "default", createFromLocation: "~mydb.db" },
    //   (db) => {
    //     db.executeSql(
    //       "CREATE TABLE IF NOT EXISTS pdfs (manual_id INTEGER PRIMARY KEY, manual_name TEXT NULL, manual_detail TEXT NULL, manual_uri TEXT NULL, manual_video_uri TEXT NULL, manual_tag INT NULL DEFAULT 0, manual_edited_timestamp TIMESTAMP NULL)",
    //       [],
    //       () => {
    //         console.log("pdfs table created!");
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //     db.transaction((tx) => {
    //       pdfs.forEach((pdf) => {
    //         // console.log("savePdfsToDb forEach");
    //         // console.log(pdf);
    //         tx.executeSql(
    //           "INSERT INTO pdfs (manual_id,manual_name, manual_detail, manual_uri,manual_video_uri,manual_tag,manual_edited_timestamp) VALUES (?, ?, ?, ?, ?, ?, ?)",
    //           [
    //             pdf.manualId,
    //             pdf.manualName,
    //             pdf.manualDetail,
    //             pdf.manualUri,
    //             pdf.manualVideoUri,
    //             pdf.manualTag,
    //             pdf.manualEditedTimeStamp,
    //           ],
    //           (_, result) => {
    //             console.log("savePdfsToDbResult1");
    //             console.log(result);
    //             console.log("savePdfsToDbResult2");
    //           },
    //           (_, error) => console.log(error)
    //         );
    //       });
    //     });
    //   },
    //   (error) => console.log(error)
    // );
  };

  const renderItem = ({ item }) => {
    // console.log(item);
    return (
      <View>
        <Pressable
          style={({ pressed }) => [pressed && styles.pressed]}
          onPress={() => handlePdfPress(item)}
        >
          <PdfItem item={item} />
        </Pressable>
        {/* {isEditMode &&(
        <View>
        <PdfItem  item ={item} editingMode={isEditMode}/>
        <View>
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={editManualPressHandler(item)}
          />
          <IconButton
            icon="trash"
            color={GlobalStyles.colors.primary600}
            size={24}
            // onPress={deleteManualPressHandler(item)}
          />
          </View>
          </View>)} */}
      </View>
    );
  };
  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...pdfList];
    const prevIndex = pdfList.findIndex((item) => item.id === rowKey);
    newData.splice(prevIndex, 1);
    setPdfList(newData);
  };

  const renderHiddenItem = (itemData, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => {
          editManualPressHandler(itemData.item);
          // console.log(itemData);
          closeRow(rowMap, itemData.item.manualId);
        }}
      >
        <Text style={styles.backTextWhite}>編集</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={async () => {
          const confirmDel = await verifyDelete("マニュアルの削除");
          if (confirmDel) {
            deleteManualPressHandler(itemData.item);
            deleteRow(rowMap, itemData.item.manualId);
          }
        }}
      >
        <Text style={styles.backTextWhite}>削除</Text>
      </TouchableOpacity>
    </View>
  );

  const handlePdfPress = async (pdf) => {
    // setPdfModalVisible(!pdfModalVisible);
    setCurPdf(pdf);
    openPdfInNewWindow(pdf.manualUri);
    console.log(pdf.manualUri);
  };

  async function manualEditSubmitHandler(manualData) {
    setModalVisible(!modalVisible);
    if (isManualEditing) {
      try {
        const respponse = await updatePdf(manualData);
        console.log(respponse);
      } catch (err) {
        console.log(err);
      }
      fetchPdf();
    } else {
      try {
        const respponse = await addPdf(manualData);
        console.log(respponse);
      } catch (err) {
        console.log(err);
      }
      fetchPdf();
    }
  }

  function manualEditCancelHandler() {
    setModalVisible(!modalVisible);
  }

  const addManualPressHandler = () => {
    setPdfData();
    setModalVisible(!modalVisible);
    setIsManualEditing(false);
    setModalTitle("マニュアル追加");
  };

  // const editModeManualPressHandler = () => {
  //   setIsEditMode(!isEditMode);
  // };

  const editManualPressHandler = (pdf) => {
    setPdfData(pdf);
    setModalVisible(!modalVisible);
    setIsManualEditing(true);
    setModalTitle("マニュアル編集");
  };

  const deleteManualPressHandler = async (manualData) => {
    try {
      const respponse = await deletePdf(manualData);
      console.log(respponse);
    } catch (err) {
      console.log(err);
    }
    fetchPdf();
  };

  const staffEvalPressHandler = () => {};

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };
  // const fallbackText ="サーバーの問題でマニュアルリストを表示できません　- 後でもう一度試してください";
  let pdfListContent = (
    <Text style={styles.infoText}>
      <Text style={styles.infoTextHeader}>{`サーバーと接続中...`}</Text>
      {`
    
    サーバーの問題が発生する時もありので。一定期間、お知らせが出なかったら後でもう一度試してください`}
    </Text>
  );

  // const handlePress = () => {};
  if (pdfList.length > 0) {
    if (isAdmin) {
      pdfListContent = (
        <SwipeListView
          useFlatList={true}
          data={pdfList}
          renderItem={renderItem}
          keyExtractor={(item) => item.manualId}
          renderHiddenItem={renderHiddenItem}
          rightOpenValue={-150}
          onRowDidOpen={onRowDidOpen}
          disableRightSwipe={true}
          closeOnScroll={true}
        />
      );
    } else {
      pdfListContent = (
        <FlatList
          data={pdfList}
          renderItem={renderItem}
          keyExtractor={(item) => item.manualId}
        />
      );
    }
  }
  return (
    <View>
      <View style={styles.titleContainerIconLeft}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <IconButton
            icon="home"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={() => {
              navigation.goBack();
            }}
            styleButtonContainer={styles.homeIconContainer}
          ></IconButton>
          <View style={styles.iconLeftText}>
            <Text style={styles.textHome}>ホームへ</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.titleContainerIcon}>
        {isAdmin && (
          <IconButton
            icon="add-circle"
            color={GlobalStyles.colors.primary600}
            size={buttonFntSize}
            onPress={addManualPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )}
        {/* {!isAdmin && (
          <IconButton
            icon="settings"
            color={GlobalStyles.colors.primary600}
            size={32}
            onPress={editModeManualPressHandler}
            styleButtonContainer={styles.iconContainer}
          />
        )} */}
        <IconButton
          icon="create"
          color={GlobalStyles.colors.neutral500}
          size={buttonFntSize}
          onPress={staffEvalPressHandler}
          styleButtonContainer={styles.evalIconContainer}
          disabled={true}
        />
      </View>
      <View style={styles.rootContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
            <View style={styles.titleContainerText}>
              <Text style={styles.title}>マニュアル</Text>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          {pdfListContent}

          {modalVisible && (
            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                alert("ページが閉じる");
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={styles.modalTitleContainer}>
                    <Text style={styles.titleText}>{modalTitle}</Text>
                  </View>
                  <View>
                    <StoreManualEditForm
                      onSubmit={manualEditSubmitHandler}
                      onCancel={manualEditCancelHandler}
                      defaultValues={pdfData}
                      userRole={userRole}
                    />
                  </View>
                </View>
              </View>
            </Modal>
          )}
        </View>
      </View>
      {/* {pdfModalVisible && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={pdfModalVisible}
          onRequestClose={() => {
            alert("PDFページが閉じる");
            setModalVisible(!pdfModalVisible);
          }}
        >
          <View style={styles.pdfCenteredView}>
            <View style={styles.pdfModalView}>
              <View style={styles.modalTopContainer}>
                <View style={styles.modalCloseContainer}>
                  <IconButton
                    icon="close"
                    color={GlobalStyles.colors.primary600}
                    size={24*fontScale}
                    onPress={() => {
                      closePdfModal();
                    }}
                    styleButtonContainer={styles.pdfCloseIconContainer}
                  ></IconButton>
                </View>
                <View style={styles.pdfModalTitleContainer}>
                  <Text style={styles.titleText}>{curPdf.manualName}</Text>
                </View>
              </View>
              <View>
                  <Document file={{ uri: curPdf.manualUri, cache: true }} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
              </View>
            </View>
          </View>
        </Modal>
      )} */}
    </View>
  );
}

export default StoreManualScreen;

const makeStyles = (hieghtScale, fontScale) =>
  StyleSheet.create({
    rootContainer: {
      flex: 1,
      backgroundColor: GlobalStyles.colors.neutral10,
      marginHorizontal: "3%",
      marginBottom: 50,
    },
    headerContainer: {
      // marginTop: "5%",
      flex: 1 / hieghtScale,
      flexDirection: "row",
    },
    contentContainer: {
      flex: 10,
    },
    pdfContainer: {
      flex: 1,
      // justifyContent: "flex-start",
      // alignItems: "center",
      // marginTop: 25,
      // position: "absolute",
      // zIndex: 10,
    },
    pdf: {
      // flex: 1,
      width: Dimensions.get("window").width,
      // height: "80%",
      height: Dimensions.get("window").height * 0.8,
    },
    subMenuContainer: {
      flex: 2,
      // marginBottom: 50,
    },
    titleContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    titleContainerIcon: {
      flexDirection: "row",
      position: "absolute",
      marginTop: "5%",
      right: "2%",
      zIndex: 5,
    },
    titleContainerIconLeft: {
      position: "absolute",
      marginTop: "5%",
      left: "4%",
      zIndex: 5,
    },
    closePdfModalContainer: {
      // position: "absolute",
    },
    iconContainer: {
      marginHorizontal: 1,
      paddingLeft: 1,
      paddingRight: 1,
    },
    evalIconContainer: {
      marginHorizontal: 2,
      marginRight: 4,
    },
    homeIconContainer: {
      marginHorizontal: 2,
      marginRight: 4,
      paddingBottom: 0,
    },
    menuRowContainer: {
      flex: 1,
      flexDirection: "row",
    },
    buttonContainer: {
      marginHorizontal: "2.5%",
      width: "45%",
      height: "100%",
    },
    subbuttonContainer: {
      marginVertical: 10,
      width: "95%",
      alignSelf: "center",
    },
    button: {
      marginTop: "5%",
      height: "95%",
    },
    buttonDisabled: {
      opacity: 0.75,
      backgroundColor: GlobalStyles.colors.neutral400,
    },
    subbutton: {
      minWidth: 341,
      height: 65,
    },
    titleContainerText: {},
    title: {
      fontSize: 32 * fontScale,
      marginBottom: 4,
      color: GlobalStyles.colors.primary600,
      fontWeight: "bold",
    },
    buttonText: {
      marginHorizontal: 16,
      fontSize: 26 * fontScale,
    },
    subbuttonText: {
      fontSize: 26 * fontScale,
    },
    notificationReportBadge: {
      position: "absolute",
      right: "35%",
      top: "7%",
      width: 35,
      height: 35,
      borderRadius: 100,
      backgroundColor: GlobalStyles.colors.error400,
      alignItems: "center",
      justifyContent: "center",
      // alignSelf: "center",
      marginRight: 4,
      zIndex: 100,
    },
    textReport: {
      color: GlobalStyles.colors.neutral0,
      fontSize: 16 * fontScale,
    },
    textHome: {
      color: GlobalStyles.colors.primary600,
      fontSize: 16 * fontScale,
    },
    notificationChatBadge: {
      position: "absolute",
      right: "5%",
      top: "7%",
      width: 35,
      height: 35,
      borderRadius: 100,
      backgroundColor: GlobalStyles.colors.error400,
      alignItems: "center",
      justifyContent: "center",
      // alignSelf: "center",
      marginRight: 4,
      zIndex: 100,
    },
    textChat: {
      color: GlobalStyles.colors.neutral0,
      fontSize: 16 * fontScale,
    },
    centeredView: {
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      marginTop: "10%",
      backgroundColor: GlobalStyles.colors.neutral10,
      borderRadius: 20,
      padding: 10,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalTopContainer: {
      flexDirection: "row",
    },
    modalTitleContainer: {
      // backgroundColor: GlobalStyles.colors.neutral200,
      marginTop: 24,
      alignContent: "center",
      alignItems: "center",
    },
    pdfModalTitleContainer: {
      // backgroundColor: GlobalStyles.colors.neutral200,
      alignContent: "center",
      alignItems: "center",
      flex: 20,
      marginVertical: 10,
    },
    modalCloseContainer: {
      alignContent: "center",
      alignItems: "center",
      flex: 1,
    },
    pdfCloseIconContainer: {
      marginHorizontal: 0,
      marginVertical: 10,
      padding: 0,
      alignContent: "center",
      alignItems: "center",
    },
    pdfCenteredView: {
      justifyContent: "center",
      alignItems: "center",
      // marginTop: 22,
    },
    pdfModalView: {
      margin: 20,
      marginTop: "15%",
      backgroundColor: GlobalStyles.colors.neutral10,
      borderRadius: 20,
      paddingVertical: 10,
      paddingHorizontal: 5,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    titleText: {
      fontSize: 20 * fontScale,
      fontWeight: "bold",
      marginBottom: 8,
      textAlign: "center",
      color: GlobalStyles.colors.primary600,
    },
    pdfContainer: {
      marginHorizontal: "5%",
      marginBottom: 15,
      backgroundColor: GlobalStyles.colors.neutral50,
      borderRadius: 10,
    },
    rowBack: {
      backgroundColor: GlobalStyles.colors.neutral400,
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      marginHorizontal: "5%",
      marginBottom: 15,
      borderRadius: 10,
    },
    backRightBtn: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: 75,
    },
    backRightBtnLeft: {
      backgroundColor: GlobalStyles.colors.neutral400,
    },
    backRightBtnRight: {
      backgroundColor: GlobalStyles.colors.primary700,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    backTextWhite: {
      color: GlobalStyles.colors.neutral10,
      fontSize: 16 * fontScale,
    },
    pressed: {
      opacity: 0.9,
    },
    // infoText: {
    //   color: GlobalStyles.colors.primary600,
    //   fontSize: 22*fontScale,
    //   textAlign: "center",
    //   marginTop: 32,
    // },
    infoText: {
      color: GlobalStyles.colors.primary600,
      textAlign: "center",
    },
    infoTextHeader: {
      color: GlobalStyles.colors.primary600,
      fontSize: 20 * fontScale,
      fontWeight: "600",
    },
  });
