import { Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import ChatRoomsList from "./RoomList";

function ChatRoomsOutput({ chatRooms, chatsPeriod, fallbackText }) {
  let content = <Text style={styles.infoText}>{fallbackText}</Text>;

  if (chatRooms.length > 0 && chatRooms) {
    content = <ChatRoomsList chatRooms={chatRooms} />;
  }
  return <View style={styles.container}>{content}</View>;
}

export default ChatRoomsOutput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    flexDirection: "column-reverse",
  },
  infoText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    marginTop: 32,
  },
});
