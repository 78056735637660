import { StyleSheet, View } from "react-native";
import TaskComment from "../component/TaskManager/TaskComment";

function TaskManagerCommentScreen({ navigation, route }) {
  const task = route.params.params;
  // console.log(task.dailyReportId);
  return (
    <View style={styles.taskCommentContainer}>
      <TaskComment
        taskId={task.dailyReportId}
        assignedUser={task.assignedUser}
      />
      {/* <TaskComment taskId={task.taskId} assignedUser={assignedUser}/> */}
    </View>
  );
}
export default TaskManagerCommentScreen;

const styles = StyleSheet.create({
  taskCommentContainer: { flex: 1 },
});
