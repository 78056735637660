import { createContext, useReducer } from "react";

export const TroubleReportContext = createContext({
  troubleReports: [],
  addTroubleReport: (
    id,
    {
      troubleReportId,
      problemId,
      reportData,
      imgUri,
      videoUri,
      processSteps,
      userName,
      userImage,
      customerScore,
      staffScore,
    }
  ) => {},
  setTroubleReport: (troubleReports) => {},
  deleteTroubleReport: (id) => {},
  updateTroubleReport: (
    id,
    {
      troubleReportId,
      problemId,
      reportData,
      imgUri,
      videoUri,
      processSteps,
      userName,
      userImage,
      customerScore,
      staffScore,
    }
  ) => {},
});

function troubleReportsReducer(state, action) {
  switch (action.type) {
    case "ADD":
      // const id = new Date().toString() + Math.random().toString();
      return [action.payload, ...state];
    case "SET":
      // console.log(state);
      const inverted = action.payload.reverse();
      return inverted;
    case "UPDATE":
      const updatableTroubleReportIndex = state.findIndex(
        (troublereport) =>
          troublereport.troublereportRoomId ===
          action.payload.troublereportRoomId
      );

      // console.log(updatableDailyReportIndex);
      const updatableTroubleReport = state[updatableTroubleReportIndex];
      const updatedItem = { ...updatableTroubleReport, ...action.payload };
      const updatedtroubleReports = [...state];
      updatedtroubleReports[updatableTroubleReportIndex] = updatedItem;
      return updatedtroubleReports;
    case "DELETE":
      return state.filter(
        (troublereport) => troublereport.id !== action.payload
      );
    default:
      return state;
  }
}

function TroubleReportContextProvider({ children }) {
  const [troubleReportsState, dispatch] = useReducer(troubleReportsReducer, []);

  function addTroubleReport(troubleReportData) {
    dispatch({ type: "ADD", payload: troubleReportData });
  }
  function setTroubleReport(troubleReports) {
    dispatch({ type: "SET", payload: troubleReports });
  }
  function deleteTroubleReport(id) {
    dispatch({ type: "DELETE", payload: id });
  }
  function updateTroubleReport(id, troubleReportData) {
    dispatch({ type: "UPDATE", payload: { id: id, data: troubleReportData } });
  }

  const value = {
    troubleReports: troubleReportsState,
    setTroubleReport: setTroubleReport,
    addTroubleReport: addTroubleReport,
    deleteTroubleReport: deleteTroubleReport,
    updateTroubleReport: updateTroubleReport,
  };

  return (
    <TroubleReportContext.Provider value={value}>
      {children}
    </TroubleReportContext.Provider>
  );
}
export default TroubleReportContextProvider;
