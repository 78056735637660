import { FlatList, StyleSheet, Text, View } from "react-native";
import DailyReportRoom from "./Room";
import { SwipeListView } from "react-native-swipe-list-view";
import { GlobalStyles } from "../../constant/styles";
import { deleteDailyReportRooms } from "../../util/http";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TouchableOpacity } from "react-native-gesture-handler";
import { verifyDelete } from "../../util/verifyDelete";
function renderDailyReportRoomsItem(itemData) {
  // console.log(itemData.item);
  return <DailyReportRoom {...itemData.item} />;
}

function DailyReportRoomsList({ dailyReportRooms }) {
  const [isAdmin, setIsAdmin] = useState(true);
  async function fetchRole() {
    const storedRole = await AsyncStorage.getItem("role");
    switch (storedRole) {
      case "admin":
        setIsAdmin(true);
        break;
      case "manager":
        setIsAdmin(true);
        break;
      default:
        setIsAdmin(false);
    }
  }

  fetchRole();

  function sortData() {
    dailyReportRooms.sort((room_a,room_b) => {
      return room_b.priority - room_a.priority||(new Date(room_a.latestUpdateTimestamp) < new Date(room_b.latestUpdateTimestamp) ? 1 : -1) ;
    }
    );

  }
  sortData();
  const navigation = useNavigation();
  const [roomList, setRoomList] = useState(dailyReportRooms);
  const onRowDidOpen = (rowKey) => {};
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };
  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...roomList];
    const prevIndex = roomList.findIndex((item) => item.id === rowKey);
    newData.splice(prevIndex, 1);
    setRoomList(newData);
  };

  const renderHiddenItem = (itemData, rowMap) => {
    // fetchRole();
    // console.log(isAdmin);
    return (
      <View style={styles.rowBack}>
        <TouchableOpacity
          style={[styles.backRightBtn, styles.backRightBtnLeft]}
          onPress={() => {
            navigation.navigate("DailyReportFormStack", {
              screen: "ManageDailyReportRoom",
              params: {
                id: itemData.item.id,
                roomId: itemData.item.roomId,
                roomName: itemData.item.roomName,
              },
            });
            closeRow(rowMap, itemData.item.id);
          }}
        >
          <Text style={styles.backTextWhite}>編集</Text>
        </TouchableOpacity>
        {isAdmin && (
          <TouchableOpacity
            style={[styles.backRightBtn, styles.backRightBtnRight]}
            onPress={async () => {
              const confirmDel = await verifyDelete("日報ルームの削除");
              if (confirmDel) {
                // console.log("room deleted");
                deleteDailyReportRooms(itemData.item.roomId);
                deleteRow(rowMap, itemData.item.id);
              }
            }}
          >
            <Text style={styles.backTextWhite}>削除</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };


  return (
    <>
      {/* {isAdmin && ( */}

      <SwipeListView
        useFlatList={true}
        data={roomList}
        renderItem={renderDailyReportRoomsItem}
        keyExtractor={(item) => item.id}
        renderHiddenItem={renderHiddenItem}
        rightOpenValue={-150}
        onRowDidOpen={onRowDidOpen}
        disableRightSwipe={true}
        closeOnScroll={true}
        initialNumToRender={10}
      />

      {/* )} */}
      {/* {!isAdmin && (
        <FlatList
          data={roomList}
          renderItem={renderDailyReportRoomsItem}
          keyExtractor={(item) => item.id}
          initialNumToRender={10}
        />
      )} */}
    </>
  );
}
export default DailyReportRoomsList;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  backTextWhite: {
    color: GlobalStyles.colors.neutral10,
    fontSize: 16,
  },
  rowFront: {
    alignItems: "center",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    height: 50,
  },
  rowBack: {
    backgroundColor: GlobalStyles.colors.neutral400,
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  backRightBtn: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: GlobalStyles.colors.neutral300,
  },
  backRightBtnRight: {
    backgroundColor: GlobalStyles.colors.primary700,
  },
});
