export const handleWebDownload = async ( fileUrl, filename) => {
    try {
        // console.log(fileUrl);
        // console.log(filename);
        const response = await fetch(fileUrl);
        const blob = await response.blob();
    if (window.showSaveFilePicker) {
    const fileHandle = await window.showSaveFilePicker({
        suggestedName: filename,
        types: [{
        description: 'Image file',
        accept: {'image/png': ['.png']}
        }],
    });
    const writable = await fileHandle.createWritable();
    await writable.write(blob);
    await writable.close();
    alert('File saved successfully!');}
    else {
        // Function to convert an image Blob from JPEG to PNG
        function convertJpegToPng(jpegBlob, callback) {
            // Create a new Image element
            const img = new Image();
            img.onload = function() {
                // Create a canvas
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                // Draw the image onto the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // Convert the canvas to a PNG blob
                canvas.toBlob(function(pngBlob) {
                    callback(pngBlob);  // Return the new PNG blob
                }, 'image/png');
            };

            // Create a URL for the JPEG blob and set it as the image source
            img.src = URL.createObjectURL(jpegBlob);
        }

        // Example usage:
        // Assuming `jpegBlob` is your original Blob of MIME type image/jpeg
        convertJpegToPng(blob, function(pngBlob) {
            // console.log('Converted PNG Blob MIME type:', pngBlob.type);

            // Now you can download the PNG Blob or use it as needed
            const url = URL.createObjectURL(pngBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 100);

            alert('PNG file has been created and download initiated!');
        });
      }
    } catch (error) {
    console.error('Error saving the file:', error);
    alert('Failed to save file.');
    }
  };

export default  function openPdfInNewWindow(pdfUrl) {
    window.open(pdfUrl, '_blank');
}