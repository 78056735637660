import { Pressable, View, Text, StyleSheet, Image, Alert } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import { getFormattedDate } from "../../util/date";
import { useNavigation } from "@react-navigation/native";
import Button from "../UI/Button";
import { useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { useState } from "react";
import Checkbox from "expo-checkbox";

function UserItem({
  userId,
  userName,
  userImage,
  onCheck,
  defaultUser,
  isMemberlist,
}) {
  const hadProfileImg = userImage !== null ? true : false;
  // const authCtx = useContext(AuthContext);

  const [inputs, setInput] = useState({
    selectedUserId: userId,
    isSelected: defaultUser?.userId?.includes(userId) ? true : false,
    addOrDelete: defaultUser?.userId?.includes(userId) ? true : false,
  });
  // const navigation = useNavigation();

  function inputChangedHandler(inputIdentifier, enteredValue) {
    setInput((curInputValues) => {
      return {
        ...curInputValues,
        [inputIdentifier]: enteredValue,
      };
    });
  }
  function userPressHandler() {
    setInput((currentState) => {
      return { ...currentState, isSelected: !inputs.isSelected };
    }); //necessary to create a new object because else the hook won't be updated
    const userData = {
      selectedUserId: inputs.selectedUserId,
      isSelected: !inputs.isSelected,
    };
    onCheck(userData);
  }

  return (
    <Pressable
      onPress={userPressHandler}
      style={({ pressed }) => pressed && styles.pressed}
    >
      <View style={styles.userListItem}>
        <View style={styles.profilePictureContainer}>
          <View style={styles.profilePicture}>
            {hadProfileImg && (
              <Image
                style={styles.profileImg}
                source={userImage ? { uri: `${userImage}` } : null}
              />
            )}
            {!hadProfileImg && userName && (
              <Text style={styles.textProfile}>{`${userName
                .charAt(0)
                .toUpperCase()}`}</Text>
            )}
          </View>
        </View>
        <View style={styles.userNameContainer}>
          <Text style={[styles.textBase, styles.description]}>{userName}</Text>
        </View>
        {!isMemberlist && (
          <Checkbox
            value={inputs.isSelected}
            onValueChange={userPressHandler}
            style={styles.checkbox}
            color={GlobalStyles.colors.primary600}
          />
        )}
      </View>
    </Pressable>
  );
}

export default UserItem;

const styles = StyleSheet.create({
  pressed: {
    opacity: 0.7,
  },
  userListItem: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: GlobalStyles.colors.neutral10,
    justifyContent: "space-between",
    borderBottomWidth: 0.25,
    borderTopWidth: 0.25,
    borderColor: GlobalStyles.colors.primary1000,
    flexDirection: "row",
    borderRadius: 6,
  },
  textBase: {
    marginLeft: 6,
    color: GlobalStyles.colors.primary600,
  },
  description: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: "bold",
  },

  checkbox: {
    alignSelf: "center",
    borderRadius: 6,
  },
  profilePictureContainer: {
    flex: 1,
    justifyContent: "center",
  },
  userNameContainer: {
    marginLeft: 16,
    flex: 8,
    alignSelf: "center",
  },
  profilePicture: {
    width: 36,
    height: 36,
    borderRadius: 100,
    backgroundColor: GlobalStyles.colors.primary600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 16,
  },
  profileImg: {
    width: 36,
    height: 36,
    borderRadius: 1000,
  },
});
