import { useEffect } from "react";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import DocumentPicker from "react-native-document-picker";
import { GlobalStyles } from "../../constant/styles";
// import Input from "../Auth/Input";
import Input from "./../DailyReport/Input";
import Button from "../UI/Button";
import * as Progress from "react-native-progress";
import firebase from "firebase/app";
import "firebase/storage";
import { storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";
import ImagePicker from "../DailyReport/ImagePicker";
import InputBoard from "./InputBoard";
import AsyncStorage from "@react-native-async-storage/async-storage";

function NewsEditForm({ onSubmit, onCancel, defaultValues, userRole }) {
  // let hadProfileImg = defaultValues.userImage ? true : false;
  const [progress, setProgress] = useState(0);
  const [UID, setUID] = useState("");
  const [isUpload, setIsUpload] = useState(false);

  const [progressStatus, setProgressStatus] = useState(
    "しばらくお待ちください、アップロードしています。。。"
  );
  const [enteredNewsId, setEnteredNewsId] = useState(
    defaultValues ? defaultValues.newsId : ""
  );
  const [enteredNewsTitle, setEnteredNewsTitle] = useState(
    defaultValues ? defaultValues.newsTitle : ""
  );
  const [enteredNewsDescription, setEnteredNewsDescription] = useState(
    defaultValues ? defaultValues.newsDescription : ""
  );

  const [enteredNewsUriImage, setEnteredNewsUriImage] = useState(
    defaultValues ? defaultValues.newsUriImage : ""
  );
  const [enteredNewsUri, setEnteredNewsUri] = useState(
    defaultValues ? defaultValues.newsUri : ""
  );
  const [enteredNewsTag, setEnteredNewsTag] = useState(
    defaultValues ? defaultValues.newsTag : ""
  );
  useEffect(() => {}, []);
  useEffect(() => {
    async function fetchRole() {
      const uid = await AsyncStorage.getItem("uid");
      setUID(uid);
    }
    fetchRole();
  }, []);
  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case "newsTitle":
        setEnteredNewsTitle(enteredValue);
        break;
      case "newsDescription":
        setEnteredNewsDescription(enteredValue);
        break;
      case "newsUriImage":
        setEnteredNewsUriImage(enteredValue);
        break;
      case "newsUri":
        setEnteredNewsUri(enteredValue);
        break;
      case "newsTag":
        setEnteredNewsTag(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      newsId: enteredNewsId,
      newsTitle: enteredNewsTitle,
      newsDescription: enteredNewsDescription,
      newsUriImage: enteredNewsUriImage,
      newsUri: enteredNewsUri,
      newsTag: enteredNewsTag,
    });
  }

  const handleUpload = async (result) => {
    // console.log("result");
    // console.log(result);
    try {
      setIsUpload(true);
      setProgress(0);
      setProgressStatus("しばらくお待ちください、アップロードしています。。。");
      // Open the document picker
      // const result = await DocumentPicker.pick({
      //   type: [DocumentPicker.types.pdf],
      // });

      // console.log("result[0]");
      // console.log(result[0]);
      // console.log(storage);
      // Get a reference to the PDF file in Firebase Storage
      // const pdfRef = firebase.storage().ref("pdfs").child(result[0].name);
      // Create a reference to 'mountains.jpg'
      const imageUri = result.uri.split("/");
      const lastImageUri = imageUri[imageUri.length - 1];
      const newsRef = ref(storage, `news_picture/UID_${UID}_${lastImageUri}`);

      // Create a File object from the selected file
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function () {
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", result.uri, true);
        xhr.send(null);
      });
      // const file = await RNFS.readFile(result[0].uri, 'base64');
      // Fetch the file and get the data as a blob
      // const response = await fetch(result.uri);
      // const blob = await response.blob();

      // Create a File object from the blob
      // const file = new File([blob], `UID_${UID}_${lastImageUri}`, {
      //   type: "image/jpeg",
      // });
      // console.log("file");
      // console.log(file);

      // Get metadata properties
      getMetadata(newsRef)
        .then((metadata) => {
          // Metadata now contains the metadata for 'images/forest.jpg'
          // console.log(metadata);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });

      const metadata = {
        contentType: "image/jpeg",
      };

      // Create a task to upload the PDF file
      const task = uploadBytesResumable(newsRef, blob, metadata);
      // console.log("task");
      // console.log(task);
      // Monitor the task's progress
      task.on(
        "state_changed",
        (snapshot) => {
          // Get the task progress, including the number of bytes uploaded and the total number of bytes
          // console.log(snapshot);
          // const progress =  Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
          // console.log(`Upload is  ${progress} % done`);
          // setProgress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(task.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            setEnteredNewsUriImage(downloadURL);
          });
          setProgress(1);
          setProgressStatus("アップロードが完了しました。");
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <View>
        <View>
          <InputBoard
            // label="お知らせの件名"
            // onUpdateValue={updateInputValueHandler.bind(this, "newsTitle")}
            // value={enteredNewsTitle}
            // keyboardType="default"
            // placeholderValue="【公開】名古屋で新店舗開店について"
            label="お知らせの件名"
            textInputConfig={{
              onChangeText: updateInputValueHandler.bind(this, "newsTitle"),
              value: enteredNewsTitle,
              color: GlobalStyles.colors.primary600,
              placeholder: "【公開】名古屋で新店舗開店について",
              keyboardType: "default",
              borderRadius: 10,
              backgroundColor: GlobalStyles.colors.neutral0,
              borderColor: GlobalStyles.colors.primary600,
              borderWidth: 0.4,
              selectionColor: GlobalStyles.colors.primary600,
            }}
            // styleTextInput={{
            //   height: 270,
            // }}
            // onUpda
          />
        </View>
        <View>
          <InputBoard
            label="お知らせの内容"
            textInputConfig={{
              multiline: true,
              onChangeText: updateInputValueHandler.bind(
                this,
                "newsDescription"
              ),
              value: enteredNewsDescription,
              color: GlobalStyles.colors.primary600,
              placeholder: "お知らせ...",
              keyboardType: "default",
              borderRadius: 10,
              backgroundColor: GlobalStyles.colors.neutral0,
              borderColor: GlobalStyles.colors.primary600,
              borderWidth: 0.4,
              selectionColor: GlobalStyles.colors.primary600,
            }}
            styleTextInput={{
              height: 270,
            }}
            // onUpdateValue={updateInputValueHandler.bind(this, "newsDescription")}
            // value={enteredNewsDescription}

            // styleText
          />
        </View>
        <View>
          <InputBoard
            // label="お知らせの動画リング"
            // onUpdateValue={updateInputValueHandler.bind(this, "newsUri")}
            // value={enteredNewsUri}
            // value={enteredNewsTag}
            // keyboardType="default"
            // placeholderValue="お知らせの動画リング"
            label="お知らせの参照リング"
            textInputConfig={{
              onChangeText: updateInputValueHandler.bind(this, "newsUri"),
              value: enteredNewsUri,
              color: GlobalStyles.colors.primary600,
              placeholder: "https://www.google.co.jp",
              keyboardType: "default",
              borderRadius: 10,
              backgroundColor: GlobalStyles.colors.neutral0,
              borderColor: GlobalStyles.colors.primary600,
              borderWidth: 0.4,
              selectionColor: GlobalStyles.colors.primary600,
            }}
          />
        </View>
        <View>
          <InputBoard
            // label="お知らせのタッグ"
            // onUpdateValue={updateInputValueHandler.bind(this, "newsTag")}
            // value={enteredNewsTag}
            // value={enteredNewsUri}
            // keyboardType="number-pad"
            // placeholderValue="お知らせのタッグ"
            label="お知らせのタッグ"
            textInputConfig={{
              onChangeText: updateInputValueHandler.bind(this, "newsTag"),
              value: enteredNewsTag,
              color: GlobalStyles.colors.primary600,
              placeholder: "例：0　(記入しなくても大丈夫です)",
              keyboardType: "number-pad",
              borderRadius: 10,
              backgroundColor: GlobalStyles.colors.neutral0,
              borderColor: GlobalStyles.colors.primary600,
              borderWidth: 0.4,
              selectionColor: GlobalStyles.colors.primary600,
            }}
            isOptional={true}
          />
          <Text style={styles.remarkText}>
            ＊お知らせのタッグは優先度を設定します（優先度は値が小さいほど優先順位が高くなります）
          </Text>
        </View>

        <View>
          <View style={styles.uploadContainer}>
            <ImagePicker
              textInput={"お知らせの画像を選択してください"}
              onTakenImage={handleUpload}
              userPreview={false}
              imageQuality={0.1}
            />
            {/* <Button
              title="お知らせの画像選択"
              onPress={handleUpload}
              styleButton={{
                backgroundColor: GlobalStyles.colors.primary700,
              }}
            >
              お知らせの画像を選択してください
            </Button> */}
          </View>
          {isUpload && (
            <View style={styles.progressBarContainer}>
              <Text style={[styles.progressText, styles.textBase]}>
                {progressStatus}
              </Text>
              <Progress.Bar
                progress={progress}
                width={300}
                color={"rgba(251, 254, 238, 1)"}
              />
            </View>
          )}
        </View>
        <View style={styles.buttons}>
          <Button
            style={styles.button}
            mode="flat"
            onPress={onCancel}
            styleText={[styles.buttonText, styles.textBase]}
          >
            キャンセル
          </Button>
          <Button
            style={styles.button}
            onPress={submitHandler}
            styleButton={{
              backgroundColor: GlobalStyles.colors.primary700,
            }}
            styleText={styles.buttonText}
          >
            {"保存"}
          </Button>
        </View>
      </View>
    </>
  );
}

export default NewsEditForm;

const styles = StyleSheet.create({
  form: {},
  container: {
    padding: 16,
    minWidth: "80%",
  },
  dropdown: {
    height: 50,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },

  textBase: { color: GlobalStyles.colors.primary600 },
  textProfile: {
    color: GlobalStyles.colors.neutral0,
    fontSize: 36,
  },
  profileImg: {
    width: 120,
    height: 120,
    borderRadius: 1000,
  },
  remarkText: {
    color: GlobalStyles.colors.error600,
    fontSize: 9,
  },
  dropdownText: {
    color: GlobalStyles.colors.primary600,
    fontSize: 14,
    marginBottom: 6,
  },
  buttons: {
    marginVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: GlobalStyles.colors.neutral100,
  },
  button: {
    minWidth: 120,
    marginHorizontal: 18,
  },

  buttonText: {
    fontSize: 16,
  },
  progressText: {
    fontSize: 12,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 100,
  },
  uploadContainer: { marginVertical: "2%" },
  progressBarContainer: { alignItems: "center" },
});
