import { Alert } from "react-native";

export async function verifyDelete(alertTitle) {
  return new Promise((resolve, reject) => {
    Alert.alert(
      alertTitle,
      "この項目を完全に削除しますか？",
      [
        {
          text: "いいえ",
          onPress: () => resolve(false), // User pressed cancel
          style: "cancel",
        },
        {
          text: "はい",
          onPress: () => resolve(true), // User pressed OK
        },
      ],
      { cancelable: false }
    );
  });
}
