import { Text, View, StyleSheet } from "react-native";
import { GlobalStyles } from "../../constant/styles";
import UserAuthList from "./UserAuthList";

function UserAuthOutput({
  userAuths,
  fallbackText,
  onSetting,
  styleContainer,
}) {
  let content = <Text style={styles.infoText}>{fallbackText}</Text>;

  if (userAuths.length > 0) {
    content = <UserAuthList userAuths={userAuths} onSetting={onSetting} />;
  }
  return <View style={[styles.container, styleContainer]}>{content}</View>;
}

export default UserAuthOutput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.colors.neutral10,
    flexDirection: "column-reverse",
    marginTop: 18,
    borderRadius: 6,
  },
  infoText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    marginTop: 32,
  },
});
